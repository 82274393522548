import React, { useEffect, useMemo, useState } from 'react';
import { Button, DatePicker, Select, Table } from 'antd';
import {
  EVERY_MINUTE,
  fetchAssets,
  FREQUENCIES,
  listMarketHistory,
  selectors,
} from 'state';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import Label from './Label';
import { ReloadOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const columnOptions = [
  'id',
  'backup',
  'timestamp',
  'underlying_asset',
  'frequency',
  'open',
  'high',
  'low',
  'close',
  'volume',
  'bid',
  'ask',
  'duration',
  'bpv',
  'ask_yield',
  'bid_yield',
  'accrued_interest',
  'accrued_days',
  'coupon_date',
  'coupon_rate',
];

const HistoryTable: React.FC = () => {
  const dispatch = useDispatch();
  const { items: underlyingAssets, fetched } = useSelector(
    selectors.underlyingAssetsApi
  );
  const { waiting } = useSelector(selectors.marketHistoryAPI);
  const marketHistoryList = useSelector(selectors.marketHistoryList);

  const [filters, setFilters] = useState<{
    frequency?: number;
    underlying_asset?: number;
    timestamp_range?: Moment[];
  }>({ frequency: EVERY_MINUTE });
  const [selectedColumns, setSelectedColumns] = useState([
    'id',
    'timestamp',
    'bid',
    'ask',
  ]);

  useEffect(() => {
    if (!fetched) {
      dispatch(fetchAssets({}));
    }
  }, [fetched]);

  useEffect(() => {
    dispatch(
      listMarketHistory({
        underlying_asset: filters.underlying_asset ?? 0,
        frequency: filters.frequency ?? -1,
        timestamp_range: (filters.timestamp_range || []).map(t => {
          return t.format('YYYY-MM-DDTHH:mm:ss');
        }),
      })
    );
  }, [filters]);

  const parseValue = (value: any) => {
    let result = undefined;
    if (typeof value === 'string') {
      result = Number(value);
    }
    if (['undefined', 'number'].includes(typeof value)) {
      result = value as number | undefined;
    }
    return result;
  };

  const columns = useMemo(() => {
    return [
      { title: 'ID', dataIndex: 'id' },
      {
        title: 'Backup',
        dataIndex: 'backup',
        render() {
          return '';
        },
      },
      { title: 'Timestamp', dataIndex: 'timestamp' },
      { title: 'Asset', dataIndex: 'underlying_asset' },
      { title: 'Frequency', dataIndex: 'frequency' },
      { title: 'Open', dataIndex: 'open' },
      { title: 'High', dataIndex: 'high' },
      { title: 'Low', dataIndex: 'low' },
      { title: 'Close', dataIndex: 'close' },
      { title: 'Volume', dataIndex: 'volume' },
      { title: 'Bid', dataIndex: 'bid' },
      { title: 'Ask', dataIndex: 'ask' },
      { title: 'Duration', dataIndex: 'duration' },
      { title: 'BPV', dataIndex: 'bpv' },
      { title: 'Ask Yield', dataIndex: 'ask_yield' },
      { title: 'Bid Yield', dataIndex: 'bid_yield' },
      { title: 'Accrued Interest', dataIndex: 'accrued_interest' },
      { title: 'Accrued days', dataIndex: 'accrued_days' },
      { title: 'Coupon Date', dataIndex: 'coupon_date' },
      { title: 'Coupon Rate', dataIndex: 'coupon_rate' },
    ].filter(col => selectedColumns.includes(col.dataIndex));
  }, [selectedColumns]);

  return (
    <div>
      <div style={{ display: 'flex', gap: 16 }}>
        <Label title="Asset (ID, Ric, Ticker, Name)">
          <Select
            value={filters.underlying_asset}
            onChange={value => {
              const underlying_asset = parseValue(value);
              setFilters({ ...filters, underlying_asset });
            }}
            allowClear
            showSearch
            filterOption={(value, option) => {
              const searchId = Number(value);
              const assetId = Number(option?.value);

              if (!isNaN(searchId)) {
                return assetId.toString().includes(searchId.toString());
              } else {
                const lowerValue = value.toLowerCase();
                const asset = underlyingAssets[assetId];
                if (!asset) {
                  return false;
                }

                const name = (asset.name || '').toLowerCase();
                const ric = (asset.ric || '').toLowerCase();
                const ticker = (asset.ticker || '').toLowerCase();

                return (
                  name.includes(lowerValue) ||
                  ric.includes(lowerValue) ||
                  ticker.includes(lowerValue)
                );
              }
            }}
            style={{ width: 200 }}
          >
            {Object.values(underlyingAssets).map(asset => {
              return (
                <Select.Option key={asset.id} value={asset.id}>
                  {asset.name}
                </Select.Option>
              );
            })}
          </Select>
        </Label>

        <Label title="Frequency">
          <Select
            allowClear
            value={filters.frequency}
            onChange={value => {
              const frequency = parseValue(value);
              setFilters({ ...filters, frequency });
            }}
            style={{ width: 150 }}
          >
            {Object.entries(FREQUENCIES).map(([frequency, name]) => {
              return (
                <Select.Option
                  key={Number(frequency)}
                  value={Number(frequency)}
                >
                  {name}
                </Select.Option>
              );
            })}
          </Select>
        </Label>

        <Label title="Date & Time">
          <RangePicker
            showTime={{
              defaultValue: [
                moment('00:00:00', 'h:mm:ss'),
                moment('00:00:00', 'h:mm:ss'),
              ],
            }}
            value={filters.timestamp_range as any}
            onChange={(values: any) => {
              setFilters({ ...filters, timestamp_range: values });
            }}
          />
        </Label>

        <Label title="Columns">
          <Select
            mode="multiple"
            value={selectedColumns}
            onChange={values => {
              setSelectedColumns(values);
            }}
          >
            {columnOptions.map(key => {
              return (
                <Select.Option key={key} value={key}>
                  {key}
                </Select.Option>
              );
            })}
          </Select>
        </Label>

        <Label title="Refresh">
          <Button
            onClick={() => {
              setFilters({ ...filters });
            }}
          >
            <ReloadOutlined />
          </Button>
        </Label>
      </div>

      <Table
        rowKey="id"
        loading={waiting}
        dataSource={marketHistoryList}
        columns={columns}
        pagination={false}
        bordered
        style={{ marginTop: 16 }}
      />
    </div>
  );
};

export default HistoryTable;
