import React from 'react';
import { Tabs } from 'antd';

import NewsTab from './NewsTab';
import AssetsTab from './AssetsTab/AssetsTab';
import PresetsTab from './PresetsTab';
import CurrenciesTab from './CurrenciesTab';
import IndexFundsTab from './IndexFundsTab/IndexFundsTab';
import FXCrossTable from './FXCrossTable';
import DepositsTab from './Deposits';
import EquitiesTab from './Equities';
import SwapPointsTab from './SwapPointsTab';
import MarketHoursTab from './MarketHours';

const Data: React.FC = () => {
  return (
    <div>
      <Tabs defaultActiveKey="assets">
        <Tabs.TabPane key="assets" tab="Assets">
          <AssetsTab />
        </Tabs.TabPane>
        <Tabs.TabPane key="equities" tab="Equities">
          <EquitiesTab />
        </Tabs.TabPane>
        <Tabs.TabPane key="market_hours" tab="Geography/Market Hours">
          <MarketHoursTab />
        </Tabs.TabPane>
        <Tabs.TabPane key="deposits" tab="Deposits">
          <DepositsTab />
        </Tabs.TabPane>
        <Tabs.TabPane key="presets" tab="Presets">
          <PresetsTab />
        </Tabs.TabPane>
        <Tabs.TabPane key="currencies" tab="Currencies">
          <CurrenciesTab />
        </Tabs.TabPane>
        <Tabs.TabPane key="indexFunds" tab="Index Funds">
          <IndexFundsTab />
        </Tabs.TabPane>
        <Tabs.TabPane key="news" tab="News">
          <NewsTab />
        </Tabs.TabPane>
        <Tabs.TabPane key="FXCross" tab="FX Cross">
          <FXCrossTable />
        </Tabs.TabPane>
        <Tabs.TabPane key="SwapPoints" tab="Swap Points">
          <SwapPointsTab />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Data;
