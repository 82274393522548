import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputNumber, Form, Button, Card } from 'antd';

import {
  updateSAL,
  ASSET_CLASSES,
  selectors,
  RISK_WEIGHTED_APPROACH,
  CASH,
} from 'state';

const SAA: React.FC = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { strategic_asset_allocations } = useSelector(selectors.player);
  const { risk_approach } = useSelector(selectors.activeSession);

  const assetClasses = { ...ASSET_CLASSES };
  if (risk_approach === RISK_WEIGHTED_APPROACH) delete assetClasses[CASH];

  const len = Object.keys(assetClasses).length;
  let initialValues;
  if (strategic_asset_allocations) {
    initialValues = Object.values(strategic_asset_allocations).map(
      item => item.percent * 100
    );
  } else {
    initialValues = Array(len).fill(0);
    initialValues[0] = 100;
  }

  return (
    <Card title="Strategic Asset Allocation">
      <Form
        form={form}
        initialValues={initialValues}
        labelCol={{ span: 9 }}
        onFinish={() => {
          let data = form.getFieldsValue();
          data = Object.values(data).map((val, index) => ({
            asset_class: index,
            percent: val / 100 || 0,
          }));
          dispatch(
            updateSAL({
              strategic_asset_allocations: data as any,
            })
          );
        }}
      >
        {Object.entries(assetClasses).map(([key, label]) => (
          <Form.Item label={label} name={key} key={key}>
            <InputNumber
              min={0}
              max={100}
              formatter={value => `${value}%`}
              parser={value => `${value?.replace('%', '')}`}
              disabled={!!strategic_asset_allocations.length}
            />
          </Form.Item>
        ))}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!!strategic_asset_allocations.length}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default SAA;
