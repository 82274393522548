import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { Row } from 'antd';

import Background from 'assets/images/login-background.jpg';
import Login from './Login';

const Auth: React.FC = () => {
  const { url } = useRouteMatch();

  const gradient =
    'linear-gradient(' +
    'to top, rgba(24, 144, 255, 0.5) 0%, ' +
    'rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.8)' +
    ')';

  return (
    <div
      style={{
        backgroundImage: `${gradient}, url(${Background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Row justify="center">
        <div style={{ width: '420px' }}>
          <Switch>
            <Route path={`${url}/login`}>
              <Login />
            </Route>
            <Redirect to={`${url}/login`} />
          </Switch>
        </div>
      </Row>
    </div>
  );
};

export default Auth;
