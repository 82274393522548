import React, { useState } from 'react';
import { Form, Radio, Input, Typography } from 'antd';

import { FETCH_TRAINER, CREATE_TRAINER } from './constants';

const TrainerForm: React.FC = () => {
  const [type, setType] = useState(CREATE_TRAINER);

  return (
    <div>
      <Typography.Title
        level={4}
        style={{ borderBottom: '1px solid #000', paddingBottom: 5 }}
      >
        Trainer
      </Typography.Title>

      <Form.Item>
        <Radio.Group value={type} onChange={e => setType(e.target.value)}>
          <Radio value={CREATE_TRAINER}>Create New</Radio>
          <Radio value={FETCH_TRAINER}>Existing</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="trainerName" label="Name" rules={[{ required: true }]}>
        <Input placeholder="john_doe" />
      </Form.Item>

      {type === CREATE_TRAINER && (
        <Form.Item
          name="trainerPassword"
          label="Password"
          rules={[{ required: true }]}
        >
          <Input placeholder="password" />
        </Form.Item>
      )}
    </div>
  );
};

export default TrainerForm;
