import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { EmptyHeader } from 'components';

import { logout, selectors } from 'state';

const MyHeader: React.FC = () => {
  const dispatch = useDispatch();
  const username = useSelector(selectors.username);
  // console.log('username in header: ', username);

  return (
    <EmptyHeader defaultKey="users" level={2}>
      <Menu.Item key="data">
        <Link to={`/dashboard/data`}>Data</Link>
      </Menu.Item>
      <Menu.Item key="market-history">
        <Link to={`/dashboard/market-history`}>Market History</Link>
      </Menu.Item>
      <Menu.Item key="users">
        <Link to={`/dashboard/users`}>Users</Link>
      </Menu.Item>
      <Menu.Item key="trainers">
        <Link to={`/dashboard/trainers`}>Trainers</Link>
      </Menu.Item>
      <Menu.Item key="sessions">
        <Link to={`/dashboard/sessions`}>Sessions</Link>
      </Menu.Item>
      <Menu.Item key="initial-portfolio">
        <Link to={'/dashboard/initial-portfolio'}>Initial portfolio</Link>
      </Menu.Item>
      <Menu.Item key="commands">
        <Link to={'/dashboard/commands'}>Commands</Link>
      </Menu.Item>
      <Menu.Item key="errors">
        <Link to={'/dashboard/errors'}>Errors</Link>
      </Menu.Item>
      <Menu.SubMenu
        title={
          <>
            <UserOutlined />
            {username}
          </>
        }
        style={{ float: 'right' }}
      >
        <Menu.Item onClick={() => dispatch(logout({}))}>Logout</Menu.Item>
      </Menu.SubMenu>
    </EmptyHeader>
  );
};

export default MyHeader;
