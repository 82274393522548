import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { Button, DatePicker, InputNumber, message, Spin, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';

import { fetchNews, News, selectors, updateNews, uploadNews } from 'state';
import EditableTable from './EditableTable';
import NewsBodyModal from './NewsBodyModal';
import { API_URL } from 'settings';

const NewsTab: React.FC = () => {
  const dispatch = useDispatch();

  let news = useSelector(selectors.newsList);
  const fetching = useSelector(selectors.newsFetching);
  const token = useSelector(selectors.token);

  const [newsRange, setNewsRange] = useState<(Moment | undefined)[]>([
    undefined,
    undefined,
  ]);
  const [showBody, setShowBody] = useState(false);
  const [newsId, setNewsId] = useState(-1);
  const [importanceFilter, setImportanceFilter] = useState<number | undefined>(
    0
  );
  const [fileSelected, setFileSelected] = useState(false);
  const [data, setData] = useState(new FormData());

  useEffect(() => {
    const startDate = Cookies.get('adminNewsStartDate');
    const endDate = Cookies.get('adminNewsEndDate');
    setNewsRange([moment(startDate), moment(endDate)]);
  }, []);

  useEffect(() => {
    const [startDate, endDate] = newsRange;
    if (!startDate || !endDate) return;

    Cookies.set('adminNewsStartDate', startDate.toISOString());
    Cookies.set('adminNewsEndDate', endDate.toISOString());

    if (endDate.diff(startDate, 'days') > 32) {
      message.error('Cannot select dates further apart than 32!');
      return;
    }

    dispatch(
      fetchNews({
        start_date: newsRange[0]?.toISOString() || '',
        end_date: newsRange[1]?.toISOString() || '',
      })
    );
  }, [dispatch, newsRange]);

  const addDaysToRange = (amount: number) => {
    setNewsRange([
      newsRange[0]?.add(amount, 'day'),
      newsRange[1]?.add(amount, 'day'),
    ]);
  };

  if (typeof importanceFilter === 'number') {
    news = news.filter(n => n.importance === importanceFilter);
  }

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const raw = e.target.files as FileList;
    const formData = new FormData();
    if (raw[0]) formData.append('news_file', raw[0], raw[0].name);
    setData(formData);
    setFileSelected(true);
  };

  const uploadHandler = async () => {
    // fetch(`${API_URL}/admin/upload_news/`, {
    //   method: 'POST',
    //   body: data,
    //   headers: {
    //     Authorization: `JWT ${token}`,
    //   },
    // })
    //   .then(Response => {
    //     if (Response.status !== 200) {
    //       message.error('An error has occurred');
    //       (document.getElementById('news_file') as HTMLInputElement).value = '';
    //       setFileSelected(false);
    //     } else {
    //       (document.getElementById('news_file') as HTMLInputElement).value = '';
    //       setFileSelected(false);
    //       message.success('Successful operation');
    //     }
    //   })
    //   .catch(() => {
    //     message.error('An error has occurred');
    //   });

    const file = data.get('news_file') as File;

    function readFileAsText(file: File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsText(file);
      });
    }

    const fileContent = await readFileAsText(file);
    console.log('fileContent: ', fileContent);
    console.log('typeof fileContent: ', typeof fileContent);
    dispatch(uploadNews({ news_file: fileContent }));
    // let csrftoken = Cookies.get(CSRF_COOKIE_NAME);
    // if (csrftoken === undefined) {
    //   csrftoken = '';
    // }
    // fetch(`${API_URL}/admin/upload_news/`, {
    //   method: 'POST',
    //   body: data,
    //   headers: {
    //     Authorization: `JWT ${token}`,
    //     'x-csrftoken': csrftoken,
    //     HTTP_COOKIE: `${CSRF_COOKIE_NAME}=${csrftoken};`,
    //   },
    //   // credentials: 'include',
    // })
    //   .then(Response => {
    //     if (Response.status !== 200) {
    //       message.error('An error has occurred');
    //       (document.getElementById('news_file') as HTMLInputElement).value = '';
    //       setFileSelected(false);
    //     } else {
    //       (document.getElementById('news_file') as HTMLInputElement).value = '';
    //       setFileSelected(false);
    //       message.success('Successful operation');
    //     }
    //   })
    //   .catch(() => {
    //     message.error('An error has occurred');
    //   });
  };

  const infoHover = (
    <div>
      <p>headline,body,timestamp,importance</p>
      <p>where timestamp format is: 06-28-2022</p>
    </div>
  );

  return (
    <div>
      <form id={'news_form'}>
        <Popover
          placement={'right'}
          content={infoHover}
          title=".csv header must look like:"
        >
          <InfoCircleOutlined />
        </Popover>
        <Button
          disabled={!fileSelected}
          style={{ marginLeft: 10 }}
          onClick={uploadHandler}
        >
          Upload
        </Button>
        <input
          style={{ marginLeft: '8px', marginBottom: '12px' }}
          type="file"
          id="news_file"
          name="news_file"
          accept=".csv"
          onChange={e => handleFileSelection(e)}
        />
      </form>

      <Button onClick={() => addDaysToRange(-1)}>prev day</Button>
      <DatePicker.RangePicker
        format="YYYY-MM-DD"
        value={newsRange as any}
        onChange={range => {
          if (!range) return;
          setNewsRange(range as any);
        }}
      />
      <Button onClick={() => addDaysToRange(1)}>next day</Button>
      <span style={{ marginLeft: '8px' }}>
        <span style={{ marginRight: '8px' }}>Importance=</span>
        <InputNumber
          style={{ width: 50 }}
          value={importanceFilter}
          onChange={setImportanceFilter}
        />
      </span>

      <p style={{ marginTop: '8px' }}>
        Current range: {newsRange[0]?.format('YYYY-MM-DD')}
        {' -> '}
        {newsRange[1]?.format('YYYY-MM-DD')}
      </p>
      <p>News: {news.length}</p>
      <Spin spinning={fetching}>
        <EditableTable
          data={news}
          onSave={a => {
            dispatch(updateNews(a));
          }}
          fixedFields={['importance']}
          ignoredFields={['body']}
          columns={{
            headline: {
              dataIndex: 'headline',
              width: '400px',
              render(value: any) {
                return (
                  <div
                    style={{
                      wordWrap: 'initial',
                      whiteSpace: 'initial',
                    }}
                  >
                    {value}
                  </div>
                );
              },
            },
          }}
          buttons={[
            {
              onClick: record => {
                dispatch(
                  updateNews({
                    ...record,
                    importance: record.importance - 1,
                  })
                );
              },
              title: '-',
            },
            {
              onClick: (record: News) => {
                dispatch(
                  updateNews({
                    ...record,
                    importance: record.importance + 1,
                  })
                );
              },
              title: '+',
            },
            {
              onClick: record => {
                setShowBody(true);
                setNewsId(record.id);
              },
              title: 'Body',
            },
          ]}
        />
      </Spin>
      {showBody && (
        <NewsBodyModal
          visible={showBody}
          hide={() => setShowBody(false)}
          newsId={newsId}
        />
      )}
    </div>
  );
};

export default NewsTab;
