import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { selectors } from 'state';
import { formatters } from 'services';
import { utils } from 'state';

const SectorTable: React.FC = () => {
  const dataSource = useSelector(selectors.equityPnlBySector);
  const session = useSelector(selectors.activeSession);
  const reportingCurrency = utils.useReportingCurrency(session.id);

  return (
    <Table
      rowKey="sector"
      bordered
      pagination={false}
      dataSource={dataSource}
      columns={[
        {
          title: 'Sector',
          dataIndex: 'sector',
          width: '34%',
        },
        {
          title: 'PnL, ' + reportingCurrency.symbol,
          dataIndex: 'pnl',
          align: 'right',
          render: formatters.millionDollars,
          sorter: (a, b) => a.pnl - b.pnl,
          defaultSortOrder: 'descend',
          width: '33%',
        },
        {
          title: 'Allocation',
          dataIndex: 'allocation',
          align: 'right',
          render: formatters.percent2Digit,
          sorter: (a, b) => (a.allocation - b.allocation) * 100,
          width: '33%',
        },
      ]}
    />
  );
};

export default SectorTable;
