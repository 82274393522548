import React, { useMemo, useState } from 'react';
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Switch,
  Button,
} from 'antd';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text' | 'boolean';
  record: unknown;
  index: number;
  children: React.ReactNode;
}

const inputNodes: { [key: string]: JSX.Element } = {
  number: <InputNumber />,
  boolean: <Switch />,
};

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputNodes[inputType] || <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          valuePropName={inputType === 'boolean' ? 'checked' : undefined}
          // rules={[
          //   {
          //     required: true,
          //     message: `Please Input ${title}!`,
          //   },
          // ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

type Props<Item> = {
  data: Item[];
  onSave: (item: Item) => void;
  onDelete?: (item: Item) => void;
  fixedFields?: string[];
  ignoredFields?: string[];
  nonEditableFields?: string[];
  columns?: any;
  buttons?: { onClick: (record: Item) => void; title: string }[];
};

function EditableTable<Item extends { id: number }>({
  data,
  onSave,
  onDelete,
  fixedFields,
  ignoredFields,
  nonEditableFields,
  columns,
  buttons,
}: React.PropsWithChildren<Props<Item>>) {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState(0);

  const cancel = () => {
    setEditingKey(0);
  };

  const tableColumns: {
    title: string;
    dataIndex: string;
    editable?: boolean;
    [key: string]: any;
  }[] = useMemo(() => {
    const isFixed = (key: string) => fixedFields?.includes(key);
    const isIgnored = (key: string) => ignoredFields?.includes(key);
    const isEditing = (record: Item) => record.id === editingKey;

    const edit = (record: Item) => {
      form.setFieldsValue({ ...record });
      setEditingKey(record.id);
    };

    const save = async (key: React.Key) => {
      try {
        const row = (await form.validateFields()) as Item;

        onSave({ ...row, id: key });
        setEditingKey(0);
      } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
      }
    };

    const formatColTitle = (key: string) => {
      const tokens = key.split('_');
      const upperTokens = tokens.map(
        t => `${t.charAt(0).toUpperCase()}${t.slice(1)}`
      );
      return upperTokens.join(' ');
    };

    const keyColumns = [
      {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        width: '100px',
        render: function Operation(value: never, record: Item) {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <a onClick={() => save(record.id)} style={{ marginRight: 8 }}>
                Save
              </a>
              {onDelete && (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => {
                    onDelete(record);
                  }}
                >
                  <a style={{ marginRight: 8 }}>Delete</a>
                </Popconfirm>
              )}
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <span>
              <a style={{ marginRight: '10px' }} onClick={() => edit(record)}>
                Edit
              </a>
              {buttons?.map((btn, i) => (
                <Button
                  style={{
                    padding: '0 10px',
                    marginRight: i !== buttons?.length - 1 ? '10px' : '0',
                  }}
                  key={btn.title}
                  onClick={() => btn.onClick(record)}
                >
                  {btn.title}
                </Button>
              ))}
            </span>
          );
        },
      },
      ...Object.keys(data[0] || {})
        .filter(key => !isIgnored(key))
        .sort((a, b) => (isFixed(a) && isFixed(b) ? 0 : isFixed(a) ? -1 : 1))
        .map(key => {
          const col = {
            key,
            title: formatColTitle(key),
            dataIndex: key,
            ...columns?.[key],
          };

          return {
            ...col,
            editable: (() => {
              nonEditableFields?.push('id');
              return !nonEditableFields?.includes(key);
            })(),
            // editable: key !== 'id',
            fixed: isFixed(key) ? 'left' : undefined,
          };
        }),
    ];

    return keyColumns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        render:
          col.render ??
          ((val: unknown) => {
            if (typeof val === 'boolean')
              return <Switch disabled checked={val} />;
            return val;
          }),
        onCell: (record: Item) => ({
          record,
          inputType: typeof (record as any)[col.dataIndex],
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
  }, [
    buttons,
    columns,
    data,
    editingKey,
    fixedFields,
    form,
    ignoredFields,
    onSave,
  ]);

  if (!data) return null;

  return (
    <Form form={form} component={false}>
      <Table
        rowKey="id"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        // expandable={{
        //   expandedRowRender: function exp(record) {
        //     return <p style={{ margin: 0 }}>{(record as any).body}</p>;
        //   },
        //   rowExpandable: record => (record as any).body,
        // }}
        bordered
        dataSource={data}
        columns={tableColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
}

export default EditableTable;
