import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import Chart from 'react-apexcharts';

import { formatters } from 'services';
import { selectors } from 'state';

const HTMDurationBreakdown: React.FC = () => {
  const data = useSelector(selectors.HTMDurationBreakdown());
  const series = [
    {
      name: 'Duration',
      data: data,
    },
  ];

  return (
    <div style={{ minWidth: 242 }}>
      <Typography.Title
        level={4}
        style={{ whiteSpace: 'nowrap', marginBottom: 0 }}
      >
        HTM Duration Breakdown
      </Typography.Title>
      <Chart
        height={300}
        series={series}
        type="bar"
        options={{
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          tooltip: {
            y: {
              formatter: formatters.commas2Digits,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              formatter: formatters.commas2Digits,
            },
          },
        }}
      />
    </div>
  );
};

export default HTMDurationBreakdown;
