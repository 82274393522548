import React from 'react';
import { Modal } from 'antd';

type Props = {
  visible: boolean;
  hide: () => void;
  playingSessions: number[] | undefined;
  pausedSessions: number[] | undefined;
};

const UsageTable: React.FC<Props> = ({
  visible,
  hide,
  playingSessions,
  pausedSessions,
}) => {
  if (playingSessions === undefined) {
    playingSessions = [];
  }
  if (pausedSessions === undefined) {
    pausedSessions = [];
  }
  return (
    <Modal
      visible={visible}
      onCancel={hide}
      title="Asset used in: "
      footer={
        [
          /* leave empty to delete ok cancel buttons*/
        ]
      }
    >
      <div> playing sessions: {playingSessions.join(', ')}</div>
      <div>paused sessions: {pausedSessions.join(', ')}</div>
    </Modal>
  );
};

export default UsageTable;
