import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Button,
  Radio,
  InputNumber,
  Card,
  Row,
  Col,
  message,
  Input,
} from 'antd';

import {
  selectors,
  BUY,
  buyForwardContract,
  SELL,
  utils,
  FROZEN,
  PAUSED,
} from 'state';
import { formatters } from 'services';
import { cashExchange, getFWDCurrencyPair } from '../../state/app/utils';
import TextArea from 'antd/es/input/TextArea';

type props = {
  visible: boolean;
  hide: () => void;
  id: number;
};

const HedgeOrderModal: React.FC<props> = ({ visible, hide, id }) => {
  const dispatch = useDispatch();
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);
  const contractsIndexed = useSelector(
    selectors.fwdContractsIndexed(undefined)
  );
  const session = useSelector(selectors.activeSession);
  const { transaction_fee, risk_approach } = session;

  const financialInstrument = useSelector(
    selectors.financialInstrumentById(id)
  );
  const underlyingAsset = useSelector(
    selectors.underlyingAssetOfInstrument(financialInstrument)
  );
  const asset_variable_currency = useSelector(
    selectors.currencyById(underlyingAsset.variable_currency)
  );
  const asset_base_currency = useSelector(
    selectors.currencyById(underlyingAsset.base_currency)
  );

  const [quantity, setQuantity] = useState(100);

  const player = useSelector(selectors.player);
  const [transaction, setTransaction] = useState(BUY);
  const [investmentRationale, setInvestmentRationale] = useState<string>('');

  const reportingCurrency = useSelector(
    selectors.currencyById(session.reporting_currency)
  );
  const remainingReportingHedge = useSelector(
    selectors.remainingReportingHedge
  );
  const price = transaction === BUY ? underlyingAsset.ask : underlyingAsset.bid;

  let maxQuantity;
  const totalInstrumentValueByContractSum = utils.forwardInstrumetnReportingValue(
    financialInstrument,
    underlyingAsset,
    session,
    assetList,
    currencyList,
    contractsIndexed
  );

  // console.log(
  //   'totalInstrumentValueByContractSum: ',
  //   totalInstrumentValueByContractSum
  // );

  const assetClassLimit =
    (financialInstrument.pv_limit * session.starting_cash_amount) /
    session.fx_leverage;

  if (asset_base_currency === reportingCurrency) {
    let remainingLimit = assetClassLimit - financialInstrument.amount;
    if (remainingLimit < 0) remainingLimit = 0;
    maxQuantity = Math.min(remainingLimit, remainingReportingHedge);
  } else {
    let remainingLimit = assetClassLimit - totalInstrumentValueByContractSum;
    if (remainingLimit < 0) remainingLimit = 0;
    remainingLimit = cashExchange(
      session.reporting_currency,
      underlyingAsset.base_currency,
      remainingLimit,
      0,
      assetList,
      currencyList,
      0,
      true
    );
    const remainingHedge = cashExchange(
      session.reporting_currency,
      underlyingAsset.base_currency,
      remainingReportingHedge,
      0,
      assetList,
      currencyList
    );
    maxQuantity = Math.min(remainingHedge, remainingLimit);
  }
  const swapPoints =
    transaction === BUY
      ? underlyingAsset.ask_swap_points
      : underlyingAsset.bid_swap_points;
  const spot = price - swapPoints;

  const { name } = underlyingAsset;

  const wallet = player.wallets.find(
    wallet => underlyingAsset.variable_currency === wallet.currency
  );

  if (!wallet) {
    message.error('Wallet not found!');
    return null;
  }

  const value = quantity * price;
  const sign = transaction === BUY ? 1 : -1;
  const total = value * (1 + transaction_fee);

  let satisfiesInvestmentRationaleRequirement;
  let investmentRationalePlaceholder;
  if (session.require_investment_rationale === false) {
    satisfiesInvestmentRationaleRequirement = true;
    investmentRationalePlaceholder = 'Investment Rationale';
  } else {
    investmentRationalePlaceholder =
      'Investment Rationale | required field min. 10 char.';
    if (
      investmentRationale !== undefined &&
      investmentRationale !== '' &&
      investmentRationale.length > 9
    ) {
      satisfiesInvestmentRationaleRequirement = true;
    } else {
      satisfiesInvestmentRationaleRequirement = false;
    }
  }

  return (
    <Modal
      visible={visible}
      title={`${name} Forward Contract`}
      onOk={hide}
      onCancel={hide}
      width={600}
      footer={[
        <Button
          key="place"
          type="primary"
          disabled={
            quantity <= 0 ||
            quantity > maxQuantity ||
            session.status === FROZEN ||
            session.status === PAUSED ||
            !satisfiesInvestmentRationaleRequirement
          }
          onClick={() => {
            dispatch(
              buyForwardContract({
                financial_instrument_id: financialInstrument.id,
                amount: sign * quantity,
                transaction: transaction,
                investment_rationale: investmentRationale,
              })
            );
            hide();
          }}
        >
          {transaction === BUY ? 'Buy forward' : 'Sell forward'}
        </Button>,
      ]}
    >
      Transaction:{' '}
      <Radio.Group
        onChange={e => setTransaction(e.target.value)}
        value={transaction}
      >
        <Radio value={BUY}>Buy</Radio>
        <Radio value={SELL}>Sell</Radio>
      </Radio.Group>
      <br />
      Price: ({asset_variable_currency.symbol}){' '}
      {/*{selectedAsset.resourcetype !== 'FX'*/}
      {/*  ? formatters.commas2Digits(price)*/}
      {/*  : formatters.commas4Digits(price)}*/}
      {formatters.priceFormat(
        price,
        underlyingAsset.ticker,
        underlyingAsset.resourcetype
      )}
      <br />
      Quantity:{' '}
      <InputNumber
        value={quantity}
        // min={minQuantity}
        max={maxQuantity}
        style={{ width: 150 }}
        formatter={formatters.commasNoDigits}
        // parser={parseNumber}
        onChange={val => setQuantity(val || 0)}
      />
      {`/ ${formatters.commasNoDigits(maxQuantity)}`}
      {/*<br />*/}
      <Card style={{ marginTop: 10 }}>
        <Row justify="center">
          <h3>Order Summary</h3>
        </Row>
        <Row justify="space-between" align="middle">
          <Col>
            Quantity
            <br />
            {formatters.commasNoDigits(quantity)}
          </Col>
          <Col>
            Spot
            <br />
            {formatters.priceFormat(
              spot,
              underlyingAsset.ticker,
              underlyingAsset.resourcetype
            )}
          </Col>
          <Col>
            Swap points
            <br />
            {formatters.priceFormat(
              swapPoints,
              underlyingAsset.ticker,
              underlyingAsset.resourcetype
            )}
          </Col>
          <Col>
            Value
            <br />
            {formatters.commasNoDigits(value)}
          </Col>
          <Col>
            Fees*
            <br />
            {formatters.percent1Digit(transaction_fee)}
          </Col>
        </Row>
        <br />

        <Row justify={'center'}>
          <Col>
            Total
            <br />
            {formatters.commasNoDigits(total)}
          </Col>
        </Row>
        {/*<br />*/}
        {/*{risk_approach === CASH_APPROACH && (*/}
        {/*  <Row>Available Cash: {formatters.commasNoDigits(wallet.amount)}</Row>*/}
        {/*)}*/}
      </Card>
      <TextArea
        maxLength={511}
        minLength={10}
        placeholder={investmentRationalePlaceholder}
        value={investmentRationale}
        onChange={e => setInvestmentRationale(e.target.value)}
      />
    </Modal>
  );
};

export default HedgeOrderModal;
