import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { RichText, RoadmapContent, selectors } from 'state';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';

type ContentType = Omit<RoadmapContent, 'id'>;

type State = {
  layoutType: number | undefined;
  setLayoutType: Dispatch<SetStateAction<number | undefined>>;
  content: { [areaCode: number]: ContentType };
  setContent: Dispatch<SetStateAction<{ [areaCode: number]: ContentType }>>;
  getTextState: (
    areaCode: number
  ) => readonly [RichText[], (value: RichText[]) => void];
  getImageState: (
    areaCode: number
  ) => readonly [FormData, (value: FormData) => void];
  save: () => void;
};

const LayoutContext = createContext<State | undefined>(undefined);

const initialSlateValue: RichText[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const initialImageValue: FormData = new FormData();

const LayoutProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [layoutType, setLayoutType] = useState<number | undefined>(0);
  const [content, setContent] = useState<{ [areaCode: number]: ContentType }>(
    {}
  );

  const player = useSelector(selectors.player);
  const person = useSelector(selectors.selectedPerson);

  const getTextState = (areaCode: number) => {
    // console.log(content[areaCode]?.text);
    const value = content[areaCode]?.text ?? initialSlateValue;

    const setValue = (value: RichText[]) => {
      setContent({
        ...content,
        [areaCode]: { ...content[areaCode], area_code: areaCode, text: value },
      });
    };

    return [value, setValue] as const;
  };

  const getImageState = (areaCode: number) => {
    // console.log(content[areaCode]?.image?.getAll('image'));
    const value = content[areaCode]?.image ?? initialImageValue;
    // console.log(Object.values(content));

    const setValue = (value: FormData) => {
      setContent({
        ...content,
        [areaCode]: { ...content[areaCode], area_code: areaCode, image: value },
      });
    };

    return [value, setValue] as const;
  };

  const save = () => {
    if (layoutType === undefined) {
      message.error('Invalid layout type');
      return;
    }
    if (person === undefined) {
      message.error('Team member not selected');
      return;
    }

    // commented because this would trigger an S3 file fetch and currently boto3 S3 access is deactivated in backend
    // dispatch(
    //   createRoadmapItem({
    //     player_id: player.id,
    //     person_id: person.id,
    //     type: layoutType,
    //     roadmap_contents: Object.values(content),
    //   })
    // );
  };

  const value: State = {
    layoutType,
    setLayoutType,
    content,
    setContent,
    getTextState,
    getImageState,
    save,
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export default LayoutProvider;

export const useLayoutProvider = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayoutProvider must be called inside LayoutProvider');
  }
  return context;
};
