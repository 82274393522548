import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Timeline } from 'antd';

import { selectors } from 'state';
import ContentViewer from './ContentViewer';

const Roadmap: React.FC = () => {
  const dispatch = useDispatch();
  const player = useSelector(selectors.player);
  const roadmapItems = useSelector(selectors.selfRoadmapItemsList);

  useEffect(() => {
    // commented because this would trigger an S3 file fetch and currently boto3 S3 access is deactivated in backend
    // dispatch(listRoadmapItems({ player_id: player?.id ?? 0 }));
  }, []);

  if (!roadmapItems.length) {
    return <Empty description="There are no user entries." />;
  }

  const sortedItems = roadmapItems.sort((a, b) =>
    b.timestamp.localeCompare(a.timestamp)
  );

  return (
    <Timeline mode="left">
      {sortedItems.map(item => {
        const date = new Date(item.timestamp).toLocaleString();
        return (
          <Timeline.Item key={item.id}>
            <div
              style={{
                width: '100%',
                maxWidth: '700px',
              }}
            >
              <div style={{ backgroundColor: '#fafafa', padding: '0 20px' }}>
                {date}
              </div>
              <div
                style={{
                  border: '1px solid #fafafa',
                  padding: '20px',
                }}
              >
                {item.roadmap_contents.map(content => {
                  return <ContentViewer key={content.id} content={content} />;
                })}
              </div>
            </div>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

export default Roadmap;
