import React from 'react';
import { Tabs } from 'antd';
import StockSplits from './StockSplits';
import HistoryTable from './HistoryTable';

const MarketHistory: React.FC = () => {
  return (
    <div>
      <Tabs>
        <Tabs.TabPane key="History Table" tab="History Table">
          <HistoryTable />
        </Tabs.TabPane>
        <Tabs.TabPane key="Stock Splits" tab="Stock Splits">
          <StockSplits />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default MarketHistory;
