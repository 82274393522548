import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Table } from 'antd';

import { selectors } from 'state';

type Props = {
  visible: boolean;
  hide: () => void;
  klassId: number;
};

const KlassUsers: React.FC<Props> = ({ visible, hide, klassId }) => {
  const klass = useSelector(selectors.klassById(klassId));

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      onOk={hide}
      title={`${klass.name} trainees`}
    >
      <Table
        rowKey="id"
        bordered
        pagination={false}
        dataSource={klass.trainees}
        columns={[{ title: 'Name', dataIndex: 'username' }]}
      />
    </Modal>
  );
};

export default KlassUsers;
