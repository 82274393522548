import React, { useState } from 'react';
import { Button, InputNumber, Modal, Card, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, breakDeposit, DepositInstrument, makeDeposit } from 'state';
import { formatters } from 'services';
import { selectedPerson } from '../../../../state/app/selectors';
import { AreaChart } from 'components';

type Props = {
  visible: boolean;
  hide: () => void;
};

const AssetEodPreviewModal: React.FC<Props> = ({ visible, hide }) => {
  const assetEodData = useSelector(selectors.assetEodData);
  // console.log(indexFundData);
  const bid_series = assetEodData.data.map((item, index) => ({
    x: item.timestamp,
    y: item.bid || 0,
  }));

  const ask_series = assetEodData.data.map((item, index) => ({
    x: item.timestamp,
    y: item.ask || 0,
  }));
  let yield_series: any[] = [];
  if (assetEodData.is_yield) {
    yield_series = assetEodData.data.map((item, index) => ({
      x: item.timestamp,
      y: item.yield_value,
    }));
  }

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      title={`All EoD datapoints for: ${assetEodData.ric}`}
      footer={
        [
          /* leave empty to delete ok cancel buttons*/
        ]
      }
    >
      <div>
        {assetEodData.is_yield ? (
          <AreaChart
            title=""
            dataSeriesName={'yield'}
            data={yield_series}
            yaxisFormatter={formatters.commas4Digits}
            // xaxis={xaxis}
            // tooltipDateFormat={'d MMM HH:mm'}
            // additionalLines={[{ data: ask_series, name: 'ask' }]}
          />
        ) : (
          <AreaChart
            title=""
            dataSeriesName={'bid'}
            data={bid_series}
            yaxisFormatter={formatters.commas4Digits}
            // xaxis={xaxis}
            // tooltipDateFormat={'d MMM HH:mm'}
            additionalLines={[{ data: ask_series, name: 'ask' }]}
          />
        )}
      </div>
    </Modal>
  );
};

export default AssetEodPreviewModal;
