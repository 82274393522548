import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import IndividualReport from 'reusable/IndividualReport';
import { getIndividualReport, selectors } from 'state';

const AdminIndividualReport: React.FC = () => {
  const dispatch = useDispatch();
  const { player_id } = useParams();
  const player = useSelector(selectors.playerById(player_id));
  const report = selectors.individualReport(player_id);

  useEffect(() => {
    if (!report) {
      dispatch(getIndividualReport({ player_id }));
    }
  }, [dispatch, player_id, report]);

  if (!player_id) {
    return <div>Player id missing in url, wrong path?</div>;
  }

  if (!report) {
    return <div>No report</div>;
  }

  return <IndividualReport player={player} />;
};

export default AdminIndividualReport;
