import React, { useEffect } from 'react';
import { Tabs } from 'antd';

import { listPlayerDividends, listSessionEquities, selectors } from 'state';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from 'components';
import Valuation from './Valuation';
import Dividends from './Dividends';
import CorrelationMatrix from './CorrelationMatrix';

const EquitiesResearch: React.FC = () => {
  const dispatch = useDispatch();

  const { waiting: divWaiting, fetched: divFetched } = useSelector(
    selectors.dividendsApi
  );
  const { waiting: eqWaiting, fetched: eqFetched } = useSelector(
    selectors.equitiesApi
  );

  useEffect(() => {
    if (!eqFetched) {
      dispatch(listSessionEquities({}));
    }
  }, [dispatch, eqFetched]);

  useEffect(() => {
    if (!divFetched) {
      dispatch(listPlayerDividends({}));
    }
  }, [dispatch, divFetched]);

  if (divWaiting || eqWaiting) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Tabs>
        <Tabs.TabPane tab="Valuation" key="valuation">
          <Valuation />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Dividends" key="dividends">
          <Dividends />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Correlation Matrix" key="correlation-matrix">
          <CorrelationMatrix />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default EquitiesResearch;
