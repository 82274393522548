import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import { Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { EmptyHeader } from 'components';

import {
  selectors,
  logout,
  TRADING_WINDOWS,
  REAL_TIME_SCENARIO,
  HISTORICAL_SCENARIO,
  CREATING,
} from 'state';

const MyHeader: React.FC = () => {
  const dispatch = useDispatch();
  const username = useSelector(selectors.username);
  const { trading_time_type } = useSelector(selectors.activeSession);
  const session = useSelector(selectors.activeSession);
  const { url } = useRouteMatch();
  const showCentralBank =
    session.scenario_type === HISTORICAL_SCENARIO &&
    session.status !== CREATING &&
    session.show_auctions;

  return (
    <EmptyHeader defaultKey="graph" home={`${url}`}>
      <Menu.Item key="overview">
        <Link to={`${url}/overview`}>Overview</Link>
      </Menu.Item>
      {showCentralBank && (
        <Menu.Item key="central-bank">
          <Link to={`${url}/central-bank`}>Central Bank</Link>
        </Menu.Item>
      )}
      <Menu.Item key="session-settings">
        <Link to={`${url}/session-settings`}>Session Settings</Link>
      </Menu.Item>
      {/*<Menu.Item key="benchmarks">*/}
      {/*  <Link to={`${url}/benchmarks`}>Benchmarks</Link>*/}
      {/*</Menu.Item>*/}
      {trading_time_type === TRADING_WINDOWS && (
        <Menu.Item key="trading-intervals">
          <Link to={`${url}/trading-intervals`}>Trading Windows</Link>
        </Menu.Item>
      )}
      {/*<Menu.Item key="settings">*/}
      {/*  <Link to={`${url}/settings`}>Settings</Link>*/}
      {/*</Menu.Item>*/}
      <Menu.Item key="performance">
        <Link to={`${url}/performance`}>Performance</Link>
      </Menu.Item>
      {!(session.scenario_type === REAL_TIME_SCENARIO) && (
        <Menu.Item key="macro-background">
          <Link to={`${url}/macro-background`}>Macro Background</Link>
        </Menu.Item>
      )}
      {/*<Menu.Item key="instruments">*/}
      {/*  <Link to={`${url}/instruments`}>Instruments</Link>*/}
      {/*</Menu.Item>*/}
      <Menu.SubMenu
        title={
          <>
            <UserOutlined />
            {username}
          </>
        }
        style={{ float: 'right' }}
      >
        <Menu.Item>
          <Link to={`/dashboard`}>Exit session</Link>
        </Menu.Item>
        <Menu.Item onClick={() => dispatch(logout({}))}>Logout</Menu.Item>
      </Menu.SubMenu>
    </EmptyHeader>
  );
};

export default MyHeader;
