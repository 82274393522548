import { appManager, AppState } from '../manager';
import { arrayToIndexedObject } from 'services/indexedObject';
import type { Preset, CreatePresetPayload } from './types';

export const createPreset = appManager.createApi<
  CreatePresetPayload,
  Preset,
  AppState
>('CREATE_PRESET', {
  method: 'POST',
  path: '/presets',
  successReducer(state, result) {
    state.presets.items[result.id] = result;
  },
});

export const listPresets = appManager.createApi<unknown, Preset[], AppState>(
  'LIST_PRESETS',
  {
    method: 'GET',
    path: '/presets',
    successReducer(state, result) {
      state.presets.items = arrayToIndexedObject(result);
    },
  }
);

export const updatePreset = appManager.createApi<Preset, Preset, AppState>(
  'UPDATE_PRESET',
  {
    method: 'POST',
    path: '/presets/:id/update_preset',
    successReducer(state, result) {
      state.presets.items[result.id] = result;
    },
  }
);
