import React, { useEffect } from 'react';
import { Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ExecutionErrorType, retrieveExecutionErrors, selectors } from 'state';
import { formatters } from 'services';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import { dateWithTimeSeconds } from 'services/formatters';

const { Title } = Typography;

const ExecutionErrors: React.FC = () => {
  const dispatch = useDispatch();
  const executionErrors = useSelector(selectors.executionErrorsSelector);
  const executionErrorsList = Object.values(executionErrors.items);

  useEffect(() => {
    if (!executionErrors.fetched) {
      dispatch(retrieveExecutionErrors({}));
    }
  }, []);

  const columns: ((
    | ColumnGroupType<ExecutionErrorType>
    | ColumnType<ExecutionErrorType>
  ) & {
    dataIndex: string;
  })[] = [
    {
      title: 'id',
      dataIndex: 'id',
      fixed: 'left',
    },
    {
      title: 'error',
      dataIndex: 'text',
      render: (text: string) => (
        <div style={{ whiteSpace: 'normal' }}>{text || '--'}</div>
      ),
    },
    {
      title: 'timestamp',
      dataIndex: 'timestamp',
      // width: 250,
      defaultSortOrder: 'descend',
      sorter: (a: ExecutionErrorType, b: ExecutionErrorType) => {
        const dateA = formatters.dateWithTime(a.timestamp || '');
        const dateB = formatters.dateWithTime(b.timestamp || '');
        return dateA.localeCompare(dateB);
      },
      render: val => formatters.dateWithTimeSecondsMiliseconds(val),
      fixed: 'right',
    },
  ];

  return (
    <div>
      <Typography.Title level={4}>Order Execution Errors</Typography.Title>
      <Table
        // style={{ width: '1000px' }}
        columns={columns}
        dataSource={executionErrorsList}
        pagination={{ pageSize: 10 }}
        bordered
      />
    </div>
  );
};
// deploy
export default ExecutionErrors;
