import { appManager, AppState } from '../manager';
import {
  addElementToIndexedArray,
  arrayToIndexedObject,
} from 'services/indexedObject';

import * as types from '../types';

export const fetchKlasses = appManager.createApi<
  unknown,
  types.Klass[],
  AppState
>('FETCH_KLASSES', {
  path: '/klasses',
  method: 'GET',
  successReducer(state, result) {
    state.klasses.items = arrayToIndexedObject(result);
  },
});

export const fetchPersons = appManager.createApi<
  unknown,
  types.Person[],
  AppState
>('FETCH_PERSONS', {
  path: '/persons',
  method: 'GET',
  successReducer(state, result) {
    state.persons.items = arrayToIndexedObject(result);
  },
});

export const createPerson = appManager.createApi<
  { name: string },
  types.Person,
  AppState
>('CREATE_PERSON', {
  path: '/persons',
  method: 'POST',
  successReducer(state, result) {
    addElementToIndexedArray(state.persons.items, result);
  },
});

export const createKlass = appManager.createApi<
  types.CreateKlassPayload,
  types.Klass,
  AppState
>('CREATE_KLASS', {
  path: '/klasses',
  method: 'POST',
  successReducer(state, result) {
    addElementToIndexedArray(state.persons.items, result);
  },
});
