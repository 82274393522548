import { appManager, AppState } from '../manager';
import { arrayToIndexedObject } from 'services/indexedObject';

import * as types from './types';

export const fetchBadges = appManager.createApi<
  unknown,
  types.Badge[],
  AppState
>('FETCH_BADGES', {
  path: '/badges',
  method: 'GET',
  successReducer(state, result) {
    state.badges.items = arrayToIndexedObject(result);
  },
});
