import EditableTable from '../EditableTable';
import {
  clearBenchmarkPreview,
  clearIndexFundPreview,
  deleteIndexFund,
  IndexFund,
  requestBenchmarkPreview,
  requestIndexFundPreview,
  selectors,
  updateIndexFund,
} from 'state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'services';
import CreateIndexFundModal from './CreateIndexFundModal';
import { Button, Spin } from 'antd';
import IndexFundPreviewModal from './IndexFundPreviewModal';
import { findRenderedDOMComponentWithClass } from 'react-dom/test-utils';
import RefreshIndexFundModal from './RefreshIndexFundModal';

const IndexFundsTab: React.FC = () => {
  const dispatch = useDispatch();
  const indexFunds = useSelector(selectors.indexFundsList);
  const waiting = useSelector(selectors.waiting);
  const [CreateModal, showCreateModal] = useModal(CreateIndexFundModal);
  const [refreshModal, showRefreshModal] = useModal(RefreshIndexFundModal);
  const [showPreview, setShowPreview] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const indexFundData = useSelector(selectors.indexFundPreviewData);

  useEffect(() => {
    if (indexFundData) {
      setPreviewLoading(false);
      setShowPreview(true);
    } else {
      setShowPreview(false);
    }
  }, [indexFundData]);
  useEffect(() => {
    // Cleanup function
    return () => {
      dispatch(clearIndexFundPreview({}));
    };
  }, []);

  return (
    <>
      {refreshModal}
      {CreateModal}
      <div style={{ marginBottom: 10 }}>
        <Button type="primary" onClick={() => showCreateModal({})}>
          Create
        </Button>
        <Button
          // type="primary"
          onClick={() => showRefreshModal({})}
          style={{ marginLeft: 10 }}
        >
          Refresh EoD
        </Button>
        {previewLoading && <Spin style={{ marginLeft: 10 }} />}
      </div>
      <EditableTable
        data={indexFunds}
        onSave={(a: IndexFund) => {
          dispatch(updateIndexFund(a));
        }}
        onDelete={(item: IndexFund) => {
          dispatch(deleteIndexFund(item));
        }}
        buttons={[
          {
            onClick: record => {
              // console.log('yes');
              // setShowPreview(true);
              setPreviewLoading(true);
              // setShowPreview(true);
              dispatch(requestIndexFundPreview({ index_fund_id: record.id }));
            },
            title: 'Show EoD',
          },
        ]}
      />
      {showPreview && (
        <IndexFundPreviewModal
          visible={showPreview}
          hide={() => {
            setShowPreview(false);
          }}
        />
      )}
    </>
  );
};

export default IndexFundsTab;
