import React from 'react';
import {
  useLocation,
  Redirect as DefaultRedirect,
  Link as DefaultLink,
} from 'react-router-dom';

type Props = {
  to: string;
};

export const Redirect: React.FC<Props> = ({ to }) => {
  const { search } = useLocation();

  return <DefaultRedirect to={to + search} />;
};

export const Link: React.FC<Props> = ({ to, children }) => {
  const { search } = useLocation();

  return <DefaultLink to={to + search}>{children}</DefaultLink>;
};
