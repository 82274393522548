import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Form, Row, Col, Input, Button, Popover, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { API_URL } from 'settings';
import { createKlass, selectors } from 'state';
import TrainerForm from './TrainerForm';
import TraineesForm from './TraineesForm';
import NamesGenerator from './NamesGenerator';

const removeSpaces = (str: string) => str?.replace(/\s+/, '');

const CreateKlass: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [fileSelected, setFileSelected] = useState(false);
  const [data, setData] = useState(new FormData());
  const token = useSelector(selectors.token);

  const processData = () => {
    const _data = form.getFieldsValue();
    const data = {
      name: removeSpaces(_data.klassName),
      trainer: {
        name: removeSpaces(_data.trainerName),
        password: removeSpaces(_data.trainerPassword),
      },
      trainees: {
        names: _data.traineeNames.split(',').map(removeSpaces),
        password: removeSpaces(_data.traineePassword),
      },
    };
    dispatch(createKlass(data));
  };

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const raw = e.target.files as FileList;
    const formData = new FormData();
    if (raw[0]) formData.append('klass_details_file', raw[0], raw[0].name);
    setData(formData);
    setFileSelected(true);
  };

  const uploadHandler = async () => {
    fetch(`${API_URL}/klasses/upload_klass_csv/`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then(() => {
        (document.getElementById(
          'klass_details_file'
        ) as HTMLInputElement).value = '';
        message.success('Successful operation');
        setFileSelected(false);
      })
      .catch(error => {
        message.error('An error has occurred');
        console.log(error);
      });
  };
  const infoHover = (
    <div>
      <p>klass_name,trainer,trainer_pass,user_pass,users,persons</p>
      <p>
        Example entry:
        test_klass1,trainer1,,1234,Team1,&quot;Tudor,Livia,Vali&quot;
      </p>
      <p>
        -Leave trainer_pass empty to use existing trainer, fill it to create new
        trainer.
      </p>
      <p> -Persons must be separated by a comma, no spaces</p>
    </div>
  );

  return (
    <Card title="Create Class">
      <Form form={form} onFinish={processData} className="generate_class">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Class Name"
              name="klassName"
              rules={[{ required: true }]}
            >
              <Input placeholder="DEMO_CLASS" style={{ maxWidth: 400 }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <TraineesForm />
            <TrainerForm />
          </Col>
          <Col span={12}>
            <NamesGenerator
              setNames={names => form.setFieldsValue({ traineeNames: names })}
            />
          </Col>
        </Row>
        <Button
          type="primary"
          onClick={form.submit}
          style={{ marginBottom: 10 }}
        >
          Create Class
        </Button>
        <div style={{ display: 'inline-block', float: 'right' }}>
          <Popover
            placement={'right'}
            content={infoHover}
            title=".csv header must look like:"
          >
            <InfoCircleOutlined />
          </Popover>
          <Button
            disabled={!fileSelected}
            style={{ marginLeft: 10 }}
            onClick={uploadHandler}
          >
            Upload
          </Button>
          <input
            style={{
              marginLeft: '8px',
              marginBottom: '12px',
              display: 'inline-block',
              width: 200,
            }}
            type="file"
            id="klass_details_file"
            name="klass_details_file"
            accept=".csv"
            onChange={e => handleFileSelection(e)}
          />
        </div>
      </Form>
    </Card>
  );
};

export default CreateKlass;
