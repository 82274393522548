import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Typography } from 'antd';

import RegionTable from './RegionTable';
import { selectors } from 'state';
import RegionBarChart from './RegionBarChart';
import RegionPieChart from './RegionPieChart';

const ReturnByRegion: React.FC = () => {
  const { allow_short_sell } = useSelector(selectors.activeSession);

  const Chart = allow_short_sell ? RegionBarChart : RegionPieChart;

  return (
    <Row style={{ flexFlow: 'nowrap' }} gutter={10}>
      <Col flex="1">
        <Typography.Title level={3}>Return by Region</Typography.Title>
        <RegionTable />
      </Col>
      <Col flex="1">
        <Typography.Title level={3}>Allocation by Region</Typography.Title>
        <Chart />
      </Col>
    </Row>
  );
};

export default ReturnByRegion;
