import React, { createContext, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'state';

type logsContextType = {
  selectedRows: React.Key[];
  setSelectedRows: (selected: React.Key[]) => void;
  showOnlyActivePlayers: boolean;
  setShowOnlyActivePlayers: (showOnlyActivePlayers: boolean) => void;
};

export const TrainerSessionContext = createContext<logsContextType>({
  selectedRows: [],
  // Using an empty function here makes sense, since you provide an empty, default value.
  // Otherwise, you have to check if setSelected is undefined every time you'd want to use it, which is annoying.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedRows: () => {},
  showOnlyActivePlayers: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowOnlyActivePlayers: () => {},
});

export function TrainerSessionContextWrapper(props: {
  children: ReactElement;
}) {
  const players = useSelector(selectors.playersList);
  const activePlayers = players.filter(player => player.deals !== 0);
  let defaultShowState = false;
  let defaultSelectedState: React.Key[] = [];
  if (players !== undefined && players[0] !== undefined) {
    defaultSelectedState = [players[0].id];
  }
  if (activePlayers !== undefined && activePlayers.length > 0) {
    defaultShowState = true;
    defaultSelectedState = [activePlayers[0].id];
  }

  const [selectedRows, setSelectedRows] = useState<React.Key[]>(
    defaultSelectedState
  );
  const [showOnlyActivePlayers, setShowOnlyActivePlayers] = useState<boolean>(
    defaultShowState
  );

  return (
    <TrainerSessionContext.Provider
      value={{
        selectedRows: selectedRows,
        setSelectedRows: setSelectedRows,
        showOnlyActivePlayers: showOnlyActivePlayers,
        setShowOnlyActivePlayers: setShowOnlyActivePlayers,
      }}
    >
      {props.children}
    </TrainerSessionContext.Provider>
  );
}
