import React from 'react';

import { Space, Statistic, Typography, Divider } from 'antd';

import { formatters } from 'services';
import type { IndividualReport } from 'state';

type Props = {
  report: IndividualReport;
};

const Returns: React.FC<Props> = ({ report }) => {
  const { returns } = report;

  return (
    <div>
      <Divider orientation="left">
        <Typography.Title level={3}>Returns</Typography.Title>
      </Divider>

      <Space size={10}>
        <Statistic
          title="Returns %"
          value={returns.returns_percent}
          formatter={formatters.percent2Digit}
        />

        <Divider type="vertical" />
        <Statistic
          title="Realized Return"
          value={returns.realized_return}
          formatter={formatters.commasNoDigits}
        />

        <Divider type="vertical" />
        <Statistic
          title="Unrealized Return"
          value={returns.unrealized_return}
          formatter={formatters.commasNoDigits}
        />
        <Divider type="vertical" />

        <Divider type="vertical" />
        <Statistic
          title="Fx PnL"
          value={returns.fx_return}
          formatter={formatters.commas2Digits}
        />
        <Divider type="vertical" />

        {/*<Divider type="vertical" />*/}
        {/*<Statistic*/}
        {/*  title="Gross Profit"*/}
        {/*  value={returns.gross_profit}*/}
        {/*  formatter={formatters.commasNoDigits}*/}
        {/*/>*/}

        {/*<Divider type="vertical" />*/}
        {/*<Statistic*/}
        {/*  title="Gross Loss"*/}
        {/*  value={returns.gross_loss}*/}
        {/*  formatter={formatters.commasNoDigits}*/}
        {/*/>*/}

        {/*<Divider type="vertical" />*/}
        {/*<Statistic*/}
        {/*  title="Total Net Return"*/}
        {/*  value={returns.total_net_return}*/}
        {/*  formatter={formatters.commasNoDigits}*/}
        {/*/>*/}
      </Space>
    </div>
  );
};

export default Returns;
