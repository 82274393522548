import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Space, Typography } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/es/table';

import {
  fetchSessions,
  fetchKlasses,
  selectors,
  SESSION_STATUSES,
  fetchPersons,
  FINISHED,
  SCENARIO_TYPES,
  RISK_APPROACHES,
  Session,
} from 'state';
import { formatters } from 'services';

const Sessions: React.FC = () => {
  const dispatch = useDispatch();
  const sessions = useSelector(selectors.sessions);
  const klasses = useSelector(selectors.klasses);

  useEffect(() => {
    dispatch(fetchSessions({}));
    dispatch(fetchKlasses({}));
    dispatch(fetchPersons({}));
  }, [dispatch]);

  return (
    <div>
      <Typography.Title level={4}>Sessions</Typography.Title>

      <Table
        rowKey="id"
        bordered
        dataSource={sessions as Session[]}
        pagination={false}
        columns={
          [
            {
              title: 'Id',
              dataIndex: 'id',
              key: 'id',
              sorter: (a, b) => a.id - b.id,
            },
            {
              title: 'Class',
              dataIndex: 'klass',
              key: 'klass',
              render: (klass: number) => klasses[klass]?.name,
              sorter: (a, b) => a.klass - b.klass,
            },
            {
              title: 'Type',
              dataIndex: 'scenario_type',
              key: 'scenario_type',
              render: (scenario_type: keyof typeof SCENARIO_TYPES) =>
                SCENARIO_TYPES[scenario_type],
            },
            {
              title: 'Risk approach',
              dataIndex: 'risk_approach',
              key: 'risk_approach',
              render: (risk_approach: keyof typeof RISK_APPROACHES) =>
                RISK_APPROACHES[risk_approach],
            },
            {
              title: 'Created date',
              dataIndex: 'create_date',
              render: function StartDate(value) {
                return value ? formatters.dateWithTime(value) : '-';
              },
              sorter: (a, b) => a.create_date.localeCompare(b.create_date),
              defaultSortOrder: 'descend',
            },
            {
              title: 'Finish date',
              dataIndex: 'finish_date',
              render: function EndDate(value) {
                return value ? formatters.dateWithTime(value) : '-';
              },
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (status: 0 | 1 | 2 | 3) => SESSION_STATUSES[status],
              sorter: (a, b) => a.status - b.status,
            },
            {
              title: 'Action',
              key: 'action',
              render: function Action(text, record) {
                return (
                  <Space key={record.id} size="middle">
                    <Link target="_blank" to={`/session/${record.id}`}>
                      {record.status !== FINISHED ? 'Join' : 'View Returns'}
                    </Link>
                  </Space>
                );
              },
            },
          ] as (ColumnType<Session> | ColumnGroupType<Session>)[]
        }
      />
    </div>
  );
};

export default Sessions;
