import React, { useState } from 'react';
import { Button, InputNumber, Modal, Card, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, breakDeposit, DepositInstrument, makeDeposit } from 'state';
import { formatters } from 'services';
import { AreaChart } from 'components';

type Props = {
  visible: boolean;
  hide: () => void;
};

const InstrumentPreviewModal: React.FC<Props> = ({ visible, hide }) => {
  const session = useSelector(selectors.activeSession);

  const bid = session.instrument_preview.bid.map((item, index) => ({
    x: index * session.frequency + 1,
    y: item,
  }));
  const ask = [
    {
      name: 'ask',
      data: session.instrument_preview.ask.map((item, index) => ({
        x: index * session.frequency + 1,
        y: item,
      })),
    },
  ];

  const xaxis = {
    tooltip: {
      enabled: false,
    },
    labels: {
      formatter: function (value: unknown) {
        return `Day ${value}`;
      },
      show: false,
    },
  };
  return (
    <Modal
      visible={visible}
      onCancel={hide}
      title={`${session.instrument_preview.asset_name} price preview for ${
        session.start_date.split('T')[0]
      } to ${session.end_date.split('T')[0]}`}
      footer={
        [
          /* leave empty to delete ok cancel buttons*/
        ]
      }
    >
      <AreaChart
        title=""
        dataSeriesName="bid"
        data={bid}
        yaxisFormatter={formatters.commas2Digits}
        xaxis={xaxis}
        // tooltipDateFormat={'d MMM HH:mm'}
        additionalLines={ask}
      />
    </Modal>
  );
};

export default InstrumentPreviewModal;
