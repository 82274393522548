import React from 'react';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons/lib';

type Props = {
  price: string;
  priceDiff: number;
};

const PriceUp = <CaretUpOutlined style={{ color: 'darkgreen' }} />;
const PriceDown = <CaretDownOutlined style={{ color: 'orangered' }} />;

const PriceCell: React.FC<Props> = ({ price, priceDiff }) => {
  return (
    <div>
      {price}
      {priceDiff < 0 && PriceUp}
      {priceDiff > 0 && PriceDown}
    </div>
  );
};

export default React.memo(PriceCell);
