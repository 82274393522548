import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectors } from 'state';
import { useCookieProvider } from './CookieProvider';
// import tradingAudio from 'assets/media/trading.mp3';

const tradingAudio =
  'https://s3.us-west-2.amazonaws.com/traderion.pm.static/trading.mp3';

const SoundPlayer: React.FC = () => {
  const session = useSelector(selectors.activeSession);
  const [muted, setMuted] = useState(true);
  const { soundEnabled } = useCookieProvider();

  useEffect(() => {
    setTimeout(() => setMuted(false), 1000);
  }, []);

  if (!session?.allow_sound || soundEnabled !== 'enabled') {
    return null;
  }

  return (
    <audio style={{ width: 0, height: 0 }} autoPlay loop muted={muted}>
      <source src={tradingAudio} type="audio/ogg" />
      <source src={tradingAudio} type="audio/mpeg" />
    </audio>
  );
};

export default SoundPlayer;
