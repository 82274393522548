import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space } from 'antd';

import {
  CREATING,
  PAUSED,
  selectors,
  REAL_TIME_SCENARIO,
  CASH_APPROACH,
  FROZEN,
} from 'state';
import ExchangeModal from '../ExchangeModal';
import Deposits from './Deposits';
import RatesOfferModal from '../RatesOfferModal';
import RequestsModal from '../RequestsModal';

import CashAccounts from './components_of_index/CashAccounts';
import ClientMoneyAndCashAccounts from './components_of_index/ClientMoneyAndCashAccounts';
import { fetchClientMoney } from '../../../../state/app/client_money/actions';

export type CashTableType = {
  outstanding: number;
  id: number;
  currency: number;
  amount: number;
  collateral_amount: number;
  total_currency_interest: number;
  name: string;
  symbol: string;
};

const CashTab: React.FC = () => {
  const dispatch = useDispatch();
  const session = useSelector(selectors.activeSession);
  const [visibleExchange, setVisibleExchange] = useState(false);
  const [visibleRatesModal, setVisibleRatesModal] = useState(false);
  const [visibleRequestsModal, setVisibleRequestsModal] = useState(false);

  const isPaused = session.status === PAUSED || session.status === CREATING;

  const isRealTime = session.scenario_type === REAL_TIME_SCENARIO;
  const isCashApproach = session.risk_approach === CASH_APPROACH;
  const showDeposits = isRealTime && isCashApproach;

  return (
    <div>
      <Button
        type="primary"
        onClick={() => setVisibleExchange(true)}
        disabled={isPaused || session.status === FROZEN}
        style={{ marginBottom: 15 }}
      >
        Cash Exchange
      </Button>
      {session.client_money && (
        <Space>
          <Button
            type="primary"
            onClick={() => setVisibleRatesModal(true)}
            disabled={isPaused || session.status === FROZEN}
            style={{ marginBottom: 15, marginLeft: 15 }}
          >
            Adjust Client Rates
          </Button>
          <Button
            type="primary"
            onClick={() => setVisibleRequestsModal(true)}
            disabled={isPaused || session.status === FROZEN}
            style={{ marginBottom: 15, marginLeft: 5 }}
          >
            Interbank Requests
          </Button>
        </Space>
      )}

      {session.client_money ? (
        <ClientMoneyAndCashAccounts />
      ) : (
        <div>
          <CashAccounts use_card={false} />
          <br />
          {showDeposits && <Deposits />}
        </div>
      )}

      {visibleExchange && (
        <ExchangeModal
          visible={visibleExchange}
          hide={() => setVisibleExchange(false)}
        />
      )}

      {visibleRatesModal && (
        <RatesOfferModal
          visible={visibleRatesModal}
          hide={() => setVisibleRatesModal(false)}
        />
      )}

      {visibleRequestsModal && (
        <RequestsModal
          visible={visibleRequestsModal}
          hide={() => setVisibleRequestsModal(false)}
        />
      )}
    </div>
  );
};

export default CashTab;
