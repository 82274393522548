import React, { useState } from 'react';
import { Button, InputNumber, Modal, Card, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, breakDeposit, DepositInstrument, makeDeposit } from 'state';
import { formatters } from 'services';
import { selectedPerson } from '../../../../state/app/selectors';
import { AreaChart } from 'components';

type Props = {
  visible: boolean;
  hide: () => void;
};

const IndexFundPreviewModal: React.FC<Props> = ({ visible, hide }) => {
  const indexFundData = useSelector(selectors.indexFundPreviewData);
  // console.log(indexFundData);
  const data = indexFundData.data.map((item, index) => ({
    x: item.timestamp,
    y: item.close,
  }));

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      title={`All EoD datapoints for: ${indexFundData.ric}`}
      footer={
        [
          /* leave empty to delete ok cancel buttons*/
        ]
      }
    >
      <AreaChart
        title=""
        dataSeriesName={indexFundData.ric}
        data={data}
        yaxisFormatter={formatters.commasNoDigits}
        // xaxis={xaxis}
        // tooltipDateFormat={'d MMM HH:mm'}
        // additionalLines={additionalDataSeries}
      />
    </Modal>
  );
};

export default IndexFundPreviewModal;
