import React from 'react';
import { Empty } from 'antd';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import { formatters } from 'services';
import { REGIONS, selectors } from 'state';

const RegionBarChart: React.FC = () => {
  const instruments = useSelector(selectors.financialInstrumentsList);
  const data = useSelector(selectors.pnlByRegion(instruments));

  if (!data.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const series = [
    {
      name: 'Short',
      data: data.map(group => ({
        x: REGIONS[group.region],
        y: group.short,
      })),
    },
    {
      name: 'Long',
      data: data.map(group => ({
        x: REGIONS[group.region],
        y: group.long,
      })),
    },
  ];

  return (
    <div style={{ marginTop: '-30px' }}>
      <Chart
        series={series}
        type="bar"
        options={{
          chart: {
            width: '100%',
            type: 'bar',
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          tooltip: {
            y: {
              formatter: formatters.million,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              formatter: formatters.million,
            },
          },
        }}
      />
    </div>
  );
};

export default RegionBarChart;
