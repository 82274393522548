import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Button,
  Table,
  Popover,
  Space,
  Empty,
  Typography,
  Popconfirm,
} from 'antd';

import {
  selectors,
  ForwardContract,
  closeForwardContract,
  PAUSED,
  CDSContractType,
  REAL_TIME_SCENARIO,
  TBillContractType,
  FROZEN,
  sellTBillContract,
} from 'state';
import { formatters } from 'services';
import { cashExchange, useReportingCurrency } from '../../state/app/utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {
  CDSContractsOfInstrument,
  TBillContractsOfInstrument,
} from '../../state/app/selectors';
import { ONGOING_TBILL_CONTRACT } from '../../state/app/auctions/types';

type props = {
  visible: boolean;
  hide: () => void;
  id: number;
};

type displayTBillContract = {
  contract: TBillContractType;
  key: number;
  name: string;
  // open_date: string;
  currency_symbol: string;
  open_price: string;
  amount: string;
  reporting_unrealized_pnl: string;
  reporting_value: string;
  // value: string; MKT_VAL INSTEAD
};

const TBillContractsModal: React.FC<props> = ({ visible, hide, id }) => {
  const dispatch = useDispatch();
  const contracts = useSelector(selectors.TBillContractsOfInstrument(id));
  const fin = useSelector(selectors.financialInstrumentById(id));
  const asset = useSelector(selectors.underlyingAssetOfInstrument(fin));
  // console.log('asset.bid: ', asset.bid);
  const currency = useSelector(selectors.currencyById(asset.base_currency));
  const session = useSelector(selectors.activeSession);
  const reportingCurrency = useReportingCurrency(session.id);
  const data: displayTBillContract[] = contracts
    .filter(contract => contract.status === ONGOING_TBILL_CONTRACT)
    .map(contract => {
      let maturity: string;

      const maturityDate = new Date(contract.scenario_date_maturity_date);
      const sessionDate = new Date(session.scenario_date);
      const days = Math.floor(
        (maturityDate.getTime() - sessionDate.getTime()) / (1000 * 60 * 60 * 24)
      );
      if (days === 0) {
        maturity = 'today';
      } else if (days === 1) {
        maturity = 'in 1 day';
      } else {
        if (days < 0) {
          maturity = 'matured';
        } else {
          maturity = `in ${days} days`;
        }
      }

      return {
        contract: contract,
        key: contract.id,
        name: asset.name,
        // open_date: formatters.dateWithTime(
        //   contract.scenario_date_created_timestamp
        // ),
        currency_symbol: currency.symbol,
        amount: formatters.commasNoDigits(contract.amount),
        reporting_unrealized_pnl: formatters.commas2Digits(
          contract.tbill_contract_reporting_unrealized_pnl
        ),
        open_price: formatters.commas3Digits(contract.open_price),
        // mkt_val: formatters.commasNoDigits(
        //   contract.cds_contract_current_mkt_value
        // ),
        // premium_paid: formatters.commasNoDigits(contract.premium_paid),
        // value: formatters.commas2Digits(-1 * reporting_value), MKT_VAL INSTEAD !!
        reporting_value: formatters.commas2Digits(
          (contract.amount * asset.bid) / 100
        ),
        maturity: maturity,
      };
    });

  // console.log('cds_data: ', data);

  const ActionButton = (contract: displayTBillContract) => {
    // const isClosingContract = contract.contract.closing_contract_for !== null;
    // const isClosed = contract.contract.closed_by !== null;
    const sessionPaused = session.status === PAUSED;
    // if (isClosingContract) {
    //   return `Offsetting #${contract.contract.closing_contract_for}`;
    // }
    // if (isClosed) {
    //   return `Offset by #${contract.contract.closed_by}`;
    // }
    const title = (
      <div>
        {/*<div>Are you sure?</div>*/}
        <div style={{ width: 250 }}>Sell TBill before term?</div>
      </div>
    );
    return (
      <Popconfirm
        title={title}
        onConfirm={() => {
          // handleCloseContract(contract);
          // hide();
          dispatch(sellTBillContract({ contract_id: contract.contract.id }));
        }}
        disabled={sessionPaused || session.status === FROZEN}
      >
        <Button
          type={'link'}
          disabled={sessionPaused || session.status === FROZEN}
        >
          Sell TBill
        </Button>
      </Popconfirm>
    );
  };

  const columns: ColumnsType<displayTBillContract> = [
    {
      title: 'Series',
      dataIndex: 'name',
    },
    {
      title: 'CCY',
      dataIndex: 'currency_symbol',
    },
    {
      title: 'Open Price',
      dataIndex: 'open_price',
    },
    {
      // title: `Amount, ${reportingCurrency.symbol}`,
      title: `Amount`,
      dataIndex: 'amount',
    },
    // {
    //   title: `Mkt Val, ${reportingCurrency.symbol}`,
    //   dataIndex: 'mkt_val',
    // },
    {
      title: `Unrealized, ${reportingCurrency.symbol}`,
      dataIndex: 'reporting_unrealized_pnl',
      render: (text, record) => {
        // console.log(
        //   'rep_unrlzd',
        //   record.contract.tbill_contract_reporting_unrealized_pnl
        // );
        return record.contract
          ? formatters.commasNoDigits(
              record.contract.tbill_contract_reporting_unrealized_pnl
            )
          : null;
      },
    },
    {
      title: `Value, ${reportingCurrency.symbol}`,
      dataIndex: 'reporting_value',
      // render: (text, record) => {
      //   return record.contract
      //     ? formatters.commasNoDigits(
      //         record.contract.tbill_contract_reporting_unrealized_pnl
      //       )
      //     : null;
      // },
    },
    {
      title: 'Maturity',
      dataIndex: 'maturity',
    },
    {
      title: 'Action',
      align: 'center',
      render: (val: any, record: displayTBillContract) => ActionButton(record),
    },
  ];

  // if (session.scenario_type === REAL_TIME_SCENARIO) {
  //   columns.push({
  //     title: 'Open',
  //     dataIndex: 'open_date',
  //   });
  // }

  // columns.push();

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`You don't have any active TBill contracts yet.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };
  // console.log('before return');
  return (
    <Modal
      visible={visible}
      title={`Your active ${asset.name} TBill Contracts`}
      onOk={hide}
      onCancel={hide}
      // style={{ width: '70%' }}
      width={'70%'}
      // footer={[
      //   <Button
      //     key="place"
      //     type="primary"
      //     disabled={quantity <= 0 || quantity > maxQuantity}
      //     onClick={() => {
      //       dispatch(
      //         buyForwardContract({
      //           financial_instrument_id: financialInstrument.id,
      //           amount: quantity,
      //           transaction: transaction,
      //         })
      //       );
      //       hide();
      //     }}
      //   >
      //     Buy contract
      //   </Button>,
      // ]}
    >
      <Table
        dataSource={data}
        columns={columns}
        bordered
        locale={noDataBehaviour}
      ></Table>
    </Modal>
  );
};

export default TBillContractsModal;
