import React from 'react';
import { useSelector } from 'react-redux';
import { Empty, Typography } from 'antd';
import Chart from 'react-apexcharts';

import { formatters } from 'services';
import { selectors } from 'state';

const BondDurationBreakdown: React.FC = () => {
  const bondsValueDistribution = useSelector(selectors.bondsValueDistribution);
  const { series, categories } = bondsValueDistribution;

  if (!categories.length) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <div style={{ minWidth: 242 }}>
      <Typography.Title
        level={4}
        style={{ whiteSpace: 'nowrap', marginBottom: 0 }}
      >
        Bond Duration Breakdown
      </Typography.Title>
      <Chart
        height={352}
        series={series}
        type="bar"
        options={{
          chart: {
            width: '100%',
            type: 'bar',
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories,
            labels: {
              style: {
                fontSize: '12px',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: formatters.million,
            },
          },
        }}
      />
    </div>
  );
};

export default BondDurationBreakdown;
