import queryString from 'query-string';
import { select } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { head } from 'lodash';
import { CSRF_COOKIE_NAME } from 'settings';

type apiParams<Payload> = {
  path: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  data?: Payload;
  token?: string;
  apiUrl: string;
};

export default async function apiCaller<Payload>({
  path,
  method = 'GET',
  data,
  token,
  apiUrl,
}: apiParams<Payload>) {
  const query = '?' + queryString.stringify((data as any) || {});

  let url = `${apiUrl}${path}`;
  if (!url.endsWith('/')) url += '/';

  if (method === 'GET') url += query;

  const urlParams = path.split('/').filter(s => s[0] === ':');

  urlParams.forEach(param => {
    const key = param.slice(1);
    url = url.replace(param, (data as any)[key]);
    (data as any)[key] = undefined;
  });

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  if (token) {
    headers.append('Authorization', `JWT ${token}`);
  }
  // console.log('CSRF_COOKIE_NAME: ', CSRF_COOKIE_NAME);
  const csrfToken = Cookies.get(CSRF_COOKIE_NAME);
  // console.log('csrfToken: ', csrfToken);
  if (method === 'POST' && csrfToken) {
    // console.log('within csrf if');
    headers.append('x-csrftoken', csrfToken);
  }
  // console.log('REQUEST-------------------------');
  // console.log('csrfToken: ', csrfToken);
  // console.log('method: ', method);
  // console.log('data: ', data);
  // console.log('headers: ', headers);
  // console.log('path: ', path);
  // for (const [key, value] of headers.entries()) {
  //   console.log(`${key}: ${value}`);
  // }

  const response = await fetch(url, {
    headers,
    method,
    // this might actually not be used in prod/stage, only for local
    credentials: 'include',
    body: method !== 'GET' ? JSON.stringify(data) : undefined,
  });

  try {
    return { result: await response.json(), status: response.status };
  } catch (e) {
    return { result: e, status: response.status };
  }
}
