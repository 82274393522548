import { appManager, AppState } from '../manager';
import * as types from '../types';
import { makeAPIProp, makeAPIPropByTenor } from 'services/indexedObject';
import {
  CurrentDepos,
  CurrentLoans,
  FutureDepos,
  FutureLoans,
  PastDepos,
  PastLoans,
  ReceiveClientCards,
} from './types';
import { LoanRateOfferOptions } from '../types';

export const fetchClientMoney = appManager.createApi<
  { session_id: number },
  ReceiveClientCards,
  AppState
>('GET_CLIENT_LOANS', {
  path: '/client_money/get_client_loans_depos',
  method: 'POST',
  startReducer(state) {
    state.client_money.fetching = true;
    state.client_money.fetched = false;
  },
  successReducer(state, result) {
    // console.log('client_money: ', result);
    state.client_money.current_loans = makeAPIPropByTenor(result.current_loans);
    state.client_money.past_loans = makeAPIPropByTenor(result.past_loans);
    state.client_money.future_loans = makeAPIPropByTenor(result.future_loans);

    state.client_money.current_depos = makeAPIPropByTenor(result.current_depos);
    state.client_money.past_depos = makeAPIPropByTenor(result.past_depos);
    state.client_money.future_depos = makeAPIPropByTenor(result.future_depos);

    state.client_money.lombard_rate = result.lombard_rate;

    state.client_money.fetched = false;
    state.client_money.fetching = true;
  },
  failReducer(state) {
    state.client_money.fetching = false;
    state.client_money.fetched = true;
  },
});

export const updateLoanRates = appManager.createApi<
  {
    loan_rates: { [key: number]: FutureLoans };
    player_id: number;
  },
  { updated_future_loan_cards: FutureLoans[] },
  AppState
>('UPDATE_LOANS_RATES', {
  path: '/client_money/update_loan_rates',
  method: 'POST',
  successReducer(state, result) {
    state.client_money.future_loans = makeAPIPropByTenor(
      result.updated_future_loan_cards
    );
  },
});

export const updateDepoRates = appManager.createApi<
  {
    depo_rates: { [key: number]: FutureDepos };
    player_id: number;
  },
  { updated_future_depo_cards: FutureDepos[] },
  AppState
>('UPDATE_DEPOS_RATES', {
  path: '/client_money/update_depo_rates',
  method: 'POST',
  successReducer(state, result) {
    state.client_money.future_depos = makeAPIPropByTenor(
      result.updated_future_depo_cards
    );
  },
});

appManager.createSocketListener<ReceiveClientCards, AppState>(
  'update_client_cards',
  (state, result) => {
    // console.log('update_client_cards: ', result);
    state.client_money.current_loans = makeAPIPropByTenor(result.current_loans);
    state.client_money.past_loans = makeAPIPropByTenor(result.past_loans);
    state.client_money.future_loans = makeAPIPropByTenor(result.future_loans);

    state.client_money.current_depos = makeAPIPropByTenor(result.current_depos);
    state.client_money.past_depos = makeAPIPropByTenor(result.past_depos);
    state.client_money.future_depos = makeAPIPropByTenor(result.future_depos);

    state.client_money.lombard_rate = result.lombard_rate;
  }
);
