import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Table, Typography } from 'antd';

import {
  selectors,
  ASSET_CLASSES,
  RISK_WEIGHTED_APPROACH,
  instrumentTypes,
  AssetClassOptions,
} from 'state';
import { formatters } from 'services';
import { ColumnType } from 'antd/es/table';

const LimitsAndWeights: React.FC = () => {
  const { financial_instruments } = useSelector(selectors.player);
  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const { asset_class_options, risk_approach } = useSelector(
    selectors.activeSession
  );

  const usedAssets = Object.values(
    Object.values(financial_instruments).reduce((acc, instrument) => {
      const { resourcetype } = underlyingAssets[instrument.underlying_asset];
      const assetClass = instrumentTypes.find(it => it.type === resourcetype);
      acc[resourcetype] = assetClass?.assetClass || -1;
      return acc;
    }, {} as { [resourcetype: string]: unknown })
  );

  const isRiskWeighted = risk_approach === RISK_WEIGHTED_APPROACH;

  const columnsMem: ColumnType<AssetClassOptions>[] = useMemo(() => {
    const columns: ColumnType<AssetClassOptions>[] = [
      {
        title: 'AssetClass',
        dataIndex: 'asset_class',
        render: (a: number) => ASSET_CLASSES[a],
      },
      {
        title: 'Limit',
        dataIndex: 'pv_limit',
        align: 'right',
        render: formatters.percent0Digits,
      },
    ];

    if (isRiskWeighted) {
      columns.push({
        title: 'Risk weight',
        dataIndex: 'risk_weight',
        align: 'right',
        render: formatters.percent0Digits,
      });
    }

    return columns;
  }, [isRiskWeighted]);

  const dataSource = asset_class_options.filter(asset =>
    usedAssets.includes(asset.asset_class)
  );

  return (
    <div>
      <Typography.Title level={3} style={{ whiteSpace: 'nowrap' }}>
        {risk_approach === RISK_WEIGHTED_APPROACH
          ? 'Limits & Weights'
          : 'Instrument Limits'}
      </Typography.Title>

      <Table
        rowKey="asset_class"
        bordered
        columns={columnsMem}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
};

export default LimitsAndWeights;
