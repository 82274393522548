import React, { useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { createEditor } from 'slate';
import { Editable, withReact, Slate } from 'slate-react';
import { withHistory } from 'slate-history';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  ItalicOutlined,
  MenuOutlined,
  OrderedListOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import {
  BlockButton,
  MarkButton,
  Toolbar,
  Element,
  Leaf,
  toggleMark,
} from './components';
import { HOTKEYS } from './constants';
import { RichText } from 'state';

const initialValue: RichText[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

type Props = {
  value: RichText[];
  setValue: (value: RichText[]) => void;
};

// TODO: Extend slate types module with custom text types

const SlateEditor: React.FC<Props> = ({ value, setValue }) => {
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <Slate
      editor={editor}
      value={value ?? initialValue}
      onChange={value => {
        const isAstChange = editor.operations.some(
          op => 'set_selection' !== op.type
        );
        if (isAstChange) {
          setValue(value as RichText[]);
        }
      }}
    >
      <div className="slate-editor">
        <Toolbar className="toolbar">
          <MarkButton format="bold" icon={<BoldOutlined />} />
          <MarkButton format="italic" icon={<ItalicOutlined />} />
          <MarkButton format="underline" icon={<UnderlineOutlined />} />
          <BlockButton format="heading-one" icon="H1" />
          <BlockButton format="heading-two" icon="H2" />
          <BlockButton format="block-quote" icon="Quote" />
          <BlockButton format="numbered-list" icon={<OrderedListOutlined />} />
          <BlockButton
            format="bulleted-list"
            icon={<UnorderedListOutlined />}
          />
          <BlockButton format="left" icon={<AlignLeftOutlined />} />
          <BlockButton format="center" icon={<AlignCenterOutlined />} />
          <BlockButton format="right" icon={<AlignRightOutlined />} />
          <BlockButton format="justify" icon={<MenuOutlined />} />
        </Toolbar>
        <Editable
          className="textbox"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          spellCheck
          autoFocus
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </div>
    </Slate>
  );
};

export default SlateEditor;
export { default as SlateViewer } from './SlateViewer';
