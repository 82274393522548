import React from 'react';
import { Empty } from 'antd';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import { formatters } from 'services';
import { ASSET_CLASSES, CDS, REPO, selectors } from 'state';

const AssetBarChart: React.FC = () => {
  const instruments = useSelector(selectors.financialInstrumentsList);
  const data = useSelector(selectors.pnlByAssetClass(instruments));
  // console.log('data: ', data);
  // TODO: use this until fixing the backend compute_tactical_allocations
  const dataNoReposNoCDS = data.filter(obj => {
    if (obj.asset_class === REPO || obj.asset_class === CDS) {
    } else {
      return obj;
    }
  });
  if (!data.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const series = [
    {
      name: 'Short',
      data: dataNoReposNoCDS.map(alloc => ({
        x: ASSET_CLASSES[alloc.asset_class],
        y: alloc.short,
      })),
    },
    {
      name: 'Long',
      data: dataNoReposNoCDS.map(alloc => ({
        x: ASSET_CLASSES[alloc.asset_class],
        y: alloc.long,
      })),
    },
  ];

  return (
    <div style={{ marginTop: '-30px' }}>
      <Chart
        series={series}
        type="bar"
        options={{
          chart: {
            width: '100%',
            type: 'bar',
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          tooltip: {
            y: {
              formatter: formatters.million,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              formatter: formatters.million,
            },
          },
        }}
      />
    </div>
  );
};

export default AssetBarChart;
