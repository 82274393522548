import React from 'react';
import { useSelector } from 'react-redux';

import { AreaChart } from 'components';
import { REAL_TIME_SCENARIO, selectors } from 'state';
import { formatters } from 'services';

const PortfolioValueEvolution: React.FC = () => {
  const { player_history, metrics } = useSelector(selectors.player);
  const session = useSelector(selectors.activeSession);

  const isRealTime = session.scenario_type === REAL_TIME_SCENARIO;

  const evolutionData = player_history.map((item, index) => ({
    x: isRealTime ? item.timestamp : index * session.frequency + 1,
    y: item.portfolio_return,
  }));

  const benchmarkData = metrics.map((item, index) => ({
    x: isRealTime ? item.timestamp : index * session.frequency + 1,
    y: item.market_index_return,
  }));

  if (isRealTime) {
    // Delete all the first points with return 0
    while (evolutionData.length && evolutionData[0].y === 0) {
      evolutionData.splice(0, 1);
    }
    while (benchmarkData.length && benchmarkData[0].y === 0) {
      benchmarkData.splice(0, 1);
    }
  }
  // if (session.benchmark_constant !== 0) {
  //   benchmarkData[0].y = session.benchmark_constant;
  // }
  const sessionHasNoBenchmark =
    session.benchmark_portfolio_compositions.length === 0 &&
    session.benchmark_compositions.length === 0 &&
    session.benchmark_constant === 0 &&
    !session.allow_individual_benchmark;

  const xaxis = isRealTime
    ? undefined
    : {
        tooltip: {
          enabled: false,
        },
        labels: {
          formatter: function (value: unknown) {
            return `Day ${value}`;
          },
          show: false,
        },
      };
  // console.log('evolutionData', evolutionData);

  if (sessionHasNoBenchmark) {
    return (
      <AreaChart
        title="Portfolio Return Evolution"
        dataSeriesName="Return"
        data={evolutionData}
        yaxisFormatter={formatters.percent1Digit}
        xaxis={xaxis}
        style={{ width: '100%', minWidth: '350px' }}
        tooltipDateFormat={'d MMM HH:mm'}
      />
    );
  } else {
    return (
      <AreaChart
        title="Portfolio Return Evolution"
        dataSeriesName="Return"
        data={evolutionData}
        yaxisFormatter={formatters.percent1Digit}
        xaxis={xaxis}
        style={{ width: '100%', minWidth: '350px' }}
        tooltipDateFormat={'d MMM HH:mm'}
        additionalLines={[{ name: 'Benchmark', data: benchmarkData }]}
      />
    );
  }
};

export default React.memo(PortfolioValueEvolution);
