import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import Chart from 'react-apexcharts';

import { formatters } from 'services';
import { FinancialInstrument, selectors } from 'state';

type props = {
  height: number;
};

const AFSDurationBreakdown: React.FC<props> = ({ height }) => {
  const data = useSelector(selectors.AFSDurationBreakdown());
  // console.log('duration data: ', data);
  const series = [
    {
      name: 'Holding',
      data: data,
    },
  ];

  return (
    <div style={{ minWidth: 242 }}>
      <Typography.Title
        level={4}
        style={{ whiteSpace: 'nowrap', marginBottom: 0 }}
      >
        Duration Breakdown
      </Typography.Title>
      <Chart
        height={height}
        series={series}
        type="bar"
        options={{
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          tooltip: {
            y: {
              // formatter: formatters.commas2Digits,
              formatter: (value: number) => formatters.million(value),
              // formatter: `${formatters.commas2Digits}M`,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              // formatter: formatters.commas2Digits,
              formatter: (value: number) => formatters.million(value),
            },
          },
        }}
      />
    </div>
  );
};

export default AFSDurationBreakdown;
