import {
  BUY,
  CASH_APPROACH,
  RISK_WEIGHTED_APPROACH,
  selectors,
  SELL,
  SHORT_SELL,
  utils,
} from 'state';
import { useSelector } from 'react-redux';
import usePrices from './usePrices';
import traineeSession from '../index';

const useCollateralSign = (instrumentId: number, transaction: number) => {
  const session = useSelector(selectors.activeSession);
  const instrument = useSelector(
    selectors.financialInstrumentById(instrumentId)
  );

  // SQUARE POSITION
  if (instrument.amount === 0) {
    // Buying while square
    if (transaction === BUY) {
      return 1;
    }
    // Shorting while square
    if (transaction === SHORT_SELL) {
      return 1;
    }
  }

  // LONG POSITION
  if (instrument.amount > 0) {
    // Buying while long
    if (transaction === BUY) {
      return 1;
    }
    // Selling while long
    if (transaction === SELL) {
      return -1;
    }
  }

  // SHORT POSITION
  if (instrument.amount < 0) {
    // Buying while short
    if (transaction === BUY) {
      return -1;
    }
    // Shorting while short
    if (transaction === SHORT_SELL) {
      return 1;
    }
  }
  return 1; // otherwise typescript says the return of this function is possibly undefined
};

export default useCollateralSign;
