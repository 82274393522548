import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table, Typography } from 'antd';
import { formatters } from 'services';
import { DepositInstrument, selectors } from 'state';
import DepositModal from './DepositModal';

const Deposits: React.FC = () => {
  const player = useSelector(selectors.player);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState(
    {} as DepositInstrument
  );

  const instruments = useSelector(selectors.financialInstrumentsList);
  const playableSymbols = useSelector(
    selectors.playableCurrencies(instruments)
  );
  const playableDeposits = player.deposits.filter(deposit =>
    playableSymbols.includes(deposit.currency_symbol)
  );
  const manageDepositHandler = (selectedRow: DepositInstrument) => {
    setVisibleModal(true);
    setSelectedDeposit(selectedRow);
  };

  return (
    <div>
      <Typography.Title level={4}>Deposits</Typography.Title>

      <Table
        rowKey="id"
        bordered
        dataSource={playableDeposits}
        pagination={false}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Currency',
            dataIndex: 'currency_symbol',
            key: 'currency_symbol',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
          },
          {
            title: 'Interest Rate, %',
            dataIndex: 'annualized_rate',
            key: 'annualized_rate',
            align: 'right',
            render: rate => formatters.commas2Digits(rate * 100),
          },

          {
            title: 'Initial Amount',
            dataIndex: 'initial_amount',
            key: 'initial_amount',
            align: 'right',
            render: formatters.commasNoDigits,
          },
          {
            title: 'Interest',
            dataIndex: 'accumulated_interest',
            key: 'accumulated_interest',
            align: 'right',
            render: formatters.commasNoDigits,
          },

          {
            title: 'Action',
            align: 'center',
            render: function action(selectedRow) {
              return (
                <div>
                  <Button
                    type="link"
                    onClick={() => manageDepositHandler(selectedRow)}
                  >
                    Manage Deposit
                  </Button>
                </div>
              );
            },
            key: 'action',
          },
        ]}
      />
      <DepositModal
        visible={visibleModal}
        hide={() => setVisibleModal(false)}
        deposit={selectedDeposit}
      />
    </div>
  );
};
export default Deposits;
