import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'antd';

import SAA from './SAA';
import InvestmentProfile from './InvestmentProfile';

const RiskProfile: React.FC = () => {
  return (
    <div>
      <Row justify="center">
        <Col span={8}>
          <SAA />
        </Col>
        <Col span={8}>
          <InvestmentProfile />
        </Col>
      </Row>
    </div>
  );
};

export default RiskProfile;
