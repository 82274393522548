import React from 'react';
import { Typography } from 'antd';

import {
  LayoutEditor,
  LayoutProvider,
  LayoutSelector,
  SaveButton,
} from './layouts';

const CreateItem: React.FC = () => {
  return (
    <div>
      <LayoutProvider>
        {/*<Typography.Title*/}
        {/*  level={4}*/}
        {/*  style={{ marginBottom: 10, fontStyle: 'italic' }}*/}
        {/*>*/}
        {/*  1. Select a layout*/}
        {/*</Typography.Title>*/}

        {/*<LayoutSelector />*/}

        <Typography.Title
          level={4}
          style={{ marginBottom: 10, fontStyle: 'italic' }}
        >
          Add your roadmap item below.
        </Typography.Title>

        <LayoutEditor />

        <SaveButton />
      </LayoutProvider>
    </div>
  );
};

export default CreateItem;
