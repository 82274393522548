import React from 'react';
import { useSelector } from 'react-redux';

import { Empty, Table, Typography } from 'antd';

import { selectors } from 'state';
import { formatters } from 'services';

const ReposBlotter: React.FC = () => {
  const repoContracts = useSelector(selectors.repoContracts);
  // console.log('repoContractsSettled: ', repoContracts);

  const data = repoContracts.map(contract => {
    const value =
      (contract.asset_price_at_repo_open * contract.repoed_quantity) / 100;
    // console.log('value: ', value);
    // console.log('paid_interest: ', contract.paid_interest);
    return {
      ...contract,
      value: value,
    };
  });

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`You don't have any matured repos yet.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  const columns = [
    {
      title: 'Repo',
      dataIndex: 'repo_asset_name',
    },
    {
      title: 'Open Rate',
      dataIndex: 'repo_contract_open_rate',
      render: (val: number) => formatters.commasExact2Digits(val),
    },
    {
      title: 'Repoed Asset',
      dataIndex: 'underlying_asset_name',
    },
    {
      title: 'Qty',
      dataIndex: 'repoed_quantity',
      render: (val: number) => formatters.commasNoDigits(val),
    },
    {
      title: 'At Price',
      dataIndex: 'asset_price_at_repo_open',
      render: (val: number) => formatters.commas2Digits(val),
    },
    {
      title: `Value`,
      dataIndex: 'value',
      render: formatters.commasNoDigits,
    },
    {
      title: 'CCY',
      dataIndex: 'repo_contract_ccy',
      // align: 'right',
      // render: formatters.commasNoDigits,
    },
    {
      title: `Interest`,
      dataIndex: 'paid_interest',
      render: (val: number) => {
        if (val === 0 || val === null) {
          return '--';
        } else {
          return formatters.commas1Digit(val);
        }
      },
    },
    {
      title: `Open`,
      dataIndex: 'created_timestamp',
      render: (val: string) =>
        val ? formatters.dateWithTimeSeconds(val) : '--',
    },
    {
      title: `Settled`,
      dataIndex: 'settlement_date',
      render: (val: string) =>
        val ? formatters.dateWithTimeSeconds(val) : '--',
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        bordered
        dataSource={data}
        pagination={false}
        locale={noDataBehaviour}
        columns={columns}
      />
    </div>
  );
};

export default ReposBlotter;
