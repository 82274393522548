import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Space, Typography } from 'antd';

import { Blotter } from 'reusable';
import {
  fetchAssets,
  fetchCurrencies,
  retrieveAdminPlayer,
  selectors,
} from 'state';
import { LoadingScreen } from 'components';
import OrderModal from './OrderModal';
import OpenOrders from './OpenOrders';

const Player: React.FC = () => {
  const dispatch = useDispatch();
  const { player_id, session_id } = useParams();

  const player = useSelector(selectors.playerById(player_id));
  const session = useSelector(selectors.sessionById(session_id));

  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (player?.fetched_data) return;
    dispatch(fetchAssets({}));
    dispatch(fetchCurrencies({}));
    dispatch(retrieveAdminPlayer({ player_id, session_id }));
    setLoading(true);
  }, [loading, player?.fetched_data, dispatch, player_id, session_id]);

  if (!player?.fetched_success || !session) {
    return <LoadingScreen />;
  }

  return (
    <Space direction="vertical" size={15} style={{ display: 'flex' }}>
      <Button type="primary" onClick={() => setVisibleModal(true)}>
        New Order
      </Button>

      <Typography.Title level={4} style={{ margin: 0 }}>
        Blotter
      </Typography.Title>
      <Blotter playerId={player_id} sessionId={session_id} />

      <Typography.Title level={4} style={{ margin: 0 }}>
        Open orders
      </Typography.Title>
      <OpenOrders playerId={player_id} />

      <OrderModal
        visible={visibleModal}
        hide={() => setVisibleModal(false)}
        playerId={player_id}
        sessionId={session_id}
      />
    </Space>
  );
};

export default Player;
