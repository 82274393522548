import React from 'react';
import AssetClassTable from './AssetClassTable';
import { Col, Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'state';
import AssetBarChart from './AssetBarChart';
import AssetPieChart from './AssetPieChart';

const ReturnByAssetClass: React.FC = () => {
  const { allow_short_sell } = useSelector(selectors.activeSession);

  const Chart = allow_short_sell ? AssetBarChart : AssetPieChart;

  return (
    <Row style={{ flexFlow: 'row nowrap' }} gutter={10}>
      <Col flex="1">
        <Typography.Title level={4}>Return by Asset Class</Typography.Title>
        <AssetClassTable />
      </Col>
      <Col flex="1">
        <Typography.Title level={4}>Allocation by Asset Class</Typography.Title>
        <Chart />
      </Col>
    </Row>
  );
};

export default ReturnByAssetClass;
