import { appManager, AppState } from '../manager';
import { arrayToIndexedObject } from 'services/indexedObject';
import type { initialPreset, CreateInitialPresetPayload } from './types';

export const createInitialPreset = appManager.createApi<
  CreateInitialPresetPayload,
  initialPreset,
  AppState
>('CREATE_INITIAL_PRESET', {
  method: 'POST',
  path: '/initial_portfolio_presets',
  successReducer(state, result) {
    state.initial_presets.items[result.id] = result;
  },
});

export const listInitialPresets = appManager.createApi<
  unknown,
  initialPreset[],
  AppState
>('LIST_INITIAL_PRESETS', {
  method: 'GET',
  path: '/initial_portfolio_presets',
  successReducer(state, result) {
    state.initial_presets.items = arrayToIndexedObject(result);
  },
});

export const updateInitialPreset = appManager.createApi<
  initialPreset,
  initialPreset,
  AppState
>('UPDATE_INITIAL_PRESET', {
  method: 'PATCH',
  path: '/initial_portfolio_presets/:id',
  successReducer(state, result) {
    state.initial_presets.items[result.id] = result;
  },
});
