import { appManager, AppState } from '../manager';
import type { IndividualReport } from './types';

export const getIndividualReport = appManager.createApi<
  { player_id: number },
  IndividualReport,
  AppState
>('FETCH_INDIVIDUAL_REPORT', {
  method: 'POST',
  path: '/individual_report/get',
  successReducer(state, result, payload) {
    state.individualReports[payload.player_id] = result;
  },
});

export const computeIndividualReports = appManager.createApi<
  { session_id: number },
  unknown,
  AppState
>('COMPUTE_INDIVIDUAL_REPORTS', {
  method: 'POST',
  path: '/sessions/:session_id/compute_individual_reports',
  successReducer() {
    // Do a popup
  },
  failReducer() {
    // Do a popup
  },
});
