import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { notification, Space } from 'antd';

import { EXECUTED, selectors, TRANSACTION_TYPES } from 'state';
import { formatters } from 'services';

const OrderFilled: React.FC = () => {
  const lastOrder = useSelector(selectors.lastOrder);
  const financialInstruments = useSelector(selectors.financialInstruments);
  const underlyingAssets = useSelector(selectors.underlyingAssets);

  useEffect(() => {
    if (!lastOrder) return;
    if (lastOrder.status !== EXECUTED) return;

    const {
      resourcetype,
      financial_instrument,
      open_price,
      transaction,
    } = lastOrder;

    if (resourcetype === 'MarketOrder') {
      return;
    }

    const assetId = financialInstruments[financial_instrument].underlying_asset;
    const asset = underlyingAssets[assetId];

    const price = formatters.priceFormat(
      open_price,
      asset.ticker,
      asset.resourcetype
    );

    notification.info({
      message: 'Order filled',
      description: (
        <Space direction="vertical">
          <span>Ticker: {asset.ticker}</span>
          <span>Direction: {TRANSACTION_TYPES[transaction]}</span>
          <span>Quantity: {lastOrder.quantity}</span>
          <span>Price: {price}</span>
        </Space>
      ),
    });
  }, [lastOrder?.id]);

  return null;
};

export default OrderFilled;
