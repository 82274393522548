import React from 'react';

import { Space, Statistic, Typography, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import type { IndividualReport, TopOrder, TopPosition } from 'state';
import { formatters } from 'services';

const DealDisplay: React.FC<{ deal: TopOrder }> = ({ deal }) => {
  if (deal.error) {
    return (
      <Typography.Text>
        <ExclamationCircleOutlined style={{ color: '#f5222d' }} />
        {` ${deal.error}`}
      </Typography.Text>
    );
  }

  return (
    <Space size={10}>
      <Statistic title="Instrument" value={deal.instrument} />
      <Divider type="vertical" />
      <Statistic
        title="Return"
        value={deal.value}
        formatter={formatters.commas2Digits}
      />
      <Divider type="vertical" />
      {/*<Statistic*/}
      {/*  title="Executed at"*/}
      {/*  value={new Date(deal.executed_at).toDateString()}*/}
      {/*/>*/}
      {/*<Divider type="vertical" />*/}
      <Statistic title="Quantity" value={deal.quantity} />
      <Divider type="vertical" />
      <Statistic
        title="Open"
        value={deal.open_price}
        formatter={formatters.commas4Digits}
      />
      <Divider type="vertical" />
      <Statistic
        title="Close"
        value={deal.close_price}
        formatter={formatters.commas4Digits}
      />
    </Space>
  );
};

const PositionDisplay: React.FC<{ position: TopPosition }> = ({ position }) => {
  if (position.error) {
    return (
      <Typography.Text>
        <ExclamationCircleOutlined style={{ color: '#f5222d' }} />
        {` ${position.error}`}
      </Typography.Text>
    );
  }

  return (
    <Space size={10}>
      <Statistic title="Instrument" value={position.instrument} />
      <Divider type="vertical" />
      <Statistic
        title="Return"
        value={position.value}
        formatter={formatters.commas2Digits}
      />
      {/*<Divider type="vertical" />*/}
      {/*<Statistic*/}
      {/*  title="Created at"*/}
      {/*  value={new Date(position.created_at).toDateString()}*/}
      {/*/>*/}
      {/*<Divider type="vertical" />*/}
      {/*<Statistic*/}
      {/*  title="Closed at"*/}
      {/*  value={*/}
      {/*    position.closed_at ? new Date(position.closed_at).toDateString() : '-'*/}
      {/*  }*/}
      {/*/>*/}
    </Space>
  );
};

type Props = {
  report: IndividualReport;
};

const Investments: React.FC<Props> = ({ report }) => {
  const { investments } = report;
  const {
    biggest_winner,
    biggest_loser,
    top_long_position,
    top_short_position,
  } = investments;

  return (
    <div>
      <Divider orientation="left">
        <Typography.Title level={3}>Investments</Typography.Title>
      </Divider>

      <Typography.Title level={4}>Biggest Winner</Typography.Title>
      <DealDisplay deal={biggest_winner} />
      <Divider />

      <Typography.Title level={4}>Biggest Loser</Typography.Title>
      <DealDisplay deal={biggest_loser} />
      <Divider />

      <Typography.Title level={4}>Top Long Position</Typography.Title>
      <PositionDisplay position={top_long_position} />
      <Divider />

      <Typography.Title level={4}>Top Short Position</Typography.Title>
      <PositionDisplay position={top_short_position} />
      <Divider />
    </div>
  );
};

export default Investments;
