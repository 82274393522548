import React, { ReactText, useContext } from 'react';
import { formatters } from 'services';
import { AreaChart } from 'components';
import { useSelector } from 'react-redux';
import { Metrics, Player, REAL_TIME_SCENARIO, selectors } from 'state';
import { Card, Typography } from 'antd';
import { TrainerSessionContext } from '../TrainerSessionContext';

const PlayersEvolutionGraph: React.FC = () => {
  const session = useSelector(selectors.activeSession);
  const isRealTime = session.scenario_type === REAL_TIME_SCENARIO;
  const trainerContext = useContext(TrainerSessionContext);
  const players = useSelector(selectors.playersList);

  const additionalDataSeries = trainerContext.selectedRows.map(playerId => {
    const player = players.find(player => player.id === playerId);
    let datapoints:
      | { x: ReactText; y: number }[]
      | { x: number; y: number }[] = [{ x: 0, y: 0 }];
    if (player !== undefined) {
      datapoints = player.player_history.map((datapoint, index) => {
        return {
          x: isRealTime ? datapoint.timestamp : index * session.frequency + 1,
          y: datapoint.portfolio_return,
        };
      });
    }
    return {
      name: player ? player.name : 'undefined',
      data: datapoints,
    };
  });

  let metrics: Metrics[] = [];
  if (session.allow_individual_benchmark) {
    // INDIVIDUAL BENCHMARK CASE: metrics are taken from the only selected player because each player has its own metrics
    let player: Player | undefined;
    if (trainerContext.selectedRows.length > 0) {
      player = players.find(
        player => player.id === trainerContext.selectedRows[0]
      );
    }
    if (player !== undefined) {
      metrics = player.metrics;
    }
  } else {
    // SESSION LEVEL BENCHMARK: metrics are taken from the first player, since all players will have the same benchmark
    metrics = players[0].metrics;
  }
  const benchmarkData = metrics.map((item, index) => ({
    x: isRealTime ? item.timestamp : index * session.frequency + 1,
    y: item.market_index_return,
  }));

  if (isRealTime) {
    // Delete all the first points with return 0
    // while (evolutionData.length && evolutionData[0].y === 0) {
    //     evolutionData.splice(0, 1);
    // }
    while (benchmarkData.length && benchmarkData[0].y === 0) {
      benchmarkData.splice(0, 1);
    }
  }
  // if (session.benchmark_constant !== 0) {
  //   benchmarkData[0].y = session.benchmark_constant;
  // }

  const xaxis = isRealTime
    ? undefined
    : {
        tooltip: {
          enabled: false,
        },
        labels: {
          formatter: function (value: unknown) {
            return `Day ${value}`;
          },
          show: false,
        },
      };

  return (
    <div style={{ width: 471 }}>
      <Typography.Title level={3}>
        Benchmark & Players Evolution
      </Typography.Title>
      <Card>
        <AreaChart
          title=""
          dataSeriesName="Benchmark"
          data={benchmarkData}
          yaxisFormatter={formatters.percent1Digit}
          xaxis={xaxis}
          tooltipDateFormat={'d MMM HH:mm'}
          additionalLines={additionalDataSeries}
        />
      </Card>
    </div>
  );
};

export default PlayersEvolutionGraph;
