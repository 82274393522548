import React from 'react';
import { Router } from 'react-router-dom';
import history from './state/history';
import Routes from './Routes';
import NotificationCenter from './views/NotificationCenter';
import Background from 'assets/images/login-background.jpg';
import { Typography } from 'antd';

const App: React.FC = () => {
  // const isProd = useMemo(() => {
  //   return process.env.REACT_APP_GOAL === 'production';
  // }, []);

  // if (isProd) {
  return (
    <Router history={history}>
      <Routes />
      <NotificationCenter />
    </Router>
  );
  // }

  // const gradient =
  //   'linear-gradient(' +
  //   'to top, rgba(24, 144, 255, 0.5) 0%, ' +
  //   'rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.8)' +
  //   ')';
  //
  // return (
  //   <div
  //     style={{
  //       backgroundImage: `${gradient}, url(${Background})`,
  //       backgroundPosition: 'center',
  //       backgroundSize: 'cover',
  //       backgroundRepeat: 'no-repeat',
  //       height: '100vh',
  //     }}
  //   >
  //     <div
  //       style={{
  //         position: 'absolute',
  //         top: '50%',
  //         left: '0',
  //         transform: 'translateY(-50%)',
  //         width: '100%',
  //         textAlign: 'center',
  //         background: 'rgba(255,255,255,0.9)',
  //         padding: '30px',
  //       }}
  //     >
  //       <Typography.Title level={1}>
  //         Portfolio Manager Simulator
  //       </Typography.Title>
  //       <Typography.Title level={3}>
  //         is under maintenance and will be back online soon.
  //       </Typography.Title>
  //     </div>
  //   </div>
  // );
};

App.defaultProps = {};

export default App;
