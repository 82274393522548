import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, InputNumber, Modal, Row, Select } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import { exchangeMoney, selectors, utils, Wallet } from 'state';
import { formatters } from 'services';

const formatter = (prefix: string) => (val: string | number | undefined) => {
  return `${prefix} ${formatters.commasNoDigits(val)}`;
};
const parser = (val: string | undefined) => {
  return `${val
    ?.slice(2)
    .replace(/[A-Za-z,/.`;'=+_{}|:"<>?)(*&^%$#@!~]*/g, '')}`;
};

const CurrencySelect: React.FC<{
  value: string;
  handleSelect: (wallet: Wallet) => void;
}> = ({ value, handleSelect }) => {
  const wallets = useSelector(selectors.wallets);
  const currencies = useSelector(selectors.currencies);
  const instruments = useSelector(selectors.financialInstrumentsList);
  const symbols = useSelector(selectors.playableCurrencies(instruments));

  return (
    <Select
      value={value}
      onChange={val => {
        const newWallet = wallets.find(
          item => currencies[item.currency].symbol === val
        );
        if (!newWallet) return;
        handleSelect(newWallet);
      }}
      style={{ width: 100 }}
    >
      {symbols.map(symbol => {
        return (
          <Select.Option key={symbol} value={symbol}>
            {symbol}
          </Select.Option>
        );
      })}
    </Select>
  );
};

type Props = {
  visible: boolean;
  hide: () => void;
};

const ExchangeModal: React.FC<Props> = ({ visible, hide }) => {
  const dispatch = useDispatch();
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);
  const wallets = useSelector(selectors.wallets);
  const currencies = useSelector(selectors.currencies);
  const { session } = useSelector(selectors.player);
  const reportingCurrency = utils.useReportingCurrency(session);
  const reportingWallet = useSelector(
    selectors.walletByCurrency(reportingCurrency)
  ) as Wallet;

  const [fromWallet, setFromWallet] = useState(reportingWallet);
  const [toWallet, setToWallet] = useState(wallets[0]);

  const [fromAmount, setFromAmount] = useState(0);

  useEffect(() => {
    // Get newer wallets
    const newFromWallet = wallets.find(w => w.id === fromWallet?.id);
    const newToWallet = wallets.find(w => w.id === toWallet?.id);

    // If new wallets exist, set them
    if (newFromWallet !== undefined) {
      setFromWallet(newFromWallet);
    } else {
      setFromWallet(wallets[1]);
    }

    if (newToWallet !== undefined) {
      setToWallet(newToWallet);
    } else {
      setToWallet(wallets[0]);
    }
  }, [visible, wallets]);

  const exchangeRate = utils.useExchangeRate(
    fromWallet.currency,
    toWallet.currency
  );

  const fromSymbol = currencies[fromWallet.currency]?.symbol ?? '';
  const toSymbol = currencies[toWallet.currency]?.symbol ?? '';
  const sameSymbol = fromSymbol === toSymbol;
  const notReporting = ![fromSymbol, toSymbol].includes(
    reportingCurrency.symbol
  );
  const currency_pair = utils.getCurrencyPair(
    fromWallet.currency,
    toWallet.currency,
    assetList
  );
  const disabled = sameSymbol || notReporting || currency_pair === undefined;
  //This makes sure inputs are reset to 0 in case of faulty currency pair choice (ex: USD-USD or non USD transactions)
  // useEffect(() => {
  //   if (disabled) setFromAmount(0);
  // }, [exchangeRate]);

  const handleToAmountChange = (toAmount: number | undefined) => {
    // When you input the toAmount top field, this handler fills the fromAmount bottom field.
    if (typeof toAmount !== 'number') {
      return;
    }
    const convertedFromAmount = utils.cashExchange(
      fromWallet.currency,
      toWallet.currency,
      0,
      toAmount,
      assetList,
      currencyList
    );
    setFromAmount(convertedFromAmount);
  };

  const getToAmount = () => {
    // When you input the fromAmount bottom field, this handler fills the toAmount top field.
    return utils.cashExchange(
      fromWallet.currency,
      toWallet.currency,
      fromAmount,
      0,
      assetList,
      currencyList
    );
  };

  const handleSwapCurrencies = () => {
    setFromWallet(toWallet);
    setToWallet(fromWallet);

    const newToAmount = getToAmount();
    setFromAmount(newToAmount);
  };

  const handleAllBalance = () => {
    setFromAmount(fromWallet.amount);
  };

  const handleExchange = () => {
    dispatch(
      exchangeMoney({
        from_wallet: fromWallet.id,
        to_wallet: toWallet.id,
        amount: fromAmount,
      })
    );
    setFromAmount(0);
    hide();
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      onOk={hide}
      onCancel={hide}
      title="Exchange"
      footer={
        <Row>
          <Button onClick={handleSwapCurrencies}>
            <RetweetOutlined />
          </Button>
          <Button disabled={disabled} onClick={handleAllBalance}>
            All balance
          </Button>
          <Button
            type="primary"
            disabled={
              fromAmount <= 0 || fromAmount > fromWallet.amount || disabled
            }
            onClick={handleExchange}
            style={{ marginLeft: 'auto' }}
          >
            Exchange
          </Button>
        </Row>
      }
    >
      <Row justify="space-between">
        <Col>
          <CurrencySelect
            value={currencies[toWallet.currency].symbol}
            handleSelect={setToWallet}
          />
        </Col>
        <Col>
          <InputNumber // To currency, top input
            disabled={disabled}
            style={{ width: 150 }}
            onChange={handleToAmountChange}
            value={getToAmount()}
            min={0}
            formatter={formatter('+')}
            parser={parser}
          />
        </Col>
      </Row>
      Exchange rate:{' '}
      {!disabled
        ? formatters.priceFormat(
            exchangeRate,
            currency_pair!.ticker,
            currency_pair!.resourcetype
          )
        : 'not available'}
      <hr />
      <Row justify="space-between">
        <Col>
          <Row>
            <CurrencySelect
              value={currencies[fromWallet.currency].symbol}
              handleSelect={setFromWallet}
            />
          </Row>
          <Row>Balance: {formatters.commasNoDigits(fromWallet.amount)}</Row>
        </Col>
        <Col>
          <InputNumber // From currency, bottom input
            disabled={disabled}
            style={{ width: 150 }}
            onChange={val => {
              if (val === undefined) {
                return;
              }
              setFromAmount(val);
            }}
            value={fromAmount}
            min={0}
            formatter={formatter('-')}
            parser={parser}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ExchangeModal;
