import React, { useContext } from 'react';

import { Col, Row } from 'antd';

import PortfolioValueEvolution from './PortfolioValueEvolution';
import OverviewCards from './OverviewCards';

import PriceGraph from './PriceGraph';

import {
  CASH_APPROACH,
  FinancialInstrument,
  instrumentTypes,
  selectors,
} from 'state';
import { useSelector } from 'react-redux';
import HTMDurationBreakdown from '../../PortfolioTab/Overview/HTMDurationBreakdown';
import AFSDurationBreakdown from '../../PortfolioTab/Overview/AFSDurationBreakdown';
import { TraineeSessionContext } from '../../TraineeSessionContext';
import YieldCurveGraph from './YieldCurveGraph';

type Props = {
  instrument: FinancialInstrument;
};

const PortfolioOverview: React.FC<Props> = ({ instrument }) => {
  const session = useSelector(selectors.activeSession);
  const traineeContext = useContext(TraineeSessionContext);
  const hasCashApproach = session.risk_approach === CASH_APPROACH;
  const hasHTM = useSelector(selectors.sessionHasHTMBonds);
  const hasAFS = useSelector(selectors.sessionHasAFSBonds);
  const hasEitherAFSorHTM = hasHTM || hasAFS;
  const modifiedDimensions = hasEitherAFSorHTM && hasCashApproach;
  const showHTMBreakDown =
    instrumentTypes[parseInt(traineeContext.instrumentType)].type ===
      'HTMBond' && !traineeContext.showCash;
  const showAFSBreakDown =
    instrumentTypes[parseInt(traineeContext.instrumentType)].type ===
      'AFSBond' && !traineeContext.showCash;

  const showYieldCurve =
    traineeContext.setShowCash && session.client_money && !showAFSBreakDown;

  return (
    <Row style={{ flexFlow: 'row nowrap' }}>
      {modifiedDimensions ? (
        <Col flex="0 5 22%">
          <OverviewCards />
        </Col>
      ) : (
        <Col flex="0 5 40%">
          <OverviewCards />
        </Col>
      )}

      <Col flex="0 1 20%">
        <PortfolioValueEvolution />
      </Col>
      <Col flex="0 1 24%">
        <PriceGraph instrument={instrument} />
      </Col>
      {showHTMBreakDown && (
        <Col flex="0 1 20%">
          <HTMDurationBreakdown />
        </Col>
      )}
      {showAFSBreakDown && (
        <Col flex="0 1 35%">
          <AFSDurationBreakdown height={220} />
        </Col>
      )}
      {showYieldCurve && (
        <Col flex="0 1 35%">
          <YieldCurveGraph height={260} />
        </Col>
      )}
    </Row>
  );
};

export default React.memo(
  PortfolioOverview,
  (a, b) => a.instrument.id === b.instrument.id
);
