import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input } from 'antd';
import { LoadingScreen } from 'components';

import { fetchMacroBackground, selectors, updateMacroBackground } from 'state';

const MacroBackground: React.FC = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');

  const { macro_background, fetched_macro_background, id } = useSelector(
    selectors.activeSession
  );

  useEffect(() => {
    setText(macro_background);

    if (macro_background || fetched_macro_background) return;
    dispatch(fetchMacroBackground({ session_id: id }));
  }, [macro_background, fetched_macro_background]);

  if (!fetched_macro_background) return <LoadingScreen />;

  return (
    <>
      <Button
        type="primary"
        onClick={() =>
          dispatch(
            updateMacroBackground({ session_id: id, macro_background: text })
          )
        }
      >
        save
      </Button>
      <br />
      <br />
      <Input.TextArea
        placeholder="Macro Background"
        value={text}
        autoSize
        onChange={e => setText(e.target.value)}
      />
    </>
  );
};

export default MacroBackground;
