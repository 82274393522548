import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Space, Table } from 'antd';

import {
  selectors,
  cancelOrder,
  TRANSACTION_TYPES,
  TERMS,
  ANALYSIS_TYPES,
  CREATED,
  BUY,
} from 'state';
import { formatters } from 'services';

type Props = {
  playerId: number;
};

const OpenOrders: React.FC<Props> = ({ playerId }) => {
  const dispatch = useDispatch();

  const orders = useSelector(selectors.ordersListByPlayerId(playerId));
  const financialInstruments = useSelector(
    selectors.financialInstrumentsListByPlayerId(playerId)
  );
  const currencies = useSelector(selectors.currencies);
  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const persons = useSelector(selectors.persons);
  // const { transaction_fee } = useSelector(selectors.activeSession);

  const data = orders
    .filter(order => order.status === CREATED)
    .map(order => {
      const underlying_asset_id = financialInstruments.find(
        item => item.id === order.financial_instrument
      )?.underlying_asset;
      const underlying_asset = underlyingAssets[underlying_asset_id || -1];

      const value = (order.open_price ?? order.limit_price) * order.quantity;

      return {
        ...order,
        currency: currencies[underlying_asset.variable_currency]?.symbol,
        value: !underlying_asset.resourcetype.endsWith('Bond')
          ? value
          : value / 100,
        underlying_asset_ticker: underlying_asset.ticker,
        underlying_asset_resourcetype: underlying_asset.resourcetype,
      };
    });

  const assetFilters = Object.values(
    Object.values(underlyingAssets).reduce((acc, { ticker }) => {
      acc[ticker] = { text: ticker, value: ticker };
      return acc;
    }, {} as { [key: string]: { text: string; value: string } })
  );

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={data}
      pagination={false}
      scroll={{ x: true }}
      columns={[
        {
          title: 'Asset',
          dataIndex: 'underlying_asset_ticker',
          key: 'underlying_asset_ticker',
          fixed: true,
          sorter: (a, b) =>
            a.underlying_asset_ticker.localeCompare(b.underlying_asset_ticker),
          filters: assetFilters,
          onFilter: (value, record) => {
            return value === record.underlying_asset_ticker;
          },
        },
        {
          title: 'Type',
          dataIndex: 'resourcetype',
          key: 'resourcetype',
          fixed: true,
          sorter: (a, b) =>
            a.resourcetype < b.resourcetype
              ? -1
              : a.resourcetype > b.resourcetype
              ? 1
              : 0,
        },
        {
          title: 'Transaction',
          dataIndex: 'transaction',
          key: 'transaction',
          fixed: true,
          render: (tr: number) => TRANSACTION_TYPES[tr],
          sorter: (a, b) => a.transaction - b.transaction,
        },
        {
          title: 'Currency',
          dataIndex: 'currency',
          key: 'currency',
          fixed: true,
          sorter: (a, b) => a.currency.localeCompare(b.currency),
        },
        {
          title: 'Order Price',
          dataIndex: 'limit_price',
          key: 'limit_price',
          align: 'right',
          fixed: true,
          render: (val, record) =>
            formatters.priceFormat(
              val,
              record.underlying_asset_ticker,
              record.underlying_asset_resourcetype
            ),
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
          align: 'right',
          fixed: true,
          render: (value, record) => {
            return `${
              record.transaction === BUY ? '+' : '-'
            }${formatters.commasNoDigits(value)}`;
          },
        },
        {
          title: 'Value',
          dataIndex: 'value',
          key: 'value',
          align: 'right',
          fixed: true,
          render: val => {
            return val ? formatters.commasNoDigits(val) : '--';
          },
        },
        // {
        //   title: 'Fee',
        //   dataIndex: 'fee',
        //   key: 'fee',
        //   align: 'right',
        //   render: formatters.commasNoDigits,
        // },
        {
          title: 'Timestamp',
          dataIndex: 'timestamp',
          fixed: true,
          render: val => (val ? formatters.dateWithTime(val) : '--'),
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            const date1 = formatters.dateWithTime(a.timestamp);
            const date2 = formatters.dateWithTime(b.timestamp);
            return date1.localeCompare(date2);
          },
        },
        {
          title: 'Forecast',
          dataIndex: 'forecast',
          key: 'forecast',
          render: (val, record) =>
            val !== record.open_price && val !== 0
              ? formatters.commas2Digits(val)
              : '--',
        },
        {
          title: 'Investment rationale',
          dataIndex: 'investment_rationale',
          key: 'investment_rationale',
          width: 300,
          render: text => (
            <div style={{ maxWidth: 300, whiteSpace: 'normal' }}>
              {text || '--'}
            </div>
          ),
        },
        {
          title: 'Term',
          dataIndex: 'term',
          render: val => TERMS[val as keyof typeof TERMS] || '--',
        },
        {
          title: 'Analysis',
          dataIndex: 'analysis',
          render: val =>
            ANALYSIS_TYPES[val as keyof typeof ANALYSIS_TYPES] || '--',
        },
        {
          title: 'User',
          dataIndex: 'person',
          render: val => persons[val]?.name || val,
        },
        {
          title: 'Action',
          key: 'action',
          fixed: 'right',
          render: function Action(text, record) {
            return (
              <Space key={record.id} size="middle">
                <Button
                  type="primary"
                  danger
                  onClick={() => dispatch(cancelOrder({ id: record.id }))}
                >
                  Cancel
                </Button>
              </Space>
            );
          },
        },
      ]}
    />
  );
};

export default OpenOrders;
