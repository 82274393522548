import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, Statistic } from 'antd';

import { formatters } from 'services';
import { RISK_WEIGHTED_APPROACH, selectors } from 'state';
import TacticalAssetAllocation from './TacticalAssetAllocation';
import BondDurationBreakdown from './BondDurationBreakdown';
import { useReportingCurrency } from '../../../../state/app/utils';
import HTMDurationBreakdown from './HTMDurationBreakdown';
import AFSDurationBreakdown from './AFSDurationBreakdown';

const Overview: React.FC = () => {
  const {
    // market_value,
    last_market_value,
    // fx_return,
    last_fx_return,
    // pnl_sum,
    last_pnl_sum,
    session,
    risk_weighted_value,
    metrics,
  } = useSelector(selectors.player);
  // const session = useSelector(selectors.activeSession);
  const { var05 } = metrics.slice(-1)[0];

  const portfolioPnl = useSelector(selectors.portfolioPnl());

  const reportingCurrency = useReportingCurrency(session);

  const session_obj = useSelector(selectors.activeSession);
  const isNotRiskWeighted =
    session_obj.risk_approach !== RISK_WEIGHTED_APPROACH;
  const instruments = useSelector(selectors.financialInstrumentsList);
  const ccys = useSelector(selectors.playableCurrencies(instruments));
  const differentCCYs = ccys.length > 1;
  const isNotRiskWeightedAndDifferentCCys = isNotRiskWeighted && differentCCYs;

  const sessionHasHTMassets = useSelector(selectors.sessionHasHTMBonds);
  const sessionHasAFSassets = useSelector(selectors.sessionHasAFSBonds);

  return (
    <Row style={{ flexFlow: 'row nowrap' }} gutter={16}>
      <Col>
        <Card
          size="small"
          title={'CCY: ' + reportingCurrency.symbol}
          style={{ whiteSpace: 'nowrap', width: 150 }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 13 }}>
            <Statistic
              title="Market Value"
              value={formatters.million(last_market_value)}
            />
            {isNotRiskWeighted && (
              <Statistic title="PnL" value={formatters.million(last_pnl_sum)} />
            )}
            {isNotRiskWeightedAndDifferentCCys && (
              <Statistic
                title="Cash Txn MTM"
                value={formatters.million(last_fx_return)}
              />
            )}
            <Statistic
              title="Total Return"
              value={formatters.million(last_pnl_sum)}
            />
            {!isNotRiskWeighted && (
              <>
                <Statistic
                  title="Risk Weighted Value"
                  value={formatters.million(risk_weighted_value)}
                />
                <Statistic title="VaR" value={formatters.million(var05)} />
              </>
            )}
          </div>
        </Card>
      </Col>

      <Col flex="0 1 100%">
        <TacticalAssetAllocation />
      </Col>
      <Col flex="0 1 100%">
        {/*{sessionHasHTMassets ? (*/}
        {/*  <Row style={{ flexFlow: 'row nowrap' }}>*/}
        {/*    <Col>*/}
        {/*      <HTMDurationBreakdown />*/}
        {/*    </Col>*/}
        {/*    <Col>*/}
        {/*      <AFSDurationBreakdown />*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*) : (*/}
        {/*  <BondDurationBreakdown />*/}
        {/*)}*/}
        {sessionHasAFSassets ? (
          <AFSDurationBreakdown height={350} />
        ) : (
          <BondDurationBreakdown />
        )}
      </Col>
    </Row>
  );
};

export default Overview;
