import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Col, Divider, Row, Space, Statistic } from 'antd';

import { CREATING, HISTORICAL_SCENARIO, selectors } from 'state';
import PlayersTable from './PlayersTable';
import HistoricalTimeline from './HistoricalTimeline';
import SessionDetails from './SessionDetails';
import PlayersEvolutionGraph from './PlayersEvolutionGraph';
import OverviewCards from '../../TraineeSession/MarketsTab/PortfolioOverview/OverviewCards';
import PortfolioValueEvolution from '../../TraineeSession/MarketsTab/PortfolioOverview/PortfolioValueEvolution';
import PriceGraph from '../../TraineeSession/MarketsTab/PortfolioOverview/PriceGraph';

const Overview: React.FC = () => {
  const session = useSelector(selectors.activeSession);
  const isCreating = session.status === CREATING;

  const noAssets = !session.underlying_assets.length;
  const isHistorical = session.scenario_type === HISTORICAL_SCENARIO;

  return (
    <div>
      <Row style={{ flexFlow: 'row nowrap' }} gutter={40}>
        <Col flex="0 5 26%">
          <SessionDetails />
        </Col>
        {isHistorical && (
          <Col flex="0 1 24%">
            <HistoricalTimeline />
          </Col>
        )}
        <Col flex="0 1 29.5%">{!isCreating && <PlayersEvolutionGraph />}</Col>
      </Row>

      {noAssets && (
        <Alert
          message="Please select and save the Session Instruments (Session Settings tab) in order to finish creating the session."
          type="error"
          showIcon
          style={{ marginTop: '16px', marginBottom: 16 }}
        />
      )}

      <Divider />
      <PlayersTable />
    </div>
  );
};

export default Overview;
