import React from 'react';
import { useSelector } from 'react-redux';

import HighchartsReact from 'highcharts-react-official';
import { Empty } from 'antd';

import { isEqual } from 'lodash';

import { selectors } from 'state';

const ReturnVsVolatility: React.FC = () => {
  const players = useSelector(selectors.activePlayers, isEqual);

  const series = players.map((player, index) => {
    const { player_history } = player;
    const portfolioReturns: [number, number][] = player_history.map(hs => [
      new Date(hs.timestamp).getTime(),
      hs.portfolio_return * 100_000_000,
    ]);

    return {
      name: player.name,
      data: portfolioReturns,
      visible: false,
      // Optimize access time after sorting
      initialIndex: index,
    };
  });
  const sortedSeries = [...series].sort((a, b) => {
    return b.data[b.data.length - 1][1] - a.data[a.data.length - 1][1];
  });

  sortedSeries.slice(0, 3).forEach(val => {
    series[val.initialIndex].visible = true;
  });

  if (!series.length) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <HighchartsReact
      options={{
        series: series,
        title: 'Return vs Volatility',
        xAxis: { type: 'datetime' },
        chart: {
          height: '56.25%',
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
        },
        tooltip: {
          valueDecimals: 1,
        },
      }}
    />
  );
};

export default ReturnVsVolatility;
