import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Typography } from 'antd';

import { selectors } from 'state';
import IndustryTable from './IndustryTable';
import IndustryBarChart from './IndustryBarChart';
import IndustryPieChart from './IndustryPieChart';

const ReturnByIndustry: React.FC = () => {
  const { allow_short_sell } = useSelector(selectors.activeSession);

  const Chart = allow_short_sell ? IndustryBarChart : IndustryPieChart;

  return (
    <Row style={{ flexFlow: 'row nowrap' }} gutter={10}>
      <Col flex="1">
        <Typography.Title level={4}>
          Return by Industry (Equities)
        </Typography.Title>
        <IndustryTable />
      </Col>
      <Col flex="1">
        <Typography.Title level={4}>Allocation by Industry</Typography.Title>
        <Chart />
      </Col>
    </Row>
  );
};

export default ReturnByIndustry;
