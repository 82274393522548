import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Button,
  Radio,
  InputNumber,
  Card,
  Row,
  Col,
  message,
  Select,
  Table,
  Typography,
  Empty,
} from 'antd';

import {
  selectors,
  BUY,
  buyForwardContract,
  SELL,
  utils,
  UnderlyingAsset,
  instrumentTypes,
  ReusableInstrumentTableType,
  FinancialInstrument,
  REAL_TIME_SCENARIO,
  enterRepoContract,
  PAUSED,
  CREATING,
  FROZEN,
} from 'state';
import { formatters } from 'services';
import { cashExchange, getFWDCurrencyPair } from '../../state/app/utils';
import { Simulate } from 'react-dom/test-utils';
import { underlyingAssetsList } from '../../state/app/selectors';

type props = {
  visible: boolean;
  hide: () => void;
  id: number;
};

const RepoModal: React.FC<props> = ({ visible, hide, id }) => {
  const dispatch = useDispatch();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // console.log('selectedRowKeys: ', selectedRowKeys);
  const [quantity, setQuantity] = useState(0);
  const assetList = useSelector(selectors.underlyingAssetsList);
  const financialInstruments = useSelector(selectors.financialInstruments);
  const financialInstrumentsList = useSelector(
    selectors.financialInstrumentsList
  );
  const underlyingAssetsList = useSelector(selectors.underlyingAssetsList);
  // console.log('assets from repo modal: ', underlyingAssetsList);
  // console.log('fins from repo modal: ', financialInstrumentsList);
  // financialInstrumentsList.forEach(fin =>
  //   console.log(fin.total_reporting_accrued)
  // );
  // const session = useSelector(selectors.activeSession)
  const currencyList = useSelector(selectors.currenciesList);

  const session = useSelector(selectors.activeSession);
  const isPaused = session.status === PAUSED || session.status === CREATING;
  const { transaction_fee, risk_approach } = session;

  const repo_fin = useSelector(selectors.financialInstrumentById(id));
  const repo_asset = useSelector(
    selectors.underlyingAssetOfInstrument(repo_fin)
  );
  // let collateralFin: FinancialInstrument;
  // let collateralAsset: UnderlyingAsset;
  // console.log('selectedRowKeys: ', selectedRowKeys);
  // console.log('selectedRowKeys type: ', typeof selectedRowKeys);
  // console.log('selectedRowKeys[0]: ', selectedRowKeys[0]);
  // console.log('selectedRowKeys[0] type: ', typeof selectedRowKeys[0]);

  // if (selec)
  const collateralFin = useSelector(
    selectors.financialInstrumentById2(selectedRowKeys[0] as number)
  );
  const collateralAsset = useSelector(
    selectors.underlyingAssetOfInstrument2(collateralFin)
  );
  // console.log('collateralFin: ', collateralFin);
  // console.log('collateralAsset: ', collateralAsset);
  const ccy = currencyList.find(ccy => ccy.id === repo_asset.base_currency);
  const activeBondFins = financialInstrumentsList
    .filter(fin => {
      // dumb way of isolating bonds, only bond fins will receive total_reporting_accrued from backend
      return fin.total_reporting_accrued !== undefined;
    })
    .filter(fin => {
      // isolate long bonds
      return fin.amount > 0;
    })
    .filter(fin => {
      const asset = assetList.find(asset => asset.id === fin.underlying_asset);
      if (asset !== undefined && ccy !== undefined) {
        return asset.base_currency === ccy.id;
      } else {
        return false;
      }
    });

  // console.log('activeBondsFins: ', activeBondFins);

  const dataSource = activeBondFins.flatMap(instrument => {
    const asset = underlyingAssetsList.find(
      asset => asset.id === instrument.underlying_asset
    );
    const ccy = asset
      ? currencyList.find(ccy => ccy.id === asset.base_currency)
      : null;

    if (asset && ccy) {
      return [
        {
          ...instrument,
          asset_name: asset.name,
          mtm: (instrument.amount * asset.bid) / 100,
          asset_ccy: ccy.symbol,
          key: instrument.id,
        },
      ];
    } else {
      return []; // Return an empty array for cases that don't match, which will be flattened away
    }
  });

  const onSelectChange = (selectedKeys: React.Key[]) => {
    setSelectedRowKeys(selectedKeys);
  };

  const selectionType: 'checkbox' | 'radio' = 'radio';

  const rowSelection = {
    type: selectionType,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  let maxQuantity = 0;
  let value = 0;

  if (collateralFin && collateralAsset) {
    maxQuantity = collateralFin.available_repoable_amount;
    value = quantity * collateralAsset.bid;
  }

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          No bonds in this CCY.
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  return (
    <Modal
      visible={visible}
      title={`Buy ${name} Repo`}
      onOk={hide}
      onCancel={hide}
      width={700}
      footer={[
        <Button
          key="place"
          type="primary"
          disabled={
            quantity <= 0 ||
            quantity > maxQuantity ||
            !collateralFin ||
            isPaused ||
            session.status === FROZEN
          }
          onClick={() => {
            if (collateralFin) {
              dispatch(
                enterRepoContract({
                  repo_instrument_id: repo_fin.id,
                  repoed_instrument_id: collateralFin.id,
                  repoed_shares: quantity,
                })
              );
            }
            // dispatch;
            hide();
          }}
        >
          Repo
        </Button>,
      ]}
    >
      <Card
        size={'small'}
        title={'Repo details'}
        style={{ width: 300, marginBottom: 30, marginLeft: 50 }}
        // style={{ marginBottom: 30, marginLeft: 50 }}
      >
        <div>Repo: {repo_asset.name}</div>
        <div>Rate: {formatters.commasExact2Digits(repo_asset.ask)} %</div>
        {session.scenario_type === REAL_TIME_SCENARIO && (
          <div>Maturity: {repo_fin.repo_maturity_date.split('T')[0]}</div>
        )}
        {ccy && <div>CCY: {ccy.symbol}</div>}
      </Card>
      <div style={{ marginLeft: 50 }}>Select collateral:</div>
      <Table
        rowSelection={rowSelection}
        pagination={false}
        style={{ width: 500, marginLeft: 50 }}
        // style={{ marginLeft: 50 }}
        bordered
        locale={noDataBehaviour}
        dataSource={dataSource}
        columns={[
          {
            title: 'Bond',
            dataIndex: 'asset_name',
            key: 'asset_name',
          },
          {
            title: 'MTM',
            dataIndex: 'mtm',
            key: 'mtm',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.mtm - b.mtm,
            render: (val: number) => {
              return <div>{formatters.commasNoDigits(val)}</div>;
            },
          },
          {
            title: 'CCY',
            dataIndex: 'asset_ccy',
            key: 'asset_ccy',
          },
        ]}
      />
      <br />
      <div style={{ marginLeft: 50 }}>Select amount: </div>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 50 }}>
        <InputNumber
          value={quantity}
          min={0}
          max={maxQuantity}
          formatter={formatters.commasNoDigits}
          onChange={val => setQuantity(val || 0)}
          style={{ width: 150 }}
        />

        <div>{` / ${formatters.commasNoDigits(maxQuantity)}`}</div>
      </div>
      <br />
      <div style={{ marginLeft: 50 }}>Repo value: </div>
      {ccy && (
        <div style={{ marginLeft: 50 }}>
          {formatters.commasNoDigits(value / 100)} {ccy.symbol}
        </div>
      )}
    </Modal>
  );
};

export default RepoModal;
