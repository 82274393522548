import { appManager, AppState } from '../manager';

import * as types from '../types';

import {
  currencyById,
  financialInstrumentByUnderlingAsset,
} from '../selectors';
import { utils } from '../../index';
import { UpdateForwardContract } from '../types';

appManager.createSocketListener<
  { history: types.MarketHistory; underlying_asset_id: number }[],
  AppState
>('send_price', (state, result) => {
  console.log('send_price: ', result);
  result?.forEach(res => {
    const history = res.history;
    const asset = state.underlying_assets.items[res.underlying_asset_id];
    const assetList = Object.values(state.underlying_assets.items);
    const currencyList = Object.values(state.currencies.items);
    if (!asset) return;
    if (res.history.timestamp === null) return; // prevents a nearly untraceable error if an asset has no prices
    asset.market_history?.one_day?.push(history);
    let historyBid = history.bid;
    let historyAsk = history.ask;

    if (history.fwd_bid) {
      historyBid = history.fwd_bid;
      historyAsk = history.fwd_ask;
      asset.bid_swap_points = history.bid_swap_points;
      asset.ask_swap_points = history.ask_swap_points;
    }

    asset.current_price = historyBid;
    asset.bid_diff = asset.bid - historyBid;
    asset.bid = historyBid;
    asset.ask_diff = asset.ask - historyAsk;
    asset.ask = historyAsk;
    asset.duration = history.duration;
    asset.bpv = history.bpv;
    asset.ask_yield = history.ask_yield || history.bid_yield;

    if (
      history.accrued_interest == undefined ||
      history.accrued_days == undefined ||
      history.coupon_date == undefined ||
      history.coupon_rate == undefined
    ) {
      // don't updated existing values
    } else {
      asset.accrued_interest = history.accrued_interest;
      asset.accrued_days = history.accrued_days;
      asset.coupon_date = history.coupon_date;
      asset.coupon_rate = history.coupon_rate;
    }

    const instrument = financialInstrumentByUnderlingAsset(asset.id)({
      app: state,
    } as never);
    const baseCurrency = currencyById(asset.base_currency)({
      app: state,
    } as never);
    const variableCurrency = currencyById(asset.variable_currency)({
      app: state,
    } as never);

    if (!instrument) return;

    const session = state.sessions.items[state.active_session];
    const reportingCurrency =
      state.currencies.items[session.reporting_currency];

    const price = instrument.amount > 1 ? asset.bid : asset.ask;

    let res2 = (price - instrument.avg_open_price) * instrument.amount;

    if (asset.resourcetype.endsWith('Bond')) {
      res2 /= 100;
    }

    if (
      asset.resourcetype !== 'FX' &&
      asset.resourcetype !== 'FxForwards' &&
      baseCurrency.id !== reportingCurrency.id
    ) {
      instrument.reporting_unrealized_pnl = utils.cashExchange(
        baseCurrency.id,
        reportingCurrency.symbol,
        res2,
        0,
        assetList,
        currencyList
      );
    }

    if (asset.resourcetype === 'FX') {
      const fromCurrency =
        baseCurrency.id === reportingCurrency.id
          ? variableCurrency
          : baseCurrency;
      instrument.reporting_unrealized_pnl = utils.cashExchange(
        fromCurrency.symbol,
        reportingCurrency.symbol,
        res2,
        0,
        assetList,
        currencyList
      );
    }
  });
});

appManager.createSocketListener<
  // { history: types.MarketHistory; underlying_asset_id: number }[],
  { id: number; forward_contracts: types.UpdateForwardContract[] }[],
  AppState
>('update_forward_contracts_unrealized', (state, result) => {
  result.forEach(result_item => {
    const fin = state.player.item.financial_instruments[result_item.id];
    // const existing_contracts = fin.forward_contracts;
    const updated_contracts = result_item.forward_contracts;
    updated_contracts.forEach(updated_contract => {
      const existing_contract =
        // state.forward_contracts.items[updated_contract.id];
        state.player.item.forward_contracts.items[updated_contract.id];
      if (existing_contract !== undefined) {
        existing_contract.reporting_unrealized_pnl =
          updated_contract.reporting_unrealized_pnl;
        existing_contract.reporting_realized_pnl =
          updated_contract.reporting_realized_pnl;
        existing_contract.delivery_timestamp =
          updated_contract.delivery_timestamp;
        existing_contract.close_price = updated_contract.close_price;
        existing_contract.closed_by = updated_contract.closed_by;
      }
    });
  });
});
