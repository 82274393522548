import React, { useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Select,
  Switch,
} from 'antd';
import {
  createUnderlyingAsset,
  instrumentTypes,
  marketHours,
  REGIONS,
  selectors,
  UnderlyingAsset,
} from 'state';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  CDS_FREQUECIES,
  TENOR_UNITS,
} from '../../../../state/app/repos/constants';
import { auctions_tenors } from '../../../../state/app/auctions/types';

type Props = {
  hide: () => void;
};
const CreateAssetModal: React.FC<Props> = ({ hide }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [investmentThemeForm] = Form.useForm();
  const currencies = useSelector(selectors.currencies);
  const assets = useSelector(selectors.underlyingAssetsList);
  const fxAsset = assets.filter(asset => asset.resourcetype === 'FX');
  const [assetClass, setAssetClass] = useState<
    UnderlyingAsset['resourcetype'] | undefined
  >(undefined);
  const notForex = assetClass !== 'FX' && assetClass !== 'FxForwards';
  const HTMorAFS = assetClass === 'HTMBond' || assetClass === 'AFSBond';
  const isYields = assetClass === 'Yields';
  return (
    <Modal
      visible={true}
      onOk={hide}
      onCancel={hide}
      title={'Create a new asset'}
      width={600}
      footer={null}
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          onFinish={() => {
            const data = form.getFieldsValue();
            dispatch(createUnderlyingAsset(data));
            // hide();
          }}
          initialValues={{
            visible: true,
            fetch_eod: false,
            fetch_intraday: false,
          }}
        >
          <Form.Item label="Asset Class" name="resourcetype">
            <Select
              allowClear
              showSearch
              filterOption={(inputValue, option) => {
                const lowerInput = inputValue.toLowerCase();
                return option?.children.toLowerCase().includes(lowerInput);
              }}
              onChange={value => {
                setAssetClass(value as UnderlyingAsset['resourcetype']);
              }}
            >
              {instrumentTypes.map(instrument => {
                return (
                  <Select.Option key={instrument.type} value={instrument.type}>
                    {instrument.type}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Ticker" name="ticker">
            <Input />
          </Form.Item>
          {HTMorAFS && (
            <Form.Item label="ISIN" name="isin">
              <Input />
            </Form.Item>
          )}
          <Form.Item label="RIC" name="ric">
            <Input />
          </Form.Item>

          <Form.Item label="Region" name="region">
            <Select
              allowClear
              showSearch
              filterOption={(inputValue, option) => {
                const lowerInput = inputValue.toLowerCase();
                return option?.children.toLowerCase().includes(lowerInput);
              }}
            >
              {Object.entries(REGIONS).map(([region, name]) => {
                return (
                  <Select.Option key={region} value={region}>
                    {name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {notForex && (
            <Form.Item label="Geography" name="geography">
              <Select
                allowClear
                showSearch
                filterOption={(inputValue, option) => {
                  const lowerInput = inputValue.toLowerCase();
                  return option?.children.toLowerCase().includes(lowerInput);
                }}
              >
                {Object.keys(marketHours).map(geography => {
                  return (
                    <Select.Option key={geography} value={geography}>
                      {geography}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item label="Base CCY" name="base_currency">
            <Select
              allowClear
              showSearch
              filterOption={(inputValue, option) => {
                const lowerInput = inputValue.toLowerCase();
                return option?.children.toLowerCase().includes(lowerInput);
              }}
            >
              {Object.values(currencies).map(currency => {
                return (
                  <Select.Option key={currency.id} value={currency.id}>
                    {currency.symbol}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Var CCY" name="variable_currency">
            <Select
              allowClear
              showSearch
              filterOption={(inputValue, option) => {
                const lowerInput = inputValue.toLowerCase();
                return option?.children.toLowerCase().includes(lowerInput);
              }}
            >
              {Object.values(currencies).map(currency => {
                return (
                  <Select.Option key={currency.id} value={currency.id}>
                    {currency.symbol}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item valuePropName="checked" label="Visible" name="visible">
            <Switch />
          </Form.Item>
          {!HTMorAFS && (
            // both fetch eod and intraday will be by default false for HTM and ASF bonds. This is because we don't have
            // necessary data from Refinitiv, so Htm mechanics works by incrementing data by worker (on predetermined
            // data, so frequency, coupon, coupon_date and maturity_date will be inputted once by admin). MarketHistory
            // will be made either by refresh_eod or by import_eod in backend (so manually, no daily automated pulls)
            <div>
              {isYields && (
                <div>
                  <div>
                    Please leave both fetch_intraday and fetch_eod off for
                    Yields.
                  </div>
                  <br />
                </div>
              )}
              <Form.Item
                valuePropName="checked"
                label="Fetch EoD"
                name="fetch_eod"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                label="Fetch Intraday"
                name="fetch_intraday"
              >
                <Switch />
              </Form.Item>
            </div>
          )}
          {isYields && (
            <Form.Item label="Tenor" name="tenor">
              <Select allowClear showSearch>
                {/*{Object.entries(REGIONS).map(([region, name]) => {*/}
                {Object.entries(auctions_tenors).map(([region, name]) => {
                  return (
                    <Select.Option key={region} value={region}>
                      {name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          {HTMorAFS && (
            <>
              <Divider />
              <Form.Item label="Frequency" name="frequency">
                <Input
                  placeholder={
                    'for semi annual input 6, for anual input 12, etc.'
                  }
                />
              </Form.Item>
              <Form.Item label="Coupon" name="coupon">
                <Input placeholder={'input as 4.75 for a 4.75% coupon'} />
              </Form.Item>
              <Form.Item label="Issue date" name="issue_date">
                <Input placeholder={'yyyy-mm-dd'} />
              </Form.Item>
              <Form.Item label="Maturity Date" name="maturity_date">
                <Input placeholder={'yyyy-mm-dd'} />
              </Form.Item>
              <Divider />
            </>
          )}
          {assetClass === 'FxForwards' && (
            <>
              <Form.Item
                label={
                  <>
                    No. of decimals
                    <Popover
                      content={
                        <p>
                          For prices with 4 decimals, swap points will be
                          divided by 10000. For prices with 2 decimals, swap
                          points will be divided by 100
                        </p>
                      }
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </>
                }
                name="no_of_decimals"
              >
                <Input required={true} />
              </Form.Item>
              <Form.Item
                label="Corresponding FX: "
                name="corresponding_fx_asset"
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(inputValue, option) => {
                    const lowerInput = inputValue.toLowerCase();
                    return option?.children.toLowerCase().includes(lowerInput);
                  }}
                >
                  {Object.values(fxAsset).map(asset => {
                    return (
                      <Select.Option key={asset.id} value={asset.id}>
                        {asset.ric}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          )}
          {assetClass === 'Equity' && (
            <>
              <Form.Item label="Sector" name="sector">
                <Input />
              </Form.Item>
              <Form.Item label="Industry" name="industry">
                <Input />
              </Form.Item>
            </>
          )}
          {assetClass == 'FX' && (
            <>
              <Form.Item label="Description" name="description">
                <Input />
              </Form.Item>
              <Form.Item label="Temp name" name="temp_name">
                <Input />
              </Form.Item>
            </>
          )}
          {(assetClass == 'GovtBond' ||
            assetClass == 'CorporateBond' ||
            assetClass == 'HTMBond' ||
            assetClass == 'AFSBond') && (
            <>
              <Form.Item label="Country" name="country">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="Country code" name="country_code">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="Issuer type" name="issuer_type">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="Credit quality" name="credit_quality">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="Credit rating" name="credit_rating">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="Issuer" name="issuer">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="Sector" name="sector">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="Industry" name="industry">
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label="Market classification"
                name="market_classification"
              >
                <Input allowClear />
              </Form.Item>
            </>
          )}
          {assetClass === 'Repo' && (
            <>
              please only input positive integers
              <Form.Item label="Tenor" name="tenor" required={true}>
                <Input allowClear placeholder={'2'} />
              </Form.Item>
              <Form.Item label="Tenor unit" name="tenor_unit" required={true}>
                <Select
                  placeholder={'weeks'}
                  allowClear
                  showSearch
                  filterOption={(inputValue, option) => {
                    const lowerInput = inputValue.toLowerCase();
                    return option?.children.toLowerCase().includes(lowerInput);
                  }}
                >
                  {Object.entries(TENOR_UNITS).map(([unit, name]) => {
                    return (
                      <Select.Option key={unit} value={unit}>
                        {name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          )}
          {assetClass === 'CDS' && (
            <>
              please only input positive integers (no. of years)
              <Form.Item label="Maturity" name="cds_maturity" required={true}>
                <Input allowClear placeholder={'5'} />
              </Form.Item>
              <Form.Item label="Frequency" name="cds_frequency" required={true}>
                <Select
                  placeholder={'semi-annually'}
                  allowClear
                  showSearch
                  filterOption={(inputValue, option) => {
                    const lowerInput = inputValue.toLowerCase();
                    return option?.children.toLowerCase().includes(lowerInput);
                  }}
                >
                  {Object.entries(CDS_FREQUECIES).map(([unit, name]) => {
                    return (
                      <Select.Option key={unit} value={unit}>
                        {name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          )}
          {assetClass?.includes('ETF') && (
            <>
              <Form.Item label="Classification" name="classification">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="Objectives" name="objectives">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="Temp name" name="temp_name">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="ESG score" name="esg_score">
                <Select allowClear showSearch>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => {
                    return (
                      <Select.Option key={value} value={value}>
                        {value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Top holdings" name="top_holdings">
                <Input allowClear />
              </Form.Item>
              <Form.Item label="Links" name="links">
                <Input allowClear />
              </Form.Item>
            </>
          )}
          {!assetClass?.includes('ETF') && (
            <Form.Item>
              <div
                style={{ display: 'flex', justifyContent: 'right', gap: 10 }}
              >
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
                <Button onClick={hide}>Cancel</Button>
              </div>
            </Form.Item>
          )}
        </Form>
        {assetClass?.includes('ETF') && (
          <Form
            labelCol={{ span: 5 }}
            form={investmentThemeForm}
            onFinish={() => {
              const data = form.getFieldsValue();
              data['investment_theme'] = investmentThemeForm.getFieldsValue();
              const top_holdings_str = data['top_holdings']
                ? data['top_holdings']
                : undefined;
              const links_str = data['links'] ? data['links'] : undefined;
              if (top_holdings_str) {
                data['top_holdings'] = top_holdings_str.split(',');
              }
              if (links_str) {
                data['links'] = links_str.split(',');
              }
              dispatch(createUnderlyingAsset(data));
              hide();
            }}
          >
            <Divider> Investment Theme</Divider>
            <Form.Item label="Category" name="category">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Inverse" name="inverse">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Leveraged" name="leveraged">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Asset Class" name="asset_class">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Asset Class Size" name="asset_class_size">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Asset Class Styles" name="asset_class_style">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Sector General" name="sector_general">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Sector specific" name="sector_specific">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Sector" name="sector">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Region General" name="region_general">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Region Specific" name="region_specific">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Commodity Type" name="commodity_type">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Commodity" name="commodity">
              <Input allowClear />
            </Form.Item>
            <Form.Item label="Commodity Exposure" name="commodity_exposure">
              <Input allowClear />
            </Form.Item>
            <Form.Item>
              <div
                style={{ display: 'flex', justifyContent: 'right', gap: 10 }}
              >
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
                <Button onClick={hide}>Cancel</Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </div>
    </Modal>
  );
};
export default CreateAssetModal;
