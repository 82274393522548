import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auctionsListSelector, player } from '../../../state/app/selectors';
import { Button, Empty, Popover, Spin, Table, Typography } from 'antd';
import {
  deleteUnderlyingAsset,
  requestAssetEodPreview,
  UnderlyingAsset,
} from 'state';
import { DeleteRowOutlined, EyeTwoTone } from '@ant-design/icons';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import {
  auctions_tenors,
  receiveCreatedAuctionType,
} from '../../../state/app/auctions/types';
import RespondToAuctionModal from './RespondToAuctionModal';
import ViewBidAuctionModal from './ViewBidAuctionModal';
import { formatters } from 'services';

const Auctions: React.FC = () => {
  const dispatch = useDispatch();
  const auctionsList = useSelector(auctionsListSelector);
  const thisPlayer = useSelector(player);
  const [showRespondToAuctionModal, setShowRespondToAuctionModal] = useState(
    false
  );
  const [showViewBidAuctionModal, setShowViewBidAuctionModal] = useState(false);
  const [selectedAuction, setSelectedAuction] = useState<
    receiveCreatedAuctionType | undefined
  >(undefined);

  const playerAuctionColumns: ((
    | ColumnGroupType<receiveCreatedAuctionType>
    | ColumnType<receiveCreatedAuctionType>
  ) & {
    dataIndex: string;
  })[] = [
    {
      title: 'Tenor',
      dataIndex: 'tenor',
      key: 'tenor',
      render: (value: number) => {
        return auctions_tenors[value];
      },
    },
    // {
    //   title: 'tenor_unit',
    //   dataIndex: 'tenor_unit',
    //   key: 'tenor_unit',
    //   render: (text: number) => {
    //     if (text === 0) {
    //       return <div>Day</div>;
    //     } else if (text === 1) {
    //       return <div>Week</div>;
    //     } else if (text === 2) {
    //       return <div>Month</div>;
    //     }
    //   },
    // },
    {
      title: 'Auction Series',
      dataIndex: 'auction_series',
      key: 'auction_series',
    },
    // {
    //   title: 'maturity_date',
    //   dataIndex: 'maturity_date',
    //   key: 'maturity_date',
    // },
    {
      title: 'Auction Value (M)',
      dataIndex: 'bid_value',
      key: 'bid_value',
      width: 50,
      render: (value: number) => formatters.millionNoUnit(value),
    },
    {
      title: 'Issued (M)',
      dataIndex: 'issued_value',
      key: 'issued_value',
      render: (value: number) => {
        if (value) {
          return formatters.millionNoUnit(value);
        } else {
          return '--';
        }
      },
    },
    // {
    //   title: 'Unit',
    //   dataIndex: 'bid_unit',
    //   key: 'bid_unit',
    //   render: (text: number) => {
    //     if (text === 0) {
    //       return <div>Million</div>;
    //     } else if (text === 1) {
    //       return <div>Billion</div>;
    //     } else if (text === 2) {
    //       return <div>Trillion</div>;
    //     }
    //   },
    // },
    {
      title: 'CCY',
      dataIndex: 'currency_symbol',
      key: 'ccy',
    },
    {
      title: 'Initial Ask',
      dataIndex: 'initial_ask',
      key: 'initial_ask',
      render: (value: number) => formatters.commas3Digits(value),
    },
    {
      title: 'Yield',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Actions/Status',
      dataIndex: 'finished', // indicates status of auction (finished True/False)
      render: (value, record) => {
        const responses = record.responses;
        const thisPlayersResponse = responses.find(
          response => response.player === thisPlayer.id
        );
        console.log(thisPlayersResponse);
        if (value === false) {
          if (thisPlayersResponse) {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  onClick={() => {
                    // console.log(record);
                    setSelectedAuction(record);
                    setShowViewBidAuctionModal(true);
                  }}
                >
                  View Bid
                </Button>
                <div style={{ marginLeft: 5 }}>/ open</div>
              </div>
            );
          } else {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  onClick={() => {
                    // console.log(record);
                    setSelectedAuction(record);
                    setShowRespondToAuctionModal(true);
                  }}
                >
                  Bid
                </Button>
                <div style={{ marginLeft: 5 }}>/ open</div>
              </div>
            );
          }
        } else {
          return <div>Auction has concluded</div>;
        }
      },
    },
  ];

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          The Central Bank has not launched any auctions yet. You will receive a
          notification when new auctions are available.
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  return (
    <div>
      <Table
        columns={playerAuctionColumns}
        dataSource={auctionsList}
        locale={noDataBehaviour}
        bordered
      ></Table>
      {showRespondToAuctionModal && (
        <RespondToAuctionModal
          visible={showRespondToAuctionModal}
          hide={() => {
            setShowRespondToAuctionModal(false);
          }}
          auction={selectedAuction}
        />
      )}
      {showViewBidAuctionModal && (
        <ViewBidAuctionModal
          visible={showViewBidAuctionModal}
          hide={() => {
            setShowViewBidAuctionModal(false);
          }}
          auction={selectedAuction}
        />
      )}
    </div>
  );
};

export default Auctions;
