import React from 'react';
import { Tabs } from 'antd';

import View from './View';
import Roadmap from './Roadmap';
import CreateItem from './CreateItem';

const Insights: React.FC = () => {
  return (
    <div>
      <Tabs>
        <Tabs.TabPane tab="Add Item" key="add-item">
          <CreateItem />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Roadmap" key="roadmap">
          <Roadmap />
        </Tabs.TabPane>
        <Tabs.TabPane tab="View" key="view">
          <View />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Insights;
