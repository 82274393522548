import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Typography, Card, Row, Col, Popover, Space } from 'antd';

import { listPlayerDividends, selectors, utils } from 'state';
import { formatters } from 'services';
import { ColumnsType } from 'antd/es/table';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  CurrentDepos,
  CurrentLoans,
  depo_tenors,
  loan_tenors,
  PastDepos,
  PastLoans,
} from '../../../../../state/app/client_money/types';
import CashAccounts from './CashAccounts';

const ClientMoneyAndCashAccounts: React.FC = () => {
  const dispatch = useDispatch();
  const session = useSelector(selectors.activeSession);
  const wallets = useSelector(selectors.wallets);
  const currencies = useSelector(selectors.currencies);

  const { interbank_interest } = useSelector(selectors.player);
  const reportingCurrency = utils.useReportingCurrency(session.id);
  const clientMoney = useSelector(selectors.getClientMoney);
  const totalCurrentLoans = useSelector(selectors.totalCurrentLoans);
  const totalCurrentDepos = useSelector(selectors.totalCurrentDepos);
  const [totalPastLoansAmount, totalPastLoansInterest] = useSelector(
    selectors.totalPastLoansAmountAndInterest
  );
  const [totalPastDeposAmount, totalPastDeposInterest] = useSelector(
    selectors.totalPastDeposAmountAndInterest
  );

  const reportingWallet = wallets.find(
    wallet => currencies[wallet.currency] === reportingCurrency
  )!;

  const { waiting: divWaiting, fetched: divFetched } = useSelector(
    selectors.dividendsApi
  );
  useEffect(() => {
    if (!divFetched) {
      dispatch(listPlayerDividends({}));
    }
  }, [dispatch, divFetched]);

  const assets = useSelector(selectors.underlyingAssets);
  const sessionHasNoEquities = Object.values(assets).every(
    asset => asset.resourcetype !== 'Equity'
  );

  const customLombardStyle =
    reportingWallet.amount < 0
      ? { marginTop: 12, color: 'red' }
      : { marginTop: 12 };

  const lombardInfo = (
    <div>
      <p>
        Overdraft (Negative Available Amount) at the end of the period will be
        automatically covered at the lombard rate.
      </p>
      Interest paid:{' '}
      {formatters.commasNoDigits(reportingWallet.lombard_interest)}{' '}
      {reportingCurrency.symbol}
    </div>
  );

  const pastLoansColumns: ColumnsType<PastLoans> = [
    {
      title: `Amount, ${reportingCurrency.symbol}`,
      key: 'awarded_sum',
      dataIndex: 'awarded_sum',
      align: 'right',
      render: (value: number) => formatters.commasNoDigits(value),
    },
    {
      title: 'Tenor, Yrs.',
      key: 'tenor',
      dataIndex: 'tenor',
      align: 'left',
      render: (value: number) => loan_tenors[value],
    },
    {
      title: 'Rate, %',
      key: 'rate',
      dataIndex: 'rate',
      align: 'left',
      render: (value: number) => formatters.commas2Digits(value * 100),
    },
    {
      title: `Interest, ${reportingCurrency.symbol}`,
      key: 'interest',
      dataIndex: 'interest',
      align: 'right',
      render: (value: number) => formatters.commas1Digit(value),
    },
  ];

  const pastDeposColumns: ColumnsType<PastDepos> = [
    {
      title: `Amount, ${reportingCurrency.symbol}`,
      key: 'awarded_sum',
      dataIndex: 'awarded_sum',
      align: 'right',
      render: (value: number) => formatters.commasNoDigits(value),
    },
    {
      title: 'Tenor, Mos.',
      key: 'tenor',
      dataIndex: 'tenor',
      align: 'left',
      render: (value: number) => depo_tenors[value],
    },
    {
      title: 'Rate, %',
      key: 'rate',
      dataIndex: 'rate',
      align: 'left',
      render: (value: number) => formatters.commas2Digits(value * 100),
    },
    {
      title: `Interest, ${reportingCurrency.symbol}`,
      key: 'interest',
      dataIndex: 'interest',
      align: 'right',
      render: (value: number) => formatters.commas1Digit(value),
    },
  ];

  const loansColumns: ColumnsType<CurrentLoans> = [
    {
      title: `Amount, ${reportingCurrency.symbol}`,
      key: 'awarded_sum',
      dataIndex: 'awarded_sum',
      align: 'right',
      render: (value: number) => formatters.commasNoDigits(value),
    },
    {
      title: 'Tenor, Yrs.',
      key: 'tenor',
      dataIndex: 'tenor',
      align: 'left',
      render: (value: number) => loan_tenors[value],
    },
    {
      title: 'Rate, %',
      key: 'rate',
      dataIndex: 'rate',
      align: 'left',
      render: (value: number) => formatters.commas2Digits(value * 100),
    },
  ];

  const deposColumns: ColumnsType<CurrentDepos> = [
    {
      title: `Amount, ${reportingCurrency.symbol}`,
      key: 'awarded_sum',
      dataIndex: 'awarded_sum',
      align: 'right',
      render: (value: number) => formatters.commasNoDigits(value),
    },
    {
      title: 'Tenor, Mos.',
      key: 'tenor',
      dataIndex: 'tenor',
      align: 'left',
      render: (value: number) => depo_tenors[value],
    },
    {
      title: 'Rate, %',
      key: 'rate',
      dataIndex: 'rate',
      align: 'left',
      render: (value: number) => formatters.commas2Digits(value * 100),
    },
  ];

  return (
    <div>
      <Row gutter={20}>
        <Col span={6}>
          <Card
            title={
              <Typography.Title level={4} style={{ marginTop: 16 }}>
                Previous Period
              </Typography.Title>
            }
            style={{ minWidth: 300 }}
            size={'small'}
          >
            <Row gutter={[0, 20]}>
              <Table
                style={{ marginTop: 10 }}
                pagination={false}
                bordered
                columns={pastLoansColumns}
                dataSource={Object.values(clientMoney.past_loans.items)}
                title={() => (
                  <Typography.Text strong>Client Loans:</Typography.Text>
                )}
                footer={() => (
                  <div>
                    <Row>
                      <Typography.Text strong>
                        Total loans:{' '}
                        {formatters.commasNoDigits(totalPastLoansAmount)}{' '}
                        {reportingCurrency.symbol}
                      </Typography.Text>
                    </Row>
                    <Row>
                      <Typography.Text strong>
                        Total interest: +
                        {formatters.commasNoDigits(totalPastLoansInterest)}{' '}
                        {reportingCurrency.symbol}
                      </Typography.Text>
                    </Row>
                  </div>
                )}
              />
            </Row>
            <Row>
              <Table
                pagination={false}
                bordered
                columns={pastDeposColumns}
                dataSource={Object.values(clientMoney.past_depos.items)}
                title={() => (
                  <Typography.Text strong>Client Depos:</Typography.Text>
                )}
                footer={() => (
                  <div>
                    <Row>
                      <Typography.Text strong>
                        Total depos:{' '}
                        {formatters.commasNoDigits(totalPastDeposAmount)}{' '}
                        {reportingCurrency.symbol}
                      </Typography.Text>
                    </Row>
                    <Row>
                      <Typography.Text strong>
                        Total interest:{' '}
                        {formatters.commasNoDigits(totalPastDeposInterest)}{' '}
                        {reportingCurrency.symbol}
                      </Typography.Text>
                    </Row>
                  </div>
                )}
              />
            </Row>
          </Card>
        </Col>
        <Col span={4}>
          <Card
            title={
              <Typography.Title level={4} style={{ marginTop: 16 }}>
                Current Period
              </Typography.Title>
            }
            style={{ minWidth: 300 }}
            size={'small'}
          >
            <Row gutter={[0, 20]}>
              <Table
                style={{ marginTop: 10 }}
                pagination={false}
                bordered
                columns={loansColumns}
                dataSource={Object.values(clientMoney.current_loans.items)}
                title={() => (
                  <Typography.Text strong>Client Loans:</Typography.Text>
                )}
                footer={() => (
                  <Typography.Text strong>
                    Total loans: {formatters.commasNoDigits(totalCurrentLoans)}{' '}
                    {reportingCurrency.symbol}
                  </Typography.Text>
                )}
              />
            </Row>
            <Row>
              <Table
                pagination={false}
                bordered
                columns={deposColumns}
                dataSource={Object.values(clientMoney.current_depos.items)}
                title={() => (
                  <Typography.Text strong>Client Depos:</Typography.Text>
                )}
                footer={() => (
                  <Typography.Text strong>
                    Total depos: {formatters.commasNoDigits(totalCurrentDepos)}{' '}
                    {reportingCurrency.symbol}
                  </Typography.Text>
                )}
              />
            </Row>
            <Space>
              <div style={customLombardStyle}>
                Lombard rate:{' '}
                {formatters.commas2Digits(clientMoney.lombard_rate)}%
              </div>{' '}
              <Popover placement={'top'} content={lombardInfo} title="Info">
                <InfoCircleOutlined style={{ marginTop: 14 }} />
              </Popover>
            </Space>
            <div>
              Interbank interest: {formatters.commas1Digit(interbank_interest)}{' '}
              {reportingCurrency.symbol}
            </div>
          </Card>
        </Col>
        <Col span={14}>
          <CashAccounts use_card={true} />
        </Col>
      </Row>
    </div>
  );
};

export default ClientMoneyAndCashAccounts;
