import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'antd';

import { fetchPassedNews, selectors } from 'state';

const NewsHistory: React.FC = () => {
  const dispatch = useDispatch();
  const newsList = useSelector(selectors.newsList);
  const { news_fetched, fetching_news, id } = useSelector(
    selectors.activeSession
  );

  useEffect(() => {
    if (news_fetched) return;

    dispatch(fetchPassedNews({ session_id: id }));
  }, [dispatch]);

  const pageSize = 30;

  return (
    <Table
      dataSource={newsList}
      loading={fetching_news}
      rowKey="id"
      columns={[{ title: 'Headline', dataIndex: 'headline' }]}
      pagination={{ pageSize, total: newsList.length, showSizeChanger: false }}
    />
  );
};

export default NewsHistory;
