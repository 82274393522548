import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors, updateSessionBenchmarkComposition } from 'state';
import { PercentInput } from 'components';
import { Button } from 'antd';

const BenchmarkConstant: React.FC = () => {
  const dispatch = useDispatch();
  const { benchmark_constant } = useSelector(selectors.activeSession);

  const [benchmarkConstant, setBenchmarkConstant] = useState(
    benchmark_constant * 100
  );

  return (
    <div>
      <PercentInput
        value={benchmarkConstant}
        onChange={setBenchmarkConstant}
        style={{ marginBottom: 10, marginRight: 20 }}
      />
      <Button
        type="primary"
        disabled={!benchmarkConstant}
        onClick={() =>
          dispatch(
            updateSessionBenchmarkComposition({
              constant: benchmarkConstant / 100,
            })
          )
        }
      >
        Save
      </Button>
    </div>
  );
};

export default BenchmarkConstant;
