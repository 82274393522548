import React, { createContext, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'state';

type logsContextType = {
  instrumentType: string;
  setInstrumentType: (instrumentType: string) => void;
  showCash: boolean;
  setShowCash: (showCash: boolean) => void;
};

export const TraineeSessionContext = createContext<logsContextType>({
  instrumentType: '',
  // Using an empty function here makes sense, since you provide an empty, default value.
  // Otherwise, you have to check if setSelected is undefined every time you'd want to use it, which is annoying.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInstrumentType: () => {},
  showCash: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowCash: () => {},
});

export function TraineeSessionContextWrapper(props: {
  children: ReactElement;
}) {
  const [instrumentType, setInstrumentType] = useState<string>('');
  const [showCash, setShowCash] = useState<boolean>(false);
  return (
    <TraineeSessionContext.Provider
      value={{
        instrumentType: instrumentType,
        setInstrumentType: setInstrumentType,
        showCash: showCash,
        setShowCash: setShowCash,
      }}
    >
      {props.children}
    </TraineeSessionContext.Provider>
  );
}
