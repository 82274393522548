import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Modal, Typography } from 'antd';
import { selectors, setTrainerPassword } from 'state';

type Props = {
  trainerId: number;
  visible: boolean;
  hide: () => void;
};

const UpdatePasswordModal: React.FC<Props> = ({ trainerId, visible, hide }) => {
  const dispatch = useDispatch();
  const trainers = useSelector(selectors.trainers);

  const trainer = trainers[trainerId];
  const [password, setPassword] = useState('');

  return (
    <Modal
      title="Set new password"
      visible={visible}
      onCancel={hide}
      footer={null}
      width="500px"
    >
      <Typography.Title level={3}>{trainer.username}</Typography.Title>

      <Typography.Text>Password:</Typography.Text>
      <Input value={password} onChange={e => setPassword(e.target.value)} />

      <Button
        type="primary"
        onClick={() => {
          dispatch(setTrainerPassword({ trainer_id: trainerId, password }));
        }}
        style={{ marginTop: 16 }}
      >
        Save
      </Button>
    </Modal>
  );
};

export default UpdatePasswordModal;
