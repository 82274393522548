import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Spin, Typography, Card } from 'antd';

import {
  listPlayerDividends,
  selectors,
  REAL_TIME_SCENARIO,
  CASH_APPROACH,
  HISTORICAL_SCENARIO,
} from 'state';
import { formatters } from 'services';
import { ColumnsType } from 'antd/es/table';

export type CashTableType = {
  outstanding: number;
  id: number;
  currency: number;
  amount: number;
  collateral_amount: number;
  total_currency_interest: number;
  name: string;
  symbol: string;
  // reporting_exchange_rate: number;
};

type props = {
  use_card: boolean;
};

const CashAccounts: React.FC<props> = props => {
  const { use_card } = props;
  const dispatch = useDispatch();
  const session = useSelector(selectors.activeSession);
  const wallets = useSelector(selectors.wallets);
  const currencies = useSelector(selectors.currencies);
  const instruments = useSelector(selectors.financialInstrumentsList);
  const playableSymbols = useSelector(
    selectors.playableCurrencies(instruments)
  );

  const dividends = useSelector(selectors.dividends);

  const { waiting: divWaiting, fetched: divFetched } = useSelector(
    selectors.dividendsApi
  );
  useEffect(() => {
    if (!divFetched) {
      dispatch(listPlayerDividends({}));
    }
  }, [dispatch, divFetched]);

  const playableWallets = wallets.filter(wallet =>
    playableSymbols.includes(currencies[wallet.currency].symbol)
  );

  const data = playableWallets.map(wallet => {
    const outstanding = Object.values(dividends)
      .filter(div => {
        return div.currency === wallet.currency;
      })
      .reduce((acc, div) => {
        return acc + div.outstanding;
      }, 0);
    return {
      ...currencies[wallet.currency],
      ...wallet,
      outstanding,
    };
  });

  const isRealTime = session.scenario_type === REAL_TIME_SCENARIO;
  const isCashApproach = session.risk_approach === CASH_APPROACH;
  const showDeposits = isRealTime && isCashApproach;

  const assets = useSelector(selectors.underlyingAssets);
  const sessionHasNoEquities = Object.values(assets).every(
    asset => asset.resourcetype !== 'Equity'
  );

  const walletsColumns: ColumnsType<CashTableType> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      align: 'left',
    },
    {
      title: 'Symbol',
      key: 'symbol',
      dataIndex: 'symbol',
      align: 'left',
    },
    {
      title: 'Available Amount',
      key: 'amount',
      dataIndex: 'amount',
      align: 'right',
      render: formatters.commasNoDigits,
      width: 150,
    },
  ];
  if (session.client_money) {
    walletsColumns.push(
      {
        title: 'Clients',
        key: 'client_money',
        dataIndex: 'client_money',
        align: 'right',
        render: formatters.commasNoDigits,
        width: 200,
      },
      {
        title: 'Client Interest',
        key: 'client_interest',
        dataIndex: 'client_interest',
        align: 'right',
        render: formatters.commasNoDigits,
        width: 200,
      }
    );
  }

  if (showDeposits) {
    walletsColumns.push({
      // this column shows:
      // - unrealized bonds interest + realized bonds interest (+paid coupons)
      // - depos interest
      title: 'Accrued interest',
      key: 'total_currency_interest',
      dataIndex: 'total_currency_interest',
      align: 'right',
      render: formatters.commasNoDigits,
    });
  }
  if (session.bond_leverage != 1) {
    walletsColumns.push({
      title: 'Margin',
      dataIndex: 'collateral_amount',
      key: 'collateral_amount',
      align: 'right',
      render: formatters.commasNoDigits,
    });
  }
  if (session.scenario_type === HISTORICAL_SCENARIO) {
    walletsColumns.push({
      title: 'Collateral',
      dataIndex: 'auction_collateral',
      key: 'auction_collateral',
      align: 'right',
      render: formatters.commasNoDigits,
    });
  }

  if (!sessionHasNoEquities) {
    walletsColumns.push({
      title: 'Outstanding DIV',
      key: 'outstanding',
      dataIndex: 'outstanding',
      align: 'right',
      render(value: number) {
        if (divWaiting) {
          return <Spin size="small" />;
        }
        return formatters.commas2Digits(value);
      },
    });
  }

  return (
    <div>
      {use_card ? (
        <Card
          title={
            <Typography.Title level={4} style={{ marginTop: 16 }}>
              Cash Accounts
            </Typography.Title>
          }
        >
          <Table
            rowKey="id"
            bordered
            dataSource={data}
            pagination={false}
            style={{ overflow: 'hidden' }}
            columns={walletsColumns}
          />
        </Card>
      ) : (
        <div>
          <Typography.Title level={4}>Cash Accounts</Typography.Title>
          <Table
            rowKey="id"
            bordered
            dataSource={data}
            pagination={false}
            style={{ overflow: 'hidden' }}
            columns={walletsColumns}
          />
        </div>
      )}
    </div>
  );
};

export default CashAccounts;
