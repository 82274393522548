import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
} from 'react';
import Cookies from 'js-cookie';
import { SOUND_COOKIE_KEY } from 'state';

type State = {
  soundEnabled: 'enabled' | 'disabled';
  setSoundEnabled: Dispatch<SetStateAction<'enabled' | 'disabled'>>;
};

const CookieContext = createContext<State | undefined>(undefined);

const CookieProvider: React.FC = ({ children }) => {
  const soundCookie = Cookies.get(SOUND_COOKIE_KEY) as
    | State['soundEnabled']
    | undefined;

  const [soundEnabled, setSoundEnabled] = useState(soundCookie || 'enabled');

  useEffect(() => {
    Cookies.set(SOUND_COOKIE_KEY, soundEnabled);
  }, [soundEnabled]);

  const state: State = { soundEnabled, setSoundEnabled };

  return (
    <CookieContext.Provider value={state}>{children}</CookieContext.Provider>
  );
};

export default CookieProvider;

export const useCookieProvider = () => {
  const context = useContext(CookieContext);
  if (context === undefined) {
    throw new Error('Use useCookieProvider inside CookieProvider!');
  }
  return context;
};
