import { BUY, selectors, utils } from 'state';
import { useSelector } from 'react-redux';

const usePrices = (instrumentId: number, transaction: number) => {
  const instrument = useSelector(
    selectors.financialInstrumentById(instrumentId)
  );
  const asset = useSelector(selectors.underlyingAssetOfInstrument(instrument));
  const currency = useSelector(selectors.currencyById(asset.base_currency));

  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);

  const session = useSelector(selectors.activeSession);
  const reportingCurrency = useSelector(
    selectors.currencyById(session.reporting_currency)
  );

  const result = {
    price: 0,
    reportingPrice: 0,
  };

  let price = transaction === BUY ? asset.ask : asset.bid;

  if (!price) {
    return result;
  }

  if (asset.resourcetype.endsWith('Bond')) {
    price /= 100;
    price += asset.accrued_interest / 100;
  }

  let reportingPrice;
  if (asset.resourcetype === 'FX') {
    reportingPrice = currency.id !== reportingCurrency.id ? price : 1;
  } else {
    reportingPrice = utils.cashExchange(
      asset.base_currency,
      reportingCurrency.symbol,
      price,
      0,
      assetList,
      currencyList
    );
  }

  return {
    price,
    reportingPrice,
  };
};

export default usePrices;
