import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import {
  CreateIndexFund,
  createIndexFund,
  createUnderlyingAsset,
  instrumentTypes,
  marketHours,
  RefreshIndexFund,
  refreshIndexFundDataFromRefinitiv,
  REGIONS,
  selectors,
  UnderlyingAsset,
} from 'state';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { API_URL } from 'settings';

type Props = {
  hide: () => void;
};
const RefreshIndexFundModal: React.FC<Props> = ({ hide }) => {
  const dispatch = useDispatch();
  const waiting = useSelector(selectors.waiting);
  const [form] = Form.useForm();
  const indexFunds = useSelector(selectors.indexFundsList);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [data, setData] = useState(new FormData());
  const [fileSelected, setFileSelected] = useState(false);
  const token = useSelector(selectors.token);

  useEffect(() => {
    if (waiting) {
      setRefreshLoading(true);
    } else {
      setRefreshLoading(false);
    }
  }, [waiting]);

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const raw = e.target.files as FileList;
    const formData = new FormData();
    if (raw[0]) formData.append('news_file', raw[0], raw[0].name);
    setData(formData);
    setFileSelected(true);
  };

  const uploadHandler = async () => {
    fetch(`${API_URL}/admin/upload_index_fund_history/`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then(Response => {
        if (Response.status !== 200) {
          message.error('An error has occurred');
          (document.getElementById('news_file') as HTMLInputElement).value = '';
          setFileSelected(false);
        } else {
          (document.getElementById('news_file') as HTMLInputElement).value = '';
          setFileSelected(false);
          message.success('Successful operation');
          hide();
        }
      })
      .catch(() => {
        message.error('An error has occurred');
      });
  };

  const infoHover = (
    <div>
      <p>timestamp,close,ric,delete_prev_data</p>
      <p>where timestamp format is: 06-28-2022</p>
      <p>and delete_prev_data: True / False</p>
    </div>
  );

  return (
    <Modal
      visible={true}
      onOk={form.submit}
      onCancel={hide}
      title={'Refresh Index Fund EoD data'}
      // footer={[{}]}
      width={600}
      footer={null}
    >
      <div>
        {/*<Row>*/}
        {/*<Col span={14}>*/}
        <div style={{ position: 'relative', padding: 10 }}>
          <Form
            form={form}
            labelCol={{ span: 6 }}
            // onFinish={() => {
            //   const data: RefreshIndexFund = {
            //     from_date: form.getFieldValue('from_date'),
            //     to_date: form.getFieldValue('to_date'),
            //     index_fund_id: form.getFieldValue('index_fund_id'),
            //     delete_prev_data: form.getFieldValue('delete_prev_data'),
            //   };
            //
            //   console.log(data);
            //   // dispatch(refreshIndexFundDataFromRefinitiv(data));
            //   // hide();
            // }}
            initialValues={{ delete_prev_data: false }}
          >
            <Form.Item
              label="From"
              name="from_date"
              rules={[{ required: true, message: 'required' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="To"
              name="to_date"
              rules={[{ required: true, message: 'required' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="Index Fund"
              name="index_fund_id"
              rules={[{ required: true, message: 'required' }]}
            >
              <Select showSearch allowClear optionFilterProp="children">
                {indexFunds.map(fund => {
                  return (
                    <Select.Option key={fund.id} value={fund.id}>
                      {fund.ric}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Delete prev. data"
              name="delete_prev_data"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Form>
          <Button
            key="key"
            loading={refreshLoading}
            // type="primary"
            style={{ position: 'absolute', right: 25, bottom: -10 }}
            onClick={() => {
              const data: RefreshIndexFund = {
                from_date: form.getFieldValue('from_date'),
                to_date: form.getFieldValue('to_date'),
                index_fund_id: form.getFieldValue('index_fund_id'),
                delete_prev_data: form.getFieldValue('delete_prev_data'),
              };

              dispatch(refreshIndexFundDataFromRefinitiv(data));
            }}
          >
            Pull new data from Refinitiv
          </Button>
          {refreshLoading && (
            <Typography.Text
              type={'secondary'}
              style={{ position: 'absolute', right: 25, bottom: -35 }}
            >
              This may take a while, but you can safely close this modal.
            </Typography.Text>
          )}
        </div>
        <Divider style={{ marginTop: 45 }} />
        {/*</Col>*/}
        {/*<Col style={{ borderLeft: '2px solid #eeeeee' }} span={10}>*/}
        <div style={{ padding: 10 }}>
          <form id={'news_form'}>
            <Popover
              placement={'right'}
              content={infoHover}
              title=".csv header must look like:"
            >
              <InfoCircleOutlined />
            </Popover>
            <Button
              disabled={!fileSelected}
              style={{ marginLeft: 10 }}
              onClick={uploadHandler}
            >
              Upload
            </Button>
            <input
              style={{ marginLeft: '8px', marginBottom: '12px' }}
              type="file"
              id="news_file"
              name="news_file"
              accept=".csv"
              onChange={e => handleFileSelection(e)}
            />
          </form>
        </div>
        {/*</Col>*/}
        {/*</Row>*/}
      </div>
    </Modal>
  );
};
export default RefreshIndexFundModal;
