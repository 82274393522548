import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchKlasses, listTrainers, selectors } from 'state';
import { Button, List, Table } from 'antd';
import { LoadingScreen } from 'components';
import { useModal } from 'services';
import UpdatePasswordModal from './UpdatePasswordModal';
import EditClassModal from './EditClassModal';

const Trainers: React.FC = () => {
  const dispatch = useDispatch();
  const { waiting } = useSelector(selectors.trainersApi);
  const trainers = useSelector(selectors.trainersList);
  const klasses = useSelector(selectors.klasses);

  const [PasswordModal, showPassword] = useModal(UpdatePasswordModal);
  const [ClassModal, showClassModal] = useModal(EditClassModal);
  useEffect(() => {
    dispatch(listTrainers({}));
    dispatch(fetchKlasses({}));
  }, []);

  if (waiting) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Table
        rowKey="id"
        dataSource={trainers}
        columns={[
          { title: 'ID', dataIndex: 'id' },
          { title: 'Username', dataIndex: 'username' },
          {
            title: 'Classes (press class title to manage class users)',
            dataIndex: 'klasses',
            render(text, record) {
              return (
                <List>
                  {record.klasses.map(k => {
                    return (
                      <List.Item
                        key={k.id}
                        style={{ padding: 2 }}
                        onClick={() => {
                          showClassModal({ klassId: k.id });
                        }}
                      >
                        {k.name}
                      </List.Item>
                    );
                  })}
                </List>
              );
            },
          },
          {
            title: 'Actions',
            render(text, record) {
              return (
                <div>
                  <Button
                    onClick={() => showPassword({ trainerId: record.id })}
                  >
                    Set password
                  </Button>
                </div>
              );
            },
          },
        ]}
        pagination={false}
        bordered
      />

      {PasswordModal}
      {ClassModal}
    </>
  );
};

export default Trainers;
