import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FinancialInstrument,
  HISTORICAL_SCENARIO,
  retrieveHistory,
  selectors,
} from 'state';
import { Col, Modal, Row, Tabs } from 'antd';
import { AreaChart } from 'components';
import { FullscreenOutlined } from '@ant-design/icons';

type props = {
  instrument: FinancialInstrument;
};

const periods = [
  { value: 'one_day', name: '1D' },
  // { value: 'five_days', name: '5 days' },
  { value: 'one_month', name: '1M' },
  { value: 'six_months', name: '6M' },
  { value: 'one_year', name: '1Y' },
  { value: 'three_years', name: '3Y' },
  // { value: 'five_years', name: '5 years' },
  // { value: 'max_period', name: 'max' },
];

const PriceGraph: React.FC<props> = ({ instrument }) => {
  const dispatch = useDispatch();

  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const marketHistory =
    underlyingAssets[instrument?.underlying_asset]?.market_history;
  const asset = underlyingAssets[instrument.underlying_asset];

  const [expanded, setExpanded] = useState(false);

  const [period, setPeriod] = useState<
    | 'one_day'
    | 'five_days'
    | 'one_month'
    | 'six_months'
    | 'one_year'
    | 'three_years'
  >(periods[0].value as any);

  useEffect(() => {
    // console.log('before instrument');
    if (!instrument) return;
    // console.log('before market history');
    if (!marketHistory[period]) {
      dispatch(
        retrieveHistory({
          underlying_asset_id: underlyingAssets[instrument.underlying_asset].id,
          period: period as any,
        })
      );
    }
  }, [instrument?.underlying_asset, period]);

  const { scenario_type } = useSelector(selectors.activeSession);
  const isHistorical = scenario_type === HISTORICAL_SCENARIO;

  const name = underlyingAssets[instrument.underlying_asset].ticker;

  // const data =
  //   marketHistory[period as 'one_day']?.map(item => ({
  //     x: item.timestamp,
  //     y:
  //       // graphType === 'area'
  //       //   ? item.bid || item.close
  //       //   : [item.open, item.high, item.low, item.close],
  //       item.bid || item.close,
  //   })) || [];

  const data =
    marketHistory[period as 'one_day']?.map(item => {
      let price = item.bid || item.close;
      if (asset.resourcetype === 'FxForwards') {
        price = item.fwd_bid || item.close;
      }
      return {
        x: item.timestamp,
        y:
          // graphType === 'area'
          //   ? item.bid || item.close
          //   : [item.open, item.high, item.low, item.close],
          price,
      };
    }) || [];

  let tooltipDateFormat: string;
  switch (period) {
    case 'one_day':
      tooltipDateFormat = 'HH:mm';
      break;
    case 'five_days':
      tooltipDateFormat = 'ddd, d MMMM HH:mm';
      break;
    case 'one_month':
    case 'six_months':
      tooltipDateFormat = 'ddd, d MMMM';
      break;
    default:
      tooltipDateFormat = 'd MMMM yyyy';
  }

  if (isHistorical) tooltipDateFormat = 'ddd, d MMMM';

  const Chart = (
    <Row style={{ flexFlow: 'row nowrap', width: '100%' }}>
      <Col flex={1} style={{ minWidth: '350px' }}>
        <AreaChart
          title={name}
          dataSeriesName={name}
          data={data}
          yaxisFormatter={(value: any) => value.toLocaleString()}
          tooltipDateFormat={tooltipDateFormat}
          style={{ width: expanded ? 800 : '100%' }}
          toolbar={expanded}
          hideDate={isHistorical}
        />
      </Col>
      <Col>
        <Tabs
          style={{ height: expanded ? '100%' : 260 }}
          tabPosition="right"
          activeKey={period}
          onChange={val => {
            if (val === 'expand') return setExpanded(true);
            setPeriod(val as any);
          }}
        >
          {!expanded && (
            <Tabs.TabPane
              key="expand"
              tab={
                <FullscreenOutlined
                  style={{ fontSize: '20px', padding: 0, margin: 0 }}
                />
              }
            />
          )}
          {(isHistorical ? [] : periods).map(item => (
            <Tabs.TabPane key={item.value} tab={item.name} />
          ))}
        </Tabs>
      </Col>
    </Row>
  );

  return (
    <Row style={{ flexFlow: 'row nowrap' }}>
      {expanded ? (
        <Modal
          width={1000}
          visible={expanded}
          onCancel={() => setExpanded(false)}
          footer={[]}
        >
          {Chart}
        </Modal>
      ) : (
        Chart
      )}
    </Row>
  );
};

export default PriceGraph;
