import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createMatrix,
  getCorrelationValues,
  retrieveLastMatrix,
  selectors,
} from 'state';
import {
  Button,
  Col,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
  Divider,
  Spin,
  Progress,
  Popover,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import MatrixCanvas from './MatrixCanvas';

const MIN_ROWS = 2;
const MAX_ROWS = 15;

const INITIAL_MATRIX = [
  { row: undefined, col: undefined },
  { row: undefined, col: undefined },
  { row: undefined, col: undefined },
];

const CorrelationMatrix: React.FC = () => {
  const dispatch = useDispatch();
  const { item: stateMatrix, fetched, waiting } = useSelector(
    selectors.correlationMatrixAPI
  );
  const correlationValuesWaitingList = useSelector(
    selectors.correlationValuesWaitingList
  );
  const underlyingAssets = useSelector(selectors.underlyingAssets);

  const [match, setMatch] = useState(true);
  const [matrix, setMatrix] = useState<{ row?: number; col?: number }[]>(
    INITIAL_MATRIX
  );

  useEffect(() => {
    if (!fetched) {
      dispatch(retrieveLastMatrix({}));
    }
  }, [fetched]);

  useEffect(() => {
    if (stateMatrix.id) {
      setMatrix(stateMatrix.correlation_fields);
    }
  }, [stateMatrix]);

  const isMatrixValid = matrix.reduce((acc, field) => {
    return acc && field.col !== undefined && field.row !== undefined;
  }, true);

  const onGenerate = () => {
    const rows = matrix.map(m => m.row) as number[];
    const columns = matrix.map(m => m.col) as number[];
    dispatch(getCorrelationValues({ rows, columns }));
  };

  const onSave = () => {
    dispatch(
      createMatrix({
        correlation_fields: matrix as {
          row: number;
          col: number;
        }[],
      })
    );
  };

  const onReset = () => {
    if (!stateMatrix.correlation_fields?.length) {
      setMatrix(INITIAL_MATRIX);
    } else {
      setMatrix(stateMatrix.correlation_fields);
    }
  };

  const onAdd = () => {
    setMatrix([...matrix, { row: undefined, col: undefined }]);
  };

  return (
    <Row gutter={32}>
      <Col span={10}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          <div>
            <Switch
              checked={match}
              onChange={checked => {
                if (checked) {
                  const newMatrix = matrix.map(d => {
                    return { ...d, col: d.row };
                  });
                  setMatrix(newMatrix);
                }
                setMatch(checked);
              }}
            />{' '}
            Symmetric
          </div>

          <div>
            {matrix.map((d, index) => {
              return (
                <Row key={index}>
                  <Col span={11}>
                    <EquitySelect
                      value={d.row}
                      onChange={(value: number) => {
                        const newMatrix = [...matrix];
                        const newFields = { ...newMatrix[index], row: value };
                        if (match) {
                          newFields.col = value;
                        }
                        newMatrix[index] = newFields;
                        setMatrix(newMatrix);
                      }}
                    />
                  </Col>
                  <Col span={11}>
                    <EquitySelect
                      value={d.col}
                      onChange={(value: number) => {
                        const newMatrix = [...matrix];
                        newMatrix[index] = { ...newMatrix[index], col: value };
                        setMatrix(newMatrix);
                      }}
                    />
                  </Col>
                  <Col
                    span={2}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {matrix.length > MIN_ROWS ? (
                      <Button
                        type="link"
                        danger
                        onClick={() => {
                          const newMatrix = [...matrix];
                          newMatrix.splice(index, 1);
                          setMatrix(newMatrix);
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    ) : (
                      <Tooltip title="A minimum of 2 rows must exist">
                        <Button type="link" danger disabled>
                          <DeleteOutlined />
                        </Button>
                      </Tooltip>
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>

          <Row>
            <Col span={22} style={{ display: 'flex', gap: 8 }}>
              {matrix.length < MAX_ROWS ? (
                <Button onClick={onAdd}>
                  <PlusOutlined /> Add
                </Button>
              ) : (
                <Tooltip title="You can only add up to 15 rows">
                  <Button disabled>
                    <PlusOutlined /> Add
                  </Button>
                </Tooltip>
              )}

              <Button
                type="link"
                onClick={onReset}
                danger
                style={{ marginLeft: 'auto' }}
              >
                Reset
              </Button>

              <Button disabled={!isMatrixValid} onClick={onSave}>
                Save
              </Button>

              <Button
                type="primary"
                disabled={!isMatrixValid}
                onClick={onGenerate}
              >
                Generate
              </Button>
            </Col>
          </Row>
        </div>
        <Divider />
        <Row>
          <Col>
            <Typography.Title level={4}>Ongoing calculations:</Typography.Title>
          </Col>
          <Col>
            {correlationValuesWaitingList.length > 0 ? (
              <div>
                <ul style={{ marginTop: 5 }}>
                  {correlationValuesWaitingList.map(pair => {
                    const asset1 = underlyingAssets[pair[0]].ticker;
                    const asset2 = underlyingAssets[pair[1]].ticker;
                    const computed = pair[2];

                    return (
                      <li key={`${asset1}&${asset2}`}>
                        <Typography.Text>
                          {asset1}, {asset2}
                        </Typography.Text>
                        {computed == 0 && (
                          <Spin style={{ marginLeft: 6 }} size={'small'} />
                        )}
                        {computed == 1 && (
                          <Progress
                            style={{ marginLeft: 6 }}
                            type="circle"
                            percent={100}
                            width={13}
                          />
                        )}
                        {computed == -1 && (
                          <Popover
                            placement="right"
                            content={
                              <p> Computation error, try to regenerate.</p>
                            }
                          >
                            <Progress
                              style={{ marginLeft: 6 }}
                              type="circle"
                              percent={100}
                              status="exception"
                              width={13}
                            />
                          </Popover>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <div style={{ marginTop: 5, marginLeft: 25 }}>
                <Typography.Text>None</Typography.Text>
              </div>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={14} style={{ display: 'flex', justifyContent: 'center' }}>
        <MatrixCanvas correlationFields={matrix} />
      </Col>
    </Row>
  );
};

const EquitySelect: React.FC<{
  value: number | undefined;
  onChange: (value: number) => void;
}> = ({ value, onChange }) => {
  const equities = useSelector(selectors.equityList);

  const options = equities.map(equity => {
    return { value: equity.id, name: equity.name };
  });

  return (
    <Select
      style={{ width: '100%' }}
      value={value}
      placeholder="Select equity"
      onChange={onChange}
      allowClear
      showSearch
      filterOption={(inputValue, option) => {
        const lowerInput = inputValue.toLowerCase();
        return (option?.children || '').toLowerCase().includes(lowerInput);
      }}
    >
      {options.map(o => {
        return (
          <Select.Option key={o.value} value={o.value}>
            {o.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default CorrelationMatrix;
