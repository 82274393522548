import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Card, Select, Typography } from 'antd';

import { selectors } from 'state';

import BenchmarkIndexComposition from './BenchmarkIndexComposition';
import BenchmarkConstant from './BenchmarkConstant';
import BenchmarkPortfolioComposition from './BenchmarkPortfolioComposition';
import IndexFundsTable from './IndexFundsTable';

const BenchmarkSelector: React.FC = () => {
  const { benchmark_constant, benchmark_portfolio_compositions } = useSelector(
    selectors.activeSession
  );

  const isConstant = !!benchmark_constant;
  const isPortfolio = !!benchmark_portfolio_compositions.length;

  const [benchmarkType, setBenchmarkType] = useState(
    isConstant ? 0 : isPortfolio ? 1 : 2
  );

  return (
    <div style={{ minWidth: 330 }}>
      <Typography.Title level={3}>Benchmark composition</Typography.Title>
      <Card>
        <p>Benchmark type: </p>
        <Select
          value={benchmarkType}
          onChange={setBenchmarkType}
          style={{ width: 250, marginBottom: 20 }}
        >
          <Select.Option value={0}>Constant</Select.Option>
          <Select.Option value={1}>Benchmark Portfolio</Select.Option>
          <Select.Option value={2}>Index Fund Composition</Select.Option>
        </Select>
        {benchmarkType === 0 && <BenchmarkConstant />}
        {benchmarkType === 1 && <BenchmarkPortfolioComposition />}
        {benchmarkType === 2 && <BenchmarkIndexComposition />}
      </Card>
      {benchmarkType === 2 && <IndexFundsTable />}
    </div>
  );
};

export default BenchmarkSelector;
