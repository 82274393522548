import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Typography } from 'antd';

import { formatters } from 'services';
import {
  // GROUP_ASSET_LIMITS,
  GROUP_ASSET_NAME,
  GROUP_OF_ASSET,
  selectors,
} from 'state';

const PortfolioLimits: React.FC = () => {
  const { tactical_asset_allocations } = useSelector(selectors.player);
  const session = useSelector(selectors.activeSession);
  const group_allocations = session.asset_group_allocations;

  const groupedAssets = Object.entries(GROUP_ASSET_NAME)
    .map(([group_id, name]) => {
      let allocation = group_allocations.find(
        obj => obj.asset_group === Number(group_id)
      );
      if (allocation === undefined) {
        allocation = {
          lower_limit: 0,
          upper_limit: 0,
          asset_group: Number(group_id),
        };
      }
      const limit = [allocation.lower_limit, allocation.upper_limit];

      let percent = 0;
      tactical_asset_allocations
        .filter(
          asset => GROUP_OF_ASSET[asset.asset_class] === parseInt(group_id)
        )
        .forEach(asset => {
          percent += asset.percent;
        });

      return { name, limit, percent };
    })
    .filter(obj => obj.limit[1] !== 0);
  if (groupedAssets.length === 0) {
    return <></>;
  }
  return (
    <div>
      <Typography.Title level={4}>Portfolio Limits</Typography.Title>
      <Table
        rowKey="name"
        pagination={false}
        dataSource={groupedAssets}
        bordered
        columns={[
          {
            title: 'Asset Class',
            dataIndex: 'name',
          },
          {
            title: 'Allocation',
            dataIndex: 'percent',
            render: function allocation(val, record) {
              const [min, max] = record.limit;

              return (
                <span
                  style={{
                    color: val >= min && val <= max ? '' : '#f5222d',
                  }}
                >
                  {formatters.percent1Digit(val)}
                </span>
              );
            },
          },
          {
            title: 'Limits',
            dataIndex: 'limit',
            render: ([min, max]) =>
              `${Math.floor(min * 100)} - ${Math.floor(max * 100)} %`,
          },
        ]}
      />
    </div>
  );
};

export default PortfolioLimits;
