import React, { useEffect } from 'react';
import EditableTable from './EditableTable';
import {
  Currency,
  fetchAssets,
  fetchFxForwards,
  selectors,
  UnderlyingAsset,
  updateCurrency,
  updateFxForwards,
} from 'state';
import { useDispatch, useSelector } from 'react-redux';

const SwapPointsTab: React.FC = () => {
  const dispatch = useDispatch();
  const currencies = useSelector(selectors.currenciesList);
  const assets = useSelector(selectors.underlyingAssetsList);

  useEffect(() => {
    if (assets.length === 0) {
      dispatch(fetchAssets({}));
    }
  }, []);

  // console.log(assets);

  const data = assets
    .filter(asset => {
      return asset.resourcetype === 'FxForwards';
    })
    .map(asset => {
      return {
        id: asset.id,
        ric: asset.ric,
        name: asset.name,
        no_of_decimals: asset.no_of_decimals,
        corresponding_fx_asset: assets.find(current_asset => {
          return current_asset.id === asset.corresponding_fx_asset;
        })?.ric,
        resourcetype: asset.resourcetype,
      };
    });

  // console.log(data);

  return (
    <>
      <p>
        If asset price is quoted in 4 decimals, swap points need to be divided
        by 10000. If asset price is quoted in 2 decimals, swap points need to be
        divided by 100
      </p>
      <p>
        Corresponding Fx Asset is used for correlating spot prices with swap
        points to infer forward rates and vice-versa. Enter the ric of the
        corresponding FX asset in order to change.
      </p>
      <EditableTable
        data={data}
        onSave={(a: Partial<UnderlyingAsset>) => {
          dispatch(updateFxForwards(a));
        }}
        nonEditableFields={['ric', 'name', 'resourcetype']}
      />
    </>
  );
};

export default SwapPointsTab;
