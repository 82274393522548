import React from 'react';
import { Form, Input, Typography } from 'antd';

const TraineesForm: React.FC = () => {
  return (
    <div>
      <Typography.Title
        level={4}
        style={{ borderBottom: '1px solid #000', paddingBottom: 5 }}
      >
        Trainees
      </Typography.Title>
      <Form.Item name="traineeNames" label="Names" rules={[{ required: true }]}>
        <Input.TextArea placeholder="name1,name2,name3" />
      </Form.Item>

      <Form.Item
        name="traineePassword"
        label="Password"
        rules={[{ required: true }]}
      >
        <Input placeholder="password" />
      </Form.Item>
    </div>
  );
};

export default TraineesForm;
