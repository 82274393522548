import React from 'react';

import { Space, Statistic, Typography, Divider } from 'antd';

import { formatters } from 'services';
import type { IndividualReport } from 'state';

export type Props = {
  report: IndividualReport;
};

const ReturnPerTrade: React.FC<Props> = ({ report }) => {
  const { return_per_trade: returnPerTrade } = report;

  const minMaxFormatter = (value: Array<number>) => {
    const val0 = formatters.commasNoDigits(value[0]);
    const val1 = formatters.commasNoDigits(value[1]);
    return `${val0} / ${val1}`;
  };

  return (
    <div>
      <Divider orientation="left">
        <Typography.Title level={3}>Return per trade</Typography.Title>
      </Divider>

      <Space size={10} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Statistic
          title="Average Profit"
          value={returnPerTrade.avg_profit}
          formatter={formatters.commasNoDigits}
        />

        <Divider type="vertical" />
        <Statistic
          title="Average Loss"
          value={returnPerTrade.avg_loss}
          formatter={formatters.commasNoDigits}
        />

        <Divider type="vertical" />
        <Statistic
          title="Position pnl average"
          value={returnPerTrade.position_pnl_avg}
          formatter={formatters.commasNoDigits}
        />
      </Space>

      <Divider />

      <Space size={10} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Statistic
          title="Min/Max Realized Profit"
          value={minMaxFormatter([
            returnPerTrade.min_realized_profit,
            returnPerTrade.max_realized_profit,
          ])}
        />
        <Divider type="vertical" />
        <Statistic
          title="Min/Max Realized Loss"
          value={minMaxFormatter([
            returnPerTrade.min_realized_loss,
            returnPerTrade.max_realized_loss,
          ])}
        />
        <Divider type="vertical" />
        <Statistic
          title="Min/Max Unrealized Profit"
          value={minMaxFormatter([
            returnPerTrade.min_unrealized_profit,
            returnPerTrade.max_unrealized_profit,
          ])}
        />
        <Divider type="vertical" />
        <Statistic
          title="Min/Max Unrealized Loss"
          value={minMaxFormatter([
            returnPerTrade.min_unrealized_loss,
            returnPerTrade.max_unrealized_loss,
          ])}
        />
      </Space>
    </div>
  );
};

export default ReturnPerTrade;
