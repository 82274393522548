import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Button,
  Table,
  Popover,
  Space,
  Empty,
  Typography,
  Popconfirm,
  Badge,
  InputNumber,
  Divider,
} from 'antd';

import {
  selectors,
  ForwardContract,
  closeForwardContract,
  PAUSED,
  FROZEN,
  buyForwardContract,
  BUY,
  connectToAdminSocket,
  FinancialInstrument,
  setForwardContractOda,
  deleteForwardContractOda,
  updateForwardContractOda,
} from 'state';
import { formatters } from 'services';
import { cashExchange, useReportingCurrency } from '../../state/app/utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import {
  fwdContractById,
  OdaOfForwardContract,
} from '../../state/app/selectors';

type props = {
  visible: boolean;
  hide: () => void;
  contract_id: number | undefined;
  instrument_id: number;
};

type displayContract = {
  contract: ForwardContract;
  key: string;
  name: string;
  open_date: string;
  currency: string;
  open_price: string;
  amount: string;
  reporting_unrealized_pnl: string;
  value: string;
};

const FwdOdaModal: React.FC<props> = ({
  visible,
  hide,
  contract_id,
  instrument_id,
}) => {
  const dispatch = useDispatch();
  const session = useSelector(selectors.activeSession);
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);
  const instrument = useSelector(
    selectors.financialInstrumentById(instrument_id)
  );
  const asset = useSelector(selectors.underlyingAssetOfInstrument(instrument));
  const currency = useSelector(selectors.currencyById(asset.base_currency));
  const reportingCurrency = useReportingCurrency(session.id);
  const contract = useSelector(selectors.fwdContractById(contract_id));
  // console.log('contract: ', contract);
  const oda = useSelector(selectors.OdaOfForwardContract(contract!.id));
  const [stopLossPrice, setStopLossPrice] = useState<number>(
    oda?.stop_loss_price || asset.bid
  );
  const [takeProfitPrice, setTakeProfitPrice] = useState<number>(
    oda?.take_profit_price || asset.bid
  );
  let contract_display_data: displayContract | undefined;
  if (contract) {
    const reporting_value = cashExchange(
      asset.base_currency,
      session.reporting_currency,
      contract.amount,
      0,
      assetList,
      currencyList
      // contract.open_price
    );

    contract_display_data = {
      contract: contract,
      key: contract.open_date,
      name: asset.name,
      open_date: contract.open_date.split('T')[0],
      currency: currency.symbol,
      open_price: formatters.priceFormat(
        contract.open_price,
        asset.ticker,
        asset.resourcetype
      ),
      amount: formatters.commas2Digits(contract.amount),
      reporting_unrealized_pnl: formatters.commas2Digits(
        contract.reporting_unrealized_pnl
      ),
      value: formatters.commas2Digits(-1 * reporting_value),
    };
  }

  let data: displayContract[] | undefined = [];

  // useEffect(() => {}, [contract_from_state]);

  if (contract_display_data !== undefined) {
    data = [contract_display_data];
  }
  console.log('data: ', data);

  // const columns: ColumnsType<displayContract> = [
  const columns: ColumnsType<displayContract> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Open',
      dataIndex: 'open_date',
    },
    {
      title: 'Maturity',
      dataIndex: 'contract',
      render: (text, record) => {
        return record.contract ? record.contract.maturity_date : null;
      },
    },
    {
      title: 'CCY',
      dataIndex: 'currency',
    },
    {
      title: 'Open Price',
      dataIndex: 'open_price',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: `Unrealized, ${reportingCurrency.symbol}`,
      dataIndex: 'reporting_unrealized_pnl',
      render: (text, record) => {
        return record.contract
          ? formatters.commasNoDigits(record.contract.reporting_unrealized_pnl)
          : null;
      },
    },
    {
      title: `Realized, ${reportingCurrency.symbol}`,
      dataIndex: 'contract',
      render: (text, record) => {
        return record.contract
          ? formatters.commasNoDigits(record.contract.reporting_realized_pnl)
          : null;
      },
    },
    {
      title: `Value, ${reportingCurrency.symbol}`,
      dataIndex: 'value',
    },
    // {
    //   title: ActionColumnTitle,
    //   render: (val: any, record: displayContract) => ActionButton(record),
    // },
    {
      title: 'ID',
      dataIndex: 'contract',
      render: (val, record) => record.contract.id,
    },
  ];

  const has_oda = contract?.oda;

  // const status = false;

  return (
    <Modal
      visible={visible}
      title={`ODA Menu`}
      onOk={hide}
      onCancel={hide}
      width={'50%'}
      footer={[
        <Button
          disabled={!has_oda}
          key="place"
          type="danger"
          onClick={() => {
            if (contract) {
              dispatch(deleteForwardContractOda({ contract_id: contract.id }));
              hide();
            }
          }}
        >
          Disable
        </Button>,
        <Button
          key="place"
          type="primary"
          onClick={() => {
            if (contract) {
              if (contract.oda) {
                dispatch(
                  updateForwardContractOda({
                    contract_id: contract.id,
                    stop_loss_price: stopLossPrice,
                    take_profit_price: takeProfitPrice,
                  })
                );
              } else {
                dispatch(
                  setForwardContractOda({
                    contract_id: contract.id,
                    stop_loss_price: stopLossPrice,
                    take_profit_price: takeProfitPrice,
                  })
                );
                // hide();
              }
            }
          }}
        >
          {/*{contract?.contract.oda ? 'Update' : 'Set'}*/}
          {has_oda ? 'Update' : 'Set'}
        </Button>,
      ]}
    >
      <div>
        <Typography.Text strong>Forward contract:</Typography.Text>
        <Table
          // title={() => <div>Forward contract:</div>}
          dataSource={data}
          columns={columns}
          bordered
          pagination={false}
          // locale={noDataBehaviour}
        ></Table>
        <br />
        {/*<Typography.Text strong>Current ODA status:</Typography.Text>*/}
        <Typography.Text strong>Current price:</Typography.Text>
        <InputNumber
          style={{ marginLeft: 10, width: 125 }}
          formatter={() => {
            return `${formatters.commasExact4Digits(
              asset.bid
            )} / ${formatters.commasExact4Digits(asset.ask)}`;
          }}
          disabled
        />
        <div style={{ marginTop: 10 }}>
          <Typography.Text strong>Current swap:</Typography.Text>
          <InputNumber
            style={{ marginLeft: 10, width: 125 }}
            formatter={() => {
              return `${formatters.commasExact4Digits(
                asset.bid_swap_points
              )} / ${formatters.commasExact4Digits(asset.ask_swap_points)}`;
            }}
            disabled
          />
        </div>

        <Divider />
        <Badge
          status={has_oda ? 'processing' : 'default'}
          color={has_oda ? 'green' : 'orange'}
          text={has_oda ? 'ODA active' : 'ODA inactive'}
        />

        <div style={{ marginTop: 15 }}>
          Stop Loss: {'  '}
          <InputNumber
            style={{ marginLeft: 5 }}
            step={0.0001}
            precision={4}
            value={stopLossPrice}
            // min={instrumentAmount > 0 ? undefined : currentPrice}
            // max={instrumentAmount > 0 ? currentPrice : undefined}
            onChange={val => {
              if (val) {
                setStopLossPrice(val);
              }
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          Take Profit:{' '}
          <InputNumber
            step={0.0001}
            precision={4}
            value={takeProfitPrice}
            // min={instrumentAmount > 0 ? currentPrice : undefined}
            // max={instrumentAmount > 0 ? undefined : currentPrice}
            onChange={val => {
              if (val) {
                setTakeProfitPrice(val);
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FwdOdaModal;
