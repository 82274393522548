import React, { useState } from 'react';
import {
  Button,
  Card,
  InputNumber,
  Modal,
  notification,
  Select,
  Table,
  Typography,
} from 'antd';
import {
  auctions_tenors,
  receiveCreatedAuctionType,
} from '../../../state/app/auctions/types';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuctionResponse } from '../../../state/app/auctions/actions';
import { player } from '../../../state/app/selectors';
import { formatters } from 'services';
type Props = {
  visible: boolean;
  hide: () => void;
  auction: receiveCreatedAuctionType | undefined;
};

export const respondToAuctionColumns: ((
  | ColumnGroupType<receiveCreatedAuctionType>
  | ColumnType<receiveCreatedAuctionType>
) & {
  dataIndex: string;
})[] = [
  {
    title: 'Tenor',
    dataIndex: 'tenor',
    key: 'tenor',
    render: (value: number) => {
      return auctions_tenors[value];
    },
  },
  // {
  //   title: 'Unit',
  //   dataIndex: 'tenor_unit',
  //   key: 'tenor_unit',
  //   render: (text: number) => {
  //     if (text === 0) {
  //       return <div>Day</div>;
  //     } else if (text === 1) {
  //       return <div>Week</div>;
  //     } else if (text === 2) {
  //       return <div>Month</div>;
  //     }
  //   },
  // },
  {
    title: 'Series',
    dataIndex: 'auction_series',
    key: 'auction_series',
  },
  // {
  //   title: 'Maturity',
  //   dataIndex: 'maturity_date',
  //   key: 'maturity_date',
  // },
  {
    title: 'Auction value',
    dataIndex: 'bid_value_no_units',
    key: 'bid_value_no_units',
  },
  {
    title: 'Unit',
    dataIndex: 'bid_unit',
    key: 'bid_unit',
    render: (text: number) => {
      if (text === 0) {
        return <div>Million</div>;
      } else if (text === 1) {
        return <div>Billion</div>;
      } else if (text === 2) {
        return <div>Trillion</div>;
      }
    },
  },
  {
    title: 'CCY',
    dataIndex: 'currency_symbol',
    key: 'ccy',
  },
  {
    title: 'rate',
    dataIndex: 'rate',
    key: 'rate',
  },
];
const ViewBidAuctionModal: React.FC<Props> = ({ visible, hide, auction }) => {
  // console.log('auction from modal: ', auction);
  const dispatch = useDispatch();
  const playerObj = useSelector(player);
  // const [bid, setBid] = useState<number | undefined>(10);
  // const [bidUnit, setBidUnit] = useState(0);
  const thisPlayer = useSelector(player);
  const thisPlayersResponse = auction?.responses.find(
    response => response.player === thisPlayer.id
  );
  return (
    <Modal
      visible={visible}
      onCancel={hide}
      width={900}
      footer={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {/*<div style={{ marginRight: 5 }}>(time left: 2:34)</div>*/}
          {/*<Button onClick={() => hide()}>Cancel</Button>*/}
          <Button
            onClick={() => {
              // handlePlaceBid();
            }}
            disabled={true}
          >
            Waiting auction close
          </Button>
        </div>
      }
    >
      <Typography.Title level={4} style={{ margin: 0 }}>
        Auction response
      </Typography.Title>
      <br />
      <Card title={'Auction Details:'} size={'small'}>
        <Table
          pagination={false}
          bordered
          dataSource={auction ? [auction] : []}
          columns={respondToAuctionColumns}
        ></Table>
      </Card>
      <br />
      <Card title={'Your offer'} size={'small'} style={{ width: 250 }}>
        <InputNumber
          style={{ marginRight: 10, width: 120 }}
          min={1}
          value={
            thisPlayersResponse ? thisPlayersResponse.initial_bid_value : 0
          }
          disabled={true}
          formatter={formatters.commasNoDigits}
        />
        {auction?.currency_symbol}
        {/*<Select*/}
        {/*  style={{ width: 120 }}*/}
        {/*  value={thisPlayersResponse ? thisPlayersResponse.bid_unit : 0}*/}
        {/*  options={[*/}
        {/*    {*/}
        {/*      value: 0,*/}
        {/*      label: 'million',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      value: 1,*/}
        {/*      label: 'billion',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      value: 2,*/}
        {/*      label: 'trillion',*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  disabled*/}
        {/*/>*/}
      </Card>
    </Modal>
  );
};

export default ViewBidAuctionModal;
