import React from 'react';

import { Button, Form, Select, Space } from 'antd';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons/lib';

type Props = {
  name: string;
  addNewText: string;
  renderField: (field: any) => React.ReactNode;
};

const DynamicFormField: React.FC<Props> = props => {
  const { name, renderField, addNewText } = props;

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(field => (
            <Space key={field.key}>
              {renderField(field)}

              <MinusCircleOutlined onClick={() => remove(field.name)} />
            </Space>
          ))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              block
            >
              <PlusOutlined /> {addNewText}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default DynamicFormField;
