import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Input } from 'antd';

import { selectors, updateNews } from 'state';

type Props = {
  visible: boolean;
  hide: () => void;
  newsId: number;
};

const NewsBodyModal: React.FC<Props> = ({ visible, hide, newsId }) => {
  const dispatch = useDispatch();
  const news = useSelector(selectors.newsById(newsId));

  const [body, setBody] = useState(news?.body);

  return (
    <Modal
      visible={visible}
      onOk={() => dispatch(updateNews({ id: newsId, body }))}
      onCancel={hide}
      width={1000}
    >
      <Input.TextArea
        autoSize={{ minRows: 30 }}
        value={body}
        onChange={e => setBody(e.target.value)}
      />
    </Modal>
  );
};

export default NewsBodyModal;
