import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Checkbox, Descriptions, Typography } from 'antd';

import { selectors, toggleInvestmentRationale, toggleSound } from 'state';

const OtherSettings: React.FC = () => {
  const dispatch = useDispatch();
  const session = useSelector(selectors.activeSession);

  if (!session) {
    return null;
  }

  return (
    <div>
      <Typography.Title level={3}>Other Settings</Typography.Title>
      <Card>
        <Checkbox
          checked={session.require_investment_rationale}
          onChange={e => {
            dispatch(
              toggleInvestmentRationale({
                require_investment_rationale: e.target.checked,
              })
            );
          }}
        >
          Require investment rationale
        </Checkbox>
        <Checkbox
          style={{ marginLeft: 0 }}
          checked={session.allow_sound}
          onChange={e => {
            dispatch(toggleSound({ allow_sound: e.target.checked }));
          }}
        >
          Allow sound
        </Checkbox>
      </Card>
    </div>
  );
};

export default OtherSettings;
