import React from 'react';

import { LAYOUT1, LAYOUT2, LAYOUTS } from 'state';
import { useLayoutProvider } from './LayoutProvider';

const thumbs = {
  [LAYOUT1]: (
    <div className="roadmap__thumb roadmap__thumb--1">
      <div className="box1"></div>
    </div>
  ),
  [LAYOUT2]: (
    <div className="roadmap__thumb roadmap__thumb--2">
      <div className="box1"></div>
      <div className="box2"></div>
    </div>
  ),
};

const LayoutSelector: React.FC = () => {
  const { layoutType, setLayoutType } = useLayoutProvider();

  return (
    <div className="roadmap__layouts" style={{ marginBottom: 32 }}>
      {Object.keys(LAYOUTS).map(l => {
        const currentLayout = Number(l);
        const isSelected = currentLayout === layoutType;

        return (
          <div
            key={l}
            onClick={() => {
              // TODO: ENABLE THIS
              if (currentLayout == LAYOUT2) {
                return;
              }
              setLayoutType(currentLayout);
            }}
            className={`roadmap__layout ${isSelected ? 'active' : ''}`}
          >
            {thumbs[l as '0']}
          </div>
        );
      })}
    </div>
  );
};

export default LayoutSelector;
