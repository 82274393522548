import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectors,
  ASSET_CLASSES,
  RISK_WEIGHTED_APPROACH,
  updateLeverageSettings,
  updateAssetClassOptions,
  AssetClassOptions,
  MarginOptions,
} from 'state';
import { EditableTable } from 'reusable';
import { LoadingScreen, PercentInput } from 'components';
import { Button, InputNumber, Popover, Table, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

type State = {
  [type: string]: MarginOptions;
};
const LeverageSettings: React.FC = () => {
  const dispatch = useDispatch();
  const { asset_class_options, risk_approach } = useSelector(
    selectors.activeSession
  );
  const session = useSelector(selectors.activeSession);
  const [editing, setEditing] = useState(false);
  const [margins, setMargins] = useState<State>({});
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    const newMargins = {
      FxForwards: { type: 'FxForwards', margin: session.fx_leverage },
      Bonds: { type: 'Bonds', margin: session.bond_leverage },
    } as State;

    setMargins(newMargins);
  }, [session.fx_leverage, session.bond_leverage]);

  if (margins === undefined) {
    return <LoadingScreen />;
  }

  const dataSource = [
    {
      type: 'FxForwards',
      margin: session.fx_leverage * 100,
    },
    {
      type: 'Bonds',
      margin: session.bond_leverage * 100,
    },
  ];

  const reset = () => {
    const newMargins = {} as State;
    newMargins['FxForwards'] = {
      type: 'FxForwards',
      margin: session.fx_leverage,
    };
    newMargins['Bonds'] = {
      type: 'Bonds',
      margin: session.bond_leverage,
    };
    setMargins(newMargins);
    setErrors([]);
  };

  const getProps = (field: 'margin') => {
    return {
      render(_: unknown, record: typeof dataSource[0]) {
        if (editing) {
          // const assetClass = record.asset_class;
          const allocation = margins[record.type];
          const margin = allocation?.[field] ?? 0;

          return (
            <div>
              <InputNumber
                value={margin}
                style={{ width: 70 }}
                onChange={value => {
                  if (value === undefined) {
                    return;
                  }
                  setMargins({
                    ...margins,
                    [record.type]: {
                      ...allocation,
                      [field]: value,
                    },
                  });
                }}
                step={0.01}
                min={0}
                max={1}
                formatter={value => {
                  const nr = Number(value) || 0;
                  return `${Math.round(nr * 100)}%`;
                }}
                parser={value => {
                  const parsedValue = `${value?.replaceAll('%', '')}`;
                  const nr = Number(parsedValue);
                  if (!isNaN(nr)) {
                    return (nr / 100).toFixed(2);
                  }
                  return '';
                }}
              />
            </div>
          );
        }

        if (!record.margin) {
          return <div>-</div>;
        }
        return <div>{Math.round(record.margin)}%</div>;
      },
    };
  };

  const editButton = (
    <div
      style={{ position: 'relative', display: 'flex', gap: '10px', height: 30 }}
    >
      {!editing && (
        <Button
          onClick={() => {
            setEditing(true);
          }}
          style={{ position: 'absolute', right: 0 }}
        >
          Edit
        </Button>
      )}
      {editing && (
        <>
          <Button
            type="primary"
            onClick={() => {
              if (!isValid()) {
                return;
              }

              setEditing(false);
              dispatch(updateLeverageSettings({ margins: margins }));
            }}
            style={{ position: 'absolute', right: 90 }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setEditing(false);
              reset();
            }}
            style={{ position: 'absolute', right: 0 }}
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  );

  const isValid = () => {
    const errorsList = [];

    const valid = Object.values(margins).reduce((acc, { margin }) => {
      return acc && margin >= 0 && margin <= 100;
    }, true);

    if (!valid) {
      errorsList.push('Limits must be between 0 and 100');
    }

    if (errorsList.length) {
      setErrors(errorsList);
      return false;
    }

    return true;
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Percentage',
      ...getProps('margin'),
    },
  ];

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <Typography.Title level={4} style={{ display: 'inline-block' }}>
          Margin
        </Typography.Title>
      </div>

      <Table
        rowKey="margins"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        footer={() => editButton}
      />

      {/*<EditableTable*/}
      {/*  handleSave={item => {*/}
      {/*    item.percent /= 100;*/}
      {/*    dispatch(*/}
      {/*      updateLeverageSettings({ type: item.type, percent: item.percent })*/}
      {/*    );*/}
      {/*    console.log(item);*/}
      {/*  }}*/}
      {/*  columns={columns}*/}
      {/*  dataSource={dataSource}*/}
      {/*/>*/}
    </div>
  );
};

export default LeverageSettings;
