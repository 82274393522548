import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Empty, Space, Table, Typography } from 'antd';

import SessionSettings from './SessionSettings';

import {
  selectors,
  fetchSessions,
  SESSION_STATUSES,
  playPauseSession,
  PLAYING,
  PAUSED,
  SCENARIO_TYPES,
  RISK_APPROACHES,
  fetchHistoricalStep,
} from 'state';
import { formatters } from 'services';
import { PlusOutlined } from '@ant-design/icons';
import { LoadingScreen } from 'components';

type props = {
  klass: number;
};

const noData = (
  <Empty
    description={
      <Typography.Text type={'secondary'}>
        No sessions on selected class. Create a new session or select other
        class to see its sessions.
      </Typography.Text>
    }
  />
);

const noDataBehaviour = {
  emptyText: noData,
};

const Sessions: React.FC<props> = ({ klass: klassId }) => {
  const dispatch = useDispatch();
  const sessions = useSelector(selectors.sessions).filter(
    item => item.klass === klassId
  );
  const klass = useSelector(selectors.klassById(klassId));

  // console.log(klass);

  const [createSessionVisible, setCreateSessionVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchSessions({}));
    dispatch(fetchHistoricalStep({}));
  }, [dispatch]);

  if (klass === undefined) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        <Typography.Title level={4} style={{ margin: 0 }}>
          Sessions
        </Typography.Title>
        <Button type="primary" onClick={() => setCreateSessionVisible(true)}>
          <PlusOutlined /> Create
        </Button>
      </div>
      <Table
        rowKey="id"
        bordered
        dataSource={sessions}
        pagination={false}
        locale={noDataBehaviour}
        columns={[
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
          },
          {
            title: 'Class',
            dataIndex: 'klass',
            key: 'klass',
            render: () => klass.name,
          },
          {
            title: 'Type',
            dataIndex: 'scenario_type',
            key: 'scenario_type',
            render(value: keyof typeof SCENARIO_TYPES) {
              return SCENARIO_TYPES[value];
            },
          },
          {
            title: 'Risk approach',
            dataIndex: 'risk_approach',
            key: 'risk_approach',
            render: (risk_approach: keyof typeof RISK_APPROACHES) =>
              RISK_APPROACHES[risk_approach],
          },
          {
            title: 'Created at',
            dataIndex: 'create_date',
            render(value) {
              return value ? formatters.dateWithTime(value) : '-';
            },
          },
          {
            title: 'Finished at',
            dataIndex: 'finish_date',
            render(value) {
              return value ? formatters.dateWithTime(value) : '-';
            },
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: function Status(status: 0 | 1 | 2 | 3 | 4 | 5, record) {
              return (
                <div>
                  {`${SESSION_STATUSES[status]}   `}
                  <Button
                    onClick={() => {
                      dispatch(
                        playPauseSession({
                          id: record.id,
                          current_status: record.status,
                        })
                      );
                    }}
                  >
                    {record.status === PLAYING && 'Pause'}
                    {record.status === PAUSED && 'Play'}
                  </Button>
                </div>
              );
            },
          },
          {
            title: 'Actions',
            key: 'actions',
            render: function Action(text, record) {
              return (
                <Space key={record.id} size="middle">
                  <Link target="_blank" to={`/session/${record.id}`}>
                    <Button type="primary">Join session</Button>
                  </Link>
                </Space>
              );
            },
          },
        ]}
      />

      <SessionSettings
        visible={createSessionVisible}
        hide={() => setCreateSessionVisible(false)}
        klassId={klassId}
      />
    </div>
  );
};

export default Sessions;
