import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';

import { Redirect } from 'reusable';
import { LoadingScreen, PausedSession } from 'components';
import {
  selectors,
  fetchBadges,
  connectToSessionSocket,
  disconnectFromSessionSocket,
  retrieveSession,
  fetchKlasses,
  fetchSessions,
  FINISHED,
  HISTORICAL_SCENARIO,
  REAL_TIME_SCENARIO,
  fetchHistoricalStep,
  fetchForwardContracts,
  fetchMarketHours,
} from 'state';
import PersonSelector from '../TraineeDashboard/PersonSelector';
import { IndividualReport } from 'reusable';
import Header from './Header';
import RiskProfile from './RiskProfile';
import MarketsTab from './MarketsTab';
import BlotterTab from './BlotterTab';
import OpenOrders from './OpenOrders';
import Metrics from './Metrics';
import Settings from './Settings';
import PortfolioTab from './PortfolioTab';
import NewsHistory from './NewsHistory';
import MacroBackground from './MacroBackground';
import Benchmarks from './Benchmarks';
import SoundPlayer from './SoundPlayer';
import CookieProvider from './CookieProvider';
import EquitiesResearch from './EquitiesResearch';
import Insights from './Insights';
import HistoricalProgressTrainee from '../../components/Charts/HistoricalProgressTrainee';
import { TraineeSessionContextWrapper } from './TraineeSessionContext';
import Auctions from './Auctions';
import { fetchAuctions, fetchYields } from '../../state/app/auctions/actions';
import { fetchClientMoney } from '../../state/app/client_money/actions';

const TraineeDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectors.token) as string;
  const session = useSelector(selectors.activeSession);
  const player = useSelector(selectors.player);
  const selectedPerson = useSelector(selectors.selectedPerson);
  const assets = useSelector(selectors.underlyingAssets);

  const { url } = useRouteMatch();
  const { id } = useParams();
  useEffect(() => {
    // dispatch(retrievePlayerId({id}));
    dispatch(retrieveSession({ id }));
    dispatch(fetchBadges({}));
    dispatch(fetchKlasses({}));
    dispatch(fetchSessions({}));
    dispatch(fetchHistoricalStep({}));
    dispatch(fetchAuctions({ session_id: id }));
    // console.log('id: ', id);
    dispatch(fetchForwardContracts({ session_id: id }));
    dispatch(fetchYields({ session_id: id }));
    dispatch(fetchMarketHours({}));

    dispatch(connectToSessionSocket(token, id));

    return () => {
      dispatch(disconnectFromSessionSocket(token, id));
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (
  //     Object.keys(player).length !== 0 &&
  //     !fwdContractsFetching &&
  //     !fwdContractsFetched
  //   ) {
  //     dispatch(fetchForwardContracts({ player_id: player.id }));
  //   }
  // }, [dispatch, player]);

  if (!session) return <LoadingScreen fullscreen />;

  const isFinished = session.status === FINISHED;

  const isRealTime = session?.scenario_type === REAL_TIME_SCENARIO;

  const sessionHasNoEquities = Object.values(assets).every(
    asset => asset.resourcetype !== 'Equity'
  );

  const showEquitiesResearch = isRealTime && !sessionHasNoEquities;

  return (
    <div>
      <CookieProvider>
        <TraineeSessionContextWrapper>
          <div>
            <Header />
            <PersonSelector visible={!selectedPerson} />
            <PausedSession
              status={session.status}
              delay={session.delay_minutes_left}
            />
            <HistoricalProgressTrainee isHistorical={!isRealTime} />
            <div style={{ padding: '30px 70px 70px 70px' }}>
              <Switch>
                <Route path={`${url}/markets`}>
                  <MarketsTab />
                </Route>

                {showEquitiesResearch && (
                  <Route path={`${url}/equities-research`}>
                    <EquitiesResearch />
                  </Route>
                )}

                <Route path={`${url}/blotter`}>
                  <BlotterTab />
                </Route>
                <Route path={`${url}/open-orders`}>
                  <OpenOrders />
                </Route>
                <Route path={`${url}/auctions`}>
                  <Auctions />
                </Route>
                <Route path={`${url}/portfolio`}>
                  <PortfolioTab />
                </Route>
                <Route path={`${url}/news`}>
                  <NewsHistory />
                </Route>

                {session.allow_individual_benchmark && (
                  <Route path={`${url}/benchmarks`}>
                    <Benchmarks />
                  </Route>
                )}

                <Route path={`${url}/metrics`}>
                  <Metrics />
                </Route>
                <Route path={`${url}/risk-profile`}>
                  <RiskProfile />
                </Route>
                <Route path={`${url}/settings`}>
                  <Settings />
                </Route>

                {session.scenario_type === HISTORICAL_SCENARIO && (
                  <Route path={`${url}/macro-background`}>
                    <MacroBackground />
                  </Route>
                )}

                <Route path={`${url}/individual_report`}>
                  <IndividualReport player={player} />
                </Route>
                <Route path={`${url}/insights`}>
                  <Insights />
                </Route>

                <Redirect
                  to={`${url}/${isFinished ? 'portfolio' : 'markets'}`}
                />
              </Switch>
            </div>
            <SoundPlayer />
          </div>
        </TraineeSessionContextWrapper>
      </CookieProvider>
    </div>
  );
};

export default TraineeDashboard;
