export const TRAINEE = 0;
export const TRAINER = 1;
export const ADMIN = 2;

export const USER_TYPES = {
  [TRAINEE]: 'Trainee',
  [TRAINER]: 'Trainer',
  [ADMIN]: 'Admin',
};

export const PAUSED = 0;
export const PLAYING = 1;
export const FINISHED = 2;
export const CREATING = 3;
export const DELAY = 4;
export const FROZEN = 5;

export const SESSION_STATUSES = {
  [PAUSED]: 'Paused',
  [PLAYING]: 'Running',
  [FINISHED]: 'Finished',
  [CREATING]: 'Creating',
  [DELAY]: 'Delay',
  [FROZEN]: 'Frozen',
};

export const BUY = 0;
export const SELL = 1;
export const SHORT_SELL = 2;

export const TRANSACTION_TYPES: { [key: number]: string } = {
  [BUY]: 'Buy',
  [SELL]: 'Sell',
  [SHORT_SELL]: 'Short Sell',
};

export const CASH = 0;
export const EQUITY = 1;
export const FX = 2;
export const GOVT_BOND = 3;
export const METAL = 4;
export const ETFEquities = 5;
export const ETFBonds = 6;
export const ETFCommodities = 7;
export const CORPORATE_BOND = 8;
export const CRYPTO = 9;
export const FX_FWD = 10;
export const HTM_BOND = 11;
export const AFS_BOND = 12;

export const REPO = 13;

export const CDS = 14;
export const YIELDS = 15;
export const TBILL = 16;

export type AssetClass =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16;

export const ASSET_CLASSES: { [key: string]: string } = {
  [CASH]: 'Cash',
  [EQUITY]: 'Equities',
  [FX]: 'FX',
  [FX_FWD]: 'FX Forwards',
  [GOVT_BOND]: 'Govt Bonds',
  [CORPORATE_BOND]: 'Corporate Bonds',
  [HTM_BOND]: 'HTM Bonds',
  [AFS_BOND]: 'Bonds',
  [METAL]: 'Metals',
  [ETFEquities]: 'ETF Equities',
  [ETFBonds]: 'ETF Bonds',
  [ETFCommodities]: 'ETF Commodities',
  [CRYPTO]: 'Crypto',
  [REPO]: 'Repos',
  [CDS]: 'CDS',
  [YIELDS]: 'Yields',
  [TBILL]: 'TBills',
};

// export const FIRST_LOAN_TENOR = 0;
// export const SECOND_LOAN_TENOR = 1;
// export const THIRD_LOAN_TENOR = 2;
// export const FOURTH_LOAN_TENOR = 3;
// export const FIFTH_LOAN_TENOR = 4;
// export const SIXTH_LOAN_TENOR = 5;
//
// export const LOAN_TENORS: { [key: string]: string } = {
//   [FIRST_LOAN_TENOR]: '0-1',
//   [SECOND_LOAN_TENOR]: '1-3',
//   [THIRD_LOAN_TENOR]: '3-7',
//   [FOURTH_LOAN_TENOR]: '7-10',
//   [FIFTH_LOAN_TENOR]: '10-15',
//   [SIXTH_LOAN_TENOR]: '15+',
// };

type Limit = [number, number]; // [min, max]

export const CASH_GROUP = 0;
export const EQUITIES_GROUP = 1;
export const BONDS_GROUP = 2;
export const ALTERNATIVES_GROUP = 3;
export const FX_GROUP = 4;
export const CRYPTO_GROUP = 5;
export const AFS_GROUP = 6;
export const HTM_GROUP = 7;
export const REPO_GROUP = 8;
export const CDS_GROUP = 9;

export const GROUP_OF_ASSET: { [key: string]: number } = {
  [CASH]: CASH_GROUP,
  [EQUITY]: EQUITIES_GROUP,
  [GOVT_BOND]: BONDS_GROUP,
  [CORPORATE_BOND]: BONDS_GROUP,
  [HTM_BOND]: HTM_GROUP,
  [AFS_BOND]: AFS_GROUP,
  [METAL]: ALTERNATIVES_GROUP,
  [ETFEquities]: EQUITIES_GROUP,
  [ETFBonds]: BONDS_GROUP,
  [ETFCommodities]: ALTERNATIVES_GROUP,
  [FX]: FX_GROUP,
  [FX_FWD]: FX_GROUP,
  [CRYPTO]: CRYPTO_GROUP,
  [REPO]: REPO_GROUP,
  [CDS]: CDS_GROUP,
};

export const ASSETS_OF_GROUP: { [key: string]: number[] } = {
  [CASH_GROUP]: [CASH],
  [EQUITIES_GROUP]: [EQUITY, ETFEquities],
  [BONDS_GROUP]: [GOVT_BOND, ETFBonds, CORPORATE_BOND], //, HTM_BOND, AFS_BOND],
  [AFS_GROUP]: [AFS_BOND],
  [HTM_GROUP]: [HTM_BOND],
  [ALTERNATIVES_GROUP]: [METAL, ETFCommodities, REPO, CDS],
  [FX_GROUP]: [FX, FX_FWD],
  [CRYPTO_GROUP]: [CRYPTO],
};

// export const GROUP_ASSET_LIMITS: { [key: string]: Limit } = {
//   [EQUITIES_GROUP]: [0.5, 0.7],
//   [BONDS_GROUP]: [0.2, 0.4],
//   [HTM_GROUP]: [0.2, 0.4],
//   [AFS_GROUP]: [0.2, 0.4],
//   [ALTERNATIVES_GROUP]: [0, 0.2],
//   [CASH_GROUP]: [0, 0.2],
//   [FX_GROUP]: [0, 0.1],
//   [CRYPTO_GROUP]: [0, 0],
// };

export const GROUP_ASSET_NAME: { [key: string]: string } = {
  [CASH_GROUP]: 'Cash',
  [EQUITIES_GROUP]: 'Equities',
  [BONDS_GROUP]: 'Bonds',
  [AFS_GROUP]: 'Bonds',
  [HTM_GROUP]: 'HTM Bonds',
  [ALTERNATIVES_GROUP]: 'Alternatives',
  [FX_GROUP]: 'FX',
  [CRYPTO_GROUP]: 'Crypto',
  [REPO_GROUP]: 'Repo',
  [CDS_GROUP]: 'CDS',
};

export const FIRST = 1;
export const SECOND = 2;
export const THIRD = 3;
export const FOURTH = 4;
export const OPENEND = 5;

// export const FIFTH = 5;
// export const SIXTH = 6;
// export const SEVENTH = 7;
// export const EIGHT = 8;
// export const NINGHT = 9;
// export const TENTH = 10;
// export const OPENEND = 11;

export const DURATION_INTERVALS_NAME: { [key: string]: string } = {
  [FIRST]: '0-3',
  [SECOND]: '4-7',
  [THIRD]: '8-15',
  [FOURTH]: '16-30',
  [OPENEND]: '31+',
  // [SIXTH]: '5-6',
  // [SEVENTH]: '6-7',
  // [EIGHT]: '7-8',
  // [NINGHT]: '8-9',
  // [TENTH]: '9-10',
  // [OPENEND]: '10+',
};

export const GROUP_ASSET_INDEX: { [key: string]: number } = {
  Cash: CASH_GROUP,
  Equities: EQUITIES_GROUP,
  Bonds: BONDS_GROUP,
  Alternatives: ALTERNATIVES_GROUP,
  FX: FX_GROUP,
  Crypto: CRYPTO_GROUP,
  HTMBonds: HTM_GROUP,
  AFSBonds: AFS_GROUP,
};

export const instrumentTypes = [
  { type: 'Equity', name: 'Equities', assetClass: EQUITY },
  { type: 'ETFEquities', name: 'ETF Equities', assetClass: ETFEquities },
  { type: 'ETFBonds', name: 'ETF Bonds', assetClass: ETFBonds },
  {
    type: 'ETFCommodities',
    name: 'ETF Commodities',
    assetClass: ETFCommodities,
  },
  { type: 'GovtBond', name: 'Govt Bonds', assetClass: GOVT_BOND },
  { type: 'HTMBond', name: 'HTM Bonds', assetClass: HTM_BOND },
  { type: 'AFSBond', name: 'Bonds', assetClass: AFS_BOND },
  {
    type: 'CorporateBond',
    name: 'Corporate Bonds',
    assetClass: CORPORATE_BOND,
  },
  { type: 'FX', name: 'FX', assetClass: FX },
  { type: 'FxForwards', name: 'FX Forwards', assetClass: FX_FWD },
  // { type: 'FX', name: 'FX Hedge', assetClass: FX },
  { type: 'Metal', name: 'Metals', assetClass: METAL },
  { type: 'Crypto', name: 'Crypto', assetClass: CRYPTO },
  { type: 'Repo', name: 'Repos', assetClass: REPO },
  { type: 'CDS', name: 'CDS', assetClass: CDS },
  { type: 'Yields', name: 'Yields', assetClass: YIELDS },
  { type: 'TBill', name: 'TBills', assetClass: TBILL },
];

export const typesOrder = instrumentTypes.map(el => el.type);

// TODO: keep market hours minutes in multiples of 3
export const marketHours: {
  [key: string]: {
    open: string;
    close: string;
    sunday_open?: string;
    friday_close?: string;
  };
} = {
  US: {
    open: '13:33',
    close: '19:57',
  },
  Germany: {
    open: '07:03',
    close: '15:27',
  },
  UK: {
    open: '07:21',
    close: '15:15',
  },
  Ireland: {
    open: '07:33',
    close: '16:27',
  },
  France: {
    open: '07:03',
    close: '15:27',
  },
  Japan: {
    open: '00:03',
    close: '05:57',
  },
  China: {
    open: '01:33',
    close: '06:57',
  },
  Singapore: {
    open: '01:03',
    close: '08:57',
  },
  'South Korea': {
    open: '00:03',
    close: '06:27',
  },
  Taiwan: {
    open: '01:03',
    close: '05:27',
  },
  India: {
    open: '03:48',
    close: '09:58',
  },
  Australia: {
    open: '00:03',
    close: '05:57',
  },
  Netherlands: {
    open: '07:03',
    close: '15:27',
  },
  Spain: {
    open: '07:03',
    close: '15:27',
  },
  Switzerland: {
    open: '07:33',
    close: '16:18',
  },
  Portugal: {
    open: '07:03',
    close: '15:27',
  },
  Italy: {
    open: '07:18',
    close: '15:12',
  },
  Norway: {
    open: '07:03',
    close: '14:18',
  },
  Belgium: {
    open: '07:03',
    close: '15:27',
  },
  'Saudi Arabia': {
    open: '07:03',
    close: '11:57',
  },
  Nigeria: {
    open: '10:15',
    close: '14:00',
  },
  Indonesia: {
    open: '02:45',
    close: '08:30',
  },
  UAE: {
    open: '06:02',
    close: '10:57',
  },
  Metals: {
    sunday_open: '21:00',
    open: '21:45', // weekday opens
    close: '21:00', // weekday closes, excluding Friday
    friday_close: '21:45',
  },
};

export const domicilesArray = [
  'BRA',
  'CHN',
  'DEU',
  'GBR',
  'IDN',
  'IND',
  'JPN',
  'USA',
  'ZAF',
];

export const issuerTypeArray = ['GOV.', 'AGN.', 'CORP.'];

export const GLOBAL = 0;
export const APAC = 1;
export const EUROPE = 2;
export const NAM = 3;
export const LATAM = 4;
export const ME = 5;
export const AFRICA = 6;
export const WEST_AFRICA = 7;
export const REGIONS = {
  [GLOBAL]: 'Global',
  [APAC]: 'APAC',
  [EUROPE]: 'Europe',
  [NAM]: 'North America',
  [LATAM]: 'Latin America',
  [ME]: 'Middle East',
  [AFRICA]: 'Africa',
  [WEST_AFRICA]: 'West Africa',
};

export const CASH_APPROACH = 0;
export const RISK_WEIGHTED_APPROACH = 1;

export const RISK_APPROACHES = {
  [CASH_APPROACH]: 'Cash',
  [RISK_WEIGHTED_APPROACH]: 'Risk weighted',
};

export const REPORTING_CURRENCIES = ['USD', 'EUR', 'GBP', 'JPY', 'CHF', 'IDR'];

export const TECHNICAL = 0;
export const FUNDAMENTAL = 1;

export const ANALYSIS_TYPES = {
  [TECHNICAL]: 'Technical',
  [FUNDAMENTAL]: 'Fundamental',
};

export const SHORT_TERM = 0;
export const MEDIUM_TERM = 1;

export const TERMS = {
  [SHORT_TERM]: 'Short Term',
  [MEDIUM_TERM]: 'Medium Term',
};

export const NORMAL_TIME = 0;
export const TRADING_WINDOWS = 1;

export const TRADING_TIME_TYPES = {
  [NORMAL_TIME]: '24/5',
  [TRADING_WINDOWS]: 'Trading Windows',
};

export const REAL_TIME_SCENARIO = 0;
export const HISTORICAL_SCENARIO = 1;

export const SCENARIO_TYPES = {
  [REAL_TIME_SCENARIO]: 'Real Time',
  [HISTORICAL_SCENARIO]: 'Historical',
};

export const PORTFOLIO_VOLATILITY_METRICS = 0;
export const ALPHA_METRICS = 1;
export const BETA_METRICS = 2;
export const SHARPE_METRICS = 3;
export const TREYNOR_METRICS = 4;
export const VAR_METRICS = 5;
export const DURATION_METRICS = 6;
export const PORTFOLIO_METRICS = 7;

export const METRICS_TYPES = {
  [PORTFOLIO_VOLATILITY_METRICS]: 'Portfolio Volatility',
  [ALPHA_METRICS]: 'Alpha',
  [BETA_METRICS]: 'Beta',
  [SHARPE_METRICS]: 'Sharpe Ratio',
  [TREYNOR_METRICS]: 'Treynor Ratio',
  [VAR_METRICS]: 'VaR',
  [DURATION_METRICS]: 'Duration (long/short)',
  [PORTFOLIO_METRICS]: 'Portfolio',
};

export const CREATED = 0;
export const EXECUTED = 1;
export const CANCELED = 2;
export const ORDER_STATUSES = {
  [CREATED]: 'Created',
  [EXECUTED]: 'Executed',
  [CANCELED]: 'Canceled',
};

export const RESOURCE_TYPES = [
  'MarketOrder',
  'StopLossOrder',
  'TakeProfitOrder',
];

export const SOUND_COOKIE_KEY = 'soundEnabled';

export const LAYOUT1 = 0;
export const LAYOUT2 = 1;

export const LAYOUTS = {
  [LAYOUT1]: 'Layout 1',
  [LAYOUT2]: 'Layout 2',
};

export const EVERY_MINUTE = 0;
export const EVERY_30_MINUTES = 1;
export const DAILY = 2;
export const WEEKLY = 3;

export const FREQUENCIES = {
  [EVERY_MINUTE]: 'Every Minute',
  [EVERY_30_MINUTES]: 'Every 30 Minutes',
  [DAILY]: 'Daily',
  [WEEKLY]: 'Weekly',
};

export const STOP_LOSS = 0;
export const TAKE_PROFIT = 1;
export const MARKET_ORDER = 2;
