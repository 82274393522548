import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, Checkbox, Typography } from 'antd';

import { selectors, METRICS_TYPES, updateSessionDisplayMetrics } from 'state';

const options = Object.entries(METRICS_TYPES).map(([index, name]) => ({
  label: name,
  value: parseInt(index),
}));

const MetricsSelector: React.FC = () => {
  const dispatch = useDispatch();
  const { display_metrics, id } = useSelector(selectors.activeSession);
  const [choices, setChoices] = useState(display_metrics);

  return (
    <div>
      <Typography.Title level={4}>Metrics</Typography.Title>
      <Card>
        <Checkbox.Group
          options={options}
          value={choices}
          onChange={values => setChoices(values as number[])}
        />
        <div style={{ marginTop: 16 }}>
          <Button
            type="primary"
            onClick={() =>
              dispatch(
                updateSessionDisplayMetrics({
                  session_id: id,
                  display_metrics: choices,
                })
              )
            }
          >
            Save
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default MetricsSelector;
