import React from 'react';

import SlateEditor from '../SlateEditor';
import { useLayoutProvider } from './LayoutProvider';

const Layout1: React.FC = () => {
  const { getTextState } = useLayoutProvider();
  const [value, setValue] = getTextState(1);

  return <SlateEditor value={value} setValue={setValue} />;
};

export default Layout1;
