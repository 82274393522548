import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, InputNumber } from 'antd';
import { selectors, fetchUnderlyingDeposits, updateDeposits } from 'state';

const DepositsTab: React.FC = () => {
  const dispatch = useDispatch();
  const deposits = useSelector(selectors.underlyingDepositsList);

  useEffect(() => {
    if (!deposits.length) {
      dispatch(fetchUnderlyingDeposits({}));
    }
  }, [dispatch]);

  const update = (record: any, key: any) => (val: any) => {
    if (val === '') val = null;
    dispatch(updateDeposits({ id: record.id, [key]: val }));
  };

  return (
    <>
      <div>
        Input overnight rates as 0.000194 for 0.0194% (~7.00% annualized)
      </div>

      <Table
        loading={!deposits.length}
        dataSource={deposits}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
        columns={[
          { title: 'ID', dataIndex: 'id' },
          {
            title: 'Name',
            dataIndex: 'name',
            // render: (val, record) => TextType(val, update(record, 'name')),
            // sorter: (a, b) =>
            //   a.ticker < b.ticker ? -1 : a.ticker > b.ticker ? 1 : 0,
            // ...columnSearchWidget({ dataIndex: 'name' }),
          },
          {
            title: 'Overnight Rate',
            dataIndex: 'overnight_rate',
            render: (val, record) => {
              return (
                <RateEditor
                  initialValue={val}
                  update={update(record, 'overnight_rate')}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

const RateEditor: React.FC<{ initialValue: number; update: any }> = ({
  initialValue,
  update,
}) => {
  const [editing, setEditing] = useState(false);
  const [rate, setRate] = useState<number | undefined>(initialValue);

  if (!editing) {
    return (
      <div
        className="editable-cell-value-wrap"
        onClick={() => setEditing(true)}
      >
        {rate}
      </div>
    );
  }

  const hide = () => {
    setEditing(false);
  };

  return (
    <div style={{ display: 'flex', gap: 8 }}>
      <InputNumber
        value={rate}
        onChange={value => setRate(value)}
        style={{ width: 100 }}
        onKeyDown={e => {
          if (e.key !== 'Enter') return;
          rate !== initialValue && update(rate);
          hide();
        }}
        onBlur={() => {
          rate !== initialValue && update(rate);
          hide();
        }}
      />
      <InputNumber
        value={rate !== undefined ? rate * 360 : undefined}
        onChange={value => {
          if (value !== undefined) {
            setRate(value / 360);
          } else {
            setRate(undefined);
          }
        }}
        style={{ width: 100 }}
        onKeyDown={e => {
          if (e.key !== 'Enter') return;
          rate !== initialValue && update(rate);
          hide();
        }}
        onBlur={() => {
          rate !== initialValue && update(rate);
          hide();
        }}
      />
    </div>
  );
};

export default DepositsTab;
