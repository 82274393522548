import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Descriptions, Table, Typography } from 'antd';

import { selectors, createOrder, SELL, BUY, instrumentTypes } from 'state';
import { formatters } from 'services';
import { TextShortener } from 'components';
import { InvestmentData } from '../index';
import { useReportingCurrency } from '../../../../state/app/utils';
import ContractsModal from '../../ContractsModal';

type Props = {
  data: InvestmentData[];
};

const InvestmentsTable: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const { session } = useSelector(selectors.player);
  const reportingCurrency = useReportingCurrency(session);
  const [financialInstrument, setFinancialInstrument] = useState(data[0]);
  const [visibleContractsModal, setVisibleContractsModal] = useState(false);

  const { tactical_asset_allocations } = useSelector(selectors.player);

  const allocations = {} as { [assetClass: number]: string };
  tactical_asset_allocations.forEach(allocation => {
    if (allocation.percent !== 0) {
      allocations[allocation.asset_class] = formatters.percent2Digit(
        allocation.percent
      );
    }
  });

  const getTAA = (assetClass: number) => {
    if (!allocations[assetClass]) {
      return '';
    }

    return (
      <Typography.Text
        // type="danger"
        style={{
          fontSize: '75%',
        }}
      >
        <> {allocations[assetClass]}</>
      </Typography.Text>
    );
  };

  return (
    <div>
      <Table
        rowKey="id"
        bordered
        pagination={false}
        dataSource={data}
        title={data => {
          let totalValue = 0;
          // console.log(data);
          let rowAssetClass;
          if (data.length) {
            const assetType = data[0].resourcetype;
            const rowType = instrumentTypes.find(instrumentType => {
              return instrumentType.type === assetType;
            });
            if (rowType) {
              rowAssetClass = rowType.assetClass;
            }
          }
          data.forEach(row => {
            totalValue += row.reportingValue * Math.sign(row.amount);
          });

          // const percent = totalValue / market_value;
          const percent = rowAssetClass ? getTAA(rowAssetClass) : 0;

          return (
            <Descriptions bordered>
              <Descriptions.Item label="Total">
                {formatters.million(totalValue) +
                  ' ' +
                  reportingCurrency.symbol}
              </Descriptions.Item>
              <Descriptions.Item label="Percent">{percent}</Descriptions.Item>
            </Descriptions>
          );
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',

            sorter: (a, b) => (a.name > b.name ? -1 : 1),
            render: name => TextShortener({ text: name, maxLength: 25 }),
          },
          {
            title: 'BBG Ticker',
            dataIndex: 'ticker',

            sorter: (a, b) => (a.ticker > b.ticker ? -1 : 1),
          },
          {
            title: 'Open Price',
            dataIndex: 'avg_open_price',
            align: 'right',

            sorter: (a, b) => a.avg_open_price - b.avg_open_price,
            render: (val, record) =>
              formatters.priceFormat(val, record.ticker, record.resourcetype),
          },
          {
            title: 'BID',
            dataIndex: 'bid',
            align: 'right',

            sorter: (a, b) => a.bid - b.bid,
            render: (val, record) =>
              formatters.priceFormat(val, record.ticker, record.resourcetype),
          },
          {
            title: 'ASK',
            dataIndex: 'ask',
            align: 'right',

            sorter: (a, b) => a.ask - b.ask,
            render: (val, record) =>
              formatters.priceFormat(val, record.ticker, record.resourcetype),
          },
          {
            title: 'Currency',
            dataIndex: 'currency',
            filters: [
              { text: 'USD', value: 'USD' },
              { text: 'EUR', value: 'EUR' },
              { text: 'GBP', value: 'GBP' },
              { text: 'JPY', value: 'JPY' },
            ],
            onFilter: (value, record) => record.currency === value,
          },
          {
            title: 'Amount',
            dataIndex: 'amount',
            align: 'right',

            sorter: (a, b) => a.amount - b.amount,
            render: formatters.commasNoDigits,
          },
          {
            title: 'Unrealized, ' + reportingCurrency.symbol,
            dataIndex: 'reporting_unrealized_pnl',
            align: 'right',

            sorter: (a, b) =>
              a.reporting_unrealized_pnl - b.reporting_unrealized_pnl,
            render: formatters.commasNoDigits,
          },
          {
            title: 'Realized, ' + reportingCurrency.symbol,
            dataIndex: 'reporting_realized_pnl',
            align: 'right',

            sorter: (a, b) =>
              a.reporting_realized_pnl - b.reporting_realized_pnl,
            render: formatters.commasNoDigits,
          },
          {
            title: 'Return %',
            dataIndex: 'return_percent',
            align: 'right',
            // TODO: Check this.
            sorter: (a: any, b: any) => a.return_percent - b.return_percent,
            render: formatters.percent1Digit,
          },
          {
            title: 'Value, ' + reportingCurrency.symbol,
            dataIndex: 'reportingValue',
            align: 'right',
            render: formatters.commasNoDigits,
          },
          {
            title: 'Portfolio %',
            dataIndex: 'dollarToPortfolioRatio',
            align: 'right',
            render: formatters.percent1Digit,
          },
          {
            title: 'Actions',
            render: function Actions(_, record) {
              const transaction = record.amount > 0 ? SELL : BUY;
              const quantity =
                record.amount > 0 ? record.amount : -record.amount;
              if (record.fx_hedge) {
                return (
                  <Button
                    onClick={() => {
                      setFinancialInstrument(record);
                      setVisibleContractsModal(true);
                    }}
                  >
                    Show contracts
                  </Button>
                );
              }
              return (
                <Button
                  danger
                  onClick={() =>
                    dispatch(
                      createOrder({
                        financial_instrument: record.id,
                        transaction,
                        quantity,
                        forecast: 0,
                        investment_rationale: 'Close position',
                        resourcetype: 'MarketOrder',
                        limit_price: 0,
                        hedge: false,
                        closes_position: true,
                      })
                    )
                  }
                >
                  Close
                </Button>
              );
            },
          },
        ]}
      />
      {visibleContractsModal && (
        <ContractsModal
          visible={visibleContractsModal}
          hide={() => setVisibleContractsModal(false)}
          id={financialInstrument.id}
        />
      )}
    </div>
  );
};

export default InvestmentsTable;
