import React from 'react';
import { Table } from 'antd';
import { REGIONS, selectors, utils } from 'state';
import { formatters } from 'services';
import { useSelector } from 'react-redux';

const Dividends: React.FC = () => {
  const equities = useSelector(selectors.equityList);
  const currencies = useSelector(selectors.currencies);
  const dividends = useSelector(selectors.dividends);
  const assetList = useSelector(selectors.underlyingAssetsList);
  const session = useSelector(selectors.activeSession);

  const dataSource = equities.map(equity => {
    const outstanding = Object.values(dividends)
      .filter(d => !d.paid && d.equity === equity.id)
      .reduce((acc, d) => {
        return acc + d.outstanding;
      }, 0);

    const reportingOutstanding = utils.cashExchange(
      equity.variable_currency,
      session.reporting_currency,
      outstanding,
      0,
      assetList,
      Object.values(currencies)
    );

    return { ...equity, outstanding, reportingOutstanding };
  });

  return (
    <div>
      <Table
        rowKey="id"
        dataSource={dataSource}
        pagination={{
          defaultPageSize: 20,
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            sorter(a, b) {
              return a.name.localeCompare(b.name);
            },
          },
          {
            title: 'Region',
            dataIndex: 'region',
            render(region: keyof typeof REGIONS) {
              return REGIONS[region];
            },
            sorter(a, b) {
              return a.region - b.region;
            },
            filters: Object.entries(REGIONS).map(([key, value]) => {
              return {
                text: value,
                value: Number(key),
              };
            }),
            onFilter: (value, record) => value === record.region,
          },
          {
            title: 'BBG Ticker',
            dataIndex: 'ticker',
            sorter(a, b) {
              return a.ticker.localeCompare(b.ticker);
            },
          },
          {
            title: 'CCY',
            dataIndex: 'variable_currency',
            render(ccy) {
              return currencies[ccy]?.symbol || '-';
            },
            sorter(a, b) {
              return a.variable_currency - b.variable_currency;
            },
            filters: Object.values(currencies).map(ccy => {
              return {
                text: ccy.symbol,
                value: ccy.id,
              };
            }),
            onFilter: (value, record) => value === record.variable_currency,
          },
          {
            title: 'DIV Yield',
            dataIndex: 'last_div_yield',
            align: 'right',
            render(value) {
              if (typeof value !== 'number') {
                return '-';
              }

              const MIN = 0.01;
              const MAX = 0.2;

              let opacity = 0;
              if (value <= MIN) {
                opacity = 0;
              } else if (value >= MAX) {
                opacity = 1;
              } else {
                opacity = value / MAX;
              }

              let color = 'inherit';
              if (opacity >= 0.9) {
                color = '#fff';
              }

              return (
                <div
                  style={{
                    backgroundColor: `rgba(22,119,255,${opacity})`,
                    color,
                    marginTop: '-5px',
                    marginLeft: '-5px',
                    marginRight: '-5px',
                    marginBottom: '-5px',
                    padding: '5px',
                  }}
                >
                  {formatters.percent2Digit(value)}
                </div>
              );
            },
            sorter(a, b) {
              return (a.last_div_yield ?? 0) - (b.last_div_yield ?? 0);
            },
          },
          {
            title: 'DIV/Share',
            dataIndex: 'last_div_per_share',
            align: 'right',
            render(value) {
              const v = Number(value);
              if (v < 0) {
                return '-';
              }
              return formatters.commas2Digits(value);
            },

            sorter(a, b) {
              if (a.last_div_per_share && b.last_div_per_share) {
                return a.last_div_per_share - b.last_div_per_share;
              }

              return 0;
            },
          },
          {
            title: 'EX-DIV',
            dataIndex: 'last_div_ex_date',
            render(value) {
              if (typeof value !== 'string') {
                return '-';
              }
              return new Date(value).toISOString().split('T')[0];
            },
            sorter(a, b) {
              if (!a.last_div_ex_date || !b.last_div_ex_date) {
                return 0;
              }
              const d1 = new Date(a.last_div_ex_date)
                .toISOString()
                .split('T')[0];
              const d2 = new Date(b.last_div_ex_date)
                .toISOString()
                .split('T')[0];
              return d1.localeCompare(d2);
            },
          },
          {
            title: 'DIV Paydate',
            dataIndex: 'last_div_pay_date',
            render(value) {
              if (typeof value !== 'string') {
                return '-';
              }
              return new Date(value).toISOString().split('T')[0];
            },
            sorter(a, b) {
              if (!a.last_div_pay_date || !b.last_div_pay_date) {
                return 0;
              }
              const d1 = new Date(a.last_div_pay_date)
                .toISOString()
                .split('T')[0];
              const d2 = new Date(b.last_div_pay_date)
                .toISOString()
                .split('T')[0];
              return d1.localeCompare(d2);
            },
          },
          {
            title: 'Outstanding DIV',
            align: 'center',
            children: [
              {
                title: 'Asset CCY',
                dataIndex: 'outstanding',
                render: formatters.commas2Digits,
                align: 'center',
                sorter(a, b) {
                  return a.outstanding - b.outstanding;
                },
              },
              {
                title: 'Reporting CCy',
                dataIndex: 'reportingOutstanding',
                render: formatters.commas2Digits,
                align: 'center',
                sorter(a, b) {
                  return a.reportingOutstanding - b.reportingOutstanding;
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default Dividends;
