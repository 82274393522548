import { appManager, AppState } from '../manager';
import {
  CreateRoadmapItem,
  RoadmapItem,
  CreateInsightsReport,
  InsightsReport,
} from 'state';

// commented because this would trigger an S3 file fetch and currently boto3 S3 access is deactivated in backend
export const createRoadmapItem = appManager.createApi<
  CreateRoadmapItem,
  RoadmapItem,
  AppState
>('CREATE_ROADMAP_ITEM', {
  path: '/roadmap_items',
  method: 'POST',
  successReducer(state, result) {
    state.roadmapItems.items[result.id] = result;
    state.player.item.roadmap_items?.push(result.id);
  },
});

// commented because this would trigger an S3 file fetch and currently boto3 S3 access is deactivated in backend
export const createInsightsReport = appManager.createApi<
  CreateInsightsReport,
  unknown,
  AppState
>('CREATE_INSIGHTS_REPORT', {
  path: '/insights_reports',
  method: 'POST',
  successReducer(state) {
    state.insightsReports.creating = true;
  },
});

appManager.createSocketListener<InsightsReport, AppState>(
  'insights_report_created',
  (state, result) => {
    state.insightsReports.items[result.id] = result;
    state.player.item.insights_reports?.push(result.id);
    state.insightsReports.creating = false;
  }
);

appManager.createSocketListener<string, AppState>(
  'insights_report_error',
  state => {
    state.insightsReports.creating = false;
    state.error = 'Failed to create Insights Report!';
  }
);
