import React, { useEffect, useState } from 'react';
import {
  createFXCross,
  deleteFXCross,
  fetchFXCross,
  FXCross,
  selectors,
  updateFXCross,
} from 'state';
import EditableTable from './EditableTable';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Input } from 'antd';

const FXCrossTable: React.FC = () => {
  const dispatch = useDispatch();
  const fxCrossList = useSelector(selectors.fxCrossList);

  const [ticker, setTicker] = useState('');
  const [pairOne, setPairOne] = useState('');
  const [pairTwo, setPairTwo] = useState('');
  const [operator, setOperator] = useState('');

  useEffect(() => {
    dispatch(fetchFXCross({}));
  }, [dispatch]);

  const handleCreate = () => {
    dispatch(
      createFXCross({
        cross_ticker: ticker,
        pair_one_str: pairOne,
        operator: operator,
        pair_two_str: pairTwo,
      })
    );
  };

  return (
    <div>
      <div style={{ marginBottom: 25, minWidth: 350 }}>
        <Typography style={{ marginBottom: 10 }}>
          Add new FX Cross pair:{' '}
        </Typography>
        <Input
          minLength={7}
          maxLength={7}
          style={{ width: 90, marginRight: 5 }}
          value={ticker}
          onChange={e => {
            setTicker(e.target.value);
          }}
        />
        <Typography style={{ display: 'inline', marginRight: 5 }}>
          {' '}
          ={' '}
        </Typography>
        <Input
          maxLength={7}
          style={{ width: 90, marginRight: 5 }}
          value={pairOne}
          onChange={e => {
            setPairOne(e.target.value);
          }}
        />
        <Input
          maxLength={1}
          style={{ width: 30, marginRight: 5 }}
          value={operator}
          onChange={e => {
            setOperator(e.target.value);
          }}
        />
        <Input
          maxLength={7}
          style={{ width: 90, marginRight: 15 }}
          value={pairTwo}
          onChange={e => {
            setPairTwo(e.target.value);
          }}
        />
        <Button onClick={handleCreate}>Save</Button>
      </div>
      <br />
      <EditableTable
        data={fxCrossList}
        onSave={(a: FXCross) => {
          dispatch(updateFXCross(a));
        }}
        onDelete={(a: FXCross) => {
          dispatch(deleteFXCross(a));
        }}
        ignoredFields={[
          'base_currency',
          'variable_currency',
          'ticker',
          'name',
          'region',
        ]}
      />
    </div>
  );
};
export default FXCrossTable;
