import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Button, Space, Select, Typography } from 'antd';
import { ColumnType, ColumnGroupType } from 'antd/lib/table/interface';
import { CSVLink } from 'react-csv';

import {
  selectors,
  TRANSACTION_TYPES,
  TERMS,
  CREATED,
  ORDER_STATUSES,
  CANCELED,
  BUY,
  Order,
} from 'state';
import { formatters } from 'services';

type Props = {
  playerId: number;
  sessionId?: number;
};

type BlotterData = Order & {
  underlying_asset_ticker: string;
  underlying_asset_resourcetype: string;
  currency: string;
  value: number;
};

const Blotter: React.FC<Props> = ({ playerId }) => {
  const orders = useSelector(selectors.ordersListByPlayerId(playerId));
  const financialInstruments = useSelector(
    selectors.financialInstrumentsListByPlayerId(playerId)
  );
  const currencies = useSelector(selectors.currencies);
  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const persons = useSelector(selectors.persons);
  // const { transaction_fee } = useSelector(
  //   sessionId ? selectors.sessionById(sessionId) : selectors.activeSession
  // );
  const [selected, setSelected] = useState<string[]>([]);

  const data = useMemo(() => {
    return orders
      .filter(order => order.status !== CREATED)
      .map(order => {
        const underlying_asset_id = financialInstruments.find(
          item => item.id === order.financial_instrument
        )?.underlying_asset;
        const underlying_asset = underlyingAssets[underlying_asset_id || -1];

        return {
          ...order,
          currency: currencies[underlying_asset.variable_currency]?.symbol,
          value: !underlying_asset.resourcetype.endsWith('Bond')
            ? order.open_price * order.quantity
            : (order.open_price * order.quantity) / 100,
          underlying_asset_ticker: underlying_asset.ticker,
          underlying_asset_resourcetype: underlying_asset.resourcetype,
        };
      });
    // .map(order => ({
    //   ...order,
    //   fee: (order.value * transaction_fee).toFixed(2),
    // }));
  }, [financialInstruments, underlyingAssets, currencies, orders]);

  const displayedOrders = data.filter(order =>
    selected.includes(order.underlying_asset_ticker)
  );

  const usedTickersDoubles = data.map(order => order.underlying_asset_ticker);
  const usedTickers = [...new Set(usedTickersDoubles)]; // eliminates duplicates

  const columns: ((ColumnGroupType<BlotterData> | ColumnType<BlotterData>) & {
    dataIndex: string;
  })[] = [
    {
      title: 'Asset',
      dataIndex: 'underlying_asset_ticker',
      key: 'underlying_asset_ticker',
      fixed: 'left',
      sorter: (a, b) =>
        a.underlying_asset_ticker.localeCompare(b.underlying_asset_ticker),
    },
    {
      title: 'Transaction',
      dataIndex: 'transaction',
      key: 'transaction',
      fixed: true,
      render: (tr: number) => TRANSACTION_TYPES[tr],
      sorter: (a, b) => a.transaction - b.transaction,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      fixed: true,
      sorter: (a, b) => a.currency.localeCompare(b.currency),
    },
    {
      title: 'Open Price',
      dataIndex: 'open_price',
      key: 'open_price',
      align: 'right',
      fixed: true,
      render: (val, record) =>
        formatters.priceFormat(
          val,
          record.underlying_asset_ticker,
          record.underlying_asset_resourcetype
        ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      fixed: true,
      render: (value, record) => {
        return `${
          record.transaction === BUY ? '+' : '-'
        }${formatters.commasNoDigits(value)}`;
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      fixed: true,
      align: 'right',
      render: formatters.commasNoDigits,
    },
    // {
    //   title: 'Fee',
    //   dataIndex: 'fee',
    //   key: 'fee',
    //   align: 'right',
    //   render: formatters.commasNoDigits,
    // },
    {
      title: 'Timestamp',
      fixed: true,
      dataIndex: 'timestamp',
      render: (_, record) => {
        const val = record.executed_at || record.canceled_at || 0;
        return val ? formatters.dateWithTime(val) : '--';
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const dateA = formatters.dateWithTime(
          a.executed_at || a.canceled_at || ''
        );
        const dateB = formatters.dateWithTime(
          b.executed_at || b.canceled_at || ''
        );
        return dateA.localeCompare(dateB);
      },
    },
    {
      title: 'Forecast',
      dataIndex: 'forecast',
      key: 'forecast',
      render: (val, record) =>
        val !== record.open_price && val !== 0
          ? formatters.commas2Digits(val)
          : '--',
    },
    {
      title: 'Investment rationale',
      dataIndex: 'investment_rationale',
      key: 'investment_rationale',
      width: 300,
      render: text => (
        <div style={{ maxWidth: 300, whiteSpace: 'normal' }}>
          {text || '--'}
        </div>
      ),
    },
    {
      title: 'Term',
      dataIndex: 'term',
      render: val => TERMS[val as keyof typeof TERMS] || '--',
    },
    {
      title: 'Analysis',
      dataIndex: 'analysis',
      render: val => TERMS[val as keyof typeof TERMS] || '--',
    },
    // {
    //   title: 'Hedge',
    //   dataIndex: 'hedge',
    //   render: val => (val ? 'Yes' : 'No'),
    // },
    {
      title: 'User',
      dataIndex: 'person',
      render: val => persons[val]?.name || val,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      fixed: 'right',
      render: (val: keyof typeof ORDER_STATUSES) => ORDER_STATUSES[val],
      sorter: (a, b) => a.status - b.status,
    },
  ];

  const csvData = useMemo(() => {
    const titles = columns.map(col => col.title);

    const body = data.map((order, index) => {
      return columns.map(col => {
        const value = order[col.dataIndex as keyof typeof order];
        return col.render ? col.render(value, order, index) : value;
      });
    });

    body.unshift(titles);
    return body;
  }, [data]);
  return (
    <div style={{ position: 'relative' }}>
      <Space style={{ marginBottom: '17px', width: '100%' }}>
        <Typography.Text type="secondary">Filter by Assets:</Typography.Text>
        <Select
          mode="multiple"
          allowClear
          style={{ minWidth: '415px' }}
          placeholder="type to search assets..."
          value={selected}
          onChange={value => setSelected(value as string[])}
        >
          {usedTickers.map(ticker => (
            <Select.Option key={ticker} value={ticker}>
              {ticker}
            </Select.Option>
          ))}
        </Select>
        <Button
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            // top: '34px',
            // margin: '19px 0',
          }}
        >
          <CSVLink data={csvData}>Download CSV</CSVLink>
        </Button>
      </Space>
      <Table
        rowKey="id"
        bordered
        scroll={{ x: true }}
        dataSource={selected.length === 0 ? data : displayedOrders}
        pagination={false}
        rowClassName={record => (record.status === CANCELED ? 'disabled' : '')}
        columns={columns}
      />
    </div>
  );
};

export default Blotter;
