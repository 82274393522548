import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import HighchartsReact from 'highcharts-react-official';

import { instrumentTypes, selectors, utils, Currency } from 'state';

type Props = {
  playerId: number;
  selectedInstrumentType: string;
};

const formatter = (number: number, reportingCurrency: Currency) => {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: reportingCurrency.symbol,
    maximumFractionDigits: 0,
  });
};

const BarChart = (
  name: string,
  series: { name: string; y: number }[],
  categories: string[],
  total: string,
  reportingCurrency: Currency
) => (
  <Col span={12}>
    <HighchartsReact
      options={{
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'bar',
        },
        title: {
          text: `${name} PnL Distribution`,
        },
        xAxis: {
          categories: categories,
        },
        yAxis: {
          title: {
            // text: `Percent of total ${name} PnL`,
            text: `${reportingCurrency.symbol} value | (Total ${name} PnL: ${total} ${reportingCurrency.symbol})`,
          },
        },
        tooltip: {
          valueSuffix: reportingCurrency.symbol,
        },
        series: [
          {
            name: ' ',
            colorByPoint: true,
            data: series,
          },
        ],
      }}
    />
  </Col>
);

const PnlAllocations: React.FC<Props> = ({
  playerId,
  selectedInstrumentType,
}) => {
  const { financial_instruments, session } = useSelector(
    selectors.playerById(playerId)
  );
  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const reportingCurrency = utils.useReportingCurrency(session);

  const allInstruments = Object.values(financial_instruments);
  const data = allInstruments.map(fn => ({
    ...fn,
    underlyingAsset: underlyingAssets[fn.underlying_asset], //attaches all underlying asset information
    pnl: fn.reporting_unrealized_pnl + fn.reporting_realized_pnl,
  }));

  const grouped = instrumentTypes
    .map(instrumentType => {
      const instruments = data.filter(
        fn => fn.underlyingAsset.resourcetype === instrumentType.type
      );

      return {
        name: instrumentType.name,
        instruments,
        total: instruments.reduce((acc, d) => acc + d.pnl, 0),
      };
    })
    .filter(gr => gr.total);

  // FOR BY TYPE PNL CHART
  const selectedTypes =
    selectedInstrumentType === 'All'
      ? grouped
      : grouped.filter(instrType => instrType.name === selectedInstrumentType);

  const byTypePnLDistributionChart = selectedTypes.map(group => {
    let seriesData = group.instruments;

    if (seriesData.length > 10) {
      const sorted = group.instruments.sort((a, b) =>
        Math.abs(a.pnl) > Math.abs(b.pnl) ? 1 : -1
      );
      seriesData = sorted.slice(-10);
      const trailingPositions = sorted.slice(0, sorted.length - 10);

      const createOthers = (trailingPositions: any[]) => {
        const othersTickers = trailingPositions.map(
          instrument => underlyingAssets[instrument.underlying_asset].ticker
        );

        return {
          name: `others: ${othersTickers}`,
          y: Math.round(trailingPositions.reduce((acc, d) => acc + d.pnl, 0)),
        };
      };
      const others = createOthers(trailingPositions);

      const series: { name: string; y: number }[] = seriesData.map(
        instrument => {
          return {
            name: instrument.underlyingAsset.ticker,
            y: Math.round(instrument.pnl),
          };
        }
      );
      series.push(others);
      const categories = series.map(asset_type => asset_type.name);
      const formattedTotal = formatter(group.total, reportingCurrency);

      return BarChart(
        group.name,
        series,
        categories,
        formattedTotal,
        reportingCurrency
      );
    } else {
      const series: { name: string; y: number }[] = seriesData.map(
        instrument => {
          return {
            name: instrument.underlyingAsset.ticker,
            y: Math.round(instrument.pnl),
          };
        }
      );
      const categories = series.map(asset_type => asset_type.name);
      const formattedTotal = formatter(group.total, reportingCurrency);

      return BarChart(
        group.name,
        series,
        categories,
        formattedTotal,
        reportingCurrency
      );
    }
  });

  // FOR GENERAL PNL CHART
  const distributionSeries = grouped.map(gr => ({
    name: gr.name,
    y: Math.round(gr.total),
  }));
  const categoriesForPNL = distributionSeries.map(
    asset_type => asset_type.name
  );
  const total = grouped.reduce((acc, gr) => acc + gr.total, 0);
  const formattedTotal = formatter(total, reportingCurrency);
  const generalPnLDistributionChart = useMemo(
    () =>
      BarChart(
        ' ',
        distributionSeries,
        categoriesForPNL,
        formattedTotal,
        reportingCurrency
      ),
    [distributionSeries]
  );

  return (
    <Row>
      {generalPnLDistributionChart}
      {byTypePnLDistributionChart}
    </Row>
  );
};

export default PnlAllocations;
