import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Typography } from 'antd';

import { LoadingScreen } from 'components';

import BenchmarkAndFundPerformance from './BenchmarkAndFundPerformance';
import ReturnVsVolatility from './ReturnVsVolatility';
import FundsPerformance from './FundsPerformance';
// import AllocationsVsBenchmark from './AllocationsVsBenchmark';

import { fetchMetrics, selectors } from 'state';

const Performance: React.FC = () => {
  const dispatch = useDispatch();
  const { metrics_fetched, metrics_fetching, id } = useSelector(
    selectors.activeSession
  );

  useEffect(() => {
    if (metrics_fetched) return;

    dispatch(fetchMetrics({ session_id: id }));
  }, [dispatch]);

  if (metrics_fetching || !metrics_fetched) return <LoadingScreen />;

  return (
    <div>
      <Row>
        <Col span={18}>
          <Typography.Title level={4}>
            Benchmark and Fund Performance
          </Typography.Title>
          <BenchmarkAndFundPerformance />
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          <Typography.Title level={4}>Return vs Volatility</Typography.Title>
          <ReturnVsVolatility />
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          <Typography.Title level={4}>Funds Performance</Typography.Title>
          <Typography.Text>
            Click on legend to activate / deactivate values
          </Typography.Text>
          <FundsPerformance />
        </Col>
      </Row>
      {/*<Row>*/}
      {/*  <Col span={18}>{<AllocationsVsBenchmark />}</Col>*/}
      {/*</Row>*/}
    </div>
  );
};

export default Performance;
