import { call, ForkEffect, put, takeEvery, select } from 'redux-saga/effects';
import { combineReducers, Reducer } from 'redux';

import { API, CreateModuleOptions } from './types';
import apiCaller from './ApiCaller';

import { StateManager, combineManagers } from './index';

import {
  PING_INTERVAL,
  PONG_TIMEOUT,
  PING,
  PONG,
  SOCKET_STATES,
  SOCKET_OPENED,
} from './constants';
import Cookies from 'js-cookie';

type ConstructorProps = {
  socketUrl?: string;
  managers: StateManager[];
};

export default class GlobalManager {
  readonly sockets: {
    [key: string]: {
      socket: WebSocket;
      uri: string;
      token: string;
      reconnectAttempts: number;
    };
  };
  readonly timeouts: {
    [key: string]: NodeJS.Timeout;
  };
  private pingInterval: NodeJS.Timeout | null;
  readonly logging: boolean;

  private handleMessage: (...args: unknown[]) => void;
  private handleOpen: (...args: unknown[]) => void;
  private handleClose: (...args: unknown[]) => void;
  private handleReconnect: (...args: unknown[]) => void;

  readonly socketUrl: string;

  readonly reducer: Reducer;
  readonly saga: () => Generator;
  readonly socketEvents: Record<string, any>;

  constructor(props: ConstructorProps) {
    this.sockets = {};
    this.timeouts = {};
    this.pingInterval = null;
    this.logging = false;

    // Callbacks
    this.handleMessage = () => {
      /*empty*/
    };
    this.handleOpen = () => {
      /*empty*/
    };
    this.handleClose = () => {
      /*empty*/
    };
    this.handleReconnect = () => {
      /*empty*/
    };

    this.socketUrl = props.socketUrl || '';
    const { reducer, saga, socketEvents } = combineManagers(props.managers);

    this.reducer = reducer;
    this.saga = saga;
    this.socketEvents = socketEvents;
  }

  private _ping = (socketDesc: string) => {
    this.sockets[socketDesc].socket.send(PING);

    this.timeouts[socketDesc] = setTimeout(() => {
      this.sockets[socketDesc].socket.close(4008, 'ping_timeout');
    }, PONG_TIMEOUT);
  };

  private _onPong = (socketDesc: string) => {
    clearTimeout(this.timeouts[socketDesc]);
  };

  private _listen = (socketDesc: string) => {
    const { socket } = this.sockets[socketDesc];

    socket.onopen = event => {
      this.pingInterval = setInterval(
        () => this._ping(socketDesc),
        PING_INTERVAL
      );
      this._log(event, socketDesc);
      this.handleOpen(socketDesc);

      this.sockets[socketDesc].reconnectAttempts = 0;
    };

    socket.onclose = event => {
      clearInterval(this.pingInterval as NodeJS.Timeout);
      // console.log(this.sockets);
      // attempt to reconnect if socket connection is dropped

      if (!event.wasClean && this.sockets[socketDesc].reconnectAttempts++ < 5) {
        const { token, uri } = this.sockets[socketDesc];
        setTimeout(() => this.connectToSocket(socketDesc, token, uri), 10000);
      }

      this.handleClose(socketDesc, event.wasClean);
      this._log(event, socketDesc);
    };

    socket.onmessage = event => {
      const message = event.data;
      if (message === PONG) this._onPong(socketDesc);
      else {
        // const parsedData = JSON.parse(event.data);
        // if (parsedData.type === 'send_price') {
        //   console.log('parsedData: ', parsedData);
        //   console.log('typeof parsedData: ', typeof parsedData);
        //   console.log('parsedData.type: ', parsedData.type);
        //   console.log('typeof parsedData.type: ', typeof parsedData.type);
        //   console.log('socketDesc: ', socketDesc);
        // }
        this.handleMessage(socketDesc, JSON.parse(event.data));
        this._log(event, socketDesc);
      }
    };
  };

  private _log = (event: any, socketDesc: string) => {
    // this.logging &&
    //   console.log({
    //     event,
    //     state: this.getState(socketDesc),
    //     socketObj: this.sockets,
    //   });
  };

  public getState = (socketDesc: string) =>
    SOCKET_STATES[this.sockets[socketDesc].socket.readyState];

  public connectToSocket = (socketDesc: string, token: string, uri: string) => {
    if (
      this.sockets[socketDesc] &&
      SOCKET_STATES[this.sockets[socketDesc].socket.readyState] ===
        SOCKET_OPENED
    )
      return;
    // console.log('USED TOKEN TO INITIATE WS CONNECTION: ');
    // console.log(token);
    const current_token = Cookies.get('token');
    if (current_token != undefined) {
      token = current_token;
    } else {
      return;
    }
    try {
      const socket = new WebSocket(`${this.socketUrl}${uri}?token=${token}`);
      // console.log('socket: ', socket);
      this.sockets[socketDesc] = { token, uri, socket, reconnectAttempts: 0 };
      this._listen(socketDesc);
    } catch (e) {
      console.log('socket connection error: ', e);
    }
  };

  public disconnectFromSocket = (socketDesc: string) => {
    // console.log(this.sockets);
    this.sockets[socketDesc].socket.close();
  };

  public onOpen = (func: any) => {
    this.handleOpen = func;
  };

  public onReconnect = (func: any) => {
    this.handleReconnect = func;
  };

  public onMessage = (func: any) => {
    this.handleMessage = func;
  };

  public onClose = (func: any) => {
    this.handleClose = func;
  };
}
