import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space, Table, Select, Typography } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons/lib';

import {
  selectors,
  REGIONS,
  updateSessionUnderlyingAssets,
  retrieveUnderlyingAssets,
  CREATING,
  instrumentTypes,
  listPresets,
  requestInstrumentPricePreview,
  HISTORICAL_SCENARIO,
  clearBenchmarkPreview,
  clearInstrumentPreview,
  YIELDS,
  TBILL,
} from 'state';
import { columnSearchWidget, TextShortener } from 'components';
import { useModal } from 'services';
import { ETFDetailsModal } from 'reusable';
import BenchmarkPreviewModal from './Benchmarks/BenchmarkPreviewModal';
import InstrumentPreviewModal from './InstrumentPreviewModal';

const SessionInstrumentsSelector = () => {
  const dispatch = useDispatch();
  const activeSession = useSelector(selectors.activeSession);
  const currencies = useSelector(selectors.currencies);
  const { items, waiting } = useSelector(selectors.underlyingAssetsApi);
  const presets = useSelector(selectors.presets);
  const presetsList = Object.values(presets || {});

  const [DetailModal, showDetails] = useModal(ETFDetailsModal);

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);

  const session = useSelector(selectors.activeSession);
  const isHistorical = session.scenario_type === HISTORICAL_SCENARIO;
  const showPreviewButoon = isHistorical && !session.allow_individual_benchmark;

  const hidePreviewModal = () => {
    setShowPreviewModal(false);
  };

  useEffect(() => {
    dispatch(retrieveUnderlyingAssets({}));
  }, [dispatch]);

  useEffect(() => {
    if (presetsList.length) return;

    dispatch(listPresets({}));
  }, [dispatch, presetsList.length]);

  useEffect(() => {
    if (session.instrument_preview) {
      setPreviewLoading(false);
      setShowPreviewModal(true);
    } else {
      setShowPreviewModal(false);
    }
  }, [session.instrument_preview]);
  useEffect(() => {
    // Cleanup function
    return () => {
      dispatch(clearInstrumentPreview({}));
    };
  }, []);

  const initialSelected = activeSession.underlying_assets ?? [];
  const [selected, setSelected] = useState(initialSelected);

  const isDisabled = activeSession.status !== CREATING;

  const dataSource = useMemo(() => {
    let assets = Object.values(items ?? {});
    if (isDisabled) {
      assets = assets.filter(u => selected.includes(u.id));
    } else {
      assets = assets.filter(
        u => u.resourcetype !== 'TBill' && u.resourcetype !== 'Yields'
      );
    }
    return assets;
  }, [items, isDisabled, selected]);

  return (
    // <Space direction="vertical" size={15}>
    <div>
      <Space size={15}>
        <Typography.Title level={3}>Session Instruments</Typography.Title>

        {!isDisabled && (
          <Space size={15} style={{ marginBottom: 9 }}>
            <Select
              showSearch
              allowClear
              style={{ width: 200 }}
              placeholder="Select Preset"
              optionFilterProp="children"
              onChange={value => {
                const id = Number(value);
                setSelected(presets[id]?.assets || []);
              }}
            >
              {presetsList.map(preset => {
                const { id, name } = preset;
                return (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                );
              })}
            </Select>

            <Button
              type="primary"
              onClick={() =>
                dispatch(
                  updateSessionUnderlyingAssets({
                    session_id: activeSession.id,
                    underlying_assets: selected,
                  })
                )
              }
            >
              Save
            </Button>
          </Space>
        )}
        {selected.length > 0 && (
          <Typography.Text type={'secondary'}>
            <div style={{ marginBottom: 9 }}>
              ({selected.length} instruments selected)
            </div>
          </Typography.Text>
        )}
      </Space>
      <Table
        // sticky={true}
        // style={{ marginTop: 20 }}
        rowKey="id"
        bordered
        loading={waiting}
        dataSource={dataSource}
        scroll={{ x: true }}
        // scroll: {{x: true}}
        pagination={{
          defaultPageSize: 20,
        }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selected,
          onChange: (selectedRowKeys: React.Key[]) => {
            setSelected(selectedRowKeys as number[]);
          },
          getCheckboxProps: () => ({
            disabled: isDisabled,
          }),
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            fixed: 'left',
            render: function Name(text, record) {
              if (!record.resourcetype.includes('ETF')) return text;

              return (
                <Space>
                  <Button
                    onClick={() => {
                      showDetails({ assetId: record.id });
                    }}
                  >
                    <ZoomInOutlined />
                  </Button>
                  {TextShortener({ text, maxLength: 20 })}
                </Space>
              );
            },
            sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
            ...columnSearchWidget({ dataIndex: 'name' }),
            onCell: record => {
              return {
                onClick: () => {
                  if (activeSession.scenario_type === HISTORICAL_SCENARIO) {
                    dispatch(
                      requestInstrumentPricePreview({ asset_id: record.id })
                    );
                  }
                },
              };
            },
          },
          {
            title: 'BBG Ticker',
            dataIndex: 'ticker',
            sorter: (a, b) =>
              a.ticker < b.ticker ? -1 : a.ticker > b.ticker ? 1 : 0,
            ...columnSearchWidget({ dataIndex: 'ticker' }),
          },
          {
            title: 'CCY',
            dataIndex: 'base_currency',
            render(value) {
              return currencies[value].symbol;
            },
            filters: Object.entries(currencies).map(([id, currency]) => {
              return {
                text: currency.symbol,
                value: parseInt(id),
              };
            }),
            onFilter: (value, record) => {
              return value === record.base_currency;
            },
            sorter: (a, b) => {
              const currencyA = currencies[a.base_currency]?.symbol ?? '';
              const currencyB = currencies[b.base_currency]?.symbol ?? '';
              return currencyA.localeCompare(currencyB);
            },
          },
          {
            title: 'Region',
            dataIndex: 'region',
            render(value: keyof typeof REGIONS) {
              return REGIONS[value];
            },
            filters: Object.entries(REGIONS).map(([id, region]) => {
              return {
                text: region,
                value: parseInt(id),
              };
            }),
            onFilter: (value, record) => {
              return value === record.region;
            },
            sorter: (a, b) => a.region - b.region,
          },
          {
            title: 'Geography',
            dataIndex: 'geography',
            filters: Array.from(
              new Set(dataSource.map(asset => asset.geography))
            ).map(geography => ({ text: geography, value: geography })),
            onFilter: (value, record) => {
              return value === record.geography;
            },
            sorter: (a, b) =>
              (a.geography || '').localeCompare(b.geography || ''),
          },
          {
            title: 'Asset Class',
            dataIndex: 'resourcetype',
            fixed: 'right',
            render: assetClass => {
              return instrumentTypes.find(obj => obj.type === assetClass)?.name;
            },
            filters: instrumentTypes
              .filter(
                type => type.assetClass !== YIELDS && type.assetClass !== TBILL
              )
              .map(obj => ({
                text: obj.name,
                value: obj.type,
              })),
            onFilter: (value, record) => {
              return value === record.resourcetype;
            },
            sorter: (a, b) => a.resourcetype.localeCompare(b.resourcetype),
            defaultSortOrder: 'ascend',
          },
        ]}
      />
      {DetailModal}
      {showPreviewModal && (
        <InstrumentPreviewModal
          visible={showPreviewModal}
          hide={hidePreviewModal}
        />
      )}
    </div>
    // </Space>
  );
};

export default SessionInstrumentsSelector;
