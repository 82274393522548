import React, { useState } from 'react';
import { Button, InputNumber, Modal, Card, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectors,
  breakDeposit,
  DepositInstrument,
  makeDeposit,
  PAUSED,
  FROZEN,
} from 'state';
import { formatters } from 'services';
import { selectedPerson } from '../../../../state/app/selectors';

type Props = {
  visible: boolean;
  hide: () => void;
  deposit: DepositInstrument;
};

const DepositModal: React.FC<Props> = ({ visible, hide, deposit }) => {
  const dispatch = useDispatch();
  const [amountToDeposit, setAmountToDeposit] = useState(10000);
  const person = useSelector(selectors.selectedPerson);
  const session = useSelector(selectors.activeSession);

  const makeDepositHandler = () => {
    dispatch(
      makeDeposit({
        amount_to_deposit: amountToDeposit,
        currency_symbol: deposit.currency_symbol,
      })
    );
    hide();
    setAmountToDeposit(0);
  };
  const breakDepositHandler = () => {
    dispatch(breakDeposit(deposit));
    hide();
  };

  const inactiveDeposit = () => {
    return (
      <div>
        <div style={{ display: 'inline-block' }}>Enter amount to deposit:</div>
        <InputNumber
          min={100}
          value={amountToDeposit}
          formatter={formatters.commasNoDigits}
          onChange={val => setAmountToDeposit(val || 10000)}
          style={{ width: 125, marginRight: 10, marginLeft: 30 }}
        />

        <Button
          disabled={
            amountToDeposit === 0 ||
            session.status === PAUSED ||
            session.status === FROZEN
          }
          onClick={makeDepositHandler}
        >
          Make Deposit
        </Button>
      </div>
    );
  };
  const ongoingDeposit = () => {
    return (
      <div>
        <Card size="small" title="Your deposit snapshot:">
          <p>Deposit status: {deposit.status}</p>
          <p>
            Initial deposited amount:{' '}
            {formatters.commasNoDigits(deposit.initial_amount)}{' '}
            {deposit.currency_symbol}
          </p>
          <p>
            Interest: {formatters.commasNoDigits(deposit.accumulated_interest)}{' '}
            {deposit.currency_symbol}
          </p>
          <p>
            Average annualized rate:{' '}
            {formatters.percent2Digit(deposit.average_annualized_rate)}
          </p>
          <p>Maturity: {deposit.maturity} days</p>
          <p>
            Opening date:{' '}
            {JSON.stringify(deposit.opening_date).split('T')[0].slice(1)}
          </p>
        </Card>
        <Row>
          <Col span={8}></Col>
          <Col span={8}>
            <Button
              onClick={breakDepositHandler}
              danger
              // style={{ position: 'absolute', right: 24, marginTop: 15 }}
              style={{ marginTop: 15, width: 157 }}
            >
              Break Deposit
            </Button>
          </Col>
          <Col span={8}></Col>
        </Row>
      </div>
    );
  };
  return (
    <Modal
      visible={visible}
      onCancel={hide}
      title={`${deposit.name}`}
      footer={
        [
          /* leave empty to delete ok cancel buttons*/
        ]
      }
    >
      <p>
        Currency: {deposit.currency_symbol} | O/N Rate (p.a.):{' '}
        {formatters.percent2Digit(deposit.annualized_rate)}
      </p>
      {deposit.initial_amount ? ongoingDeposit() : inactiveDeposit()}
    </Modal>
  );
};

export default DepositModal;
