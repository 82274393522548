import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, Collapse } from 'antd';
import { columnSearchWidget } from 'components';

import { selectors } from 'state';

const IndexFundsTable: React.FC = () => {
  const indexFunds = useSelector(selectors.indexFundsList);

  return (
    <Collapse>
      <Collapse.Panel header="Index funds table" key="1">
        <Table
          rowKey="id"
          bordered
          pagination={false}
          dataSource={indexFunds}
          columns={[
            {
              title: 'Issuer Name',
              dataIndex: 'issuer_name',
              ...columnSearchWidget({
                dataIndex: 'ric',
              }),
            },
            {
              title: 'Index',
              dataIndex: 'name',
              ...columnSearchWidget({
                dataIndex: 'ric',
              }),
            },
            {
              title: 'RIC',
              dataIndex: 'ric',
              ...columnSearchWidget({
                dataIndex: 'ric',
              }),
            },
          ]}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default IndexFundsTable;
