import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, InputNumber, Modal, Row, Table, Typography } from 'antd';

import { selectors } from 'state';
import { formatters } from 'services';
import { ColumnsType } from 'antd/es/table';
import {
  depo_tenors,
  FutureDepos,
  FutureLoans,
  loan_tenors,
} from '../../../state/app/client_money/types';
import {
  updateDepoRates,
  updateLoanRates,
} from '../../../state/app/client_money/actions';
import { todaysYields } from '../../../state/app/selectors';

type Props = {
  visible: boolean;
  hide: () => void;
};

export type LoansRatesType = {
  tenor: string;
  rate: number;
};

type loanState = {
  [tenor: number]: FutureLoans;
};

type depoState = {
  [tenor: number]: FutureDepos;
};

const RatesOfferModal: React.FC<Props> = ({ visible, hide }) => {
  const dispatch = useDispatch();
  const { id } = useSelector(selectors.player);
  const [editingLoans, setEditingLoans] = useState(false);
  const [editingDepos, setEditingDepos] = useState(false);
  const [loanRates, setLoanRates] = useState<loanState>({});
  const [depoRates, setDepoRates] = useState<depoState>({});
  const clientMoney = useSelector(selectors.getClientMoney);
  const clientProjections = useSelector(selectors.getClientProjections);
  const yields = useSelector(selectors.todaysYields);
  const wk4_yield = yields.yields.wk4;

  const futureLoans: FutureLoans[] = Object.values(
    clientMoney.future_loans.items
  );
  const futureDepos: FutureDepos[] = Object.values(
    clientMoney.future_depos.items
  );
  const loanDataSource = futureLoans.map(obj => {
    return {
      ...obj,
      rate: parseFloat((obj.rate * 100).toFixed(2)),
    };
  });
  const depoDataSource = futureDepos.map(obj => {
    return {
      ...obj,
      rate: parseFloat((obj.rate * 100).toFixed(2)),
    };
  });

  useEffect(() => {
    const newLoanRates = {} as loanState;
    loanDataSource.forEach(a => {
      newLoanRates[a.tenor] = {
        id: a.id,
        tenor: a.tenor,
        rate: a.rate,
        min: a.min,
        max: a.max,
      };
    });
    const newDepoRates = {} as depoState;
    depoDataSource.forEach(a => {
      newDepoRates[a.tenor] = {
        id: a.id,
        tenor: a.tenor,
        rate: a.rate,
        min: a.min,
        max: a.max,
      };
    });
    setLoanRates(newLoanRates);
    setDepoRates(newDepoRates);
  }, []);

  const resetLoans = () => {
    const oldLoanRates = {} as loanState;
    loanDataSource.forEach(a => {
      oldLoanRates[a.tenor] = {
        id: a.id,
        tenor: a.tenor,
        rate: a.rate,
        min: a.min,
        max: a.max,
      };
    });
    setLoanRates(oldLoanRates);
  };

  const resetDepos = () => {
    const oldDepoRates = {} as depoState;
    depoDataSource.forEach(a => {
      oldDepoRates[a.tenor] = {
        id: a.id,
        tenor: a.tenor,
        rate: a.rate,
        min: a.min,
        max: a.max,
      };
    });
    setDepoRates(oldDepoRates);
  };

  const getLoanProps = (field: 'rate') => {
    return {
      render(_: unknown, record: typeof futureLoans[0]) {
        if (editingLoans) {
          const rate_offer = loanRates[record.tenor];
          const rate = rate_offer?.[field] ?? 0;

          return (
            <div>
              <InputNumber
                value={rate}
                // formatter={formatters.commas2Digits}
                max={wk4_yield + 7}
                style={{ width: 85 }}
                onChange={value => {
                  if (value === undefined) {
                    return;
                  }
                  setLoanRates({
                    ...loanRates,
                    [record.tenor]: {
                      ...rate_offer,
                      tenor: record.tenor,
                      [field]: value,
                    },
                  });
                }}
                step={0.01}
              />
            </div>
          );
        }

        if (!record.rate) {
          return <div>-</div>;
        }
        return <div>{formatters.commas2Digits(record.rate)}</div>;
      },
    };
  };

  const getDepoProps = (field: 'rate') => {
    return {
      render(_: unknown, record: typeof futureDepos[0]) {
        if (editingDepos) {
          const rate_offer = depoRates[record.tenor];
          const rate = rate_offer?.[field] ?? 0;

          return (
            <div>
              <InputNumber
                value={rate}
                // formatter={formatters.commas2Digits}
                style={{ width: 85 }}
                // min={}
                min={0}
                onChange={value => {
                  if (value === undefined) {
                    return;
                  }
                  setDepoRates({
                    ...depoRates,
                    [record.tenor]: {
                      ...rate_offer,
                      tenor: record.tenor,
                      [field]: value,
                    },
                  });
                }}
                step={0.01}
              />
            </div>
          );
        }

        if (!record.rate) {
          return <div>-</div>;
        }
        return <div>{formatters.commas2Digits(record.rate)}</div>;
      },
    };
  };

  const editLoansButton = (
    <div
      style={{ position: 'relative', display: 'flex', gap: '10px', height: 30 }}
    >
      {!editingLoans && (
        <Button
          onClick={() => {
            setEditingLoans(true);
          }}
          style={{ position: 'absolute', right: 0 }}
        >
          Edit
        </Button>
      )}
      {editingLoans && (
        <>
          <Button
            type="primary"
            onClick={() => {
              setEditingLoans(false);
              dispatch(
                updateLoanRates({
                  loan_rates: loanRates,
                  player_id: id,
                })
              );
            }}
            style={{ position: 'absolute', right: 90 }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setEditingLoans(false);
              resetLoans();
            }}
            style={{ position: 'absolute', right: 0 }}
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  );

  const editDeposButton = (
    <div
      style={{ position: 'relative', display: 'flex', gap: '10px', height: 30 }}
    >
      {!editingDepos && (
        <Button
          onClick={() => {
            setEditingDepos(true);
          }}
          style={{ position: 'absolute', right: 0 }}
        >
          Edit
        </Button>
      )}
      {editingDepos && (
        <>
          <Button
            type="primary"
            onClick={() => {
              setEditingDepos(false);
              dispatch(
                updateDepoRates({
                  depo_rates: depoRates,
                  player_id: id,
                })
              );
            }}
            style={{ position: 'absolute', right: 90 }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setEditingDepos(false);
              resetDepos();
            }}
            style={{ position: 'absolute', right: 0 }}
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  );

  const LoansRatesColumns = [
    {
      title: 'Tenor, Yrs.',
      dataIndex: 'tenor',
      render: (value: number) => loan_tenors[value],
    },
    {
      title: 'Rate, %',
      ...getLoanProps('rate'),
    },
  ];

  const DeposRatesColumns = [
    {
      title: 'Tenor, Mos.',
      dataIndex: 'tenor',
      render: (value: number) => depo_tenors[value],
    },
    {
      title: 'Rate, %',
      ...getDepoProps('rate'),
    },
  ];

  const projectionColumns: ColumnsType<any> = [
    {
      title: 'Projection Type',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Amount, USD',
      key: 'amount',
      dataIndex: 'amount',
      render: (value: number) => formatters.commasNoDigits(value),
    },
  ];

  const projectionData = [
    {
      projection_type: 'Min. Loans',
      amount: formatters.commasNoDigits(20_000_000),
    },
    {
      projection_type: 'Max. Loans',
      amount: formatters.commasNoDigits(60_000_000),
    },
    { projection_type: 'Min. Depos', amount: formatters.commasNoDigits(0) },
    {
      projection_type: 'Max. Depos',
      amount: formatters.commasNoDigits(30_000_000),
    },
  ];

  return (
    <Modal
      visible={visible}
      onOk={hide}
      onCancel={hide}
      title="Set next period's Rates Offer"
    >
      <Row style={{ marginBottom: 20 }}>
        <Table
          pagination={false}
          bordered
          dataSource={clientProjections}
          columns={projectionColumns}
          title={() => (
            <Typography.Text strong>Future Client Projections:</Typography.Text>
          )}
        />
      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <Table
            pagination={false}
            bordered
            dataSource={loanDataSource}
            columns={LoansRatesColumns}
            title={() => (
              <Typography.Text strong>Set Loans Rates:</Typography.Text>
            )}
            footer={() => editLoansButton}
          />
          <Typography.Text></Typography.Text>
        </Col>
        <Col span={12}>
          <Table
            pagination={false}
            bordered
            dataSource={depoDataSource}
            columns={DeposRatesColumns}
            title={() => (
              <Typography.Text strong>Set Depos Rates:</Typography.Text>
            )}
            footer={() => editDeposButton}
            // footer={() => {
            //   return <Button style={{ marginLeft: 135 }}>Edit</Button>;
            // }}
          />
        </Col>
      </Row>
      <br />
      <Typography.Text>
        * Projections estimated for best/worst interbank rates
      </Typography.Text>
    </Modal>
  );
};

export default RatesOfferModal;
