import React from 'react';
import { useSelector } from 'react-redux';

import { Descriptions, Card } from 'antd';

import { selectors } from 'state';

const TradingWindows: React.FC = () => {
  const { trading_intervals } = useSelector(selectors.activeSession);

  return (
    <div>
      <Card>
        <Descriptions title={`Trading Windows (GMT+0)`} column={1}>
          {trading_intervals.map((item, index) => (
            <Descriptions.Item key={index}>
              {item.start} - {item.end}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Card>
    </div>
  );
};

export default TradingWindows;
