import React from 'react';
import Chart from 'react-apexcharts';

type Data = { x: string | number; y: number };

type Props = {
  data: Data[];
  title: string;
  dataSeriesName: string;
  style?: React.CSSProperties;
  xaxis?: Record<string, unknown>;
  yaxisFormatter?: (value: string | number | undefined) => string;
  tooltipDateFormat?: string;
  toolbar?: boolean;
  additionalLines?: { name: string; data: Data[] }[];
  cleaningCondition?: (arg0: Data) => boolean;
  hideDate?: boolean;
};

const AreaChart: React.FC<Props> = ({
  data,
  title,
  dataSeriesName,
  style,
  yaxisFormatter,
  xaxis,
  tooltipDateFormat,
  toolbar,
  additionalLines,
  cleaningCondition,
  hideDate,
}) => {
  if (cleaningCondition && data.some(cleaningCondition)) data = [];

  const options: Record<string, unknown> = {
    chart: {
      toolbar: {
        show: toolbar || false,
      },
      animations: {
        enabled: false,
      },
    },
    title: {
      text: title,
    },
    stroke: { curve: 'smooth' },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: yaxisFormatter
          ? yaxisFormatter
          : (value: string | number | undefined) =>
              value?.toLocaleString?.() ?? '',
      },
    },
    xaxis: xaxis ?? {
      type: 'datetime',
      tooltip: {
        enabled: false,
      },
      tickPlacement: 'between',
      labels: {
        show: !hideDate,
      },
    },
  };

  if (tooltipDateFormat) {
    options['tooltip'] = {
      x: {
        show: !hideDate,
        format: tooltipDateFormat,
      },
    };
  }

  return (
    <div style={style}>
      <Chart
        options={options}
        series={[
          {
            name: dataSeriesName,
            data: data,
          },
          ...(additionalLines || []),
        ]}
        type="area"
      />
    </div>
  );
};

export default AreaChart;
