import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Button, Select, Card } from 'antd';

import {
  DynamicFormField,
  DynamicFormFieldItem,
  PercentInput,
} from 'components';
import {
  selectors,
  updatePlayerBenchmarkComposition,
  BenchmarkComposition as BenchmarkCompositionType,
} from 'state';

const BenchmarkComposition: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const indexFunds = useSelector(selectors.indexFundsList);
  const session = useSelector(selectors.activeSession);
  const player = useSelector(selectors.player);

  let benchmark_compositions;
  if (session.allow_individual_benchmark && player.benchmark_compositions) {
    benchmark_compositions = player.benchmark_compositions;
  } else {
    benchmark_compositions = session.benchmark_compositions;
  }
  const benchmark_data = benchmark_compositions.map(item => ({
    ...item,
    weight: item.weight * 100,
  }));

  const onFinish = () => {
    const composition = form
      .getFieldValue('composition')
      .map((item: BenchmarkCompositionType) => ({
        ...item,
        weight: item.weight / 100,
      }));

    dispatch(
      updatePlayerBenchmarkComposition({ composition, player_id: player.id })
    );
  };

  return (
    <Card title="Benchmark Composition">
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ composition: benchmark_data }}
      >
        <DynamicFormField
          name="composition"
          addNewText="Add Fund"
          renderField={field => (
            <>
              <DynamicFormFieldItem
                field={field}
                label="Fund"
                name="fund"
                missingMessage="Missing Fund"
              >
                <Select style={{ width: 200 }}>
                  {indexFunds.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.ric}
                    </Select.Option>
                  ))}
                </Select>
              </DynamicFormFieldItem>

              <DynamicFormFieldItem
                field={field}
                label="Weight"
                name="weight"
                missingMessage="Missing Weight"
              >
                <PercentInput />
              </DynamicFormFieldItem>
            </>
          )}
        />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default BenchmarkComposition;
