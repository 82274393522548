import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, updatePortfolioName } from 'state';
import { MEDIA_URL } from 'settings';

const View: React.FC = () => {
  const dispatch = useDispatch();
  const player = useSelector(selectors.player);
  const people = useSelector(selectors.persons);
  const person = useSelector(selectors.selectedPerson);
  const reports = useSelector(selectors.selfInsightsReports);
  const isCreating = useSelector(selectors.creatingInsightsReport);

  const playerId = player?.id;

  useEffect(() => {
    if (!playerId) {
      return;
    }
    // commented because this would trigger an S3 file fetch and currently boto3 S3 access is deactivated in backend
    // dispatch(retrieveInsightsReports({ player_id: playerId }));
  }, [dispatch, playerId]);

  useEffect(() => {
    if (player?.portfolio_name) {
      setPortfolioName(player.portfolio_name);
    }
  }, [player]);

  const [portfolioName, setPortfolioName] = useState('');

  const dataSource = reports.sort((a, b) =>
    b.created_at.localeCompare(a.created_at)
  );

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Table
          rowKey="id"
          dataSource={dataSource}
          columns={[
            {
              title: 'Created at',
              dataIndex: 'created_at',
              render(text) {
                const date = new Date(text);
                return `${date.toLocaleString()}`;
              },
            },
            {
              title: 'Person',
              dataIndex: 'person',
              render(person) {
                return people[person]?.name ?? '';
              },
            },
            {
              title: 'Action',
              dataIndex: 'file',
              render(url: string | undefined) {
                let s3Url = '';

                // Media urls include /media
                // https://s3.[region].amazonaws.com/[bucket]/media/insights/[FILE_NAME]?AWSAccessKeyId=[STRING]&Signature=[STRING]&Expires=[NUMBER]

                if (typeof url === 'string' && url.includes('/media')) {
                  let relativePath = url.split('/media')[1];
                  // Check if url has query params (have no idea how to fix this in django-storages)
                  if (!!relativePath && relativePath.includes('?')) {
                    relativePath = relativePath.split('?')[0];
                  }

                  if (!relativePath.startsWith('/')) {
                    relativePath = '/' + relativePath;
                  }

                  s3Url = `${MEDIA_URL}${relativePath}`;
                }

                return (
                  <Button
                    disabled={!s3Url}
                    onClick={() => {
                      const a = document.createElement('a');
                      a.href = s3Url;
                      a.download = `Portfolio_Insights_${player.name}`;
                      a.click();
                    }}
                  >
                    Download
                  </Button>
                );
              },
            },
          ]}
          pagination={false}
        />
      </Col>

      <Col span={12}>
        <div style={{ wordWrap: 'unset' }}>Portfolio name:</div>
        <div style={{ display: 'flex', gap: 16, marginBottom: 16 }}>
          <Input
            value={portfolioName}
            onChange={e => setPortfolioName(e.target.value)}
          />

          <Button
            onClick={() => {
              dispatch(
                updatePortfolioName({
                  player_id: player.id,
                  portfolio_name: portfolioName,
                })
              );
            }}
            type="primary"
          >
            Save
          </Button>
        </div>

        <Button
          onClick={() => {
            // commented because this would trigger an S3 file fetch and currently boto3 S3 access is deactivated in backend
            // dispatch(
            //   createInsightsReport({
            //     player_id: player.id,
            //     person_id: person?.id,
            //   })
            // );
          }}
          loading={isCreating}
          disabled={isCreating}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          Generate
        </Button>
      </Col>
    </Row>
  );
};

export default View;
