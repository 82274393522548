import React, { useState } from 'react';

import { Row, Col, Card, Form } from 'antd';

import PlayerSettings from './PlayerSettings';

const Settings: React.FC = () => {
  return (
    <div>
      <Row justify="center">
        <Col span={8}>
          <PlayerSettings />
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
