import React, { useState } from 'react';
import type { CSSProperties } from 'react';

import { PLAYING, selectors, utils } from 'state';
import { useSelector } from 'react-redux';
import { Button, Progress } from 'antd';
import { formatStringDateTraineeInfo } from '../../state/app/utils';
import {
  MinusCircleOutlined,
  MinusCircleTwoTone,
  MinusOutlined,
  PlusCircleFilled,
  PlusCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';

type props = {
  isHistorical: boolean;
};

const HistoricalProgressTrainee: React.FC<props> = ({ isHistorical }) => {
  const session = useSelector(selectors.activeSession);
  const historicalStep = useSelector(selectors.historicalStep);
  const [minimized, setMinimized] = useState<boolean>(true);

  if (!isHistorical) return null;

  const commonStyles = {
    position: 'fixed',
    zIndex: 99999,
  } as CSSProperties;

  let pausedStyles = {
    top: '89.5vh',
    right: 0,
    transformOrigin: 'right top',
    transform: 'translateX(-20%)',
    backgroundColor: 'rgb(0,21,41)',
    width: '300px',
    paddingBottom: '60px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '10px',
    fontSize: '15px',
  } as CSSProperties;

  if (minimized) {
    pausedStyles = {
      top: '94vh',
      right: 0,
      transformOrigin: 'right top',
      transform: 'translateX(-20%)',
      backgroundColor: 'rgb(0,21,41)',
      width: '300px',
      paddingBottom: '60px',
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '10px',
      fontSize: '15px',
    } as CSSProperties;
  }

  const overlayStyles = {
    ...commonStyles,
    ...pausedStyles,
  };

  const progressBarAnimated:
    | 'success'
    | 'normal'
    | 'exception'
    | 'active'
    | undefined = session.status === PLAYING ? 'active' : 'normal';

  const currentDateRaw = new Date(session.start_date);
  currentDateRaw.setDate(currentDateRaw.getDate() + session.elapsed_days);
  const currentDate = formatStringDateTraineeInfo(currentDateRaw.toISOString());

  const progress = Math.floor(
    ((session.total_simulated_days - session.remaining_simulated_days) /
      session.total_simulated_days) *
      100
  );
  if (!minimized) {
    return (
      <div style={overlayStyles}>
        <a
          style={{ position: 'absolute', right: 15 }}
          onClick={() => {
            setMinimized(true);
          }}
        >
          <MinusOutlined style={{ color: 'white' }} />
        </a>
        <span
          style={{
            color: '#c0bebe',
          }}
        >
          <div>Simulation day: {currentDate}</div>
          <div>Remaining time: {session.remaining_session_time}</div>
          <Progress
            strokeColor={{
              from: '#108ee9',
              to: '#87d068',
            }}
            percent={progress}
            status={progressBarAnimated}
            showInfo={false}
          />
          <div style={{ fontSize: 11 }}>
            (1 period / {utils.formattedHistoricalStep(historicalStep)} /{' '}
            {session.frequency} simulated{' '}
            {session.frequency === 1 ? 'day' : 'days'})
          </div>
        </span>
      </div>
    );
  } else {
    return (
      <div style={overlayStyles}>
        <a
          style={{ position: 'absolute', right: 15 }}
          onClick={() => {
            setMinimized(false);
          }}
        >
          {/*<PlusCircleTwoTone />*/}
          <PlusOutlined style={{ color: 'white' }} />
        </a>
        <span
          style={{
            color: '#c0bebe',
          }}
        >
          <div>Remaining time: {session.remaining_session_time}</div>
          <Progress
            strokeColor={{
              from: '#108ee9',
              to: '#87d068',
            }}
            percent={progress}
            status={progressBarAnimated}
            showInfo={false}
          />
        </span>
      </div>
    );
  }
};

export default HistoricalProgressTrainee;
