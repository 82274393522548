import React from 'react';

import { Divider, Space, Statistic, Typography } from 'antd';

import { formatters } from 'services';
import type { IndividualReport } from 'state';

type Props = {
  report: IndividualReport;
};

const Trades: React.FC<Props> = ({ report }) => {
  const { trades } = report;

  return (
    <div>
      <Divider orientation="left">
        <Typography.Title level={3}>Trades</Typography.Title>
      </Divider>

      <Space size={10}>
        <Statistic
          title="Total"
          value={trades.total}
          formatter={formatters.commasNoDigits}
        />
        <Divider type="vertical" />
        <Statistic
          title="Daily Average"
          value={trades.daily_avg}
          formatter={formatters.commas2Digits}
        />
        <Divider type="vertical" />
        <Statistic
          title="Daily Max"
          value={trades.daily_max}
          formatter={formatters.commasNoDigits}
        />
        <Divider type="vertical" />
        <Statistic
          title="Profitable trades"
          value={trades.profitable_percent}
          formatter={formatters.commas2Digits}
          suffix="%"
        />
        <Divider type="vertical" />
        <Statistic
          title="Losing trades"
          value={trades.losing_percent}
          formatter={formatters.commas2Digits}
          suffix="%"
        />
        <Divider type="vertical" />
        <Statistic
          title="Intraday trades"
          value={trades.intraday_percent}
          formatter={formatters.commas2Digits}
          suffix="%"
        />
      </Space>
    </div>
  );
};

export default Trades;
