import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingScreen } from 'components';

import { fetchMacroBackground, selectors } from 'state';

const MacroBackground: React.FC = () => {
  const dispatch = useDispatch();

  const { macro_background, fetched_macro_background, id } = useSelector(
    selectors.activeSession
  );

  useEffect(() => {
    if (macro_background || fetched_macro_background) return;
    dispatch(fetchMacroBackground({ session_id: id }));
  }, [macro_background, fetched_macro_background, dispatch, id]);

  if (!fetched_macro_background) return <LoadingScreen />;

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '900px',
        margin: '0 auto',
      }}
    >
      {(macro_background || '')
        .split('\n')
        .map((text, index) => [text, <br key={index} />])
        .reduce((acc, arr) => [...acc, ...arr], [])}
    </div>
  );
};

export default MacroBackground;
