import React, { useState } from 'react';
import { Button, Divider, Form, Input, Modal, Select, Switch } from 'antd';
import {
  CreateIndexFund,
  createIndexFund,
  createUnderlyingAsset,
  instrumentTypes,
  marketHours,
  REGIONS,
  selectors,
  UnderlyingAsset,
} from 'state';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  hide: () => void;
};
const CreateIndexFundModal: React.FC<Props> = ({ hide }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [investmentThemeForm] = Form.useForm();
  const currencies = useSelector(selectors.currencies);
  const [assetClass, setAssetClass] = useState<
    UnderlyingAsset['resourcetype'] | undefined
  >(undefined);
  const HTMorAFS = assetClass === 'HTMBond' || assetClass === 'AFSBond';
  return (
    <Modal
      visible={true}
      onOk={form.submit}
      onCancel={hide}
      title={'Create Index Fund'}
      width={600}
      // footer={null}
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          onFinish={() => {
            const data: CreateIndexFund = {
              ric: form.getFieldValue('ric'),
              issuer_name: form.getFieldValue('issuer_name'),
              name: form.getFieldValue('name'),
              description: form.getFieldValue('description'),
            };

            // console.log(data);
            dispatch(createIndexFund(data));
            // hide();
          }}
          initialValues={{
            visible: true,
            fetch_eod: false,
            fetch_intraday: false,
          }}
        >
          <Form.Item
            label="Ric"
            name="ric"
            rules={[{ required: true, message: 'required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Issuer Name"
            name="issuer_name"
            rules={[{ required: true, message: 'required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'required' }]}
          >
            <Input.TextArea></Input.TextArea>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default CreateIndexFundModal;
