import React, { useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  InputNumber,
  Popconfirm,
  Select,
  Table,
} from 'antd';
import Label from './Label';
import { useDispatch, useSelector } from 'react-redux';
import {
  applyStockSplit,
  createStockSplits,
  deleteStockSplits,
  fetchAssets,
  listStockSplits,
  selectors,
  splitSessionAmounts,
  StockSplit,
  undoStockSplit,
} from 'state';
import moment from 'moment/moment';
import { Moment } from 'moment';

const parseValue = (value: any) => {
  let result = undefined;
  if (typeof value === 'string') {
    result = Number(value);
  }
  if (['undefined', 'number'].includes(typeof value)) {
    result = value as number | undefined;
  }
  return result;
};

const StockSplits: React.FC = () => {
  const dispatch = useDispatch();

  const { items: underlyingAssets, fetched: fetchedAssets } = useSelector(
    selectors.underlyingAssetsApi
  );
  const { fetched: fetchedStockSplits } = useSelector(selectors.stockSplitsAPI);
  const stockSplitsList = useSelector(selectors.stockSplitsList);

  const [payload, setPayload] = useState<
    Partial<Omit<StockSplit, 'timestamp'> & { timestamp: Moment }>
  >({
    timestamp: moment('00:00:00', 'h:mm:ss'),
  });
  // const [selectedKeys, setSelectedKeys] = useState<number[]>([]);

  useEffect(() => {
    if (!fetchedAssets) {
      dispatch(fetchAssets({}));
    }
  }, [fetchedAssets]);

  useEffect(() => {
    if (!fetchedStockSplits) {
      dispatch(listStockSplits({}));
    }
  }, [fetchedStockSplits]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: 16,
          marginBottom: 16,
          alignItems: 'flex-end',
        }}
      >
        <Label title="Asset (ID, Ric, Ticker, Name)">
          <Select
            value={payload.underlying_asset}
            onChange={value => {
              const underlying_asset = parseValue(value);
              setPayload({ ...payload, underlying_asset });
            }}
            allowClear
            showSearch
            filterOption={(value, option) => {
              const searchId = Number(value);
              const assetId = Number(option?.value);

              if (!isNaN(searchId)) {
                return assetId.toString().includes(searchId.toString());
              } else {
                const lowerValue = value.toLowerCase();
                const asset = underlyingAssets[assetId];
                if (!asset) {
                  return false;
                }

                const name = (asset.name || '').toLowerCase();
                const ric = (asset.ric || '').toLowerCase();
                const ticker = (asset.ticker || '').toLowerCase();

                return (
                  name.includes(lowerValue) ||
                  ric.includes(lowerValue) ||
                  ticker.includes(lowerValue)
                );
              }
            }}
            style={{ width: 200 }}
          >
            {Object.values(underlyingAssets).map(asset => {
              return (
                <Select.Option key={asset.id} value={asset.id}>
                  {asset.name}
                </Select.Option>
              );
            })}
          </Select>
        </Label>

        <Label title="Timestamp">
          <DatePicker
            showTime
            value={payload.timestamp}
            onChange={value => {
              setPayload({ ...payload, timestamp: value || undefined });
            }}
          />
        </Label>

        <Label title="Ratio (1:X)">
          <InputNumber
            value={payload.ratio}
            onChange={value => setPayload({ ...payload, ratio: value })}
          />
        </Label>

        <Button
          type="primary"
          onClick={() => {
            const { timestamp, ...rest } = payload;

            dispatch(
              createStockSplits({
                ...rest,
                timestamp: timestamp?.toISOString(),
              })
            );
          }}
        >
          Create
        </Button>
      </div>

      <Table
        rowKey="id"
        // rowSelection={{
        //   type: 'checkbox',
        //   selectedRowKeys: selectedKeys,
        //   onChange: (selectedRowKeys: React.Key[]) => {
        //     setSelectedKeys(selectedRowKeys as number[]);
        //   },
        //   // getCheckboxProps: () => ({
        //   //   disabled: !presetId,
        //   // }),
        // }}
        dataSource={stockSplitsList}
        columns={[
          { title: 'ID', dataIndex: 'id' },
          {
            title: 'Asset',
            dataIndex: 'underlying_asset',
            render(value) {
              return underlyingAssets[value]?.name;
            },
          },
          { title: 'Timestamp', dataIndex: 'timestamp' },
          { title: 'Executed at', dataIndex: 'executed_at' },
          { title: 'Ratio  (1:X)', dataIndex: 'ratio' },
          {
            title: 'Action',
            render(val, record) {
              const deleteDisabled =
                record.executed_at != undefined || record.executing;
              const applyDisabled = record.executed_at != undefined;
              const undoDisabled = record.executed_at == undefined;

              return (
                <div style={{ display: 'flex', gap: 8 }}>
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                      dispatch(deleteStockSplits({ id: record.id }));
                    }}
                    disabled={deleteDisabled}
                  >
                    <Button danger disabled={deleteDisabled}>
                      Delete
                    </Button>
                  </Popconfirm>

                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                      dispatch(applyStockSplit({ id: record.id }));
                    }}
                    disabled={applyDisabled}
                  >
                    <Button
                      loading={record.executing}
                      type="primary"
                      disabled={applyDisabled}
                    >
                      Apply
                    </Button>
                  </Popconfirm>

                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                      dispatch(undoStockSplit({ id: record.id }));
                    }}
                    disabled={undoDisabled}
                  >
                    <Button
                      loading={record.executing}
                      type="primary"
                      disabled={undoDisabled}
                    >
                      Undo
                    </Button>
                  </Popconfirm>

                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                      dispatch(splitSessionAmounts({ id: record.id }));
                    }}
                  >
                    <Button>Split session amounts</Button>
                  </Popconfirm>
                </div>
              );
            },
          },
        ]}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default StockSplits;
