import React from 'react';
import { Collapse, Typography } from 'antd';
import InvestmentsTable from './InvestmentsTable';
import { useSelector } from 'react-redux';
import { selectors, typesOrder, utils } from 'state';
import { InvestmentData } from '../index';

const Investments: React.FC = () => {
  const financialInstruments = useSelector(selectors.financialInstrumentsList);
  const playableInstrumentTypes = useSelector(
    selectors.playableInstrumentTypes
  );
  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencies = useSelector(selectors.currencies);
  const currencyList = useSelector(selectors.currenciesList);
  const {
    // market_value,
    last_market_value,
    session,
  } = useSelector(selectors.player);
  const reportingCurrency = utils.useReportingCurrency(session);

  const dataSource: InvestmentData[] = financialInstruments
    .filter(item => {
      if (item.fx_hedge) {
        // this is because some fx forwards can have amount of 0 from two opposing contracts, and we still have to
        // display them even though total amount is 0.
        return item.reporting_unrealized_pnl;
      }
      return item.amount;
    })
    .map(instrument => {
      const underlying_asset = underlyingAssets[instrument.underlying_asset];
      const currency = currencies[underlying_asset.base_currency].symbol;

      const reportingValue = utils.reportingValue(
        instrument,
        currencies[underlying_asset.base_currency],
        underlyingAssets[instrument.underlying_asset].resourcetype,
        assetList,
        currencyList,
        reportingCurrency
      );

      const dollarToPortfolioRatio = reportingValue / last_market_value;

      return {
        ...underlying_asset,
        ...instrument,
        currency,
        reportingValue,
        dollarToPortfolioRatio,
      };
    })
    .sort(
      (a, b) =>
        typesOrder.indexOf(a.resourcetype) - typesOrder.indexOf(b.resourcetype)
    );

  return (
    <div>
      <Typography.Title level={3}>Investments</Typography.Title>
      <Collapse accordion>
        {playableInstrumentTypes.map(({ name, type }) => {
          const data = dataSource.filter(
            instrument =>
              underlyingAssets[instrument.underlying_asset].resourcetype ===
              type
          );

          return (
            <Collapse.Panel disabled={!data.length} key={name} header={name}>
              <InvestmentsTable data={data} />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default Investments;
