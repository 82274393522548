import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { REGIONS, selectors } from 'state';
import { formatters } from 'services';
import { useReportingCurrency } from '../../../../state/app/utils';

const RegionTable: React.FC = () => {
  const instruments = useSelector(selectors.financialInstrumentsList);
  const pnlByRegion = useSelector(selectors.pnlByRegion(instruments));
  const { session } = useSelector(selectors.player);
  // console.log(pnlByRegion);
  const reportingCurrency = useReportingCurrency(session);

  return (
    <Table
      rowKey="region"
      bordered
      pagination={false}
      dataSource={pnlByRegion}
      columns={[
        {
          title: 'Region',
          dataIndex: 'region',
          render: (text: keyof typeof REGIONS) => REGIONS[text],
          width: '34%',
        },
        {
          title: 'PnL, ' + reportingCurrency.symbol,
          dataIndex: 'pnl',
          align: 'right',
          render: formatters.million,
          width: '33%',
          sorter: (a, b) => a.pnl - b.pnl,
          defaultSortOrder: 'descend',
        },
        {
          title: 'Allocation',
          dataIndex: 'percent',
          align: 'right',
          render: formatters.percent2Digit,
          width: '33%',
          sorter: (a, b) => (a.percent - b.percent) * 100,
        },
      ]}
    />
  );
};

export default RegionTable;
