import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackTop,
  Button,
  Card,
  InputNumber,
  Popconfirm,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import {
  computeBeta,
  computeVolatility,
  deleteCorrelationValues,
  fetchHistoricalStep,
  fetchAssets,
  fetchSessions,
  selectors,
  updateHistoricalStep,
  addInstrumentsToRunningSessions,
  AddInstrumentsToRunningSessions,
} from 'state';
import session from '../Sessions/Session';
import { Simulate } from 'react-dom/test-utils';

const AddInstrumentsToRunningSession: React.FC = () => {
  const dispatch = useDispatch();
  // const historicalStep = useSelector(selectors.historicalStep);
  // const [step, setStep] = useState<number | undefined>(historicalStep);
  const sessions = useSelector(selectors.sessions);
  // console.log(sessions);
  const assets = useSelector(selectors.underlyingAssetsList);
  const waiting = useSelector(selectors.waiting);
  const [selectedSessions, setSelectedSessions] = useState<number[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<number[]>([]);

  useEffect(() => {
    if (!assets.length) {
      dispatch(fetchAssets({}));
    }
    if (!sessions.length) {
      dispatch(fetchSessions({}));
    }
  }, [dispatch]);
  // useEffect(() => {
  //   setStep(historicalStep);
  // }, [historicalStep]);

  return (
    <Card>
      <div style={{ display: 'flex', gap: 10 }}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '415px' }}
          placeholder="Select session ids"
          value={selectedSessions}
          onChange={value => setSelectedSessions(value as number[])}
        >
          {sessions.map(({ id }) => (
            <Select.Option key={id} value={id}>
              {id}
            </Select.Option>
          ))}
        </Select>
        <Select
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="children"
          style={{ width: '415px' }}
          placeholder="Select assets"
          value={selectedAssets}
          onChange={value => setSelectedAssets(value as number[])}
        >
          {assets.map(asset => (
            <Select.Option key={asset.id} value={asset.id}>
              {asset.ric}
            </Select.Option>
          ))}
        </Select>
        <Button
          onClick={() => {
            const data: AddInstrumentsToRunningSessions = {
              assets: selectedAssets,
              sessions: selectedSessions,
            };
            dispatch(addInstrumentsToRunningSessions(data));
          }}
        >
          Add
        </Button>
        {waiting && <Spin />}
      </div>
    </Card>
  );
};

export default AddInstrumentsToRunningSession;
