import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Collapse,
  Empty,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import {
  ASSET_CLASSES,
  AssetClassOptions,
  cancelRFQ,
  exchangeMoney,
  hitRFQResponse,
  INTERBANK_BORROW,
  INTERBANK_LEND,
  InterBankDeal,
  LoanRateOfferOptions,
  RFQRequest,
  RFQResponse,
  selectors,
  sendRFQ,
  sendRFQResponse,
  TRADING_WINDOWS,
  UnderlyingAsset,
  updateAssetClassOptions,
  utils,
  Wallet,
} from 'state';
import { formatters } from 'services';
import { ColumnsType, ColumnType } from 'antd/es/table';
import {
  currentPeriodInterbankDeals,
  hasActiveRequest,
  playerSentRFQRequests,
} from '../../../state/app/selectors';
import PortfolioLimits from '../PortfolioTab/PortfolioLimits';
import LimitsAndWeights from '../PortfolioTab/LimitsAndWeights';
import BenchmarkComposition from '../PortfolioTab/BenchmarkComposition';
import TradingWindows from '../PortfolioTab/TradingWindows';
import { BORROW, LEND, NOTHING } from '../../../state/app/client_money/types';

type Props = {
  visible: boolean;
  hide: () => void;
};

export type LoansRatesType = {
  tenor: string;
  rate: number;
};

type quoteState = {
  [id: number]: { bid: number; ask: number };
};

const RequestsModal: React.FC<Props> = ({ visible, hide }) => {
  const dispatch = useDispatch();
  const { other_players, id: player_id } = useSelector(selectors.player);
  const hasActiveRequest = useSelector(selectors.hasActiveRequest);
  const activeRequest = useSelector(selectors.activeRFQRequest);
  // console.log('activeRequest: ', activeRequest);
  const responsesForActiveRequests = useSelector(
    selectors.responsesForActiveRequests
  );
  const lockedInResponsesToYourRFQ = useSelector(
    selectors.lockedInResponsesToYourRFQ
  );
  const currentPeriodInterbankDeals = useSelector(
    selectors.currentPeriodInterbankDeals
  );
  const pastPeriodInterbankDeals = useSelector(
    selectors.pastPeriodInterbankDeals
  );
  const { id: session_id } = useSelector(selectors.activeSession);
  const reportingCurrency = utils.useReportingCurrency(session_id);
  // console.log('activeRequest: ', activeRequest);
  // const playerSentRFQRequests = useSelector(selectors.playerSentRFQRequests);
  // const [editing, setEditing] = useState(false);
  // const [rates, setRates] = useState<State>({});
  const [requestAmount, setRequestAmount] = useState<number>(10_000_000);
  const [selectedPlayers, setSelectedPlayers] = useState<number[]>([]);
  const [quotes, setQuotes] = useState<quoteState>({});

  useEffect(() => {
    const quotes = {} as quoteState;
    responsesForActiveRequests.forEach(response => {
      quotes[response.id] = {
        bid: response.bid || 0,
        ask: response.ask || 0,
      };
    });
    setQuotes(quotes);
  }, [responsesForActiveRequests]);

  const renderQuote = (record: RFQResponse, field: string) => {
    const quote = quotes[record.id] || { bid: 0, ask: 0 };
    const disabled =
      record.status === 'Waiting hit' ||
      record.status === 'nothing there' ||
      record.status === 'borrow'
        ? true
        : false;
    if (field === 'bid') {
      return (
        <InputNumber
          value={quote.bid}
          disabled={disabled}
          onChange={val => {
            if (val !== undefined) {
              setQuotes(prevQuotes => ({
                ...prevQuotes,
                [record.id]: {
                  ...prevQuotes[record.id],
                  bid: val,
                },
              }));
            }
          }}
          step={0.01}
        />
      );
    } else {
      return (
        <InputNumber
          value={quote.ask}
          disabled={disabled}
          onChange={val => {
            if (val !== undefined) {
              setQuotes(prevQuotes => ({
                ...prevQuotes,
                [record.id]: {
                  ...prevQuotes[record.id],
                  ask: val,
                },
              }));
            }
          }}
          step={0.01}
        />
      );
    }
    // return 0;
  };

  const currentInterBankDealsColumns: ColumnType<any>[] = [
    {
      title: 'Market taker',
      dataIndex: 'requesting_player_name',
      key: 'requesting_player_name',
      sorter: (a: InterBankDeal, b: InterBankDeal) => a.id - b.id,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: function Actions(_: any, record: InterBankDeal) {
        if (record.direction === INTERBANK_BORROW) {
          return <div>borrowed</div>;
        } else {
          return <div>lent</div>;
        }
      },
    },
    {
      title: `Amount, ${reportingCurrency.symbol}`,
      dataIndex: 'amount',
      key: 'amount',
      render: (val: number) => formatters.commasNoDigits(val),
    },
    {
      title: 'Bid, %',
      dataIndex: 'bid',
      key: 'bid',
      render: (val: number, record: InterBankDeal) => {
        if (record.direction === INTERBANK_LEND) {
          return (
            <div style={{ color: '#6aaf6a' }}>
              {formatters.commasExact2Digits(val)}
            </div>
          );
        } else {
          return <div>{formatters.commasExact2Digits(val)}</div>;
        }
      },
    },
    {
      title: 'Ask, %',
      dataIndex: 'ask',
      key: 'ask',
      render: (val: number, record: InterBankDeal) => {
        if (record.direction === INTERBANK_BORROW) {
          return (
            <div style={{ color: '#6aaf6a' }}>
              {formatters.commasExact2Digits(val)}
            </div>
          );
        } else {
          return <div>{formatters.commasExact2Digits(val)}</div>;
        }
      },
    },
    {
      title: 'Market Maker',
      dataIndex: 'responding_player_name',
      key: 'responding_player_name',
    },
  ];

  const pastInterBankDealsColumns = [
    ...currentInterBankDealsColumns,
    {
      title: `Interest, ${reportingCurrency.symbol}`,
      dataIndex: 'interest',
      key: 'interest',
      render: (val: number) => formatters.commas1Digit(val),
    },
  ];

  const responsesColumns: ColumnType<any>[] = [
    {
      title: 'Bank',
      dataIndex: 'requesting_player_name',
      key: 'requesting_player_name',
      sorter: (a: RFQResponse, b: RFQResponse) => {
        // const stringA = a.responding_player_name ?? '';
        // const stringB = b.responding_player_name ?? '';
        return a.id - b.id;
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: `Amount, ${reportingCurrency.symbol}`,
      dataIndex: 'amount',
      key: 'amount',
      render: (val: number) => formatters.commasNoDigits(val),
    },
    {
      title: 'Bid, %',
      dataIndex: 'bid',
      key: 'bid',
      render: (val: number, record: RFQResponse) => renderQuote(record, 'bid'),
    },
    {
      title: 'Ask, %',
      dataIndex: 'ask',
      key: 'ask',
      render: (val: number, record: RFQResponse) => renderQuote(record, 'ask'),
    },
    {
      title: 'Action / Status',
      dataIndex: 'action',
      key: 'action',
      render: function Actions(_: any, record: RFQResponse) {
        return (
          <div>
            {record.locked_in ? (
              record.status
            ) : (
              <Button
                type="link"
                style={{ marginRight: 5 }}
                onClick={() => {
                  console.log('send response');
                  dispatch(
                    sendRFQResponse({
                      response_id: record.id,
                      bid: quotes[record.id].bid,
                      ask: quotes[record.id].ask,
                    })
                  );
                }}
              >
                Send
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const responsesToActiveRFQColumns: ColumnType<any>[] = [
    {
      title: 'Bank',
      dataIndex: 'responding_player_name',
      key: 'responding_player_name',
      sorter: (a: RFQResponse, b: RFQResponse) => {
        // const stringA = a.responding_player_name ?? '';
        // const stringB = b.responding_player_name ?? '';
        return a.id - b.id;
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: `Amount, ${reportingCurrency.symbol}`,
      dataIndex: 'amount',
      key: 'amount',
      render: (val: number) => formatters.commasNoDigits(val),
    },
    {
      title: 'Bid, %',
      dataIndex: 'bid',
      key: 'bid',
      // render: (val: number) => formatters.percent2Digit(val),
    },
    {
      title: 'Ask, %',
      dataIndex: 'ask',
      key: 'ask',
      // render: (val: number) => formatters.percent2Digit(val),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      // align: 'center',
      render: function Actions(_: any, record: RFQResponse) {
        if (record.status === 'Waiting hit') {
          return (
            <div>
              <Button
                // type="link"
                style={{ marginRight: 5 }}
                onClick={() => {
                  console.log('send response');
                  dispatch(
                    hitRFQResponse({ response_id: record.id, type: LEND })
                  );
                }}
              >
                Lend
              </Button>
              <Button
                // type="link"
                style={{ marginRight: 5 }}
                onClick={() => {
                  console.log('send response');
                  dispatch(
                    hitRFQResponse({ response_id: record.id, type: BORROW })
                  );
                }}
              >
                Borrow
              </Button>
              <Button
                // type="link"
                style={{ marginRight: 5 }}
                onClick={() => {
                  console.log('send response');
                  dispatch(
                    hitRFQResponse({ response_id: record.id, type: NOTHING })
                  );
                }}
              >
                Nothing
              </Button>
            </div>
          );
        } else {
          return <div>{record.status}</div>;
        }
      },
    },
  ];

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`No interbank requests at the moment.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  const noDataResponses = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`No responses yet.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviourResponses = {
    emptyText: noDataResponses,
  };

  const labelColWidth = '130px';

  return (
    <Modal
      visible={visible}
      onOk={hide}
      onCancel={hide}
      title="Interbank Requests"
      width={1800}
    >
      <Row style={{ flexFlow: 'row nowrap' }} gutter={40}>
        <Col span={8}>
          <Typography.Title level={4}>Your RFQ</Typography.Title>
          <Card
            size="small"
            // title={'Launch RFQ'}
            // style={{ width: 400 }}
          >
            <Row gutter={[20, 20]}>
              <Col
                flex={labelColWidth}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                <Typography.Text>Tenor: </Typography.Text>
              </Col>
              <Col>1 Period</Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col
                flex={labelColWidth}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                <Typography.Text>Amount: </Typography.Text>
              </Col>
              <Col>
                <div>
                  <InputNumber
                    style={{ width: 120, marginRight: 10 }}
                    formatter={formatters.commasNoDigits}
                    value={requestAmount}
                    max={100000000}
                    onChange={val => {
                      console.log(val);
                      if (val) {
                        setRequestAmount(val);
                      }
                    }}
                  ></InputNumber>
                  {reportingCurrency.symbol}
                </div>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col
                flex={labelColWidth}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                <Typography.Text>Select banks: </Typography.Text>
              </Col>
              <Col>
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  style={{ width: '270px' }}
                  placeholder="Select banks"
                  value={selectedPlayers}
                  onChange={value => setSelectedPlayers(value as number[])}
                >
                  {other_players.map(player => (
                    <Select.Option key={player.id} value={player.id}>
                      {player.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col
                flex={labelColWidth}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                <Typography.Text>Action: </Typography.Text>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(
                      sendRFQ({
                        amount: requestAmount,
                        player_id: player_id,
                        selected_players: selectedPlayers,
                      })
                    );
                  }}
                  disabled={hasActiveRequest}
                >
                  Send RFQ
                </Button>
              </Col>
            </Row>
          </Card>
          <br />
          {activeRequest !== undefined ? (
            <div>
              <div>
                Your active RFQ:{' '}
                {formatters.commasNoDigits(activeRequest.amount)}{' '}
                {reportingCurrency.symbol}
                <Button
                  onClick={() => {
                    dispatch(cancelRFQ({ request_id: activeRequest.id }));
                  }}
                >
                  Cancel RFQ
                </Button>
              </div>
              <br />
              <Table
                pagination={false}
                bordered
                title={() => 'RFQ responses'}
                columns={responsesToActiveRFQColumns}
                dataSource={lockedInResponsesToYourRFQ}
                locale={noDataBehaviourResponses}
              />
            </div>
          ) : (
            <div>No active RFQ</div>
          )}
        </Col>
        <Col
          span={8}
          style={{
            width: 'min-content',
            display: 'flex',
            flexDirection: 'column',
            // gap: 40,
            borderLeft: '1px solid #eeeeee',
          }}
        >
          <Typography.Title level={4}>Interbank RFQ</Typography.Title>
          <Table
            pagination={false}
            bordered
            dataSource={responsesForActiveRequests}
            columns={responsesColumns}
            locale={noDataBehaviour}
          />
        </Col>
        <Col
          span={8}
          style={{
            width: 'min-content',
            display: 'flex',
            flexDirection: 'column',
            // gap: 40,
            borderLeft: '1px solid #eeeeee',
          }}
        >
          <Typography.Title level={4}>Interbank Deals</Typography.Title>
          <Table
            pagination={false}
            bordered
            dataSource={currentPeriodInterbankDeals}
            columns={currentInterBankDealsColumns}
            locale={noDataBehaviour}
          />
          <br />
          <Collapse accordion>
            <Collapse.Panel key={'past_deals'} header={'Past periods deals'}>
              <Table
                pagination={{ pageSize: 5 }}
                bordered
                dataSource={pastPeriodInterbankDeals}
                columns={pastInterBankDealsColumns}
                locale={noDataBehaviour}
              />
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </Modal>
  );
};

export default RequestsModal;
