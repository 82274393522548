import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, Button, Space, Input, Select, Typography } from 'antd';
import {
  PlusOutlined,
  SaveOutlined,
  RollbackOutlined,
} from '@ant-design/icons';

import {
  fetchAssets,
  listPresets,
  createPreset,
  REGIONS,
  selectors,
  updatePreset,
} from 'state';
import { columnSearchWidget } from 'components';

const PresetsTab: React.FC = () => {
  const dispatch = useDispatch();
  const assets = useSelector(selectors.underlyingAssetsList, () => true);
  const currencies = useSelector(selectors.currencies);
  const presets = useSelector(selectors.presets);

  const [selected, setSelected] = useState<number[]>([]);
  const [presetId, setPresetId] = useState(0);
  const [presetName, setPresetName] = useState('');

  useEffect(() => {
    dispatch(fetchAssets({}));
    dispatch(listPresets({}));
  }, []);

  return (
    <>
      <Space size={15} style={{ marginBottom: 15 }}>
        <Input
          value={presetName}
          onChange={e => {
            setPresetName(e.target.value);
          }}
          placeholder="Preset Name"
        />
        <Button
          type="primary"
          danger
          disabled={
            presetId ? presetName === presets[presetId].name : !presetName
          }
          onClick={() => {
            setPresetName(presetId ? presets[presetId].name : '');
          }}
        >
          <RollbackOutlined />
        </Button>
        <Button
          type="primary"
          disabled={!presetName}
          onClick={() => {
            dispatch(createPreset({ name: presetName }));
          }}
        >
          <PlusOutlined /> New
        </Button>

        <Select
          showSearch
          allowClear
          style={{ width: 200 }}
          placeholder="Select Preset"
          optionFilterProp="children"
          onChange={value => {
            const id = Number(value);
            setPresetId(id);
            setSelected(presets[id]?.assets || []);
            setPresetName(presets[id]?.name || '');
          }}
        >
          {Object.values(presets)
            .sort((a, b) => b.id - a.id)
            .map(preset => {
              const { id, name } = preset;
              return (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              );
            })}
        </Select>
        <Button
          type="primary"
          danger
          disabled={!presetId}
          onClick={() => {
            setSelected(presets[presetId].assets);
          }}
        >
          <RollbackOutlined />
        </Button>
        <Button
          type="primary"
          disabled={!presetId}
          onClick={() => {
            const prevPresent = presets[presetId];
            dispatch(
              updatePreset({
                ...prevPresent,
                name: presetName || prevPresent.name,
                assets: selected,
              })
            );
          }}
        >
          <SaveOutlined /> Save
        </Button>
        {selected.length > 0 && (
          <Typography.Text type={'secondary'}>
            ({selected.length} instruments selected)
          </Typography.Text>
        )}
      </Space>
      <Table
        loading={!assets.length}
        dataSource={assets}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selected,
          onChange: (selectedRowKeys: React.Key[]) => {
            setSelected(selectedRowKeys as number[]);
          },
          getCheckboxProps: () => ({
            disabled: !presetId,
          }),
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
            ...columnSearchWidget({ dataIndex: 'name' }),
          },
          {
            title: 'BBG Ticker',
            dataIndex: 'ticker',
            sorter: (a, b) =>
              a.ticker < b.ticker ? -1 : a.ticker > b.ticker ? 1 : 0,
            ...columnSearchWidget({ dataIndex: 'ticker' }),
          },
          {
            title: 'Variable CCY',
            dataIndex: 'variable_currency',
            render: value => currencies[value].symbol,
            filters: Object.values(currencies).map(currency => {
              return {
                text: currency.symbol,
                value: currency.id,
              };
            }),
            onFilter: (value, record) => {
              return value === record.variable_currency;
            },
          },
          {
            title: 'Base CCY',
            dataIndex: 'base_currency',
            render: value => currencies[value].symbol,
            filters: Object.values(currencies).map(currency => {
              return {
                text: currency.symbol,
                value: currency.id,
              };
            }),
            onFilter: (value, record) => {
              return value === record.base_currency;
            },
          },
          {
            title: 'Region',
            dataIndex: 'region',
            render: (value: keyof typeof REGIONS) => REGIONS[value],
            filters: Object.entries(REGIONS).map(([id, region]) => {
              return {
                text: region,
                value: parseInt(id),
              };
            }),
            onFilter: (value, record) => {
              return value === record.region;
            },
          },
          {
            title: 'Geography',
            dataIndex: 'geography',
            filters: Array.from(
              new Set(assets.map(asset => asset.geography))
            ).map(geography => ({ text: geography, value: geography })),
            onFilter: (value, record) => {
              return value === record.geography;
            },
          },
          {
            title: 'Asset Class',
            dataIndex: 'resourcetype',
            filters: Array.from(
              new Set(assets.map(asset => asset.resourcetype))
            ).map(resourcetype => ({
              text: resourcetype,
              value: resourcetype,
            })),
            onFilter: (value, record) => {
              return value === record.resourcetype;
            },
          },
        ]}
      />
    </>
  );
};

export default PresetsTab;
