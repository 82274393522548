import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Descriptions,
  Divider,
  Popover,
  Typography,
} from 'antd';
import {
  CREATING,
  DELAY,
  FINISHED,
  freezeSession,
  FROZEN,
  PAUSED,
  PLAYING,
  playPauseSession,
  REAL_TIME_SCENARIO,
  RISK_APPROACHES,
  SCENARIO_TYPES,
  selectors,
  SESSION_STATUSES,
  switchHTMPermission,
  utils,
} from 'state';
import { useDispatch, useSelector } from 'react-redux';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { formatStringDate } from '../../../state/app/utils';
import { TextShortener } from 'components';
import { InfoCircleOutlined } from '@ant-design/icons';

const SessionDetails: React.FC = () => {
  const dispatch = useDispatch();
  const session = useSelector(selectors.activeSession);
  const klass = useSelector(selectors.klassById(session?.klass));
  const noAssets = !session.underlying_assets.length;
  const sessionHasHTMassetsOverview = useSelector(
    selectors.sessionHasHTMassetsOverview
  );
  const waiting = useSelector(selectors.waiting);
  const showHTMdetils =
    sessionHasHTMassetsOverview && session.status !== CREATING;

  const getColor = () => {
    //  "success", "processing", "error", "default", "warning"
    switch (session.status) {
      case FINISHED:
        return 'red';
      case PLAYING:
        return 'green';
      case PAUSED:
        return 'orange';
      case CREATING:
      default:
        return 'blue';
    }
  };

  const delayMultipleMinutes =
    session.status === DELAY && session.delay_minutes_left > 1;
  const delayOneMinute =
    session.status === DELAY && session.delay_minutes_left === 1;
  const showFreeze =
    session.scenario_type === REAL_TIME_SCENARIO && session.status === PLAYING;

  return (
    <>
      <Typography.Title level={3}>Session details</Typography.Title>
      <Descriptions size="small" bordered column={1} style={{ minWidth: 450 }}>
        <Descriptions.Item label={<strong>Session</strong>}>
          <strong>{session.id}</strong>
        </Descriptions.Item>
        <Descriptions.Item label="Scenario">
          {SCENARIO_TYPES[session.scenario_type]}
        </Descriptions.Item>
        <Descriptions.Item label="Approach">
          {RISK_APPROACHES[session.risk_approach]}
        </Descriptions.Item>
        <Descriptions.Item label="Short limit">
          {session.allow_short_sell
            ? `${Math.floor((1 + session.short_sell_leverage) * 100)}%`
            : 'Shorting not allowed'}
        </Descriptions.Item>
        <Descriptions.Item label="Class">
          {TextShortener({ text: klass.name, maxLength: 25 })}
        </Descriptions.Item>
        <Descriptions.Item label="Created at">
          {utils.formatStringDate(session.create_date)}
        </Descriptions.Item>
        <Descriptions.Item label="Status" span={2}>
          <Badge
            status={'processing'}
            color={getColor()}
            text={SESSION_STATUSES[session.status]}
          />
          {delayMultipleMinutes && (
            <div>{session.delay_minutes_left} minutes until session starts</div>
          )}
          {delayOneMinute && <div>1 minute until session starts</div>}
          {showHTMdetils && (
            <>
              <Divider type={'vertical'} />
              <Badge
                status={'processing'}
                color={session.allow_htm ? 'green' : 'red'}
                text={
                  session.allow_htm
                    ? 'HTM rebalancing allowed'
                    : 'HTM portfolio locked'
                }
              />
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Actions">
          <Button
            type="primary"
            disabled={noAssets}
            loading={waiting}
            onClick={() => {
              dispatch(
                playPauseSession({
                  id: session.id,
                  current_status: session.status,
                  reload: session.status === CREATING,
                  // reload: false,
                })
              );
            }}
          >
            {session.status === PLAYING && 'Pause'}
            {session.status === PAUSED && 'Play'}
            {session.status === FROZEN && 'Play'}
            {session.status === CREATING && 'Finish Creating'}
          </Button>
          {showFreeze && (
            <span>
              <Button
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={() => {
                  dispatch(freezeSession({ id: session.id }));
                }}
              >
                Freeze
              </Button>
              <Popover
                content={
                  <div style={{ width: 250 }}>
                    Pausing a session halts player trading ability, freezing
                    also prevents price changes and consequently player metrics
                    changes.
                  </div>
                }
              >
                <InfoCircleOutlined />
              </Popover>
            </span>
          )}
          {showHTMdetils && (
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                dispatch(switchHTMPermission({ id: session.id }));
              }}
            >
              Allow/Lock HTM rebalancing
            </Button>
          )}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default SessionDetails;
