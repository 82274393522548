import { createSelector } from 'reselect';
import {
  BUY,
  CASH_APPROACH,
  CREATED,
  FinancialInstrument,
  ForwardContract,
  DURATION_INTERVALS_NAME,
  GROUP_OF_ASSET,
  instrumentTypes,
  OrderExecutionError,
  REGIONS,
  selectors,
  utils,
  Currency,
  RepoContractType,
  CDSContractType,
  TBillContractType,
  RFQRequest,
  RFQResponse,
  InterBankDeal,
  UnderlyingAsset,
} from 'state';
import * as constants from './constants';
import * as types from './types';
import { StoreState } from '../storeState';
import { cashExchange } from './utils';
import { useSelector } from 'react-redux';
import { ONGOING_TBILL_CONTRACT } from './auctions/types';
import { loan_tenors } from './client_money/types';

export const appLoaded = (state: StoreState) => !!state.app;

export const paginatedSessions = (state: StoreState) =>
  state.app.paginated_sessions;
export const sessions = (state: StoreState) =>
  Object.values(state.app.sessions.items);
export const sessionById = (id: number) => (state: StoreState) =>
  state.app.sessions.items[id];
export const sessionInitialAUM = (id: number) => (state: StoreState) => {
  const {
    risk_approach,
    starting_cash_amount,
    risk_weighted_limit,
  } = sessionById(id)(state);
  return risk_approach === CASH_APPROACH
    ? starting_cash_amount
    : risk_weighted_limit;
};
export const selectedPerson = (state: StoreState) =>
  state.app.persons?.items[state.auth.item.selected_person || 0];
export const activeSession = (state: StoreState) => {
  return state.app.sessions.items[state.app.active_session];
};
export const badges = (state: StoreState) => state.app.badges.items;
export const badgesList = (state: StoreState) =>
  Object.values(state.app.badges.items);
export const orders = (state: StoreState) =>
  Object.values(state.app.orders.items);
export const ordersFetching = (state: StoreState) => state.app.orders.fetching;

export const failedExecutions = (state: StoreState) => {
  return Object.values(state.app.orders.items)
    .filter(order => order.status === CREATED)
    .reduce((acc, order) => {
      if (
        order.execution_errors === undefined ||
        order.execution_errors.length === 0
      )
        return acc;
      const error = order.execution_errors[order.execution_errors.length - 1];

      if (error.message !== 'Market is not open!') {
        acc.push(error);
      }

      return acc;
    }, [] as OrderExecutionError[]);
};

export const lastOrder = (state: StoreState) => state.app.orders.last;
export const ordersListByPlayerId = (playerId: number) => (
  state: StoreState
) => {
  const player = state.app.players.items[playerId];
  const instruments = Object.values(player.financial_instruments).map(
    item => item.id
  );
  return orders(state).filter(order =>
    instruments.includes(order.financial_instrument)
  );
};
export const fwdContractsFetching = (player_id: number | undefined) => (
  state: StoreState
) => {
  if (player_id) {
    return state.app.players.items[player_id].forward_contracts.fetching;
  }
  return state.app.player.item.forward_contracts.fetching;
};

export const fwdContracts = (state: StoreState) => {
  return Object.values(state.app.player.item.forward_contracts.items);
};

export const fwdContractById = (id: number | undefined) => (
  state: StoreState
) => {
  if (id) {
    return state.app.player.item.forward_contracts.items[id];
  } else {
    return undefined;
  }
};

export const fwdContractsIndexed = (player_id: number | undefined) => (
  state: StoreState
) => {
  if (player_id) {
    return state.app.players.items[player_id].forward_contracts.items;
  }
  return state.app.player.item.forward_contracts.items;
};
export const exchangeHistory = (state: StoreState) =>
  state.app.player.item.exchange_history;
export const repoContracts = (state: StoreState) => {
  const repo_fins = Object.values(
    state.app.player.item.financial_instruments
  ).filter(fin => fin.repo_maturity_date);
  const repoContracts: RepoContractType[] = [];
  repo_fins.forEach(fin =>
    fin.repo_contracts.forEach(contract => {
      if (contract.settled) {
        repoContracts.push(contract);
      }
    })
  );
  return repoContracts;
};

export const CDSContracts = (state: StoreState) => {
  const cds_fins_with_contracts = Object.values(
    state.app.player.item.financial_instruments
  ).filter(fin => fin.cds_contracts !== null);
  const CDSContracts: CDSContractType[] = [];
  cds_fins_with_contracts.forEach(fin =>
    fin.cds_contracts.forEach(contract => CDSContracts.push(contract))
  );
  return CDSContracts;
};

export const fetchedForwardsByPlayerId = (playerId: number) => (
  state: StoreState
) => {
  return state.app.players.items[playerId].forward_contracts.fetched;
};
export const fetchingForwardsByPlayerId = (playerId: number) => (
  state: StoreState
) => {
  return state.app.players.items[playerId].forward_contracts.fetching;
};

export const TBillsClosedContracts = (state: StoreState) => {
  const tbill_fins_with_contracts = Object.values(
    state.app.player.item.financial_instruments
  ).filter(fin => fin.tbill_contracts !== null);
  const TBillContracts: TBillContractType[] = [];
  tbill_fins_with_contracts.forEach(fin =>
    fin.tbill_contracts.forEach(contract => {
      if (contract.status !== ONGOING_TBILL_CONTRACT) {
        TBillContracts.push(contract);
      }
    })
  );
  return TBillContracts;
};

export const depositsHistory = (state: StoreState) =>
  state.app.player.item.deposits_history;

export const underlyingAssetsApi = (state: StoreState) =>
  state.app.underlying_assets;
export const underlyingAssets = (state: StoreState) =>
  state.app.underlying_assets.items;
export const underlyingAssetsList = (state: StoreState) =>
  Object.values(state.app.underlying_assets.items);
export const underlyingDepositsList = (state: StoreState) =>
  Object.values(state.app.underlying_deposits.items);
export const underlyingAssetById = (id: number) => (state: StoreState) =>
  state.app.underlying_assets.items[id];

export const equityList = (state: StoreState) =>
  Object.values(state.app.equities.items);
export const equitiesApi = (state: StoreState) => state.app.equities;
export const equities = (state: StoreState) => state.app.equities.items;

export const dividends = (state: StoreState) => state.app.dividends.items;
export const dividendsApi = (state: StoreState) => state.app.dividends;

export const currencies = (state: StoreState) => state.app.currencies.items;
export const currenciesList = (state: StoreState) =>
  Object.values(state.app.currencies.items);
export const fxCrossList = (state: StoreState) => {
  return Object.values(state.app.fx_cross.items);
};

export const presets = (state: StoreState) => state.app.presets.items;
export const initialPresets = (state: StoreState) =>
  state.app.initial_presets.items;
export const presetsList = (state: StoreState) =>
  Object.values(state.app.presets.items);

export const indexFunds = (state: StoreState) => state.app.index_funds.items;
export const indexFundsList = (state: StoreState) =>
  Object.values(state.app.index_funds.items);

export const financialInstruments = (state: StoreState) =>
  state.app.player.item.financial_instruments;
export const financialInstrumentsList = (state: StoreState) =>
  Object.values(state.app.player.item.financial_instruments);
export const financialInstrumentsListByPlayerId = (playerId: number) => (
  state: StoreState
) => Object.values(state.app.players.items[playerId].financial_instruments);

export const wallets = (state: StoreState) =>
  Object.values(state.app.player.item.wallets);
export const walletById = (id: number) => (state: StoreState) => {
  return Object.values(state.app.player.item.wallets).find(
    wallet => wallet.id === id
  );
};
export const walletByCurrency = (ccy: types.Currency) => (
  state: StoreState
) => {
  return Object.values(state.app.player.item.wallets).find(
    wallet => wallet.currency === ccy.id
  );
};
export const klasses = (state: StoreState) => state.app.klasses.items;
export const klassesList = (state: StoreState) =>
  Object.values(state.app.klasses.items);

export const klassById = (id: number) => (state: StoreState) =>
  state.app.klasses.items[id];

export const player = (state: StoreState) => state.app.player.item;

export const hasActiveRequest = (state: StoreState) => {
  const requests = state.app.player.item.sent_rfq_requests;
  let hasActiveRequest = false;
  requests.forEach(request => {
    if (request.active) {
      hasActiveRequest = true;
    }
  });
  return hasActiveRequest;
};

export const activeRFQRequest = (state: StoreState): RFQRequest | undefined => {
  const requests = state.app.player.item.sent_rfq_requests;
  let activeRequest: RFQRequest | undefined = undefined;
  // console.log('requests', requests);
  requests.forEach(request => {
    // console.log(`request ${request.id} active: `, request.active);
    if (request.active) {
      activeRequest = request;
    }
  });
  return activeRequest;
};

export const playerSentRFQRequests = (state: StoreState) => {
  return state.app.player.item.sent_rfq_requests;
};

export const responsesForActiveRequests = (
  state: StoreState
): RFQResponse[] => {
  const responses = state.app.player.item.rfq_responses;
  const activeResponses: RFQResponse[] = [];
  responses.forEach(response => {
    if (response.request_active) {
      activeResponses.push(response);
    }
  });
  return activeResponses;
};

export const lockedInResponsesToYourRFQ = (
  state: StoreState
): RFQResponse[] => {
  const yourActiveRFQ = activeRFQRequest(state);
  const lockedInResponses: RFQResponse[] = [];
  const rfqResponses = yourActiveRFQ?.rfq_responses;
  if (rfqResponses && rfqResponses.length > 0) {
    rfqResponses.forEach(rfqResponse => {
      if (rfqResponse.locked_in) {
        lockedInResponses.push(rfqResponse);
      }
    });
  }
  return lockedInResponses;
};

export const currentPeriodInterbankDeals = (state: StoreState) => {
  const interbankDeals = state.app.player.item.interbank_deals;
  const activeDeals: InterBankDeal[] = [];
  interbankDeals.forEach(deal => {
    if (deal.active_interbank_deal) {
      activeDeals.push(deal);
    }
  });
  return activeDeals;
};

export const pastPeriodInterbankDeals = (state: StoreState) => {
  const interbankDeals = state.app.player.item.interbank_deals;
  const pastDeals: InterBankDeal[] = [];
  interbankDeals.forEach(deal => {
    if (!deal.active_interbank_deal) {
      pastDeals.push(deal);
    }
  });
  return pastDeals;
};

export const metrics = (state: StoreState) => state.app.player.item.metrics;

export const assetByTicker = (ticker: string) => (state: StoreState) =>
  Object.values(state.app.underlying_assets.items).find(
    item => item.ticker === ticker
  );

export const currencyById = (id: number) => (state: StoreState) =>
  state.app.currencies.items[id];

export const playerBadges = (state: StoreState) => state.app.player.item.badges;
export const playerById = (id: number) => (state: StoreState) =>
  state.app.players.items[id];

export const individualReport = (player_id: number) => (state: StoreState) => {
  return state.app.individualReports[player_id];
};

export const financialInstrumentById = (id: number, player_id?: number) => (
  state: StoreState
) =>
  player_id
    ? state.app.players.items[player_id].financial_instruments[id]
    : state.app.player.item.financial_instruments[id];

export const financialInstrumentById2 = (id: number, player_id?: number) => (
  state: StoreState
) => {
  if (id !== undefined) {
    return player_id
      ? state.app.players.items[player_id].financial_instruments[id]
      : state.app.player.item.financial_instruments[id];
  } else {
    return undefined;
  }
};

export const OdaOfForwardContract = (id: number) => (state: StoreState) => {
  const contract = state.app.player.item.forward_contracts.items[id];
  const oda_id = contract.oda;
  if (oda_id !== null) {
    return state.app.player.item.forward_oda.items[oda_id];
  }
};

export const forwardContractsOfInstrumentList = (
  id: number,
  player_id?: number
) => (state: StoreState) => {
  if (player_id) {
    // called from trainer view
    const contract_ids =
      state.app.players.items[player_id].financial_instruments[id]
        .forward_contracts;
    const contractObjectsOfInstrumentList: ForwardContract[] = Object.values(
      state.app.players.items[player_id].forward_contracts.items
    ).filter((contract: ForwardContract) => contract_ids.includes(contract.id));
    return contractObjectsOfInstrumentList;
  } else {
    // called from player view
    const contract_ids =
      state.app.player.item.financial_instruments[id].forward_contracts;
    const contractObjectsOfInstrumentList: ForwardContract[] = Object.values(
      state.app.player.item.forward_contracts.items
    ).filter((contract: ForwardContract) => contract_ids.includes(contract.id));
    return contractObjectsOfInstrumentList;
  }
};

export const CDSContractsOfInstrument = (id: number) => (state: StoreState) =>
  state.app.player.item.financial_instruments[id].cds_contracts;

export const TBillContractsOfInstrument = (id: number) => (state: StoreState) =>
  state.app.player.item.financial_instruments[id].tbill_contracts;

export const RepoContractsOfInstrument = (id: number) => (state: StoreState) =>
  state.app.player.item.financial_instruments[id].repo_contracts;

export const financialInstrumentByUnderlingAsset = (id: number) => (
  state: StoreState
) => {
  const instruments = state.app.player.item.financial_instruments;
  if (!instruments) return;

  return Object.values(instruments).find(item => item.underlying_asset === id);
};

export const underlyingAssetOfInstrument = (
  instrument: types.FinancialInstrument
) => (state: StoreState) =>
  state.app.underlying_assets.items[instrument.underlying_asset];

export const underlyingAssetOfInstrument2 = (
  instrument: types.FinancialInstrument | undefined
) => (state: StoreState) => {
  if (instrument !== undefined) {
    return state.app.underlying_assets.items[instrument.underlying_asset];
  } else {
    return undefined;
  }
};

export const odaOfInstrumentCreateSelector = (instrument_id: number) =>
  createSelector(
    [financialInstrumentById(instrument_id), state => state],
    (instrument, state) => {
      const oda = instrument.oda;
      if (!oda) return;

      const orders = state.app.orders.items;
      const { stop_loss, take_profit } = oda;

      return { stopLoss: orders[stop_loss], takeProfit: orders[take_profit] };
    }
  );
export const sessionHasHTMBonds = (state: StoreState) => {
  const underlyingAssets = Object.values(state.app.underlying_assets.items);
  const htmAssets = underlyingAssets.filter(
    asset => asset.resourcetype === 'HTMBond'
  );
  const hasHTMassets = htmAssets.length !== 0;
  return hasHTMassets;
};

export const sessionHasHTMassetsOverview = (state: StoreState) => {
  const session = state.app.sessions.items[state.app.active_session];
  const sessionAssets = session.underlying_assets;
  const htmAssets = sessionAssets.filter(asset_id => {
    const resourceType =
      state.app.underlying_assets.items[asset_id]?.resourcetype;
    if (resourceType === undefined) {
      return false;
    }

    return resourceType === 'HTMBond';
  });
  return htmAssets.length !== 0;
};

export const sessionHasAFSBonds = (state: StoreState) => {
  const underlyingAssets = Object.values(state.app.underlying_assets.items);
  const htmAssets = underlyingAssets.filter(
    asset => asset.resourcetype === 'AFSBond'
  );
  const hasAFSassets = htmAssets.length !== 0;
  return hasAFSassets;
};

export const sessionHasAFSassetsOverview = (state: StoreState) => {
  const session = state.app.sessions.items[state.app.active_session];
  const sessionAssets = session.underlying_assets;
  const htmAssets = sessionAssets.filter(asset_id => {
    const resourceType =
      state.app.underlying_assets.items[asset_id]?.resourcetype;
    if (resourceType === undefined) {
      return false;
    }
    return resourceType === 'AFSBond';
  });
  return htmAssets.length !== 0;
};

export const sessionHasRepos = (state: StoreState) => {
  const underlyingAssets = Object.values(state.app.underlying_assets.items);
  const repoAssets = underlyingAssets.filter(
    asset => asset.resourcetype === 'Repo'
  );
  const hasRepos = repoAssets.length !== 0;
  return hasRepos;
};

export const sessionHasCDS = (state: StoreState) => {
  const underlyingAssets = Object.values(state.app.underlying_assets.items);
  const CDSAssets = underlyingAssets.filter(
    asset => asset.resourcetype === 'CDS'
  );
  const hasCDS = CDSAssets.length !== 0;
  return hasCDS;
};
// TODO: these could be one function
export const sessionHasFWD = (state: StoreState) => {
  const underlyingAssets = Object.values(state.app.underlying_assets.items);
  const FWDAssets = underlyingAssets.filter(
    asset => asset.resourcetype === 'FxForwards'
  );
  const hasFWD = FWDAssets.length !== 0;
  return hasFWD;
};
export const sessionHasTBills = (state: StoreState) => {
  const underlyingAssets = Object.values(state.app.underlying_assets.items);
  const TBillsAssets = underlyingAssets.filter(
    asset => asset.resourcetype === 'TBill'
  );
  const hasTBills = TBillsAssets.length !== 0;
  return hasTBills;
};

export const bondsValueDistribution = (state: StoreState) => {
  const assetList = Object.values(state.app.underlying_assets.items);
  const currencyList = Object.values(state.app.currencies.items);
  const indexedCurrencies = currencies(state);
  const underlyingAssets = state.app.underlying_assets.items;
  const bonds = Object.values(state.app.player.item.financial_instruments)
    .filter(item =>
      underlyingAssets[item.underlying_asset].resourcetype.endsWith('Bond')
    )
    .filter(item => item.amount);
  const session = activeSession(state);
  const reportingCurrency = indexedCurrencies[session.reporting_currency];

  const intervals = [0, 2, 5, 10, 20]; // 20+
  const data = intervals.reduce((acc, value) => {
    acc[value] = [];
    return acc;
  }, {} as { [key: number]: number[] });

  bonds.forEach(item => {
    const { duration } = underlyingAssets[item.underlying_asset];
    const underlying_asset = underlyingAssets[item.underlying_asset];

    const reportingValue = utils.reportingValue(
      item,
      indexedCurrencies[underlying_asset.base_currency],
      underlyingAssets[item.underlying_asset].resourcetype,
      assetList,
      currencyList,
      reportingCurrency
    );

    let interval = -1;
    for (const i of intervals) {
      if (duration < i) {
        break;
      }
      interval = i;
    }

    if (interval === -1) {
      return;
    }

    data[interval].push(reportingValue);
  });

  const categories = intervals.map((interval, index) => {
    return index !== intervals.length - 1
      ? `${interval} - ${intervals[index + 1]}`
      : `${interval}+`;
  });

  return {
    series: [
      {
        data: intervals.map(interval => {
          return data[interval].reduce((s, n) => s + n, 0);
        }),
        name: 'Bonds',
      },
    ],
    categories,
  };
};

export const cashTacticalAssetAllocation = (state: StoreState) =>
  state.app.player.item.tactical_asset_allocations.find(
    item => item.asset_class === constants.CASH
  );

const computeDuration = (
  state: StoreState,
  filter: (item: types.FinancialInstrument) => boolean
) => {
  const underlyingAssets = state.app.underlying_assets.items;
  const values = Object.values(state.app.player.item.financial_instruments)
    .filter(item =>
      underlyingAssets[item.underlying_asset].resourcetype.endsWith('Bond')
    )
    .filter(item => item.amount)
    .filter(filter)
    .reduce(
      (acc, item) => {
        const underlyingAsset = underlyingAssets[item.underlying_asset];
        const marketValue = (Math.abs(item.amount) * underlyingAsset.bid) / 100;
        return [
          acc[0] + underlyingAsset.duration * marketValue,
          acc[1] + marketValue,
        ];
      },
      [0, 0]
    );

  if (!values[1]) return 0;
  return values[0] / values[1];
};

export const portfolioDuration = (state: StoreState) => {
  return computeDuration(state, () => true);
};

export const portfolioDurationShort = (state: StoreState) => {
  return computeDuration(state, item => item.amount < 0);
};

export const portfolioDurationLong = (state: StoreState) => {
  return computeDuration(state, item => item.amount > 0);
};

export const portfolioDv01 = (state: StoreState) => {
  const underlyingAssets = state.app.underlying_assets.items;
  return Object.values(state.app.player.item.financial_instruments)
    .filter(item =>
      underlyingAssets[item.underlying_asset].resourcetype.endsWith('Bond')
    )
    .filter(item => item.amount)
    .reduce(
      (a, b) =>
        a + (-b.amount / 10000) * underlyingAssets[b.underlying_asset].bpv,
      0
    );
};

export const playersList = (state: StoreState) =>
  Object.values(state.app.players.items);
export const activePlayers = (state: StoreState) =>
  playersList(state)
    .filter(player => player.last_portfolio_return)
    .sort((a, b) => (a.name < b.name ? -1 : 1));
export const playersListBySessionId = (id: number) => (state: StoreState) =>
  Object.values(state.app.players.items).filter(item => item.session == id);

export const playersAllocations = (state: StoreState) =>
  Object.values(state.app.players.items)
    .filter(
      player =>
        !player.tactical_asset_allocations?.every(
          allocation => allocation.percent === 0
        )
    )
    .map(player => {
      const allocations = player.tactical_asset_allocations?.reduce(
        (acc, p) => {
          const group = GROUP_OF_ASSET[p.asset_class];
          const prev = acc[group] ?? 0;
          return { ...acc, [group]: prev + p.percent };
        },
        {} as { [key: number]: number }
      );

      return {
        ...allocations,
        ...player,
      };
    });

export const persons = (state: StoreState) => state.app.persons?.items;
export const personsList = (state: StoreState) =>
  Object.values(state.app.persons?.items || {}).filter(
    p => !p.name.toLowerCase().includes('admin')
  );
export const personsListAdmin = (state: StoreState) =>
  Object.values(state.app.persons?.items || {});

export const waiting = (state: any) => state.app.waiting;
export const error = (state: any) => state.app.error;
export const lastAction = (state: any) => state.app.lastAction;

export const newsFetching = (state: StoreState) => state.app.news.fetching;
export const newsList = (state: StoreState) =>
  Object.values(state.app.news.items).sort((a, b) =>
    a.timestamp < b.timestamp ? 1 : -1
  );
export const newsById = (id: number) => (state: StoreState) =>
  state.app.news.items[id];
export const lastNews = (state: StoreState) => state.app.news.last;

export const playersCount = (state: StoreState) => playersList(state).length;
export const onlinePlayersCount = (state: StoreState) =>
  playersList(state).reduce((acc, pl) => acc + +pl.online, 0);

export const initialPortfolioStatus = (state: StoreState) =>
  state.app.initialPortfolioStatus;

export const remainingReportingHedge = (state: StoreState) =>
  state.app.player.item.remaining_reporting_absolute_hedge_amount;

export const remainingReportingAbsoluteBondLeverage = (state: StoreState) =>
  state.app.player.item.remaining_reporting_absolute_bond_leverage;

export const hedgeAmountOfInstrument = (
  instrument: types.FinancialInstrument
) => (state: StoreState) => {
  const orders = Object.values(state.app.orders.items).filter(
    od => od.hedge_for === instrument.id
  );

  let result = 0;
  orders.forEach(order => {
    const sign = order.transaction === BUY ? 1 : -1;

    result += order.quantity * sign;
  });

  return result;
};

export const playableInstrumentTypes = (state: StoreState) => {
  const instruments = Object.values(
    state.app.player.item.financial_instruments
  );
  const assets = underlyingAssets(state);

  return instrumentTypes.filter(instrType => {
    const index = instruments.findIndex(instrument => {
      const resourcetype = assets[instrument.underlying_asset].resourcetype;
      return resourcetype === instrType.type;
    });

    return index !== -1;
  });
};
export const sessionAssets = () => (state: StoreState) => {
  const sessionAssetsIDs =
    state.app.sessions.items[state.app.active_session].underlying_assets;
  const assets = Object.values(state.app.underlying_assets.items);
  return assets.filter(asset => sessionAssetsIDs.includes(asset.id));
};
export const playableAssetClasses = () => (state: StoreState) => {
  const assets = sessionAssets()(state);
  const playableAssetClasses = Array.from(
    new Set(
      assets.map(asset => {
        // const asset = assets[instrument.underlying_asset];
        const resourceType = asset.resourcetype;
        const instrumentType = instrumentTypes.find(
          instrumentType => instrumentType.type === resourceType
        )!;
        // console.log('instrumentType', instrumentType);
        return instrumentType.assetClass;
      })
    )
  );
  const session = state.app.sessions.items[state.app.active_session];
  if (session.risk_approach === CASH_APPROACH) {
    playableAssetClasses.push(0);
  }
  return playableAssetClasses;
};

export const pnlByAssetClass = (instruments: types.FinancialInstrument[]) => (
  state: StoreState
) => {
  const assets = underlyingAssets(state);

  const pnlObject: {
    [assetClass: number]: {
      pnl: number;
    } & Omit<types.TacticalAssetAllocation, 'id'>;
  } = {};

  const { tactical_asset_allocations: allocations } = player(state);

  // filter playable asset classes (so you don't display equity and crypto allocs. if players does not have those fins.)
  const playableAssetClasses_ = playableAssetClasses()(state);
  const filteredAllocations = allocations.filter(allocation =>
    playableAssetClasses_.includes(allocation.asset_class)
  );

  filteredAllocations.forEach(({ id, ...allocation }) => {
    pnlObject[allocation.asset_class] = {
      pnl: 0,
      ...allocation,
    };
  });

  instruments.forEach(instrument => {
    const {
      underlying_asset,
      reporting_unrealized_pnl,
      reporting_realized_pnl,
    } = instrument;

    const resourcetype = assets[underlying_asset].resourcetype;
    const assetClass = instrumentTypes.find(t => t.type === resourcetype)
      ?.assetClass;
    if (assetClass === undefined) {
      return;
    }
    let pnl = reporting_unrealized_pnl + reporting_realized_pnl;
    if (resourcetype === 'HTMBond') {
      pnl = reporting_realized_pnl;
    }
    pnlObject[assetClass].pnl += pnl;
  });

  return Object.values(pnlObject);
};

export const pnlByAssetGroup = (instruments: types.FinancialInstrument[]) => (
  state: StoreState
) => {
  const assets = underlyingAssets(state);
  const { tactical_asset_allocations: allocations } = player(state);

  const pnlObject: {
    [assetGroup: number]: {
      pnl: number;
    } & Omit<types.TacticalAssetAllocation, 'id' | 'asset_class'>;
  } = {};

  // filter playable asset classes (so you don't display equity and crypto allocs. if players does not have those fins.)
  const playableAssetClasses_ = playableAssetClasses()(state);
  const filteredAllocations = allocations.filter(allocation =>
    playableAssetClasses_.includes(allocation.asset_class)
  );

  filteredAllocations.forEach(allocation => {
    const assetGroup = GROUP_OF_ASSET[allocation.asset_class];
    const value = pnlObject[assetGroup] ?? {
      pnl: 0,
      long: 0,
      short: 0,
      total: 0,
      percent: 0,
      percent_long: 0,
      percent_short: 0,
    };

    pnlObject[assetGroup] = {
      pnl: 0,
      long: value.long + allocation.long,
      short: value.short + allocation.short,
      total: value.total + allocation.total,
      percent: value.percent + allocation.percent,
      percent_long: value.percent_long + allocation.percent_long,
      percent_short: value.percent_short + allocation.percent_short,
    };
  });

  instruments.forEach(instrument => {
    const {
      underlying_asset,
      reporting_unrealized_pnl,
      reporting_realized_pnl,
    } = instrument;

    const resourcetype = assets[underlying_asset].resourcetype;
    const assetClass = instrumentTypes.find(t => t.type === resourcetype)
      ?.assetClass;

    if (assetClass === undefined) {
      return;
    }
    const assetGroup = GROUP_OF_ASSET[assetClass];
    if (assetGroup == undefined) {
      return;
    }

    const pnl = reporting_unrealized_pnl + reporting_realized_pnl;
    pnlObject[assetGroup].pnl += pnl;
  });

  return Object.entries(pnlObject).map(entry => {
    const assetGroup = entry[0];
    const data = entry[1];
    return { assetGroup: Number(assetGroup), ...data };
  });
};

export const HTMDurationBreakdown = () => (state: StoreState) => {
  const instruments = Object.values(
    state.app.player.item.financial_instruments
  );
  const htmInstruments = instruments.filter(instrument => {
    const assetId = instrument.underlying_asset;
    const asset = state.app.underlying_assets.items[assetId];
    return asset.resourcetype === 'HTMBond';
  });
  const series: { x: string; y: number }[] = [];

  // these are the breakdown intervals: 0-1, 1-2, 2-3, ... , 9-10, 10+
  for (let i = 1; i <= 11; i++) {
    let lower = 0;
    let upper = 0;
    if (i !== 11) {
      lower = Number(DURATION_INTERVALS_NAME[i].split('-')[0]);
      upper = Number(DURATION_INTERVALS_NAME[i].split('-')[1]);
    } else {
      lower = 10;
      upper = 100;
    }

    let amountSum = 0;
    let unweightedSum = 0;
    htmInstruments.forEach(instrument => {
      if (
        instrument.htm_afs_duration < lower ||
        instrument.htm_afs_duration >= upper
      ) {
        return;
      }
      amountSum += instrument.amount;
      unweightedSum += instrument.amount * instrument.htm_afs_duration;
    });
    let weightedDuration = 0;
    if (amountSum !== 0) {
      weightedDuration = unweightedSum / amountSum;
    }

    const series_group = {
      x: DURATION_INTERVALS_NAME[i], // interval
      y: weightedDuration, // duration
    };
    series.push(series_group);
  }
  return series;
};

export const AFSDurationBreakdown = () => (state: StoreState) => {
  const instruments = Object.values(
    state.app.player.item.financial_instruments
  );
  // const currencies = selectors.currencies;
  const currencies = state.app.currencies.items;
  const assets = state.app.underlying_assets.items;
  const htmInstruments = instruments.filter(instrument => {
    const assetId = instrument.underlying_asset;
    const asset = state.app.underlying_assets.items[assetId];
    return asset.resourcetype === 'AFSBond';
  });
  const series: { x: string; y: number }[] = [];

  // these are the breakdown intervals: 0-1, 1-2, 2-3, ... , 9-10, 10+
  for (let i = 1; i <= 5; i++) {
    // console.log('-- i: ', i);
    let lower = 0;
    let upper = 0;
    if (i !== 5) {
      lower = Number(DURATION_INTERVALS_NAME[i].split('-')[0]);
      upper = Number(DURATION_INTERVALS_NAME[i].split('-')[1]);
    } else {
      lower = 31;
      upper = 1000;
    }

    let amountSum = 0;
    let unweightedSum = 0;
    htmInstruments.forEach(instrument => {
      if (
        instrument.htm_afs_duration < lower ||
        instrument.htm_afs_duration > upper
      ) {
        return;
      }

      // console.log(instrument.htm_afs_duration);
      const reportingAmount2 = utils.cashExchange(
        assets[instrument.underlying_asset].base_currency,
        state.app.sessions.items[state.app.active_session].reporting_currency,
        instrument.amount,
        0,
        Object.values(state.app.underlying_assets.items),
        Object.values(state.app.currencies.items)
      );
      // amountSum += instrument.amount;
      amountSum += reportingAmount2;
      unweightedSum += instrument.amount * instrument.htm_afs_duration;
    });
    let weightedDuration = 0;
    if (amountSum !== 0) {
      weightedDuration = unweightedSum / amountSum;
    }

    const series_group = {
      x: DURATION_INTERVALS_NAME[i], // interval
      // y: weightedDuration, // duration
      y: amountSum, // holding
    };
    series.push(series_group);
  }
  return series;
};

export const portfolioPnl = (instruments?: types.FinancialInstrument[]) => (
  state: StoreState
) => {
  instruments = instruments || financialInstrumentsList(state);

  let sum = 0;

  instruments.forEach(instrument => {
    const asset =
      state.app.underlying_assets.items[instrument.underlying_asset];
    if (asset.resourcetype === 'HTMBond') {
      sum += instrument.reporting_realized_pnl;
    } else {
      sum +=
        instrument.reporting_unrealized_pnl + instrument.reporting_realized_pnl;
    }
  });

  return sum;
};

export const hasEquitiesGroup = () => (state: StoreState) => {
  const sessionAssets_ = sessionAssets()(state);
  const hasEquity =
    sessionAssets_.filter(asset => asset.resourcetype === 'Equity').length > 0;
  const hasETFEquities =
    sessionAssets_.filter(asset => asset.resourcetype === 'ETFEquities')
      .length > 0;
  return hasEquity || hasETFEquities;
};

export const hasBondsGroup = () => (state: StoreState) => {
  const sessionAssets_ = sessionAssets()(state);
  const hasGovtBonds =
    sessionAssets_.filter(asset => asset.resourcetype === 'GovtBond').length >
    0;
  const hasETFBonds =
    sessionAssets_.filter(asset => asset.resourcetype === 'ETFBonds').length >
    0;
  const hasCorporateBonds =
    sessionAssets_.filter(asset => asset.resourcetype === 'CorporateBond')
      .length > 0;
  return hasGovtBonds || hasETFBonds || hasCorporateBonds;
};

export const hasAlternativesGroup = () => (state: StoreState) => {
  const sessionAssets_ = sessionAssets()(state);
  const hasMetals =
    sessionAssets_.filter(asset => asset.resourcetype === 'Metal').length > 0;
  const hasETFCommodities =
    sessionAssets_.filter(asset => asset.resourcetype === 'ETFCommodities')
      .length > 0;
  return hasMetals || hasETFCommodities;
};

export const hasCryptoGroup = () => (state: StoreState) => {
  const sessionAssets_ = sessionAssets()(state);
  return (
    sessionAssets_.filter(asset => asset.resourcetype === 'Crypto').length > 0
  );
};

export const hasHTMGroup = () => (state: StoreState) => {
  const sessionAssets_ = sessionAssets()(state);
  return (
    sessionAssets_.filter(asset => asset.resourcetype === 'HTMBond').length > 0
  );
};

export const hasAFSGroup = () => (state: StoreState) => {
  const sessionAssets_ = sessionAssets()(state);
  return (
    sessionAssets_.filter(asset => asset.resourcetype === 'AFSBond').length > 0
  );
};

type PnlByRegion = (
  instruments: types.FinancialInstrument[]
) => (
  state: StoreState
) => {
  region: keyof typeof REGIONS;
  pnl: number;
  percent: number;
  long: number;
  short: number;
}[];

export const pnlByRegion: PnlByRegion = instruments => state => {
  const assets = underlyingAssets(state);

  const playedRegions = new Set<number>();
  instruments.forEach(instrument => {
    const region = assets[instrument.underlying_asset]?.region;
    if (!region) {
      return;
    }
    playedRegions.add(region);
  });

  const pnlObject: { [key: string]: number } = {};
  playedRegions.forEach(region => (pnlObject[region] = 0));

  instruments.forEach(instrument => {
    const {
      underlying_asset,
      reporting_unrealized_pnl,
      reporting_realized_pnl,
    } = instrument;
    const asset = assets[underlying_asset];
    const region = assets[underlying_asset]?.region;
    if (!region) {
      return;
    }
    if (asset.resourcetype === 'HTMBond') {
      pnlObject[region] += reporting_realized_pnl;
    } else {
      pnlObject[region] += reporting_unrealized_pnl + reporting_realized_pnl;
    }
  });

  const { region_allocations: allocations } = player(state);

  return Object.entries(pnlObject).map(entry => {
    const region = Number(entry[0]) as keyof typeof REGIONS;
    const pnl = entry[1];

    let percent = 0;
    let long = 0;
    let short = 0;
    const regionAllocation = allocations.find(a => a.region === region);
    if (regionAllocation) {
      const allocations = regionAllocation.geography_allocations;
      percent = allocations.reduce((acc, a) => acc + a.percent, 0);
      long = allocations.reduce((acc, a) => acc + a.long, 0);
      short = allocations.reduce((acc, a) => acc + a.short, 0);
    }

    return {
      region,
      pnl,
      percent,
      long,
      short,
    };
  });
};

export const equityPnlByIndustry = (state: StoreState) => {
  const assets = underlyingAssets(state);
  const instruments = financialInstrumentsList(state);
  const indexedCurrencies = currencies(state);
  const session = activeSession(state);
  const reportingCurrency = indexedCurrencies[session.reporting_currency];

  const equities = instruments.filter(i => {
    const asset = assets[i.underlying_asset];
    if (!asset) {
      return false;
    }
    return ['Equity'].includes(asset.resourcetype);
  });

  const indexedIndustries: {
    [key: string]: {
      pnl: number;
      short: number;
      long: number;
      value: number;
      allocation: number;
    };
  } = {};

  const getDollarValue = (instrument: types.FinancialInstrument) => {
    const asset = assets[instrument.underlying_asset];
    const ccy = indexedCurrencies[asset.base_currency];

    return utils.reportingValue(
      instrument,
      ccy,
      asset.resourcetype,
      Object.values(assets),
      Object.values(indexedCurrencies),
      reportingCurrency
    );
  };

  const initialAum = sessionInitialAUM(state.app.active_session)(state);

  equities.forEach(instrument => {
    const {
      underlying_asset,
      reporting_unrealized_pnl,
      reporting_realized_pnl,
    } = instrument;

    const industry = assets[underlying_asset]?.industry ?? 'Other';
    if (indexedIndustries[industry] === undefined) {
      indexedIndustries[industry] = {
        pnl: 0,
        long: 0,
        short: 0,
        value: 0,
        allocation: 0,
      };
    }

    const pnl = reporting_unrealized_pnl + reporting_realized_pnl;
    indexedIndustries[industry].pnl += pnl;

    const dollarValue = getDollarValue(instrument);
    const valueKey = dollarValue >= 0 ? 'long' : 'short';
    indexedIndustries[industry][valueKey] += dollarValue;
    indexedIndustries[industry].value += dollarValue;
  });

  const industryData = Object.entries(indexedIndustries)
    .map(([industry, { value, ...data }]) => {
      return {
        ...data,
        industry,
        value,
        allocation: value / initialAum,
      };
    })
    .filter(i => i.value !== 0 || i.pnl !== 0);

  if (industryData.length <= 10) {
    return industryData;
  }

  let other = undefined;
  const otherIndex = industryData.findIndex(i => i.industry === 'Other');
  if (otherIndex !== -1) {
    other = industryData.splice(otherIndex, 1)[0];
  }

  industryData.sort((a, b) => b.value - a.value);

  const topTen = industryData.splice(0, 10);

  if (!other) {
    other = {
      industry: 'Other',
      pnl: 0,
      long: 0,
      short: 0,
      value: 0,
      allocation: 0,
    };
  }

  other = industryData.reduce((acc, i) => {
    acc.pnl += i.pnl;
    acc.long += i.long;
    acc.short += i.short;
    acc.value += i.value;
    acc.allocation += i.allocation;
    return acc;
  }, other);

  topTen.push(other);

  return topTen;
};

export const equityPnlBySector = (state: StoreState) => {
  const assets = underlyingAssets(state);
  const instruments = financialInstrumentsList(state);
  const indexedCurrencies = currencies(state);
  const session = activeSession(state);
  const reportingCurrency = indexedCurrencies[session.reporting_currency];

  const equities = instruments.filter(i => {
    const asset = assets[i.underlying_asset];
    if (!asset) {
      return false;
    }
    return ['Equity'].includes(asset.resourcetype);
  });

  const indexedSectors: {
    [key: string]: {
      pnl: number;
      short: number;
      long: number;
      value: number;
      allocation: number;
    };
  } = {};

  const getDollarValue = (instrument: types.FinancialInstrument) => {
    const asset = assets[instrument.underlying_asset];
    const ccy = indexedCurrencies[asset.base_currency];

    return utils.reportingValue(
      instrument,
      ccy,
      asset.resourcetype,
      Object.values(assets),
      Object.values(indexedCurrencies),
      reportingCurrency
    );
  };

  const initialAum = sessionInitialAUM(state.app.active_session)(state);

  equities.forEach(instrument => {
    const {
      underlying_asset,
      reporting_unrealized_pnl,
      reporting_realized_pnl,
    } = instrument;

    const sector = assets[underlying_asset]?.sector ?? 'Other';
    if (indexedSectors[sector] === undefined) {
      indexedSectors[sector] = {
        pnl: 0,
        long: 0,
        short: 0,
        value: 0,
        allocation: 0,
      };
    }

    const pnl = reporting_unrealized_pnl + reporting_realized_pnl;
    indexedSectors[sector].pnl += pnl;

    const dollarValue = getDollarValue(instrument);
    const valueKey = dollarValue >= 0 ? 'long' : 'short';
    indexedSectors[sector][valueKey] += dollarValue;
    indexedSectors[sector].value += dollarValue;
  });

  const sectorData = Object.entries(indexedSectors)
    .map(([sector, { value, ...data }]) => {
      return {
        ...data,
        sector,
        value,
        allocation: value / initialAum,
      };
    })
    .filter(s => s.value !== 0 || s.pnl !== 0);

  if (sectorData.length <= 10) {
    return sectorData;
  }

  let other = undefined;
  const otherIndex = sectorData.findIndex(i => i.sector === 'Other');
  if (otherIndex !== -1) {
    other = sectorData.splice(otherIndex, 1)[0];
  }

  sectorData.sort((a, b) => b.value - a.value);

  const topTen = sectorData.splice(0, 10);

  if (!other) {
    other = {
      sector: 'Other',
      pnl: 0,
      long: 0,
      short: 0,
      value: 0,
      allocation: 0,
    };
  }

  other = sectorData.reduce((acc, i) => {
    acc.pnl += i.pnl;
    acc.long += i.long;
    acc.short += i.short;
    acc.value += i.value;
    acc.allocation += i.allocation;
    return acc;
  }, other);

  topTen.push(other);

  return topTen;
};

export const playableCurrencies = (
  instruments: types.FinancialInstrument[]
) => (state: StoreState) => {
  //TODO: why not pull instruments from store?
  const assets = underlyingAssets(state);
  const allCurrencies = currencies(state);

  // const fxInstruments = instruments.filter(
  //   instrument => assets[instrument.underlying_asset].resourcetype === 'FX'
  // );

  const symbols = new Set();

  instruments.forEach(instrument => {
    const asset = assets[instrument.underlying_asset];
    const baseSymbol = allCurrencies[asset.base_currency]?.symbol;
    const varSymbol = allCurrencies[asset.variable_currency]?.symbol;

    if (baseSymbol) {
      symbols.add(baseSymbol);
    }
    if (varSymbol) {
      symbols.add(varSymbol);
    }
  });

  return Array.from(symbols) as string[];
};
export const playableCurrenciesByAssets = (state: StoreState) => {
  //TODO: why not pull instruments from store?
  const assets = underlyingAssetsList(state);
  const allCurrencies = currencies(state);

  // const fxInstruments = instruments.filter(
  //   instrument => assets[instrument.underlying_asset].resourcetype === 'FX'
  // );

  const ccys = new Set();

  assets.forEach(asset => {
    // const asset = assets[instrument.underlying_asset];
    const base = allCurrencies[asset.base_currency];
    const variable = allCurrencies[asset.variable_currency];

    if (base) {
      ccys.add(base);
    }
    if (variable) {
      ccys.add(variable);
    }
  });

  // return Array.from(symbols) as string[];
  return Array.from(ccys) as Currency[];
};

export const trainersList = (state: StoreState) =>
  Object.values(state.app.trainers.items);
export const trainers = (state: StoreState) => state.app.trainers.items;
export const trainersApi = (state: StoreState) => state.app.trainers;

export const selfInsightsReports = (state: StoreState) => {
  const reportIds = state.app.player.item.insights_reports ?? [];
  const reports = state.app.insightsReports.items;
  return reportIds.map(id => reports[id]);
};

export const creatingInsightsReport = (state: StoreState) => {
  return state.app.insightsReports.creating;
};

export const selfRoadmapItemsList = (state: StoreState) => {
  const player = state.app.player.item;
  return player.roadmap_items
    .map(id => state.app.roadmapItems.items[id])
    .filter(item => item !== undefined);
};

export const correlationMatrixAPI = (state: StoreState) =>
  state.app.correlationMatrix;
export const correlationMatrix = (state: StoreState) =>
  state.app.correlationMatrix.item;
export const correlationValuesAPI = (state: StoreState) =>
  state.app.correlationValues;
export const correlationValues = (state: StoreState) =>
  state.app.correlationValues.items;
export const correlationValuesWaitingList = (state: StoreState) =>
  state.app.correlationValuesWaitingList;

export const marketHistoryAPI = (state: StoreState) => state.app.market_history;
export const marketHistory = (state: StoreState) => {
  return state.app.market_history.items;
};
export const marketHistoryList = (state: StoreState) => {
  return Object.values(marketHistory(state));
};

export const stockSplitsAPI = (state: StoreState) => state.app.stock_splits;
export const stockSplits = (state: StoreState) => {
  return state.app.stock_splits.items;
};
export const stockSplitsList = (state: StoreState) => {
  return Object.values(stockSplits(state));
};

export const historicalStep = (state: StoreState) => {
  return state.app.historical_period_length;
};

export const indexFundPreviewData = (state: StoreState) => {
  return state.app.index_fund_preview;
};

export const assetEodData = (state: StoreState) => {
  return state.app.asset_eod_preview;
};

export const assetIntradayData = (state: StoreState) => {
  return state.app.asset_intraday_preview;
};

export const auctionsListSelector = (state: StoreState) => {
  return state.app.auctions;
};

export const getAuctionById = (auction_id: number | undefined) => (
  state: StoreState
) => {
  return state.app.auctions.find(auction => auction.id === auction_id);
};

export const getYields = (state: StoreState) => {
  return state.app.sessions.items[state.app.active_session]
    .todays_historical_yields;
};

export const getClientMoney = (state: StoreState) => state.app.client_money;

export const getClientProjections = (state: StoreState) => {
  const futureLoans = Object.values(state.app.client_money.future_loans.items);
  const futureDepos = Object.values(state.app.client_money.future_depos.items);

  let minLoans = 0;
  let maxLoans = 0;
  let minDepos = 0;
  let maxDepos = 0;

  futureLoans.forEach(loan => {
    minLoans = minLoans + loan.min;
    maxLoans = maxLoans + loan.max;
  });
  futureDepos.forEach(depo => {
    minDepos = minDepos + depo.min;
    maxDepos = maxDepos + depo.max;
  });
  // return {
  //   minLoans: minLoans,
  //   maxLoans: maxLoans,
  //   minDepos: minDepos,
  //   maxDepos: maxDepos,
  // };
  return [
    { type: 'Min. Loans', amount: minLoans },
    { type: 'Max. Loans', amount: maxLoans },
    { type: 'Min. Depos', amount: minDepos },
    { type: 'Max. Depos', amount: maxDepos },
  ];
};

export const totalCurrentLoans = (state: StoreState) => {
  const currentLoans = Object.values(
    state.app.client_money.current_loans.items
  );
  let totalCurrentLoans = 0;
  currentLoans.forEach(loan => {
    totalCurrentLoans += loan.awarded_sum;
  });
  return totalCurrentLoans;
};

export const totalPastLoansAmountAndInterest = (state: StoreState) => {
  const pastLoans = Object.values(state.app.client_money.past_loans.items);
  let totalPastLoansAmount = 0;
  let totalPastLoansInterest = 0;
  pastLoans.forEach(loan => {
    totalPastLoansAmount += loan.awarded_sum;
    totalPastLoansInterest += loan.interest;
  });
  return [totalPastLoansAmount, totalPastLoansInterest];
};

export const totalCurrentDepos = (state: StoreState) => {
  const currentDepos = Object.values(
    state.app.client_money.current_depos.items
  );
  let totalCurrentDepos = 0;
  currentDepos.forEach(depo => {
    totalCurrentDepos += depo.awarded_sum;
  });
  return totalCurrentDepos;
};

export const totalPastDeposAmountAndInterest = (state: StoreState) => {
  const pastDepos = Object.values(state.app.client_money.past_depos.items);
  let totalPastDeposAmount = 0;
  let totalPastDeposInterest = 0;
  pastDepos.forEach(depo => {
    totalPastDeposAmount += depo.awarded_sum;
    totalPastDeposInterest += depo.interest;
  });
  return [totalPastDeposAmount, totalPastDeposInterest];
};

export const clientsFetched = (state: StoreState) => {
  return state.app.client_money.fetched;
};

export const todaysYields = (state: StoreState) => {
  return state.app.sessions.items[state.app.active_session]
    .todays_historical_yields;
};

export const executionErrorsSelector = (state: StoreState) => {
  return state.app.errors_tab.execution_errors;
};

export const triedAndFailedOrdersSelector = (state: StoreState) => {
  return state.app.errors_tab.tried_and_failed_orders;
};

export const playersWithDifferencesSelector = (state: StoreState) => {
  return state.app.errors_tab.players_with_differences;
};

export const pendingMoosSelector = (state: StoreState) => {
  return state.app.errors_tab.pending_moos;
};

export const moosExecutedTodaySelector = (state: StoreState) => {
  return state.app.errors_tab.moos_executed_today;
};

export const pendingSLTPsSelector = (state: StoreState) => {
  return state.app.errors_tab.pending_sl_tps;
};

export const marketHoursItems = (state: StoreState) =>
  Object.values(state.app.market_hours.items);

export const marketHoursFetched = (state: StoreState) =>
  state.app.market_hours.fetched;

export const marketHours = (state: StoreState) => {
  return state.app.market_hours.items;
};

export const assetMarketHours = (asset: UnderlyingAsset) => (
  state: StoreState
) => {
  return state.app.market_hours.items[asset.market_hours];
};
