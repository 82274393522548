import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from './Header';
import Sessions from './Sessions';
import PersonSelector from './PersonSelector';

import {
  selectors,
  connectToKlassSocket,
  disconnectFromKlassSocket,
} from 'state';

const TraineeDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectors.token) as string;
  const selectedPerson = useSelector(selectors.selectedPerson);

  useEffect(() => {
    dispatch(connectToKlassSocket(token));

    return () => {
      dispatch(disconnectFromKlassSocket(token));
    };
  }, [dispatch, token]);

  return (
    <div>
      <Header />
      <PersonSelector visible={!selectedPerson} />
      <div style={{ padding: '30px 70px 70px 70px', width: '100%' }}>
        <Sessions />
      </div>
    </div>
  );
};

export default TraineeDashboard;
