import React from 'react';
import { SlateViewer } from '../SlateEditor';
import { RoadmapContent } from 'state';

type Props = {
  content: RoadmapContent;
};

const ContentViewer: React.FC<Props> = ({ content }) => {
  const img_url = content.image ? ((content.image as unknown) as string) : '';
  // console.log(img_url);

  return (
    <div>
      {content.text && <SlateViewer value={content.text} />}
      {content.image && (
        <div>
          <img alt="img" src={img_url} />
          {/*{content.image.getAll('image')}*/}
          {/*{typeof content.image}*/}
        </div>
      )}
    </div>
  );
};

export default ContentViewer;
