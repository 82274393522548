import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';

import {
  FinancialInstrument,
  selectors,
  PAUSED,
  CREATING,
  FINISHED,
  FROZEN,
} from 'state';
import GenericInstrumentTable from 'reusable/InstrumentTable';

type props = {
  financialInstruments: FinancialInstrument[];
  selectedInstrumentId: number;
  selectInstrument: (instrument: FinancialInstrument) => void;
  showOrderModal: () => void;
  showHedgeOrderModal: () => void;
  showCDSModal: () => void;
  showTBillModal: () => void;
  showRepoModal: () => void;
  showODAModal: () => void;
  showContractsModal: () => void; // FWD
  showCDSContractsModal: () => void;
  showTBillContractsModal: () => void;
  showRepoContractsModal: () => void;
};

const InstrumentTable: React.FC<props> = ({
  financialInstruments,
  selectInstrument,
  selectedInstrumentId,
  showOrderModal,
  showRepoModal,
  showODAModal,
  showHedgeOrderModal,
  showCDSModal,
  showTBillModal,
  showContractsModal,
  showCDSContractsModal,
  showTBillContractsModal,
  showRepoContractsModal,
}) => {
  const { status } = useSelector(selectors.activeSession);

  const isPaused = status === PAUSED || status === CREATING;
  const isFinished = status === FINISHED;

  const isHedge = financialInstruments[0]?.fx_hedge;

  const maturedHTMorAFS = (record: any) => {
    if (
      (record.resourcetype === 'HTMBond' ||
        record.resourcetype === 'AFSBond') &&
      record.htm_afs_years_to_maturity === 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <GenericInstrumentTable
        financialInstruments={financialInstruments}
        selectedInstrumentId={selectedInstrumentId}
        selectInstrument={selectInstrument}
        marketHours
        priceArrows
        playerId={undefined}
        action={function Action(text, record) {
          // console.log('action button record: ', record);
          const isRepo = record.resourcetype === 'Repo';
          const isCDS = record.resourcetype === 'CDS';
          const isTBill = record.resourcetype === 'TBill';
          type props = {
            title: string;
            disabled: boolean;
            onClick: () => void;
          };
          const ActionButton = ({ title, disabled, onClick }: props) => (
            <Button
              disabled={disabled}
              type="link"
              onClick={() => {
                selectInstrument(
                  financialInstruments.find(item => item.id === record.id) ||
                    financialInstruments[0]
                );
                onClick();
              }}
            >
              {title}
            </Button>
          );

          return (
            <div>
              <ActionButton
                title="Trade"
                disabled={
                  isPaused ||
                  status === FROZEN ||
                  record.closedFromInterval ||
                  // !record.marketIsOpen ||  // leave this on so players can still put MOO orders or limit orders
                  maturedHTMorAFS(record) ||
                  isFinished
                }
                // disabled={false}
                // disabled={record.closedFromInterval}
                onClick={() => {
                  if (record.fx_hedge) {
                    showHedgeOrderModal();
                  } else if (isRepo) {
                    showRepoModal();
                  } else if (isCDS) {
                    showCDSModal();
                  } else if (isTBill) {
                    showTBillModal();
                  } else {
                    showOrderModal();
                  }
                }}
              />
              {/*<ActionButton*/}
              {/*  title="FX Hedge"*/}
              {/*  disabled={!record.amount || !record.marketIsOpen || isHedge}*/}
              {/*  onClick={showHedgeOrderModal}*/}
              {/*/>*/}
              {!isHedge && !isRepo && !isCDS && !isTBill && (
                <ActionButton
                  title="ODA"
                  disabled={
                    isPaused ||
                    status === FROZEN ||
                    !record.amount ||
                    // !record.marketIsOpen ||
                    isHedge
                  }
                  onClick={showODAModal}
                />
              )}
              {isHedge && (
                <ActionButton
                  title="Show contracts"
                  disabled={false}
                  onClick={showContractsModal}
                />
              )}
              {isCDS && (
                <ActionButton
                  title="Show contracts"
                  disabled={false}
                  onClick={showCDSContractsModal}
                />
              )}
              {isTBill && (
                <ActionButton
                  title="Show contracts"
                  disabled={false}
                  onClick={showTBillContractsModal}
                />
              )}
              {isRepo && (
                <ActionButton
                  title="Show contracts"
                  disabled={false}
                  onClick={showRepoContractsModal}
                />
              )}

              <Button
                type={
                  selectedInstrumentId === record.id ? 'primary' : undefined
                }
                onClick={() =>
                  selectInstrument(
                    financialInstruments.find(item => item.id === record.id) ||
                      financialInstruments[0]
                  )
                }
              >
                Select
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default InstrumentTable;
