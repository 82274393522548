import React, { useState } from 'react';
import { Button, InputNumber, Modal, Card, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, breakDeposit, DepositInstrument, makeDeposit } from 'state';
import { formatters } from 'services';
import {
  assetIntradayData,
  selectedPerson,
} from '../../../../state/app/selectors';
import { AreaChart } from 'components';

type Props = {
  visible: boolean;
  hide: () => void;
};

const AssetIntradyPreviewModal: React.FC<Props> = ({ visible, hide }) => {
  const assetIntradayData = useSelector(selectors.assetIntradayData);
  // console.log(indexFundData);
  const bid_series = assetIntradayData.data.map((item, index) => ({
    x: item.timestamp,
    y: item.bid,
  }));

  const ask_series = assetIntradayData.data.map((item, index) => ({
    x: item.timestamp,
    y: item.ask,
  }));

  const open_price_series = assetIntradayData.data.map((item, index) => ({
    x: item.timestamp,
    y: item.open_price,
  }));

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      title={`Last 24hour intraday prices for: ${assetIntradayData.ric}`}
      footer={
        [
          /* leave empty to delete ok cancel buttons*/
        ]
      }
    >
      <div>
        <AreaChart
          title=""
          dataSeriesName={'bid'}
          data={bid_series}
          yaxisFormatter={formatters.commas4Digits}
          // xaxis={xaxis}
          tooltipDateFormat={'d MMM HH:mm'}
          additionalLines={[
            { data: ask_series, name: 'ask' },
            { data: open_price_series, name: 'open price' },
          ]}
        />
      </div>
    </Modal>
  );
};

export default AssetIntradyPreviewModal;
