import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  InputNumber,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import {
  auctions_tenors,
  receiveCreatedAuctionType,
} from '../../../state/app/auctions/types';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuctionResponse } from '../../../state/app/auctions/actions';
import { player, wallets } from '../../../state/app/selectors';
import { BUY, FROZEN, PAUSED, selectors, SELL } from 'state';
import { formatters } from 'services';
type Props = {
  visible: boolean;
  hide: () => void;
  auction: receiveCreatedAuctionType | undefined;
};

export const respondToAuctionColumns: ((
  | ColumnGroupType<receiveCreatedAuctionType>
  | ColumnType<receiveCreatedAuctionType>
) & {
  dataIndex: string;
})[] = [
  {
    title: 'Tenor',
    dataIndex: 'tenor',
    key: 'tenor',
    render: (value: number) => {
      return auctions_tenors[value];
    },
  },
  // {
  //   title: 'Unit',
  //   dataIndex: 'tenor_unit',
  //   key: 'tenor_unit',
  //   render: (text: number) => {
  //     if (text === 0) {
  //       return <div>Day</div>;
  //     } else if (text === 1) {
  //       return <div>Week</div>;
  //     } else if (text === 2) {
  //       return <div>Month</div>;
  //     }
  //   },
  // },
  {
    title: 'Series',
    dataIndex: 'auction_series',
    key: 'auction_series',
  },
  // {
  //   // In REAL_TIME_SCENARIO this should display actual maturity date, but so far we're only concerned with auctions
  //   // in historical sessions, thus we can't overtly display a date, instead number of days or nothing
  //   title: 'Maturity',
  //   dataIndex: 'tenor',
  //   key: 'maturity_date',
  // },
  {
    title: 'Auction value',
    dataIndex: 'bid_value_no_units',
    key: 'bid_value_no_units',
  },
  {
    title: 'Unit',
    dataIndex: 'bid_unit',
    key: 'bid_unit',
    render: (text: number) => {
      if (text === 0) {
        return <div>Million</div>;
      } else if (text === 1) {
        return <div>Billion</div>;
      } else if (text === 2) {
        return <div>Trillion</div>;
      }
    },
  },
  {
    title: 'CCY',
    dataIndex: 'currency_symbol',
    key: 'ccy',
  },
  {
    title: 'Initial Ask',
    dataIndex: 'initial_ask',
    key: 'initial_ask',
    render: (value: number) => formatters.commas3Digits(value),
  },
  {
    title: 'Yield',
    dataIndex: 'rate',
    key: 'rate',
  },
];
const RespondToAuctionModal: React.FC<Props> = ({ visible, hide, auction }) => {
  // console.log('auction from modal: ', auction);
  const dispatch = useDispatch();
  const playerObj = useSelector(player);
  const [bid, setBid] = useState<number | undefined>(0);
  const [bidUnit, setBidUnit] = useState(0);
  const thisPlayer = useSelector(player);
  const session = useSelector(selectors.activeSession);
  // console.log('auction:', auction);
  const ccy = auction!.currency;
  const ccyObj = useSelector(selectors.currencyById(ccy));
  const wallet = useSelector(selectors.walletByCurrency(ccyObj));
  // console.log('wallet:', wallet);

  const handlePlaceBid = () => {
    if (auction !== undefined && bid !== undefined) {
      dispatch(
        sendAuctionResponse({
          auction: auction?.id,
          player: playerObj.id,
          initial_bid_value: bid,
          // bid_unit: bidUnit,
        })
      );
      hide();
    } else {
      notification.warn({
        message: 'error',
      });
    }
  };

  const labelColWidth = '120px';
  let discountedValue = 0;
  if (bid) {
    discountedValue = (bid / 100) * auction!.initial_ask;
  }

  const style = discountedValue > wallet!.amount ? { color: 'red' } : {};
  const max = wallet!.amount > 0 ? wallet!.amount : 0;
  return (
    <Modal
      visible={visible}
      onCancel={hide}
      width={900}
      footer={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {/*<div style={{ marginRight: 5 }}>(time left: 2:34)</div>*/}
          <Button onClick={() => hide()}>Cancel</Button>
          <Button
            disabled={
              session.status === FROZEN || discountedValue > wallet!.amount
            }
            onClick={() => {
              handlePlaceBid();
            }}
          >
            Place bid
          </Button>
        </div>
      }
    >
      <Typography.Title level={4} style={{ margin: 0 }}>
        Place a bid
      </Typography.Title>
      <br />
      <Card title={'Auction Details:'} size={'small'}>
        <Table
          pagination={false}
          bordered
          dataSource={auction ? [auction] : []}
          columns={respondToAuctionColumns}
        ></Table>
      </Card>
      <br />
      <Card
        title={'How much would you like to bid?'}
        size={'small'}
        style={{ width: 350 }}
      >
        <Row gutter={[20, 20]}>
          <Col
            flex={labelColWidth}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Typography.Text>Value: </Typography.Text>
          </Col>
          <Col>
            <InputNumber
              style={{ marginRight: 10, width: 120, marginBlock: 10 }}
              min={1}
              max={max}
              value={bid}
              onChange={choice => {
                setBid(choice);
              }}
              formatter={formatters.commasNoDigits}
            />{' '}
            {ccyObj.symbol}
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            flex={labelColWidth}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Typography.Text>Discounted: </Typography.Text>
          </Col>
          <Col>
            {formatters.commasNoDigits(discountedValue)} {ccyObj.symbol}
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            flex={labelColWidth}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Typography.Text>Available cash: </Typography.Text>
          </Col>
          <Col>
            <div style={style}>
              {formatters.commasNoDigits(wallet!.amount)} {ccyObj.symbol}
            </div>
          </Col>
        </Row>

        {/*<div>*/}
        {/*  {ccyObj.symbol} wallet: {formatters.commasNoDigits(wallet!.amount)}*/}
        {/*</div>*/}
      </Card>
      <br />
      <div>
        * Discounted value will be collateralized until auction concludes and
        security is issued.
      </div>
      <div>
        * Total auction value will be split on a pro-rata basis among selected
        participants.
      </div>
    </Modal>
  );
};

export default RespondToAuctionModal;
