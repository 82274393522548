import React from 'react';
import { Row } from 'antd';

type Props = {
  paddingTop?: number | string;
  width?: number | string;
  backgroundColor?: string;
};

const CenteredDiv: React.FC<Props> = ({
  paddingTop,
  width,
  backgroundColor,
  children,
}) => {
  if (!paddingTop) paddingTop = 100;
  if (!width) width = 500;

  let backgroundStyle = {};
  if (backgroundColor) {
    backgroundStyle = {
      backgroundColor,
      height: '100vh',
    };
  }

  return (
    <div style={backgroundStyle}>
      <Row justify="center" style={{ paddingTop }}>
        <div style={{ width }}>{children}</div>
      </Row>
    </div>
  );
};

export default CenteredDiv;
