import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Spin, Tabs } from 'antd';

import GenericInstrumentTable from 'reusable/InstrumentTable';
import {
  selectors,
  instrumentTypes,
  EQUITY,
  FinancialInstrument,
  UnderlyingAsset,
  REAL_TIME_SCENARIO,
  FROZEN,
} from 'state';
import PnlAllocations from './PnlAllocations';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchingForwardsByPlayerId } from '../../../../state/app/selectors';
import ContractsModal from '../../../TraineeSession/ContractsModal';

type props = {
  playerId: number;
};

const InstrumentTable: React.FC<props> = ({ playerId }) => {
  const player = useSelector(selectors.playerById(playerId));
  const session = useSelector(selectors.activeSession);
  const [instrumentType, setInstrumentType] = useState('-1');
  // const fetchingForwards = useSelector(
  //   selectors.fetchingForwardsByPlayerId(playerId)
  // );

  const underlyingAssetsList = useSelector(selectors.underlyingAssetsList);
  // const _financialInstruments = useSelector(selectors.financialInstrumentsList);
  const underlyingAssets = useSelector(selectors.underlyingAssets);

  const assetsOfType = (type: string) =>
    underlyingAssetsList.filter(item =>
      type === '-1'
        ? true
        : item.resourcetype === instrumentTypes[parseInt(type)].type
    );

  const selectedAssets = assetsOfType(instrumentType);
  const financialInstruments = Object.values(player.financial_instruments)
    .filter(fn => selectedAssets.find(ul => ul.id === fn.underlying_asset))
    .filter(
      item =>
        item.amount ||
        item.reporting_realized_pnl ||
        item.reporting_unrealized_pnl
    );
  const isHedge = financialInstruments[0]?.fx_hedge;
  const [instrument, selectInstrument] = useState(financialInstruments[0]);
  const [visibleContractsModal, setVisibleContractsModal] = useState(false);

  const assets = useSelector(selectors.underlyingAssets);
  const sessionHasNoEquities = Object.values(assets).every(
    asset => asset.resourcetype !== 'Equity'
  );
  // console.log('instrumentTypes:', instrumentTypes);

  const instrumentFilter = (
    asset: UnderlyingAsset,
    type: string,
    name: string
  ) => {
    if (
      session.scenario_type === REAL_TIME_SCENARIO &&
      asset.resourcetype === 'FX'
    ) {
      return false;
    }
    const sameType =
      // underlyingAssets[instrument.underlying_asset].resourcetype === type;
      asset.resourcetype === type;

    // const hedge = instrument.fx_hedge === name.includes('Forwards');
    return sameType;
  };

  return (
    <div>
      <Tabs
        defaultActiveKey={instrumentType}
        onChange={val => {
          setInstrumentType(val);
        }}
      >
        <Tabs.TabPane key={'-1'} tab="All" />
        {instrumentTypes.map((item, i) => {
          if (sessionHasNoEquities && item.assetClass === EQUITY) {
            return;
          }
          const instruments = underlyingAssetsList.filter(asset =>
            instrumentFilter(asset, item.type, item.name)
          );

          if (!instruments.length) return null;
          return <Tabs.TabPane key={`${i}`} tab={`${item.name}`} />;
        })}
      </Tabs>
      <GenericInstrumentTable
        financialInstruments={financialInstruments}
        playerId={playerId}
        trainer_action={function Action(text, record) {
          type props = {
            title: string;
            disabled: boolean;
            onClick: () => void;
          };
          const ActionButton = ({ title, disabled, onClick }: props) => (
            <Button
              disabled={disabled}
              type="link"
              onClick={() => {
                selectInstrument(
                  financialInstruments.find(item => item.id === record.id) ||
                    financialInstruments[0]
                );
                onClick();
              }}
            >
              {title}
            </Button>
          );

          return (
            <div>
              {isHedge && (
                <ActionButton
                  title="Show contracts"
                  disabled={false}
                  onClick={() => setVisibleContractsModal(true)}
                />
              )}

              {/*<Button*/}
              {/*  type={instrument.id === record.id ? 'primary' : undefined}*/}
              {/*  onClick={() =>*/}
              {/*    selectInstrument(*/}
              {/*      financialInstruments.find(item => item.id === record.id) ||*/}
              {/*        financialInstruments[0]*/}
              {/*    )*/}
              {/*  }*/}
              {/*>*/}
              {/*  Select*/}
              {/*</Button>*/}
            </div>
          );
        }}
      />
      <PnlAllocations
        playerId={playerId}
        selectedInstrumentType={
          instrumentType === '-1'
            ? 'All'
            : instrumentTypes[parseInt(instrumentType)].name
        }
      />
      {visibleContractsModal && (
        <ContractsModal
          visible={visibleContractsModal}
          hide={() => setVisibleContractsModal(false)}
          id={instrument.id}
          player_id={playerId}
        />
      )}
    </div>
  );
};

export default InstrumentTable;
