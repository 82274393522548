import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import SessionsList from './SessionsList';
import Session from './Session';

const Sessions = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <SessionsList />
      </Route>
      <Route path={`${path}/:session_id`}>
        <Session />
      </Route>
      <Redirect to={`${path}`} />;
    </Switch>
  );
};

export default Sessions;
