export const DAYS = 0;
export const WEEKS = 1;
export const MONTHS = 2;
export const TENOR_UNITS = {
  [DAYS]: 'days',
  [WEEKS]: 'weeks',
  [MONTHS]: 'months',
};

export const SEMI_ANNUALLY = 0;
export const YEARLY = 1;
export const CDS_FREQUECIES = {
  [SEMI_ANNUALLY]: 'semi-annually',
  [YEARLY]: 'yearly',
};
