import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Button,
  Radio,
  InputNumber,
  Slider,
  Card,
  Row,
  Col,
  Input,
  message,
} from 'antd';

import {
  selectors,
  createOrder,
  SHORT_SELL,
  BUY,
  SELL,
  CASH_APPROACH,
  TERMS,
  ANALYSIS_TYPES,
  utils,
  REAL_TIME_SCENARIO,
  OrderType,
} from 'state';
import { formatters } from 'services';
import {
  OverLimitIcon,
  CannotSellIcon,
  CannotShortSellIcon,
  CannotBuyIcon,
  CannotSellPopover,
  CannotShortPopover,
  CannotSellBecauseHTMisLocked,
  CannotShortSellIcon2,
  CannotCoverMargin,
  MarketNotOpenPopover,
} from './InfoPanels';
import useMaxQuantity from './useMaxQuantity';
import usePrices from './usePrices';
import TextArea from 'antd/es/input/TextArea';
import useCollateral from './useCollateral';
import useCollateralSign from './useCollateral';
import * as util from 'util';
import { odaOfInstrumentCreateSelector } from '../../../state/app/selectors';
import { assetIsOpenNew } from '../../../state/app/utils';

type Props = {
  visible: boolean;
  hide: () => void;
  id: number;
};

const OrderModal: React.FC<Props> = ({ visible, hide, id }) => {
  const dispatch = useDispatch();

  const session = useSelector(selectors.activeSession);
  const {
    transaction_fee,
    limit_order_collateral,
    risk_approach,
    scenario_type,
    allow_short_sell,
    starting_cash_amount,
    short_sell_leverage,
    bond_leverage,
  } = session;
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);

  const player = useSelector(selectors.player);
  const instruments = useSelector(selectors.financialInstruments);
  const instrument = useSelector(selectors.financialInstrumentById(id));
  const asset = useSelector(selectors.underlyingAssetOfInstrument(instrument));
  const isHtm = asset.resourcetype === 'HTMBond';
  const allowShortSell = allow_short_sell && !isHtm;
  const currency = useSelector(selectors.currencyById(asset.base_currency));
  const assetMarketHours = useSelector(selectors.assetMarketHours(asset));
  const [isOpen] = utils.assetIsOpenNew(asset, session, assetMarketHours);

  const [quantity, setQuantity] = useState(100);
  const [orderType, setOrderType] = useState<OrderType>(
    isOpen ? 'MarketOrder' : 'LimitOrder'
  );
  useEffect(() => {
    if (!isOpen) {
      setOrderType('LimitOrder');
    }
  }, [assetMarketHours]);
  const [transaction, setTransaction] = useState(BUY);

  let { price, reportingPrice } = usePrices(id, transaction);

  const [investmentRationale, setInvestmentRationale] = useState<string>();
  const [slideValue, setSlideValue] = useState(0);
  const [forecastValue, setForecastValue] = useState(price || 0);
  const [editedForecast, setEditedForecast] = useState(false);
  const [term, setTerm] = useState();
  const [analysis, setAnalysis] = useState();

  useEffect(() => {
    //pass
  }, [instruments, dispatch]);

  // console.log(session.require_investment_rationale);
  // console.log(investmentRationale);
  let satisfiesInvestmentRationaleRequirement;
  let investmentRationalePlaceholder;
  if (session.require_investment_rationale === false) {
    satisfiesInvestmentRationaleRequirement = true;
    investmentRationalePlaceholder = 'Investment Rationale';
  } else {
    investmentRationalePlaceholder =
      'Investment Rationale | required field min. 10 char.';
    if (
      investmentRationale !== undefined &&
      investmentRationale !== '' &&
      investmentRationale.length > 9
    ) {
      satisfiesInvestmentRationaleRequirement = true;
    } else {
      satisfiesInvestmentRationaleRequirement = false;
    }
  }
  // console.log(satisfiesInvestmentRationaleRequirement);

  const isBond =
    asset.resourcetype == 'GovtBond' ||
    asset.resourcetype == 'CorporateBond' ||
    asset.resourcetype == 'HTMBond' ||
    asset.resourcetype == 'AFSBond';
  const isBondAndSell = isBond && transaction === SELL;
  const isBondAndRealTime =
    isBond && session.scenario_type === REAL_TIME_SCENARIO;

  let initialLimitPrice = price;
  if (isBond) {
    initialLimitPrice = +formatters.commas2Digits(
      price * 100 - asset.accrued_interest
    );
  }
  const [limitPrice, setLimitPrice] = useState(initialLimitPrice || 0);
  const isLeveragedBond =
    isBond && bond_leverage != 1 && risk_approach === CASH_APPROACH;
  const leveragedBondCollateralClause =
    isLeveragedBond && orderType === 'LimitOrder';
  const maxQuantity = useMaxQuantity(id, transaction, limitPrice, orderType);

  const collateralSign = useCollateralSign(id, transaction);

  if (!instrument) {
    message.error('Could not retrieve financial instrument!');
    return null;
  }
  if (!price) {
    message.error('Could not retrieve price!');
    return null;
  }

  const wallet = player.wallets.find(
    wallet => asset.variable_currency === wallet.currency
  );
  if (!wallet) {
    message.error('Could not retrieve wallet!');
    return null;
  }
  let displayBondPrice = formatters.commas2Digits(
    price * 100 - asset.accrued_interest
  );
  let displayCleanPrice = `${formatters.commas2Digits(
    price * 100 - asset.accrued_interest
  )} %`;
  let displayAccruedInterest = `${formatters.commas2Digits(
    asset.accrued_interest
  )} %`;
  if (orderType === 'LimitOrder') {
    // value = quantity * limitPrice;
    price = limitPrice;
    if (isBond) price /= 100;
    reportingPrice = utils.cashExchange(
      asset.base_currency,
      session.reporting_currency,
      price,
      0,
      assetList,
      currencyList
    );
    displayBondPrice = formatters.commas2Digits(price * 100);
    displayCleanPrice = '-';
    displayAccruedInterest = '-';
    // if (isBond) value /= 100;
  }
  const value = quantity * price;
  const total = value * (1 + transaction_fee);
  let collateral;
  if (collateralSign === -1) {
    collateral =
      (Math.abs(quantity) / Math.abs(instrument.amount)) *
      instrument.collateral *
      -1;
  } else {
    collateral = collateralSign * total * bond_leverage;
  }
  // if (orderType === 'LimitOrder') collateral = 0;
  const cantCoverCollateral = isLeveragedBond && collateral > wallet.amount;
  const displayCollateral = cantCoverCollateral ? (
    <Row style={{ color: 'red' }}>
      Margin: {formatters.commasNoDigits(collateral)}
    </Row>
  ) : (
    <Row>Margin: {formatters.commasNoDigits(collateral)}</Row>
  );

  // INFO PANEL LOGIC
  const assetLimit = instrument.pv_limit * starting_cash_amount;
  const showOverLimitIcon =
    transaction === BUY &&
    reportingPrice * (instrument.amount + 1) >= assetLimit &&
    !isLeveragedBond;
  const showCannotSellIcon = transaction === SELL && instrument.amount === 0;
  const showCannotShortIcon2 =
    transaction === SHORT_SELL && maxQuantity === 0 && !isLeveragedBond;
  const showCannotShortIcon =
    player.remaining_short_sell_amount_limit < 1000 &&
    transaction === SHORT_SELL;
  const cannotBuy =
    transaction === BUY &&
    wallet.amount < price &&
    asset.resourcetype !== 'FX' &&
    !isLeveragedBond;

  const disableHTMSellButton = isHtm && !session.allow_htm;
  const disableSellButton = instrument.amount < 0;
  const disableShortButton = instrument.amount > 0;

  const isFX = asset.resourcetype == 'FX';
  // const notFXnotBond = (isBond && isFX);

  const isNotBondOrFX =
    asset.resourcetype !== 'GovtBond' &&
    asset.resourcetype !== 'CorporateBond' &&
    asset.resourcetype !== 'HTMBond' &&
    asset.resourcetype !== 'AFSBond' &&
    asset.resourcetype !== 'FX';

  const { name } = asset;

  const showMooMocRadioButtons =
    scenario_type === REAL_TIME_SCENARIO && asset.resourcetype !== 'Crypto';
  // console.log('showMooMocRadioButtons: ', showMooMocRadioButtons);
  return (
    <Modal
      visible={visible}
      title={`Order ${name}`}
      onOk={hide}
      onCancel={hide}
      footer={[
        <Button
          key="place"
          type="primary"
          disabled={
            quantity <= 0 ||
            quantity > maxQuantity ||
            cantCoverCollateral ||
            !satisfiesInvestmentRationaleRequirement
          }
          onClick={() => {
            dispatch(
              createOrder({
                resourcetype: orderType,
                financial_instrument: instrument.id,
                forecast: editedForecast ? forecastValue : 0,
                transaction,
                quantity,
                limit_price: limitPrice,
                investment_rationale: investmentRationale,
                term,
                analysis,
                hedge: false,
              })
            );
            hide();
          }}
        >
          Place Order
        </Button>,
      ]}
    >
      Transaction:{' '}
      <Radio.Group
        onChange={e => setTransaction(e.target.value)}
        value={transaction}
      >
        <Radio value={BUY}>Buy</Radio>

        {isHtm ? (
          <CannotSellBecauseHTMisLocked visible={disableHTMSellButton}>
            <Radio value={SELL} disabled={disableHTMSellButton}>
              Sell
            </Radio>
          </CannotSellBecauseHTMisLocked>
        ) : (
          <CannotSellPopover visible={disableSellButton}>
            <Radio value={SELL} disabled={disableSellButton}>
              Sell
            </Radio>
          </CannotSellPopover>
        )}

        {allowShortSell && (
          // {isFX && (
          <CannotShortPopover visible={disableShortButton}>
            <Radio value={SHORT_SELL} disabled={disableShortButton}>
              Short Sell
            </Radio>
          </CannotShortPopover>
        )}
      </Radio.Group>
      <br />
      Price: ({currency.symbol}) {/*this gets you the clean price*/}
      {isBond && (
        <div style={{ display: 'inline-block' }}>{displayBondPrice} %</div>
      )}
      {isFX && formatters.commas4Digits(price)}
      {isNotBondOrFX && formatters.commas2Digits(price)}
      <br />
      Quantity:{' '}
      <InputNumber
        value={quantity}
        min={0}
        max={maxQuantity}
        formatter={formatters.commasNoDigits}
        onChange={val => setQuantity(val || 0)}
        style={{ width: 150 }}
      />
      {`/ ${formatters.commasNoDigits(maxQuantity)}     `}
      {/*
         Info Popovers
      */}
      {isBondAndSell && (
        <div>
          (Owned qty.: {formatters.commasNoDigits(instrument.amount)}; Repoed
          qty.: {formatters.commasNoDigits(instrument.repoed_amount)})
        </div>
      )}
      {showOverLimitIcon && <OverLimitIcon financialInstrument={instrument} />}
      {showCannotSellIcon && <CannotSellIcon allowShort={allow_short_sell} />}
      {showCannotShortIcon && (
        <CannotShortSellIcon shotSellLeverage={short_sell_leverage} />
      )}
      {showCannotShortIcon2 && (
        <CannotShortSellIcon2 pv_limit={instrument.pv_limit} />
      )}
      {cantCoverCollateral && <CannotCoverMargin />}
      {cannotBuy && <CannotBuyIcon symbol={currency.symbol} />}
      {/*
         Info Popovers
      */}
      {!isBondAndSell && <br />}
      {/*<br />*/}
      Order Type:{' '}
      <Radio.Group
        onChange={e => setOrderType(e.target.value)}
        value={orderType}
      >
        <MarketNotOpenPopover
          visible={!isOpen}
          geography={`${asset.geography}`}
          assetMarketHours={assetMarketHours}
          asset={asset}
        >
          <Radio value="MarketOrder" disabled={!isOpen}>
            Market
          </Radio>
        </MarketNotOpenPopover>

        <Radio value="LimitOrder">Limit</Radio>
        {showMooMocRadioButtons ? (
          <>
            <Radio value="MarketOnOpenOrder">MOO</Radio>
            <Radio value="MarketOnCloseOrder">MOC</Radio>
          </>
        ) : null}
      </Radio.Group>
      <br />
      Limit Price:{' '}
      <InputNumber
        style={{ marginRight: 5 }}
        disabled={!(orderType === 'LimitOrder')}
        value={limitPrice}
        min={0}
        onChange={val => setLimitPrice(val || 0)}
      />
      {isBond && <div style={{ display: 'inline-block' }}>%</div>}
      <br />
      What is your target price?
      <Slider
        marks={{ '-50': '-50%', 50: '50%' }}
        min={-50}
        max={50}
        value={slideValue}
        onChange={val => {
          setSlideValue(val as number);
          setForecastValue(Math.floor(price * (1 + (val as number) / 100)));
          setEditedForecast(true);
        }}
      />
      <InputNumber
        value={forecastValue}
        onChange={val => {
          setForecastValue(val || 0);
          setEditedForecast(true);
          setSlideValue(((val || 0) / price - 1) * 100);
        }}
      />
      {isBondAndRealTime && (
        <div>
          <br />
          <Card>
            <Row justify="center">
              <h3>Bond Pricing</h3>
            </Row>
            <Row justify="space-between" align="middle">
              <Col>
                Clean Price
                <br />
                {displayCleanPrice}
              </Col>
              <Col>
                Dirty Price
                <br />
                {formatters.commas2Digits(price * 100)} %
              </Col>
              <Col>
                Accrued Interest
                <br />
                {displayAccruedInterest}
              </Col>
              <Col>
                Dirty Amount
                <br />
                {formatters.commasNoDigits(price * quantity)} {currency.symbol}
              </Col>
            </Row>
          </Card>
          <br />
        </div>
      )}
      <Card>
        <Row justify="center">
          <h3>Order Summary</h3>
        </Row>
        <Row justify="space-between" align="middle">
          <Col>
            Quantity
            <br />
            {formatters.commasNoDigits(quantity)}
          </Col>
          {/*{!isBond && (*/}
          <Col>
            Price
            <br />
            {isBond && <div>{formatters.commas2Digits(price * 100)} %</div>}
            {isFX && formatters.commas4Digits(price)}
            {isNotBondOrFX && formatters.commas2Digits(price)}
          </Col>
          {/*)}*/}
          <Col>
            Value
            <br />
            {formatters.commasNoDigits(value)}
          </Col>
          <Col>
            Fees*
            <br />
            {formatters.percent1Digit(transaction_fee)}
          </Col>
          <Col>
            Total
            <br />
            {formatters.commasNoDigits(total)}
          </Col>
        </Row>
        <br />
        {risk_approach === CASH_APPROACH && (
          <Row>Available Cash: {formatters.commasNoDigits(wallet.amount)}</Row>
        )}
        {
          isLeveragedBond && displayCollateral
          // <Row>Collateral: {formatters.commasNoDigits(collateral)}</Row>
        }
        {leveragedBondCollateralClause &&
          'Collateral will be reserved only at order fill'}
      </Card>
      <TextArea
        maxLength={511}
        minLength={10}
        placeholder={investmentRationalePlaceholder}
        value={investmentRationale}
        onChange={e => setInvestmentRationale(e.target.value)}
      />
      <Radio.Group value={term} onChange={e => setTerm(e.target.value)}>
        {Object.entries(TERMS).map(([key, val]) => (
          <Radio value={key} key={key} style={{ width: 100 }}>
            {val}
          </Radio>
        ))}
      </Radio.Group>
      <br />
      <Radio.Group value={analysis} onChange={e => setAnalysis(e.target.value)}>
        {Object.entries(ANALYSIS_TYPES).map(([key, val]) => (
          <Radio value={key} key={key} style={{ width: 100 }}>
            {val}
          </Radio>
        ))}
      </Radio.Group>
    </Modal>
  );
};

export default OrderModal;
