import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Table,
  Typography,
} from 'antd';
import {
  addUserToClass,
  AddUserToClass,
  deleteKlass,
  deleteTrainee,
  RefreshAssetEod,
  refreshAssetEodFromRefinitiv,
  selectors,
  setTrainerPassword,
  Trainee,
  Trainer,
} from 'state';

type Props = {
  klassId: number;
  visible: boolean;
  hide: () => void;
};

const EditClassModal: React.FC<Props> = ({ klassId, visible, hide }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const klasses = useSelector(selectors.klasses);
  const klass = klasses[klassId];
  const trainees = klass.trainees;
  const title_string = `Edit ${klass.name}`;
  const disableDelete = klass.sessions.length !== 0;

  const columns: any[] = [
    {
      title: 'Trainees',
      key: 'username',
      dataIndex: 'username',
    },
    {
      title: 'Action',
      width: 80,
      align: 'center',

      render: function Action(record: Trainee) {
        return (
          <div>
            <Button
              type="link"
              onClick={() => {
                dispatch(deleteTrainee({ trainee_id: record.id }));
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          No trainees in this class.
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  return (
    <Modal
      title={title_string}
      visible={visible}
      onCancel={hide}
      footer={null}
      width="800px"
    >
      <Row gutter={50}>
        <Col span={12}>
          <Table
            columns={columns}
            dataSource={trainees}
            bordered
            locale={noDataBehaviour}
          ></Table>
          {disableDelete ? (
            <Popover
              content={'To delete a class, first delete all its sessions.'}
            >
              <Button type="danger" disabled={true} style={{ marginTop: 16 }}>
                Delete class
              </Button>
            </Popover>
          ) : (
            <Popover
              content={
                'This will also delete all trainee accounts associated with this class.'
              }
            >
              <Button
                type="danger"
                onClick={() => {
                  dispatch(deleteKlass({ klass_id: klass.id }));
                  hide();
                }}
                style={{ marginTop: 16 }}
              >
                Delete class
              </Button>
            </Popover>
          )}
        </Col>
        {/*<Col>*/}
        {/*  <div style={{margin}}></div>*/}
        {/*</Col>*/}
        {/*<Col style={{ borderLeft: '2px solid #eeeeee' }} span={2}></Col>*/}
        <Col style={{ borderLeft: '2px solid #eeeeee' }} span={12}>
          {/*<Typography.Title level={4}>*/}
          {/*  Add a new user to the class*/}
          {/*</Typography.Title>*/}
          <Card title="Add a new user to the class">
            <Form
              form={form}
              labelCol={{ span: 6 }}
              // initialValues={{ delete_prev_data: false }}
            >
              <Form.Item label="Username" name="username">
                <Input />
              </Form.Item>
              <Form.Item label="Password" name="password">
                <Input />
              </Form.Item>
            </Form>
            <Button
              key="key"
              // loading={refreshLoading}
              // type="primary"
              // style={{ position: 'absolute', right: 25, bottom: 25 }}
              onClick={() => {
                const data: AddUserToClass = {
                  username: form.getFieldValue('username'),
                  password: form.getFieldValue('password'),
                  klass_id: klassId,
                };
                // console.log(data);
                dispatch(addUserToClass(data));
              }}
            >
              Create user
            </Button>
          </Card>
        </Col>
      </Row>

      {/*<Divider>Add a new user to class</Divider>*/}
    </Modal>
  );
};

export default EditClassModal;
