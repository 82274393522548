import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, InputNumber, Space, Button, Typography, Input } from 'antd';
import {
  PlusOutlined,
  CloseOutlined,
  RollbackOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import {
  fetchAssets,
  fetchSessions,
  selectors,
  buyDefaultPortfolio,
  createInitialPreset,
  updateInitialPreset,
  listInitialPresets,
} from 'state';

import type { PartialGroup, Group } from 'state';

const InitialPortfolio = () => {
  const dispatch = useDispatch();
  const sessions = useSelector(selectors.sessions);
  const assets = useSelector(selectors.underlyingAssetsList);
  const presets = useSelector(selectors.initialPresets);

  const [selected, setSelected] = useState<number[]>([]);
  const [groupsData, setGroupsData] = useState<Group[]>([]);
  const [disabledBuy, setDisabledBuy] = useState(false);
  const [presetId, setPresetId] = useState(0);
  const [presetName, setPresetName] = useState('');

  useEffect(() => {
    if (!sessions.length) {
      dispatch(fetchSessions({}));
    }

    if (!assets.length) {
      dispatch(fetchAssets({}));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAssets({}));
    dispatch(listInitialPresets({}));
  }, [presetId]);

  const addGroup = () => {
    const newData = [...groupsData];
    newData.push({ asset: 0, value: 0 });
    setGroupsData(newData);
  };

  const setGroup = (index: number, data: PartialGroup) => {
    const newData = [...groupsData];
    newData[index] = { ...newData[index], ...data };
    setGroupsData(newData);
  };

  const removeGroup = (index: number) => {
    const newData = [...groupsData];
    newData.splice(index, 1);
    setGroupsData(newData);
  };

  const dataIsValid = () => {
    if (!selected.length || !groupsData.length) {
      return false;
    }

    return groupsData.reduce((acc, group) => acc && !!group.asset, true);
  };

  const assetOptions = useMemo(() => {
    return assets.map(asset => (
      <Select.Option key={asset.id} value={asset.id}>
        {asset.name}
      </Select.Option>
    ));
  }, [assets]);

  const groupInputs = (index: number) => {
    return (
      <Space size={15} style={{ display: 'flex' }} key={index}>
        <Select
          showSearch
          allowClear
          style={{ minWidth: 200 }}
          placeholder="Select Asset"
          optionFilterProp="children"
          value={groupsData[index]?.asset || undefined}
          onChange={value => setGroup(index, { asset: value })}
        >
          {assetOptions}
        </Select>

        <InputNumber
          style={{ minWidth: 200 }}
          min={0}
          value={groupsData[index].value}
          formatter={value =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={value => (value || '').replace(/\$\s?|(,*)/g, '')}
          onChange={value => setGroup(index, { value })}
        />

        <Button type="primary" danger onClick={() => removeGroup(index)}>
          <CloseOutlined />
        </Button>
      </Space>
    );
  };
  return (
    <Space direction="vertical" size={15} style={{ display: 'flex' }}>
      {/*V=======PRESETS SELECTOR COMPONENET============V*/}
      <>
        <Space size={15} style={{ marginBottom: 15 }}>
          <Input
            value={presetName}
            onChange={e => {
              setPresetName(e.target.value);
            }}
            placeholder="Preset Name"
          />
          <Button
            type="primary"
            danger
            disabled={
              presetId ? presetName === presets[presetId].name : !presetName
            }
            onClick={() => {
              setPresetName(presetId ? presets[presetId].name : '');
            }}
          >
            <RollbackOutlined />
          </Button>
          <Button
            type="primary"
            disabled={!presetName}
            onClick={() => {
              dispatch(createInitialPreset({ name: presetName }));
            }}
          >
            <PlusOutlined /> New
          </Button>

          <Select
            showSearch
            allowClear
            style={{ width: 200 }}
            placeholder="Select Preset"
            optionFilterProp="children"
            onChange={value => {
              const id = Number(value);
              setPresetId(id);
              setPresetName(presets[id]?.name || '');
              if (isNaN(id)) {
                setGroupsData([]);
              } else {
                setGroupsData(presets[id].asset_value_pairs || []);
              }
            }}
          >
            {Object.values(presets)
              .sort((a, b) => b.id - a.id)
              .map(preset => {
                const { id, name } = preset;
                return (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                );
              })}
          </Select>
          <Button
            type="primary"
            danger
            disabled={!presetId}
            onClick={() => {
              // setSelected(presets[presetId].assets);
            }}
          >
            <RollbackOutlined />
          </Button>
          <Button
            type="primary"
            disabled={!presetId}
            onClick={() => {
              const prevPresent = presets[presetId];
              dispatch(
                updateInitialPreset({
                  ...prevPresent,
                  name: presetName || prevPresent.name,
                  asset_value_pairs: groupsData,
                })
              );
            }}
          >
            <SaveOutlined /> Save
          </Button>
        </Space>
      </>
      {/*^=======PRESETS SELECTOR COMPONENET============^*/}
      <Typography.Title level={4} style={{ margin: 0 }}>
        Sessions
      </Typography.Title>

      <Select
        mode="multiple"
        allowClear
        style={{ minWidth: '415px' }}
        placeholder="Select session ids"
        value={selected}
        onChange={value => setSelected(value as number[])}
      >
        {sessions.map(({ id }) => (
          <Select.Option key={id} value={id}>
            {id}
          </Select.Option>
        ))}
      </Select>

      <Typography.Title level={4} style={{ margin: 0 }}>
        Assets
      </Typography.Title>

      {groupsData.map((group, index) => groupInputs(index))}

      <Space size={15} style={{ display: 'flex' }}>
        <Select disabled style={{ minWidth: 200 }} placeholder="Select Asset">
          {[]}
        </Select>
        <InputNumber disabled style={{ minWidth: 200 }} placeholder="Value" />
        <Button type="primary" onClick={addGroup}>
          <PlusOutlined />
        </Button>
      </Space>

      <Button
        type="primary"
        disabled={disabledBuy || !dataIsValid()}
        onClick={() => {
          setDisabledBuy(true);
          dispatch(
            buyDefaultPortfolio({ sessions: selected, assets: groupsData })
          );
        }}
      >
        Buy
      </Button>
    </Space>
  );
};

export default InitialPortfolio;
