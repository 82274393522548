import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Input, Form, Button, Checkbox } from 'antd';

import { selectors, updatePlayerSettings } from 'state';
import { useCookieProvider } from '../CookieProvider';

const PlayerSettings: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { name } = useSelector(selectors.player);
  const { soundEnabled, setSoundEnabled } = useCookieProvider();

  return (
    <Card title="Player settings">
      <Form
        form={form}
        initialValues={{ name }}
        onFinish={() => {
          dispatch(updatePlayerSettings({ ...(form.getFieldsValue() as any) }));
        }}
      >
        <Form.Item label="Username" name="name">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
      <Checkbox
        checked={soundEnabled === 'enabled'}
        onChange={e => {
          const value = e.target.checked ? 'enabled' : 'disabled';
          setSoundEnabled(value);
        }}
      >
        Sound enabled
      </Checkbox>
    </Card>
  );
};

export default PlayerSettings;
