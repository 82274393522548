import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { formatters } from 'services';
import { selectors } from 'state';

type Props = {
  playerId: number;
};

const Deposits: React.FC<Props> = ({ playerId }) => {
  const player = useSelector(selectors.playerById(playerId));

  const instruments = Object.values(player.financial_instruments || {});
  const playableSymbols = useSelector(
    selectors.playableCurrencies(instruments)
  );
  const playableDeposits = player.deposits.filter(deposit =>
    playableSymbols.includes(deposit.currency_symbol)
  );

  return (
    <div>
      <Table
        rowKey="id"
        bordered
        dataSource={playableDeposits}
        pagination={false}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Currency',
            dataIndex: 'currency_symbol',
            key: 'currency_symbol',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // align: 'right',
          },
          {
            title: 'Interest Rate, %',
            dataIndex: 'annualized_rate',
            key: 'annualized_rate',
            align: 'right',
            render: rate => formatters.commas2Digits(rate),
          },
          // {
          //   title: 'O/N Rate',
          //   dataIndex: 'overnight_rate',
          //   key: 'overnight_rate',
          //   render: rate => formatters.percent3Digit(rate),
          // },
          {
            title: 'Initial Amount',
            dataIndex: 'initial_amount',
            key: 'initial_amount',
            align: 'right',
            render: formatters.commasNoDigits,
          },
          {
            title: 'Interest',
            dataIndex: 'accumulated_interest',
            key: 'accumulated_interest',
            align: 'right',
            // align: 'right',
            // sorter: (a: any, b: any) => a.amount - b.amount,
            render: formatters.commasNoDigits,
          },
          // {
          //   title: 'Maturity',
          //   dataIndex: 'maturity',
          //   key: 'maturity',
          //   // align: 'right',
          // },
          // {
          //   title: 'Opening Date',
          //   dataIndex: 'opening_date',
          //   key: 'opening_date',
          //   render: date => {
          //     if (date) {
          //       return JSON.stringify(date).split('T')[0].slice(1);
          //     }
          //     return date;
          //   },
          //   // align: 'right',
          // },
        ]}
      />
    </div>
  );
};
export default Deposits;
