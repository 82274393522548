import React, { useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Select,
  Switch,
} from 'antd';
import {
  createMarketHours,
  createUnderlyingAsset,
  instrumentTypes,
  marketHours,
  REGIONS,
  selectors,
  UnderlyingAsset,
} from 'state';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { auctions_tenors } from '../../../state/app/auctions/types';
import {
  CDS_FREQUECIES,
  TENOR_UNITS,
} from '../../../state/app/repos/constants';

type Props = {
  hide: () => void;
};
const CreateGeographyModal: React.FC<Props> = ({ hide }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [investmentThemeForm] = Form.useForm();
  const currencies = useSelector(selectors.currencies);
  const assets = useSelector(selectors.underlyingAssetsList);
  const fxAsset = assets.filter(asset => asset.resourcetype === 'FX');
  const [assetClass, setAssetClass] = useState<
    UnderlyingAsset['resourcetype'] | undefined
  >(undefined);
  const notForex = assetClass !== 'FX' && assetClass !== 'FxForwards';
  const HTMorAFS = assetClass === 'HTMBond' || assetClass === 'AFSBond';
  const isYields = assetClass === 'Yields';
  return (
    <Modal
      visible={true}
      onOk={hide}
      onCancel={hide}
      title={'Create a new timezone'}
      width={450}
      footer={null}
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          onFinish={() => {
            const data = form.getFieldsValue();
            dispatch(createMarketHours(data));
            // hide();
          }}
          initialValues={{
            visible: true,
            fetch_eod: false,
            fetch_intraday: false,
          }}
        >
          <Form.Item label="Geography" name="geography">
            <Input />
          </Form.Item>
          <Form.Item label="UTC Open" name="open_hours">
            <Input placeholder={'07:03'} />
          </Form.Item>
          <Form.Item label="UTC Close" name="closing_hours">
            <Input placeholder={'15:27'} />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'right', gap: 10 }}>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
              {/*<Button onClick={hide}>Cancel</Button>*/}
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default CreateGeographyModal;
