import React, {
  useEffect,
  useMemo,
  useState,
  Key,
  useContext,
  ReactNode,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Space,
  Table,
  Switch,
  Typography,
  Button,
  Tooltip,
  Divider,
  Empty,
  Popover,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import { CSVLink } from 'react-csv';

import {
  selectors,
  Player,
  Metrics,
  utils,
  GROUP_ASSET_NAME,
  PlayerAllocations,
  GROUP_OF_ASSET,
  GROUP_ASSET_INDEX,
  CREATING,
  CASH_APPROACH,
  initializeForwardContractsState,
} from 'state';
import { formatters } from 'services';
import { columnSearchWidget } from 'components';
import PlayerModal from './PlayerDetails/PlayerModal';
import { green, red, yellow } from '@ant-design/colors';
import AssetClassAllocations from '../SessionSettings/AssetClassAllocations';
import { TrainerSessionContext } from '../TrainerSessionContext';
import {
  hasAlternativesGroup,
  hasBondsGroup,
  hasCryptoGroup,
  hasEquitiesGroup,
  sessionHasAFSassetsOverview,
} from '../../../state/app/selectors';

const PlayersTable: React.FC = () => {
  const dispatch = useDispatch();
  const trainerContext = useContext(TrainerSessionContext);

  const [selectedPlayer, setSelectedPlayer] = useState<number | undefined>();
  const session = useSelector(selectors.activeSession);
  const reportingCurrency = utils.useReportingCurrency(session.id);
  const players = useSelector(selectors.playersList);

  const isCashApproach = session.risk_approach === CASH_APPROACH;

  const hasHTM = useSelector(selectors.sessionHasHTMassetsOverview);
  const hasAFS = useSelector(selectors.sessionHasAFSassetsOverview);
  const HTMorAFS = hasAFS || hasHTM;

  const selectionType = session.allow_individual_benchmark
    ? 'radio'
    : 'checkbox';

  const defaultAllocations: PlayerAllocations = {
    Cash: 0,
    Equities: 0,
    Bonds: 0,
    Alternatives: 0,
    FX: 0,
    Crypto: 0,
  };

  type PlayerData = Player & Metrics & PlayerAllocations;
  const dataa: PlayerData[] = players.map(player => {
    const allocations = player.tactical_asset_allocations.reduce((acc, p) => {
      const group = GROUP_OF_ASSET[p.asset_class];
      const groupName = GROUP_ASSET_NAME[group] as keyof PlayerAllocations;
      const prev = acc[groupName] ?? 0;
      const obj = { ...acc, [groupName]: prev + p.percent };
      return obj;
    }, defaultAllocations);
    const finalObj = {
      ...allocations,
      ...player.last_metrics,
      ...player,
    };
    return finalObj;
  });

  const activePlayersData = dataa.filter(player => player.deals !== 0);

  const getCurrencySymbol = (locale: string, currency: string) =>
    (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();

  const currencySymbolCharacter = getCurrencySymbol(
    'en-US',
    reportingCurrency.symbol
  );

  const renderPlayerTacticalAllocations = (
    percent: number,
    record: PlayerData,
    assetGroup: string
  ) => {
    const assetGroupIndex = GROUP_ASSET_INDEX[assetGroup];
    const assetGroupAllocation = session.asset_group_allocations.find(
      group => group.asset_group === assetGroupIndex
    );
    const getBackgroundColor = () => {
      const INTENSITY = 2;

      if (!assetGroupAllocation || record.deals === 0) {
        return '#ffffff';
      }
      const { lower_limit, upper_limit } = assetGroupAllocation;

      if (percent < lower_limit) {
        return '#fde6b7';
      }
      if (percent > upper_limit) {
        return '#fdc7c7';
      }
      return '#bffda7';
    };

    return {
      props: {
        style: {
          backgroundColor: getBackgroundColor(),
          color: '#000',
        },
      },
      children: formatters.percent2Digit(percent),
    };
  };

  // const columns: ((ColumnGroupType<PlayerData> | ColumnType<PlayerData>) & {
  //   dataIndex: string;
  // })[] = useMemo(() => {
  //   return [
  const columns: ((ColumnGroupType<PlayerData> | ColumnType<PlayerData>) & {
    dataIndex: string;
  })[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
      width: 114,
      render: (name, record) => {
        return (
          <p>
            <Badge status={record.online ? 'success' : 'default'} />
            {name}
          </p>
        );
      },
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      ...columnSearchWidget({ dataIndex: 'name' }),
    },
    {
      title: 'MktVal (M, ' + currencySymbolCharacter + ')',
      dataIndex: 'last_market_value',
      fixed: 'left',
      width: 130,

      align: 'right',
      render: formatters.millionNoUnit,
      sorter: (a, b) => a.last_market_value - b.last_market_value,
    },
    {
      title: 'PnL (M, ' + currencySymbolCharacter + ')',
      dataIndex: 'last_pnl_sum',
      fixed: 'left',
      width: 109,
      align: 'right',
      render: formatters.millionNoUnitExact2Digits,
      defaultSortOrder: 'descend',
      sorter: { compare: (a, b) => a.pnl_sum - b.pnl_sum, multiple: 1 },
    },
    {
      title: 'FX PnL (M, ' + currencySymbolCharacter + ')',
      dataIndex: 'last_fx_return',
      fixed: 'left',
      width: 130,
      align: 'right',
      render: formatters.millionNoUnit,
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => a.last_fx_return - b.last_fx_return,
        multiple: 1,
      },
    },
    {
      title: 'Return',
      dataIndex: 'last_portfolio_return',
      fixed: 'left',
      width: 85,
      align: 'right',
      render: formatters.percent2Digit,
      sorter: (a, b) => a.last_portfolio_return - b.last_portfolio_return,
    },
    {
      title: 'Orders',
      dataIndex: 'deals',
      align: 'right',
      fixed: 'left',
      width: 48,
      sorter: (a, b) => a.deals - b.deals,
      // defaultSortOrder: 'descend',
      // render: formatters.percent1Digit,
      // sorter: (a, b) => a.portfolio_return - b.portfolio_return,
    },
    {
      title: 'Volatility',
      dataIndex: 'portfolio_volatility',
      align: 'right',
      render: formatters.metricsPercent1Digit,
      sorter: (a, b) => a.portfolio_volatility - b.portfolio_volatility,
    },
    {
      title: 'Alpha',
      dataIndex: 'alpha',
      align: 'right',
      render: formatters.metricsCommas2Digits,
      sorter: (a, b) => a.alpha - b.alpha,
    },
    {
      title: 'Beta',
      dataIndex: 'beta',
      align: 'right',
      render: formatters.metricsCommas2Digits,
      sorter: (a, b) => a.beta - b.beta,
    },
    {
      title: 'Sharpe',
      dataIndex: 'sharpe_ratio',
      align: 'right',
      render: formatters.metricsCommas2Digits,
      sorter: (a, b) => a.sharpe_ratio - b.sharpe_ratio,
    },
    {
      title: 'Treynor',
      dataIndex: 'treynor_ratio',
      align: 'right',
      render: formatters.metricsCommas2Digits,
      sorter: (a, b) => a.treynor_ratio - b.treynor_ratio,
    },
  ];
  if (!HTMorAFS) {
    columns.push({
      title: 'VaR (M, ' + currencySymbolCharacter + ')',
      dataIndex: 'var05',
      align: 'right',
      render: formatters.millionNoUnit,
      sorter: (a, b) => a.var05 - b.var05,
    });
  }

  let numberOfAllocationColumns = 0;

  if (isCashApproach) {
    columns.push({
      title: 'Cash',
      dataIndex: 'Cash',
      align: 'right',
      fixed: 'right',
      width: 91,

      // render: value => formatters.percent2Digit(value),
      render: (value, record, index) =>
        renderPlayerTacticalAllocations(value, record, 'Cash'),
    });
    numberOfAllocationColumns++;
  }
  const assets = useSelector(selectors.underlyingAssetsList);

  const hasEquitiesGroup = useSelector(selectors.hasEquitiesGroup());
  const hasBondsGroup = useSelector(selectors.hasBondsGroup());
  const hasAlternativesGroup = useSelector(selectors.hasAlternativesGroup());
  const hasCryptoGroup = useSelector(selectors.hasCryptoGroup());
  const hasHTMGroup = useSelector(selectors.hasHTMGroup());
  const hasAFSGroup = useSelector(selectors.hasAFSGroup());

  if (hasEquitiesGroup) {
    columns.push({
      title: 'Equities',
      dataIndex: 'Equities',
      align: 'right',
      fixed: 'right',
      width: 91,
      // render: value => formatters.percent2Digit(value),
      render: (value, record, index) =>
        renderPlayerTacticalAllocations(value, record, 'Equities'),
    });
    numberOfAllocationColumns++;
  }
  if (hasBondsGroup) {
    columns.push({
      title: 'Bonds',
      dataIndex: 'Bonds',
      align: 'right',
      fixed: 'right',
      width: 91,
      // render: value => formatters.percent2Digit(value),
      render: (value, record, index) =>
        renderPlayerTacticalAllocations(value, record, 'Bonds'),
    });
    numberOfAllocationColumns++;
  }
  if (hasAlternativesGroup) {
    columns.push({
      title: 'Alternatives',
      dataIndex: 'Alternatives',
      align: 'right',
      fixed: 'right',
      width: 91,
      // render: value => formatters.percent2Digit(value),
      render: (value, record, index) =>
        renderPlayerTacticalAllocations(value, record, 'Alternatives'),
    });
    numberOfAllocationColumns++;
  }
  if (hasCryptoGroup) {
    columns.push({
      title: 'Crypto',
      dataIndex: 'Crypto',
      align: 'right',
      fixed: 'right',
      width: 91,
      // render: value => formatters.percent2Digit(value),
      render: (value, record, index) =>
        renderPlayerTacticalAllocations(value, record, 'Crypto'),
    });
    numberOfAllocationColumns++;
  }
  if (hasAFSGroup) {
    columns.push({
      title: 'AFS Bonds',
      dataIndex: 'AFS Bonds',
      align: 'right',
      fixed: 'right',
      width: 91,
      // render: value => formatters.percent2Digit(value),
      render: (value, record, index) =>
        renderPlayerTacticalAllocations(value, record, 'AFSBonds'),
    });
    numberOfAllocationColumns++;
  }
  if (hasHTMGroup) {
    columns.push({
      title: 'HTM Bonds',
      dataIndex: 'HTM Bonds',
      align: 'right',
      fixed: 'right',
      width: 91,
      // render: value => formatters.percent2Digit(value),
      render: (value, record, index) =>
        renderPlayerTacticalAllocations(value, record, 'HTMBonds'),
    });
    numberOfAllocationColumns++;
  }
  let actionsWidth = 61;
  if (numberOfAllocationColumns === 1) {
    actionsWidth = 151;
  }
  columns.push({
    title: 'Actions',
    dataIndex: 'actions',
    fixed: 'right',
    width: actionsWidth,
    align: 'center',
    render: function Action(text, record) {
      return (
        <Space key={record.id} size="middle">
          <a
            onClick={() => {
              dispatch(
                initializeForwardContractsState({ player_id: record.id })
              );
              setSelectedPlayer(record.id);
            }}
          >
            Details
          </a>
        </Space>
      );
    },
  });

  // const csvData = useMemo(() => {
  const csvData = () => {
    const titles = columns.map(col => {
      if (col.title !== 'Actions') return col.title;
    });

    const body = dataa.map((player, index) => {
      return columns.map(col => {
        if (col.dataIndex === 'actions') return;
        const value = player[col.dataIndex as keyof typeof player];
        if (col.dataIndex === 'name') return value;
        const toWrite = col.render ? col.render(value, player, index)! : value!;
        if (typeof toWrite === 'object' && col.render) {
          return formatters.percent2Digit(value as number);
        } else {
          return col.render ? col.render(value, player, index) : value;
        }
      });
    });

    body.unshift(titles);

    return body;
  };
  // }, [columns, dataa]);

  const csvFilename = `Session_${
    session.id
  }_Overview_${new Date().toISOString().replaceAll(/\.([0-9]*)/g, '')}`;

  const glanceGroupAllocations = <AssetClassAllocations />;

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {trainerContext.showOnlyActivePlayers && session.status !== CREATING
            ? 'No active players.'
            : 'Please finish creating the session, then players and their stats will\n' +
              '          appear here.'}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  const playerAllocationCssProperty =
    actionsWidth + 91 * numberOfAllocationColumns - 217;
  const minTableWidth = 890 + actionsWidth + 91 * numberOfAllocationColumns;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    trainerContext.setSelectedRows(newSelectedRowKeys);
  };
  const selectedRowKeys = trainerContext.selectedRows;
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // getCheckboxProps: (record: LogsType) => ({
    //   disabled: !('id' in record), // Column configuration not to be checked
    // }),
  };

  return (
    <div style={{ marginTop: 30 }}>
      <div style={{ position: 'relative', minWidth: minTableWidth }}>
        <Typography.Title level={3} style={{ display: 'inline' }}>
          | Players Overview
        </Typography.Title>
        <Typography.Title
          level={3}
          style={{ display: 'inline', marginLeft: 511 }}
        >
          | Players Metrics
        </Typography.Title>
        {numberOfAllocationColumns !== 0 && (
          <Typography.Title
            level={3}
            style={{
              position: 'absolute',
              right: `${playerAllocationCssProperty}px`,
              top: 0,
              marginTop: 0,
            }}
          >
            | Players Allocations
          </Typography.Title>
        )}
        <Popover
          content={glanceGroupAllocations}
          trigger="hover"
          placement={'top'}
        >
          <InfoCircleOutlined
            style={{
              position: 'absolute',
              right: `${playerAllocationCssProperty - 20}px`,
              top: 0,
              marginTop: 10,
            }}
          />
        </Popover>
      </div>

      <Table
        rowKey="id"
        dataSource={
          !trainerContext.showOnlyActivePlayers ? dataa : activePlayersData
        }
        columns={columns}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        pagination={false}
        bordered
        style={{ marginTop: 15, minWidth: minTableWidth }}
        scroll={{ x: true }}
        locale={noDataBehaviour}
        footer={() => {
          return (
            <div style={{ position: 'relative' }}>
              <Switch
                checked={trainerContext.showOnlyActivePlayers}
                onChange={checked => {
                  trainerContext.setShowOnlyActivePlayers(checked);
                }}
                style={{ marginLeft: 20, marginBottom: 5 }}
              />
              <p style={{ marginLeft: '8px', display: 'inline' }}>
                Only show active accounts{' '}
                <Tooltip title="Accounts with at least 1 transaction">
                  <InfoCircleOutlined />
                </Tooltip>
              </p>
              <Button style={{ position: 'absolute', right: 0, bottom: -2 }}>
                <CSVLink data={csvData()} filename={csvFilename}>
                  Download CSV
                </CSVLink>
              </Button>
            </div>
          );
        }}
      />

      {selectedPlayer && (
        <PlayerModal
          playerId={selectedPlayer}
          hide={() => {
            setSelectedPlayer(0);
            // dispatch(invalidateForwardContracts({}));
          }}
        />
      )}
    </div>
  );
};

export default PlayersTable;
