import React from 'react';

import IndexFundsTable from './IndexFundsTable';
import BenchmarkComposition from './BenchmarkComposition';

const Benchmarks: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '850px',
        margin: '0 auto',
        padding: '0 20px',
      }}
    >
      <BenchmarkComposition />
      <IndexFundsTable />
    </div>
  );
};

export default Benchmarks;
