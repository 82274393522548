import React from 'react';
import EditableTable from './EditableTable';
import { Currency, selectors, updateCurrency } from 'state';
import { useDispatch, useSelector } from 'react-redux';

const CurrenciesTab: React.FC = () => {
  const dispatch = useDispatch();
  const currencies = useSelector(selectors.currenciesList);

  return (
    <EditableTable
      data={currencies}
      onSave={(a: Currency) => {
        dispatch(updateCurrency(a));
      }}
    />
  );
};

export default CurrenciesTab;
