import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BackTop, Button, Card, InputNumber, Popconfirm, Tooltip } from 'antd';
import {
  computeBeta,
  computeVolatility,
  deleteCorrelationValues,
  fetchHistoricalStep,
  selectors,
  updateHistoricalStep,
} from 'state';
import session from '../Sessions/Session';

const HistoricalPeriod: React.FC = () => {
  const dispatch = useDispatch();
  const historicalStep = useSelector(selectors.historicalStep);
  const [step, setStep] = useState<number | undefined>(historicalStep);

  useEffect(() => {
    dispatch(fetchHistoricalStep({}));
  }, []);
  useEffect(() => {
    setStep(historicalStep);
  }, [historicalStep]);

  return (
    <Card>
      <div style={{ display: 'flex', gap: 10 }}>
        <InputNumber
          placeholder={'Seconds'}
          value={step}
          onChange={value => {
            setStep(value);
          }}
        />
        <Button
          onClick={() => {
            dispatch(updateHistoricalStep({ sleep_time: step }));
          }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            dispatch(fetchHistoricalStep({}));
            window.location.reload(true);
          }}
        >
          Fetch current value
        </Button>
      </div>
    </Card>
  );
};

export default HistoricalPeriod;
