import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Select, Switch, Space, Typography, Tooltip } from 'antd';

import {
  DynamicFormField,
  DynamicFormFieldItem,
  PercentInput,
} from 'components';
import {
  selectors,
  updateAllowIndividualBenchmark,
  updateSessionBenchmarkComposition,
  BenchmarkComposition as BenchmarkCompositionType,
  requestBenchmarkPreview,
  HISTORICAL_SCENARIO,
  clearBenchmarkPreview,
} from 'state';
import { InfoCircleOutlined } from '@ant-design/icons';
import BenchmarkPreviewModal from './BenchmarkPreviewModal';

const BenchmarkIndexComposition: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const indexFunds = useSelector(selectors.indexFundsList);

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);

  const session = useSelector(selectors.activeSession);
  const isHistorical = session.scenario_type === HISTORICAL_SCENARIO;
  const showPreviewButoon = isHistorical && !session.allow_individual_benchmark;

  const hidePreviewModal = () => {
    setShowPreviewModal(false);
  };

  useEffect(() => {
    if (session.benchmark_preview) {
      setPreviewLoading(false);
      setShowPreviewModal(true);
    } else {
      setShowPreviewModal(false);
    }
  }, [session.benchmark_preview]);
  useEffect(() => {
    // Cleanup function
    return () => {
      dispatch(clearBenchmarkPreview({}));
    };
  }, []);

  const benchmark_compositions = session.benchmark_compositions.map(item => ({
    ...item,
    weight: item.weight * 100,
  }));

  const onFinish = () => {
    const composition = form
      .getFieldValue('composition')
      .map((item: BenchmarkCompositionType) => ({
        ...item,
        weight: item.weight / 100,
      }));

    dispatch(updateSessionBenchmarkComposition({ composition }));
  };
  return (
    <Space size={15} direction="vertical" style={{ display: 'flex' }}>
      <Space size={10}>
        <Switch
          checked={session.allow_individual_benchmark}
          onClick={value => {
            dispatch(updateAllowIndividualBenchmark({ allow: value }));
          }}
        />
        <Typography.Text>
          <span style={{ whiteSpace: 'nowrap' }}>
            Allow individual benchmark
          </span>
        </Typography.Text>
        <Tooltip
          title={
            'Enable individual benchmark if you want every team to set up their own benchmark'
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Space>

      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ composition: benchmark_compositions }}
      >
        <DynamicFormField
          name="composition"
          addNewText="Add Fund"
          renderField={field => (
            <>
              <DynamicFormFieldItem
                field={field}
                label="Fund"
                name="fund"
                missingMessage="Missing Fund"
              >
                <Select style={{ width: 150 }}>
                  {indexFunds.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.ric}
                    </Select.Option>
                  ))}
                </Select>
              </DynamicFormFieldItem>

              <DynamicFormFieldItem
                field={field}
                label="Weight"
                name="weight"
                missingMessage="Missing Weight"
              >
                <PercentInput style={{ width: 75 }} />
              </DynamicFormFieldItem>
            </>
          )}
        />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          {showPreviewButoon && (
            <Button
              style={{ position: 'absolute', right: 0 }}
              type="primary"
              loading={previewLoading}
              onClick={() => {
                const composition = form
                  .getFieldValue('composition')
                  .map((item: BenchmarkCompositionType) => ({
                    ...item,
                    weight: item.weight / 100,
                  }));
                // dispatch(updateSessionBenchmarkComposition({ composition }));
                setPreviewLoading(true);
                dispatch(requestBenchmarkPreview({ composition }));
              }}
            >
              Preview
            </Button>
          )}
        </Form.Item>
      </Form>
      {showPreviewModal && (
        <BenchmarkPreviewModal
          visible={showPreviewModal}
          hide={hidePreviewModal}
        />
      )}
    </Space>
  );
};

export default BenchmarkIndexComposition;
