import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

import { FINISHED, getIndividualReport, Player, selectors, ADMIN } from 'state';
import { UNAVAILABLE, ERROR } from './constants';
import Returns from './Returns';
import ReturnPerTrade from './ReturnPerTrade';
import Trades from './Trades';
// import HoldPeriod from './HoldPeriod';
import Investments from './Investments';
import ReturnsBreakdown from './ReturnsBreakdown';

type Props = {
  player: Player;
};

const IndividualReport: React.FC<Props> = ({ player }) => {
  const dispatch = useDispatch();
  const session = useSelector(selectors.activeSession);
  const report = useSelector(selectors.individualReport(player.id));
  const userType = useSelector(selectors.userType);

  const ref = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    pageStyle: '',
  });

  useEffect(() => {
    if (!report) {
      dispatch(getIndividualReport({ player_id: player.id }));
    }
  }, [dispatch, report, player, session]);

  if (userType != ADMIN && session.status !== FINISHED) {
    return (
      <Typography.Text>
        Reports are available when the session is finished.
      </Typography.Text>
    );
  }

  if (!report || report.status === UNAVAILABLE) {
    return (
      <Typography.Text>Reports are unavailable at the moment.</Typography.Text>
    );
  }

  if (report?.status === ERROR) {
    return (
      <Typography.Text>
        An error has occurred while computing your report. Please notify the
        development team.
      </Typography.Text>
    );
  }

  return (
    <div
      ref={ref}
      className="individual-report"
      style={{ maxWidth: '1200px', margin: '0 auto' }}
    >
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography.Title level={2} style={{ marginRight: 'auto' }}>
          Individual Report: {player.name}
        </Typography.Title>
        <Button onClick={handlePrint} className="download-report">
          <DownloadOutlined />
          Download PDF
        </Button>
      </div>

      <div className="individual-report__container">
        <Returns report={report} />
        <ReturnsBreakdown report={report} />
        <ReturnPerTrade report={report} />
        <Trades report={report} />
        {/*<HoldPeriod report={report} />*/}
        <Investments report={report} />
      </div>
    </div>
  );
};

export default IndividualReport;
