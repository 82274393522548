import React from 'react';
import { useSelector } from 'react-redux';

import Chart from 'react-apexcharts';
import { Empty } from 'antd';

import { selectors } from 'state';
import { formatters } from 'services';

const BenchmarkAndFundPerformance: React.FC = () => {
  const players = useSelector(selectors.activePlayers);

  const playersData = players.map(player => {
    const metrics = player.metrics;
    const lastMetrics = metrics[metrics.length - 1];
    const { market_index_return } = lastMetrics;

    return {
      benchmark: market_index_return,
      fund: player.last_portfolio_return,
    };
  });

  const series = [
    {
      name: 'Fund',
      data: playersData.map(data => data.fund),
      type: 'bar',
    },
    {
      name: 'Benchmark',
      data: playersData.map(data => data.benchmark),
      type: 'area',
    },
  ];
  const categories = players.map(player => player.name);

  if (!series.length || !playersData.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Chart
      series={series}
      options={{
        colors: ['#34a2f9', '#aaaaaa'],
        chart: {
          width: '100%',
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
          curve: 'smooth',
        },
        fill: {
          opacity: [0.85, 0.5],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        legend: {
          show: true,
        },
        xaxis: {
          categories,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: formatters.percent2Digit,
          },
        },
      }}
    />
  );
};

export default BenchmarkAndFundPerformance;
