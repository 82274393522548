import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tabs } from 'antd';

import Orders from './Orders';
import ExchangeHistory from './ExchangeHistory';

import {
  selectors,
  CASH_APPROACH,
  fetchBlotter,
  REAL_TIME_SCENARIO,
  RISK_WEIGHTED_APPROACH,
} from 'state';
import DepositsBlotters from './DepositsBlotters';
import FxForwardsBlotter from './FxForwardsBlotter';
import ReposBlotter from './ReposBlotter';
import CDSBlotter from './CDSBlotter';
import { sessionHasCDS } from '../../../state/app/selectors';
import TBillsBlotter from './TBillsBlotter';

const BlotterTab: React.FC = () => {
  const dispatch = useDispatch();
  const { fetched_blotter, fetching_blotter } = useSelector(selectors.player);
  const { risk_approach, scenario_type } = useSelector(selectors.activeSession);
  const [tab, setTab] = useState('orders');
  const session = useSelector(selectors.activeSession);
  const hasRepos = useSelector(selectors.sessionHasRepos);
  const hasCDS = useSelector(selectors.sessionHasCDS);
  // console.log('fetched_blotter: ', fetched_blotter);
  // console.log('fetching_blotter: ', fetching_blotter);
  const hasFWD = useSelector(selectors.sessionHasFWD);
  const hasTBills = useSelector(selectors.sessionHasTBills);

  useEffect(() => {
    if (fetched_blotter || fetching_blotter) return;

    dispatch(fetchBlotter({}));
  }, [dispatch]);

  const isRealTime = session.scenario_type === REAL_TIME_SCENARIO;
  const isCashApproach = session.risk_approach === CASH_APPROACH;

  const showDeposits = isRealTime && isCashApproach;
  return (
    // <div>mergi</div>
    <div>
      <Tabs defaultActiveKey="orders" onChange={setTab}>
        <Tabs.TabPane key="orders" tab="Orders" />
        {risk_approach === CASH_APPROACH && (
          <Tabs.TabPane key="fx-spot" tab="Cash Exchange" />
        )}
        {showDeposits && <Tabs.TabPane key="deposits" tab="Deposits" />}
        {hasFWD && <Tabs.TabPane key="fx_fwds" tab="FX Forwards" />}
        {hasRepos && <Tabs.TabPane key="repo_contracts" tab="Repos" />}
        {hasCDS && <Tabs.TabPane key="CDS_contracts" tab="CDS" />}
        {hasTBills && <Tabs.TabPane key="tbills_contracts" tab="TBills" />}
      </Tabs>
      {tab === 'orders' && <Orders />}
      {tab === 'fx-spot' && <ExchangeHistory />}
      {tab === 'deposits' && <DepositsBlotters />}
      {tab === 'fx_fwds' && <FxForwardsBlotter />}
      {tab === 'repo_contracts' && <ReposBlotter />}
      {tab === 'CDS_contracts' && <CDSBlotter />}
      {tab === 'tbills_contracts' && <TBillsBlotter />}
    </div>
  );
};

export default BlotterTab;
