import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Button,
  Table,
  Popover,
  Space,
  Empty,
  Typography,
  Popconfirm,
} from 'antd';

import {
  selectors,
  ForwardContract,
  closeForwardContract,
  PAUSED,
  CDSContractType,
  RepoContractType,
  REAL_TIME_SCENARIO,
} from 'state';
import { formatters } from 'services';
import { cashExchange, useReportingCurrency } from '../../state/app/utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { CDSContractsOfInstrument } from '../../state/app/selectors';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'reusable';
import { useParams } from 'react-router';

type props = {
  visible: boolean;
  hide: () => void;
  id: number;
};

type displayContract = {
  contract: RepoContractType;
  key: number;
  name: string;
  open_date: string;
  underlying_asset_name: string;
  repo_contract_open_rate: number;
  repoed_quantity: string;
  asset_price_at_repo_open: string;
  currency: string;
  value: string;
  // reporting_unrealized_pnl: string;
};

const RepoContractsModal: React.FC<props> = ({ visible, hide, id }) => {
  const dispatch = useDispatch();

  const contracts = useSelector(selectors.RepoContractsOfInstrument(id));
  const repo_fin = useSelector(selectors.financialInstrumentById(id));
  const repo_asset = useSelector(
    selectors.underlyingAssetOfInstrument(repo_fin)
  );
  const currency = useSelector(
    selectors.currencyById(repo_asset.base_currency)
  );
  const session = useSelector(selectors.activeSession);
  const reportingCurrency = useReportingCurrency(session.id);
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);
  const scenarioDate = new Date(session.scenario_date.split('T')[0]);
  const activeContracts = contracts.filter(contract => !contract.settled);
  const data: displayContract[] = activeContracts.map(contract => {
    let contract_maturity: string;
    let open_date: string;
    if (session.scenario_type === REAL_TIME_SCENARIO) {
      contract_maturity = contract.repo_contract_maturity_date;
      open_date = contract.scenario_date_created_date;
    } else {
      const repo_contract_maturity_date_obj = new Date(
        contract.repo_contract_maturity_date
      );
      const scenario_date_obj = new Date(session.scenario_date);
      let days = Math.floor(
        (repo_contract_maturity_date_obj.getTime() -
          scenario_date_obj.getTime()) /
          (1000 * 60 * 60 * 24)
      );
      if (days === 0) {
        contract_maturity = 'today';
      } else if (days === 1) {
        contract_maturity = 'in 1 day';
      } else {
        if (days < 0) {
          days = 0;
        }
        contract_maturity = `in ${days} days`;
      }
      open_date = '--';
    }
    // const contract_maturity =
    //   session.scenario_type === REAL_TIME_SCENARIO
    //     ? contract.repo_contract_maturity_date.split('T')[0]
    //     : `in ${
    //         contract.repo_contract_maturity_date - session.scenario_date
    //       } days`;

    return {
      contract: contract,
      key: contract.id,
      name: repo_asset.name,
      underlying_asset_name: contract.underlying_asset_name,
      open_date: open_date,
      repo_contract_maturity_date: contract_maturity,
      currency: currency.symbol,
      repo_contract_open_rate: contract.repo_contract_open_rate,
      repoed_quantity: formatters.commas2Digits(contract.repoed_quantity),
      asset_price_at_repo_open: formatters.commas2Digits(
        contract.asset_price_at_repo_open
      ),
      value: formatters.commasNoDigits(
        (contract.asset_price_at_repo_open * contract.repoed_quantity) / 100
      ),
      // reporting_unrealized_pnl: formatters.commas2Digits(
      //   contract.reporting_cds_contract_pnl
      // ),
      // mkt_val: formatters.commasNoDigits(
      //   contract.cds_contract_current_mkt_value
      // ),
      // value: formatters.commas2Digits(-1 * reporting_value), MKT_VAL INSTEAD !!
    };
  });

  const columns: ColumnsType<displayContract> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Open',
      dataIndex: 'open_date',
      key: 'open_date',
    },
    {
      title: 'Maturity',
      dataIndex: 'repo_contract_maturity_date',
      key: 'repo_contract_maturity_date',
    },
    {
      title: 'Open Rate',
      dataIndex: 'repo_contract_open_rate',
      key: 'repo_contract_open_rate',
      render: (val: number) => formatters.commasExact2Digits(val),
    },
    {
      title: 'Underlying asset',
      dataIndex: 'underlying_asset_name',
      key: 'underlying_asset_name',
    },
    {
      title: `Repoed Shares`,
      dataIndex: 'repoed_quantity',
      key: 'repoed_quantity',
    },
    {
      title: 'At price',
      dataIndex: 'asset_price_at_repo_open',
      key: 'asset_price_at_repo_open',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'CCY',
      dataIndex: 'currency',
      key: 'currency',
    },
    // {
    //   title: `Mkt Val, ${reportingCurrency.symbol}`,
    //   dataIndex: 'mkt_val',
    // },
    // {
    //   title: `Unrealized, ${reportingCurrency.symbol}`,
    //   dataIndex: 'reporting_unrealized_pnl',
    //   render: (text, record) => {
    //     return record.contract
    //       ? formatters.commasNoDigits(
    //           record.contract.reporting_cds_contract_pnl
    //         )
    //       : null;
    //   },
    // },
    // {
    //   title: `Realized, ${reportingCurrency.symbol}`,
    //   dataIndex: 'contract',
    //   render: (text, record) => {
    //     return record.contract
    //       ? formatters.commasNoDigits(
    //           record.contract.cds_contract_reporting_realized_pnl
    //         )
    //       : null;
    //   },
    // },
  ];
  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`You don't have any active Repo contracts yet.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };
  console.log('before return');
  return (
    <Modal
      visible={visible}
      title={`Your active ${repo_asset.name} Repo Contracts`}
      onOk={hide}
      onCancel={hide}
      width={'70%'}
    >
      <Table
        dataSource={data}
        columns={columns}
        bordered
        locale={noDataBehaviour}
      ></Table>
    </Modal>
  );
};

export default RepoContractsModal;
