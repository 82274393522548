import React from 'react';
import { Typography } from 'antd';

import { LAYOUT1, LAYOUT2 } from 'state';
import Layout1 from './Layout1';
import Layout2 from './Layout2';
import { useLayoutProvider } from './LayoutProvider';

const LayoutEditor: React.FC = () => {
  // const { layoutType } = useLayoutProvider();
  //
  // switch (layoutType) {
  //   case LAYOUT1: {
  //     return <Layout1 />;
  //   }
  //   case LAYOUT2: {
  //     return <Layout2 />;
  //   }
  //   default:
  //     return (
  //       <Typography.Paragraph>
  //         {"You haven't selected a layout yet."}
  //       </Typography.Paragraph>
  //     );
  // }
  return <Layout1 />;
};

export default LayoutEditor;
