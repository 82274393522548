import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, Select, InputNumber } from 'antd';
import { DynamicFormField, DynamicFormFieldItem } from 'components';

import { formatters } from 'services';
import {
  BenchmarkComposition as BenchmarkCompositionType,
  clearBenchmarkPreview,
  HISTORICAL_SCENARIO,
  requestBenchmarkPreview,
  retrieveUnderlyingAssets,
  selectors,
  updateSessionBenchmarkComposition,
  utils,
} from 'state';
import BenchmarkPreviewModal from './BenchmarkPreviewModal';

const BenchmarkPortfolioComposition: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);

  useEffect(() => {
    dispatch(retrieveUnderlyingAssets({}));
  }, [dispatch]);

  const underlyingAssetsList = useSelector(selectors.underlyingAssetsList);
  const session = useSelector(selectors.activeSession);
  const reportingCurrency = utils.useReportingCurrency(session.id);

  const isHistorical = session.scenario_type === HISTORICAL_SCENARIO;
  const showPreviewButoon = isHistorical && !session.allow_individual_benchmark;

  const hidePreviewModal = () => {
    setShowPreviewModal(false);
  };

  useEffect(() => {
    if (session.benchmark_preview) {
      setPreviewLoading(false);
      setShowPreviewModal(true);
    } else {
      setShowPreviewModal(false);
    }
  }, [session.benchmark_preview]);
  useEffect(() => {
    // Cleanup function
    return () => {
      dispatch(clearBenchmarkPreview({}));
    };
  }, []);

  const onFinish = () => {
    const portfolio_composition: any[] = form.getFieldValue(
      'portfolio_composition'
    );
    dispatch(updateSessionBenchmarkComposition({ portfolio_composition }));
  };

  // backend not yet capable of handling this feature in nonUSD reporting ccy.
  const disabled = reportingCurrency.symbol !== 'USD';

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          portfolio_composition: session.benchmark_portfolio_compositions,
        }}
      >
        <DynamicFormField
          name="portfolio_composition"
          addNewText="Add Instrument"
          renderField={field => (
            <>
              <DynamicFormFieldItem
                field={field}
                label="Instrument"
                name="asset"
                missingMessage="Missing Instrument"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: 120 }}
                >
                  {underlyingAssetsList.map(item => {
                    if (item.resourcetype === 'FX') {
                      // FX is excluded for a good reason, don't change! Backend is not yet capable of handling FX paris
                      // as benchmark composition assets.
                      return;
                    }
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.ticker}
                      </Select.Option>
                    );
                  })}
                </Select>
              </DynamicFormFieldItem>

              <DynamicFormFieldItem
                field={field}
                label="Value"
                name="reporting_value"
                missingMessage="Missing Value"
              >
                <InputNumber
                  formatter={n => '$' + formatters.commasNoDigits(n)}
                  style={{ width: 145 }}
                />
              </DynamicFormFieldItem>
            </>
          )}
        />

        {disabled ? (
          <Form.Item>
            <Button disabled={true}>Unavailable</Button>
          </Form.Item>
        ) : (
          <Form.Item>
            <Button disabled={disabled} type="primary" htmlType="submit">
              Save
            </Button>
            {isHistorical && (
              <Button
                style={{ position: 'absolute', right: 0 }}
                type="primary"
                loading={previewLoading}
                onClick={() => {
                  const portfolio_composition: any[] = form.getFieldValue(
                    'portfolio_composition'
                  );
                  // dispatch(updateSessionBenchmarkComposition({ composition }));
                  setPreviewLoading(true);
                  dispatch(requestBenchmarkPreview({ portfolio_composition }));
                }}
              >
                Preview
              </Button>
            )}
          </Form.Item>
        )}
      </Form>
      {showPreviewModal && (
        <BenchmarkPreviewModal
          visible={showPreviewModal}
          hide={hidePreviewModal}
        />
      )}
    </>
  );
};

export default BenchmarkPortfolioComposition;
