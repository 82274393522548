import { appManager, AppState } from '../manager';
import * as types from '../types';

export const listStockSplits = appManager.createApi<
  unknown,
  types.StockSplit[],
  AppState
>('LIST_STOCK_SPLITS', {
  path: '/stock_splits',
  method: 'GET',
  successReducer(state, result) {
    result.forEach(s => {
      state.stock_splits.items[s.id] = s;
    });
  },
});

export const createStockSplits = appManager.createApi<
  Partial<types.StockSplit>,
  types.StockSplit,
  AppState
>('CREATE_STOCK_SPLITS', {
  path: '/stock_splits',
  method: 'POST',
  successReducer(state, result) {
    state.stock_splits.items[result.id] = result;
  },
});

export const updateStockSplits = appManager.createApi<
  Partial<types.StockSplit>,
  types.StockSplit,
  AppState
>('UPDATE_STOCK_SPLITS', {
  path: '/stock_splits/:id',
  method: 'PATCH',
  successReducer(state, result) {
    state.stock_splits.items[result.id] = result;
  },
});

export const deleteStockSplits = appManager.createApi<
  { id: number },
  types.StockSplit,
  AppState
>('DELETE_STOCK_SPLITS', {
  path: '/stock_splits/:id',
  method: 'DELETE',
  successReducer(state, result, payload) {
    delete state.stock_splits.items[payload.id];
  },
});

export const applyStockSplit = appManager.createApi<
  { id: number },
  unknown,
  AppState
>('APPLY_STOCK_SPLITS', {
  path: '/stock_splits/:id/apply',
  method: 'POST',
  successReducer() {
    //
  },
});

export const undoStockSplit = appManager.createApi<
  { id: number },
  unknown,
  AppState
>('UNDO_STOCK_SPLITS', {
  path: '/stock_splits/:id/undo',
  method: 'POST',
  successReducer() {
    //
  },
});

export const splitSessionAmounts = appManager.createApi<
  { id: number },
  unknown,
  AppState
>('SPLIT_SESSION_AMOUNTS', {
  path: '/stock_splits/:id/split_session_amounts',
  method: 'POST',
  successReducer() {
    //
  },
});

appManager.createSocketListener<{ id: number }, AppState>(
  'stock_split_applying',
  (state, result) => {
    const stockSplit = state.stock_splits.items[result.id];
    if (stockSplit) {
      stockSplit.executing = true;
    }
  }
);

appManager.createSocketListener<{ id: number; executed_at: string }, AppState>(
  'stock_split_completed',
  (state, result) => {
    const stockSplit = state.stock_splits.items[result.id];
    if (stockSplit) {
      stockSplit.executing = false;
      stockSplit.executed_at = result.executed_at;
    }
  }
);

appManager.createSocketListener<{ id: number; message: string }, AppState>(
  'stock_split_error',
  (state, result) => {
    const stockSplit = state.stock_splits.items[result.id];
    if (stockSplit) {
      stockSplit.executing = false;
    }
    if (result.message) {
      state.error = result.message;
    }
  }
);
