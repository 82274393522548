import React, { useState } from 'react';

import { Button, Form, Input, InputNumber, Typography } from 'antd';

type Props = {
  setNames: (arg0: string) => void;
};

const NamesGenerator: React.FC<Props> = ({ setNames }) => {
  const [nameFormat, setNameFormat] = useState('');
  const [start, setStart] = useState<number | undefined>(1);
  const [end, setEnd] = useState<number | undefined>(10);

  const generateNames = () => {
    const newNames = [];
    for (let i = start || 0; i <= (end || 0); i++) {
      newNames.push(nameFormat + i);
    }
    const namesString = newNames.join(',');
    setNames(namesString);
  };

  return (
    <div>
      <Typography.Title
        level={4}
        style={{ borderBottom: '1px solid #000', paddingBottom: 5 }}
      >
        Generate names
      </Typography.Title>
      <Form.Item label="Name">
        <Input
          value={nameFormat}
          onChange={e => setNameFormat(e.target.value)}
          placeholder="john"
        />
      </Form.Item>
      <Form.Item label="Start">
        <InputNumber value={start} onChange={setStart} min={0} />
      </Form.Item>
      <Form.Item label="End">
        <InputNumber value={end} onChange={setEnd} min={start} />
      </Form.Item>
      <Button type="primary" ghost onClick={generateNames}>
        Generate Names
      </Button>
    </div>
  );
};

export default NamesGenerator;
