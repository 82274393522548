import { appManager, AppState } from '../manager';

import { addElementToIndexedArray } from 'services/indexedObject';

import * as types from './types';

appManager.createSocketListener<types.News[], AppState>(
  'news_received',
  (state, result) => {
    result.forEach(res => addElementToIndexedArray(state.news.items, res));

    state.news.last = result;
  }
);
