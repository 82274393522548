import React from 'react';

import { Tooltip } from 'antd';

type Props = {
  text: string;
  maxLength: number;
};

const TextShortener: React.FC<Props> = ({ text, maxLength }) => {
  if (text.length < maxLength) return <span>{text}</span>;

  return (
    <Tooltip title={text}>
      <span>{text.slice(0, maxLength)}...</span>
    </Tooltip>
  );
};

export default TextShortener;
