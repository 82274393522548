import React from 'react';
import { Button } from 'antd';
import { useLayoutProvider } from './LayoutProvider';

const SaveButton: React.FC = () => {
  const { save } = useLayoutProvider();

  return (
    <Button onClick={save} style={{ marginTop: 10 }}>
      Save
    </Button>
  );
};

export default SaveButton;
