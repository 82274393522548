import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dropdown,
  Menu,
  Pagination,
  Space,
  Table,
  Typography,
} from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';

import { formatters, useModal } from 'services';
import {
  closePositions,
  computeIndividualReports,
  CREATING,
  deleteSession,
  FINISHED,
  finishSession,
  listOpenOrders,
  PLAYING,
  playPauseSession,
  SESSION_STATUSES,
  selectors,
  fetchPaginatedSessions,
  SCENARIO_TYPES,
  RISK_APPROACHES,
} from 'state';
import { columnSearchWidget } from 'components';
import ActionModal from './ActionModal';

export type SessionFilters = {
  status?: string[];
  klass_name?: string[];
  trainer_name?: string[];
  sorter: SessionSorter;
};

export type SessionSorter = {
  field?: string;
  order?: 'ascend' | 'descend';
};

const PAGE_SIZE = 20;
const PAGE_NUMBER = 1;

const SessionsList: React.FC = () => {
  const dispatch = useDispatch();
  const {
    items: sessions,
    waiting,
    count,
    page_number,
    page_size,
  } = useSelector(selectors.paginatedSessions);

  // Move redux state to local state
  useEffect(() => {
    setPageNumber(page_number || PAGE_NUMBER);
    setPageSize(page_size || PAGE_SIZE);
  }, [page_number, page_size]);

  // Action dropdown validation
  const [Modal, showModal] = useModal(ActionModal);

  // Pagination
  const [pageNumber, setPageNumber] = useState(PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  // Request params
  const [filters, setFilters] = useState<SessionFilters>({
    sorter: {
      field: 'id',
      order: 'descend',
    },
  });

  const params = JSON.stringify(filters);

  useEffect(() => {
    dispatch(
      fetchPaginatedSessions({
        params,
        page_number: pageNumber,
        page_size: pageSize,
      })
    );
  }, [dispatch, pageNumber, pageSize, params]);

  const statusFilters = Object.entries(SESSION_STATUSES).map(([key, value]) => {
    return { text: value, value: parseInt(key) };
  });

  const scenarioFilters = Object.entries(SCENARIO_TYPES).map(([key, value]) => {
    return { text: value, value: parseInt(key) };
  });

  const approachFilters = Object.entries(RISK_APPROACHES).map(
    ([key, value]) => {
      return { text: value, value: parseInt(key) };
    }
  );

  return (
    <div>
      <Table
        rowKey="id"
        dataSource={sessions}
        bordered
        pagination={false}
        title={() => (
          <Typography.Title level={4} style={{ margin: 0 }}>
            {waiting ? (
              <>
                Loading <LoadingOutlined style={{ color: '#108ee9' }} />
              </>
            ) : (
              <>Sessions ({count})</>
            )}
          </Typography.Title>
        )}
        footer={() => (
          <Pagination
            pageSize={pageSize}
            total={count || 0}
            current={pageNumber}
            showSizeChanger
            onChange={page => {
              setPageNumber(page);
            }}
            onShowSizeChange={(page, size) => {
              setPageNumber(PAGE_NUMBER);
              setPageSize(size || PAGE_SIZE);
            }}
          />
        )}
        onChange={(pagination, antFilters, antSorter) => {
          const { order, field } = antSorter as never;
          const sorter = order !== undefined ? { order, field } : {};
          setFilters({ ...antFilters, sorter } as never);
        }}
        columns={[
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            // sorter: (a, b) => a.id - b.id,
            sorter: () => 0,
            defaultSortOrder: 'descend',
            width: '50px',
          },
          {
            title: 'Class',
            dataIndex: 'klass_name',
            key: 'klass_name',
            // sorter: (a, b) => a.klass_name.localeCompare(b.klass_name),
            sorter: () => 0,
            ...columnSearchWidget({ dataIndex: 'klass_name' }),
          },
          {
            title: 'Trainer',
            dataIndex: 'trainer_name',
            key: 'trainer_name',
            // sorter: (a, b) => a.trainer_name.localeCompare(b.trainer_name),
            sorter: () => 0,
            ...columnSearchWidget({ dataIndex: 'trainer_name' }),
          },
          {
            title: 'Create Date',
            dataIndex: 'create_date',
            key: 'create_date',
            width: '200px',
            render: text => formatters.dateWithTime(text),
            // sorter: (a, b) => a.create_date.localeCompare(b.create_date),
            sorter: () => 0,
          },
          {
            title: 'Scenario',
            dataIndex: 'scenario_type',
            key: 'scenario_type',
            render: text => SCENARIO_TYPES[text as 0 | 1],
            filters: scenarioFilters,
          },
          {
            title: 'Approach',
            dataIndex: 'risk_approach',
            key: 'risk_approach',
            render: text => RISK_APPROACHES[text as 0 | 1],
            filters: approachFilters,
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // sorter: (a, b) => a.status - b.status,
            sorter: () => 0,
            render: (status: 0 | 1 | 2 | 3) => SESSION_STATUSES[status],
            filters: statusFilters,
            // onFilter: (value, record) => value === record.status,
            width: '100px',
          },
          {
            title: 'Assets',
            dataIndex: 'underlying_assets',
            key: 'underlying_assets',
            width: '50px',
            // sorter: (a, b) =>
            //   a.underlying_assets.length - b.underlying_assets.length,
            sorter: () => 0,
            render: assets => assets.length,
          },
          {
            title: 'Actions',
            dataIndex: 'status',
            width: '50px',
            render: function Status(status: 0 | 1 | 2 | 3, record) {
              const menu = (
                <Menu>
                  <Menu.Item>
                    <Space>
                      <Button
                        style={{ width: 71 }}
                        disabled={record.status === FINISHED}
                        onClick={() => {
                          dispatch(
                            playPauseSession({
                              id: record.id,
                              current_status: record.status,
                            })
                          );
                        }}
                      >
                        {record.status === PLAYING ? 'Pause' : 'Play'}
                      </Button>
                      <Button
                        style={{ width: 71 }}
                        disabled={
                          record.status === FINISHED ||
                          record.status === CREATING
                        }
                        danger
                        onClick={() => {
                          showModal({
                            title: `End Session ${record.id}`,
                            action: finishSession({ id: record.id }),
                            message:
                              'Are you sure you want to end this session?',
                          });
                        }}
                      >
                        End
                      </Button>
                    </Space>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      style={{ width: 150 }}
                      onClick={() => {
                        showModal({
                          title: 'Close positions',
                          action: closePositions({ session_id: record.id }),
                          message:
                            'Are you sure you want to close all positions?',
                        });
                      }}
                    >
                      Close positions
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      style={{ width: 150 }}
                      disabled={record.status === CREATING}
                      onClick={() => {
                        showModal({
                          title: 'Compute individual reports',
                          action: computeIndividualReports({
                            session_id: record.id,
                          }),
                          message:
                            'Are you sure you want to compute reports?\n' +
                            'This will delete previous individual reports for session.',
                        });
                      }}
                    >
                      Compute Reports
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      onClick={() => {
                        dispatch(listOpenOrders({ session_id: record.id }));
                      }}
                      style={{ width: 150 }}
                    >
                      Open Orders
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      onClick={() => {
                        showModal({
                          title: `Delete session ${record.id}`,
                          action: deleteSession({
                            id: record.id,
                          }),
                          message:
                            'Are you sure you want to delete this session?',
                        });
                      }}
                      danger
                      style={{ width: 150 }}
                    >
                      Delete session
                    </Button>
                  </Menu.Item>
                </Menu>
              );

              return (
                <Dropdown
                  overlay={menu}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <Button>
                    <DownOutlined />
                  </Button>
                </Dropdown>
              );
            },
          },
          {
            title: 'Select',
            key: 'select',
            width: '80px',
            render: function Action(text, record) {
              return (
                <Space key={record.id} size="middle">
                  <Link to={`/dashboard/sessions/${record.id}/players`}>
                    <Button>Select</Button>
                  </Link>
                </Space>
              );
            },
          },
        ]}
      />

      {Modal}
    </div>
  );
};

export default SessionsList;
