import React from 'react';

import { Typography, Divider } from 'antd';
import Chart from 'react-apexcharts';

import { formatters } from 'services';

import type { IndividualReport } from 'state';

type Props = {
  report: IndividualReport;
};

const ReturnsBreakdown: React.FC<Props> = ({ report }) => {
  const { returns_breakdown } = report;

  const { geographySeries, geographyCategories } = Object.entries(
    returns_breakdown.return_per_geography
  ).reduce(
    (acc, [geography, value]) => {
      if (value !== 0) {
        acc.geographySeries.data.push(value);
        acc.geographyCategories.push(geography);
      }
      return acc;
    },
    {
      geographySeries: { data: [] as Array<number>, name: 'Return' },
      geographyCategories: [] as Array<string>,
    }
  );

  const { assetClassSeries, assetClassCategories } = Object.entries(
    returns_breakdown.return_per_asset_class
  ).reduce(
    (acc, [assetClass, value]) => {
      if (value !== 0) {
        acc.assetClassSeries.data.push(value);
        acc.assetClassCategories.push(assetClass);
      }

      return acc;
    },
    {
      assetClassSeries: { data: [] as Array<number>, name: 'Return' },
      assetClassCategories: [] as Array<string>,
    }
  );

  const { instrumentSeries, instrumentCategories } = Object.entries(
    returns_breakdown.return_per_instrument
  ).reduce(
    (acc, [asset_name, value]) => {
      if (value !== 0) {
        acc.instrumentSeries.data.push(value);
        const [, instrument_name] = asset_name.split('-');
        acc.instrumentCategories.push(instrument_name);
      }

      return acc;
    },
    {
      instrumentSeries: { data: [] as Array<number>, name: 'Return' },
      instrumentCategories: [] as Array<string>,
    }
  );

  return (
    <div>
      <Divider orientation="left">
        <Typography.Title level={3}>Returns Breakdown</Typography.Title>
      </Divider>
      <div
        className="flex-item"
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          gap: '30px',
        }}
      >
        <div style={{ flex: 1 }}>
          <Chart
            series={[geographySeries]}
            type="bar"
            options={{
              chart: {
                type: 'bar',
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: false,
                },
              },
              title: {
                text: 'Return per Geography',
                style: {
                  fontSize: '18px',
                  fontWeight: 400,
                },
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [
                      {
                        from: -100_000_000_000,
                        to: 0,
                        color: '#F15B46',
                      },
                    ],
                  },
                  columnWidth: '50%',
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
              },
              legend: {
                show: true,
              },
              xaxis: {
                categories: geographyCategories,
                labels: {
                  style: {
                    fontSize: '12px',
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: formatters.million,
                },
              },
            }}
          />
        </div>

        <div style={{ flex: 1 }}>
          <Chart
            series={[assetClassSeries]}
            type="bar"
            options={{
              chart: {
                type: 'bar',
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: false,
                },
              },
              title: {
                text: 'Return per Asset Class',
                style: {
                  fontSize: '18px',
                  fontWeight: 400,
                },
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [
                      {
                        from: -100_000_000_000,
                        to: 0,
                        color: '#F15B46',
                      },
                    ],
                  },
                  columnWidth: '50%',
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
              },
              legend: {
                show: true,
              },
              xaxis: {
                categories: assetClassCategories,
                labels: {
                  style: {
                    fontSize: '12px',
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: formatters.million,
                },
              },
            }}
          />
        </div>
      </div>

      <div
        className="flex-item"
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          gap: '30px',
        }}
      >
        <div style={{ flex: 1 }}>
          <Chart
            series={[instrumentSeries]}
            type="bar"
            options={{
              chart: {
                type: 'bar',
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: false,
                },
              },
              title: {
                text: 'Return per Instrument',
                style: {
                  fontSize: '18px',
                  fontWeight: 400,
                },
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [
                      {
                        from: -100_000_000_000,
                        to: 0,
                        color: '#F15B46',
                      },
                    ],
                  },
                  columnWidth: '50%',
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
              },
              legend: {
                show: true,
              },
              xaxis: {
                categories: instrumentCategories,
                labels: {
                  style: {
                    fontSize: '12px',
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: formatters.million,
                },
              },
            }}
          />
        </div>
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
};

export default ReturnsBreakdown;
