import React, { useEffect } from 'react';
import { Button, InputNumber, Space, Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  BUY,
  ExecutionErrorType,
  ORDER_STATUSES,
  PlayerWithDifferences,
  retrieveExecutionErrors,
  retrievePlayersWithDiffNumbers,
  retrieveTriedAndFailedOrders,
  selectors,
  TRANSACTION_TYPES,
  TriedAndFailedOrderType,
} from 'state';
import { formatters } from 'services';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import { dateWithTimeSeconds } from 'services/formatters';
import {
  playersWithDifferencesSelector,
  triedAndFailedOrdersSelector,
} from '../../../state/app/selectors';
import { ReloadOutlined } from '@ant-design/icons';

const { Title } = Typography;

const PlayersWithDifferences: React.FC = () => {
  const dispatch = useDispatch();
  const playersWithDifferences = useSelector(
    selectors.playersWithDifferencesSelector
  );
  const playersWithDifferencesList = Object.values(
    playersWithDifferences.items
  );

  const [difference, setDifference] = React.useState(50_000);

  useEffect(() => {
    if (!playersWithDifferences.fetched) {
      dispatch(retrievePlayersWithDiffNumbers({ difference: difference }));
    }
  }, []);

  const columns: ((
    | ColumnGroupType<PlayerWithDifferences>
    | ColumnType<PlayerWithDifferences>
  ) & {
    dataIndex: string;
  })[] = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Session',
      dataIndex: 'session',
    },
    {
      title: 'Class',
      dataIndex: 'klass',
    },
    {
      title: 'Player',
      dataIndex: 'player',
    },
    {
      title: 'Mkt Val',
      dataIndex: 'last_market_value',
      render: val => formatters.commasNoDigits(val),
    },
    {
      title: 'Return',
      dataIndex: 'last_portfolio_return',
      render: val => formatters.percent3Digit(val),
    },
    {
      title: 'Diff.',
      dataIndex: 'difference',
      render: val => formatters.commasNoDigits(Math.abs(val)),
    },
  ];

  return (
    <div>
      <Space>
        <Typography.Title level={4}>Diff. Players | </Typography.Title>
        <div style={{ paddingBottom: 10 }}>
          <InputNumber
            value={difference}
            formatter={formatters.commasNoDigits}
            onChange={val => {
              if (val) setDifference(val);
            }}
            step={10_000}
            style={{ marginRight: 10, width: 120 }}
          />
          <Button
            onClick={() =>
              dispatch(
                retrievePlayersWithDiffNumbers({ difference: difference })
              )
            }
          >
            <ReloadOutlined />
          </Button>
        </div>
      </Space>
      <Table
        // style={{ width: '1000px' }}
        columns={columns}
        dataSource={playersWithDifferencesList}
        pagination={{ pageSize: 10 }}
        bordered
      />
    </div>
  );
};
// deploy
export default PlayersWithDifferences;
