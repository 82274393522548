import Cookies from 'js-cookie';
import apiCaller from 'services/Api/ApiCaller';
import { API_URL } from 'settings';
import jwtDecode from 'jwt-decode';

export interface DecodedToken {
  exp: number;
  iat: number;
  is_admin: boolean;
  jti: string;
  token_type: string;
  user_id: number;
  user_type: number;
  username: string;
}

export default async function getCsrfToken() {
  // console.log('WITHIN CSRF FUNCTION');
  const { result, status } = await apiCaller({
    path: '/get_token',
    method: 'GET',
    data: {},
    token: Cookies.get('token'),
    apiUrl: `${API_URL}/csrf`,
  });
  // console.log('CSRF RESULT ----------------');
  // console.log(result);
  // Cookies.set('csrf_token', result.csrf_token); cookie set by the server
}
