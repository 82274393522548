import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment, { Moment } from 'moment';

import { Col, Row, Card, Form, Space, Tooltip, Button, TimePicker } from 'antd';

import {
  PlusOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { selectors, updateTradingIntervals } from 'state';

const timeFormat = 'HH:mm';

const Benchmarks: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { trading_intervals } = useSelector(selectors.activeSession);

  const tradingIntervals = trading_intervals.map(interval => ({
    interval: [
      moment(interval.start, timeFormat),
      moment(interval.end, timeFormat),
    ],
  }));

  const onFinish = () => {
    const intervals = form.getFieldValue('intervals').map((item: any) => ({
      start: item.interval[0].format(timeFormat),
      end: item.interval[1].format(timeFormat),
    }));

    dispatch(updateTradingIntervals({ intervals }));
  };

  return (
    <div>
      <Row justify="center">
        <Col span={12}>
          <Card
            title={
              <span>
                Trading windows (GMT+0){' '}
                <Tooltip
                  placement="bottomLeft"
                  title={
                    <div>
                      <p>At least one trading window required</p>
                      <p>
                        You might set an interval from 00:00 to 23:59 if you
                        want to allow for continuous trading
                      </p>
                    </div>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{
                intervals: tradingIntervals,
              }}
            >
              <Form.List name="intervals">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(field => (
                        <Space key={field.key}>
                          <Form.Item
                            {...field}
                            label="Interval"
                            name={[field.name, 'interval']}
                            fieldKey={[field.fieldKey, 'interval'] as any}
                            rules={[
                              { required: true, message: 'Missing Interval' },
                            ]}
                          >
                            <TimePicker.RangePicker
                              picker="time"
                              format={timeFormat}
                              allowClear={false}
                            />
                          </Form.Item>

                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Space>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add({
                              interval: [
                                moment('00:00', timeFormat),
                                moment('13:00', timeFormat),
                              ],
                            });
                          }}
                          block
                        >
                          <PlusOutlined /> Add Interval
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Benchmarks;
