import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Button,
  Radio,
  InputNumber,
  Card,
  Row,
  Col,
  message,
  Typography,
  Popover,
} from 'antd';

import {
  selectors,
  BUY,
  buyForwardContract,
  SELL,
  utils,
  enterCDSContract,
  FROZEN,
  PAUSED,
  enterTBillContract,
} from 'state';
import { formatters } from 'services';
import { cashExchange, getFWDCurrencyPair } from '../../state/app/utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { auctions_tenors } from '../../state/app/auctions/types';

type props = {
  visible: boolean;
  hide: () => void;
  id: number;
};

const TBillModal: React.FC<props> = ({ visible, hide, id }) => {
  const dispatch = useDispatch();
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);
  const session = useSelector(selectors.activeSession);
  const financialInstrument = useSelector(
    selectors.financialInstrumentById(id)
  );
  const underlyingAsset = useSelector(
    selectors.underlyingAssetOfInstrument(financialInstrument)
  );
  const asset_base_currency = useSelector(
    selectors.currencyById(underlyingAsset.base_currency)
  );
  const [quantity, setQuantity] = useState(100);
  const player = useSelector(selectors.player);
  const [transaction, setTransaction] = useState(BUY);
  const reportingCurrency = useSelector(
    selectors.currencyById(session.reporting_currency)
  );
  const price = transaction === BUY ? underlyingAsset.ask : underlyingAsset.bid;

  const maxQuantity = Math.floor(underlyingAsset.available_interplayer_amount);

  const { name } = underlyingAsset;

  const wallet = player.wallets.find(
    wallet => underlyingAsset.variable_currency === wallet.currency
  );

  if (!wallet) {
    message.error('Wallet not found!');
    return null;
  }

  const sign = transaction === BUY ? 1 : -1;
  const labelColWidth = '100px';

  // const daysLeft = '19 days';
  let maturity: string;

  const maturityDate = new Date(underlyingAsset.scenario_date_maturity_date);
  const sessionDate = new Date(session.scenario_date);
  const days = Math.floor(
    (maturityDate.getTime() - sessionDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  if (days === 0) {
    maturity = 'today';
  } else if (days === 1) {
    maturity = 'in 1 day';
  } else {
    if (days < 0) {
      maturity = 'matured';
    } else {
      maturity = `in ${days} days`;
    }
  }

  const style =
    quantity > maxQuantity ? { width: 130, color: 'red' } : { width: 130 };
  return (
    <Modal
      visible={visible}
      title={`${name} TBill Contract`}
      onOk={hide}
      onCancel={hide}
      width={600}
      footer={[
        <Button
          key="place"
          type="primary"
          disabled={
            quantity <= 0 ||
            // quantity > maxQuantity ||
            session.status === FROZEN ||
            session.status === PAUSED ||
            maturity === 'matured' ||
            maturity === 'today'
          }
          onClick={() => {
            dispatch(
              enterTBillContract({
                fin_id: financialInstrument.id,
                amount: sign * quantity,
                // transaction: transaction,
              })
            );
            hide();
          }}
        >
          {transaction === BUY ? 'Buy TBill' : 'Short TBill'}
        </Button>,
      ]}
    >
      <Row gutter={[20, 20]}>
        <Col
          flex={labelColWidth}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <Typography.Text>Tenor: </Typography.Text>
        </Col>
        <Col>
          {auctions_tenors[underlyingAsset.tbill_tenor]} (maturity: {maturity})
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col
          flex={labelColWidth}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <Typography.Text>Transaction: </Typography.Text>
        </Col>
        <Col>
          <Radio.Group
            onChange={e => setTransaction(e.target.value)}
            value={transaction}
          >
            <Radio value={BUY}>Buy</Radio>
            {/*shorting is disabled because it has to be a centralized short supply and it'll be a different mechanism*/}
            <Radio value={SELL} disabled={true}>
              Short
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col
          flex={labelColWidth}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <Typography.Text>Price: </Typography.Text>
        </Col>
        <Col>
          {maturity === 'matured' ? (
            '--'
          ) : (
            <div>{formatters.commas2Digits(price)} %</div>
          )}
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col
          flex={labelColWidth}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <Typography.Text>Liquidity: </Typography.Text>
        </Col>
        <Col>
          {maturity === 'matured' ? (
            '--'
          ) : (
            <div>
              {`${formatters.commasNoDigits(maxQuantity)}`}{' '}
              <Popover
                placement={'right'}
                content={
                  <div style={{ width: 350 }}>
                    <Row gutter={[20, 20]}>
                      <Col
                        flex={'170px'}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right',
                        }}
                      >
                        <Typography.Text>Total issued amount: </Typography.Text>
                      </Col>
                      <Col>
                        {formatters.commasNoDigits(
                          Math.floor(underlyingAsset.initial_issued_amount)
                        )}{' '}
                        @{' '}
                        {formatters.commas2Digits(
                          underlyingAsset.price_at_issue
                        )}
                      </Col>
                      <Col
                        flex={'170px'}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right',
                        }}
                      >
                        <Typography.Text>Inter-player float: </Typography.Text>
                      </Col>
                      <Col>
                        {formatters.commasNoDigits(
                          Math.floor(
                            underlyingAsset.available_interplayer_amount
                          )
                        )}
                      </Col>
                    </Row>
                  </div>
                }
              >
                <InfoCircleOutlined />
              </Popover>
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col
          flex={labelColWidth}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <Typography.Text>Value: </Typography.Text>
        </Col>
        <Col>
          <InputNumber
            value={quantity}
            min={0}
            // max={maxQuantity}
            style={style}
            formatter={formatters.commasNoDigits}
            onChange={val => setQuantity(val || 0)}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col
          flex={labelColWidth}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <Typography.Text>Discounted: </Typography.Text>
        </Col>
        <Col>
          {maturity === 'matured' ? (
            '--'
          ) : (
            <div>
              {formatters.commas2Digits((quantity * price) / 100)}{' '}
              {reportingCurrency.symbol}
            </div>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default TBillModal;
