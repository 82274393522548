import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Typography } from 'antd';

import { selectors } from 'state';
import SectorTable from './SectorTable';
import SectorBarChart from './SectorBarChart';
import SectorPieChart from './SectorPieChart';

const ReturnByIndustry: React.FC = () => {
  const { allow_short_sell } = useSelector(selectors.activeSession);

  const Chart = allow_short_sell ? SectorBarChart : SectorPieChart;

  return (
    <Row style={{ flexFlow: 'row nowrap' }} gutter={10}>
      <Col flex="1">
        <Typography.Title level={3}>
          Return by Sector (Equities)
        </Typography.Title>
        <SectorTable />
      </Col>
      <Col flex="1">
        <Typography.Title level={3}>Allocation by Sector</Typography.Title>
        <Chart />
      </Col>
    </Row>
  );
};

export default ReturnByIndustry;
