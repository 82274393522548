import Cookies from 'js-cookie';
import apiCaller from 'services/Api/ApiCaller';
import { API_URL } from 'settings';
import jwtDecode from 'jwt-decode';

export interface DecodedToken {
  exp: number;
  iat: number;
  is_admin: boolean;
  jti: string;
  token_type: string;
  user_id: number;
  user_type: number;
  username: string;
}

export default async function refreshTokenIfNeeded() {
  if (Cookies.get('token') === undefined) return undefined;

  const currentTokenExpiry = parseInt(Cookies.get('token_exp') || '10', 10);
  const currentTimeEpoch = Math.floor(Date.now() / 1000);
  if (currentTokenExpiry <= currentTimeEpoch) {
    // Token expired, refreshing...
    const { result, status } = await apiCaller({
      path: '/refresh_token',
      method: 'POST',
      data: { refresh: Cookies.get('refresh') },
      token: Cookies.get('token'),
      apiUrl: `${API_URL}/auth`,
    });
    // save new token
    Cookies.set('token', result.access, { sameSite: 'Strict' });
    // save new expiry time
    const decodedToken = jwtDecode(result.access) as DecodedToken;
    Cookies.set('token_exp', decodedToken['exp'].toString(), {
      sameSite: 'Strict',
    });
    return result.access;
  }
  // Token was valid, return it
  return Cookies.get('token');
}
