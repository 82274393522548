let BASE_URL: string;
let API_URL: string;
let SOCKET_URL: string;
let MEDIA_URL: string;
let CSRF_COOKIE_NAME: string;

switch (process.env.REACT_APP_GOAL) {
  case 'production': {
    BASE_URL = 'pm-prod.traderion.com';
    API_URL = `https://${BASE_URL}/api`;
    SOCKET_URL = `wss://${BASE_URL}/api`;
    MEDIA_URL = 'https://pm.api.s3.amazonaws.com/media';
    CSRF_COOKIE_NAME = 'pm_prod_csrftoken';
    break;
  }
  case 'stage': {
    BASE_URL = 'pm-stg.traderion.com';
    API_URL = `https://${BASE_URL}/api`;
    SOCKET_URL = `wss://${BASE_URL}/api`;
    MEDIA_URL = 'https://pm.stage.api.s3.amazonaws.com/media';
    CSRF_COOKIE_NAME = 'pm_stage_csrftoken';

    break;
  }
  default: {
    BASE_URL = '127.0.0.1:8000';
    // BASE_URL = '0.0.0.0:8000';
    API_URL = `http://${BASE_URL}/api`;
    SOCKET_URL = `ws://${BASE_URL}/api`;
    MEDIA_URL = `http://${BASE_URL}`;
    CSRF_COOKIE_NAME = 'pm_local_csrftoken';
  }
}

export { BASE_URL, API_URL, SOCKET_URL, MEDIA_URL, CSRF_COOKIE_NAME };
