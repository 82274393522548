import {
  Currency,
  FinancialInstrument,
  MarketHistory,
  TBillContractType,
  UnderlyingAsset,
  Wallet,
} from 'state';

export type baseAuctionType = {
  tenor: number;
  // tenor_unit: number;
  auction_series: string;
  maturity_date: Date;
  bid_value_no_units: number;
  bid_unit: number;
  currency: number;
  rate: number | null;
  rate_difference_from_historical_rate: number;
};

export type createAuctionType = baseAuctionType & { session_id: number };
export type receiveCreatedAuctionType = baseAuctionType & {
  id: number;
  bid_value: number;
  initial_ask: number;
  session_id: number;
  responses: AuctionResponseType[];
  finished: boolean;
  currency_symbol: string;
  issued_value: number;
};

export type YieldsType = {
  ccy_symbol: string;
  yields: {
    wk4: number;
    wk8: number;
    wk13: number;
    wk26: number;
    wk52: number;
    yr2: number;
    yr3: number;
    yr5: number;
    yr7: number;
    yr10: number;
    yr30: number;
  };
};

export type sendAuctionResponseType = {
  auction: number;
  player: number;
  initial_bid_value: number;
  // bid_unit: number;
};

export type AuctionResponseType = sendAuctionResponseType & {
  id: number;
  actual_value: number;
  player_name: string; // for trainer notifications
  currency_symbol: string; // symbol
};

export type IssueSecurityType = {
  auction_id: number;
};

export const auctions_tenors: { [index: number]: string } = {
  0: '4WK',
  1: '8WK',
  2: '13WK',
  3: '26WK',
  4: '52WK',
  5: '2YR',
  6: '3YR',
  7: '5YR',
  8: '7YR',
  9: '10YR',
  10: '30YR',
};

export type auctionFinishedSocketType = {
  auction_id: number;
  finished: boolean;
  asset: UnderlyingAsset;
  bid: number;
  ask: number;
  fins: FinancialInstrument[];
  wallets: Wallet[];
  market_history: MarketHistory[];
  issued_value: number;
};

export const ONGOING_TBILL_CONTRACT = 0;
export const MATURED_TBILL_CONTRACT = 1;
export const SOLD_BEFORE_TERM_TBILL_CONTRACT = 2;
