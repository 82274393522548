import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Spin, Typography } from 'antd';

import {
  listPlayerDividends,
  selectors,
  REAL_TIME_SCENARIO,
  retrieveAdminPlayer,
  CASH_APPROACH,
} from 'state';
import { formatters } from 'services';
import { LoadingScreen } from 'components';
import Deposits from './Deposits';
import { ColumnsType } from 'antd/es/table';
import { CashTableType } from '../../../../TraineeSession/MarketsTab/CashTab';

type Props = {
  playerId: number;
};

const PlayerCash: React.FC<Props> = ({ playerId }) => {
  const dispatch = useDispatch();
  const session = useSelector(selectors.activeSession);
  const player = useSelector(selectors.playerById(playerId));
  const currencies = useSelector(selectors.currencies);
  const assets = useSelector(selectors.underlyingAssets);

  const instruments = player?.financial_instruments || ({} as any);
  const playableSymbols = useSelector(
    selectors.playableCurrencies(Object.values(instruments))
  );

  const { waiting: divWaiting } = useSelector(selectors.dividendsApi);

  useEffect(() => {
    if (!player?.retrieved_full) {
      dispatch(retrieveAdminPlayer({ player_id: playerId }));
    }
  }, [player]);

  useEffect(() => {
    if (player && player.dividends === undefined) {
      dispatch(listPlayerDividends({ player_id: playerId }));
    }
  }, [player]);

  if (!player?.retrieved_full || divWaiting) {
    return <LoadingScreen />;
  }

  const playableWallets = player.wallets.filter(wallet => {
    return playableSymbols.includes(currencies[wallet.currency].symbol);
  });

  const data = playableWallets.map(wallet => {
    const outstanding = Object.values(player?.dividends || {})
      .filter(div => {
        return div.currency === wallet.currency;
      })
      .reduce((acc, div) => {
        return acc + div.outstanding;
      }, 0);

    return {
      ...currencies[wallet.currency],
      ...wallet,
      outstanding,
    };
  });

  const isRealTime = session.scenario_type === REAL_TIME_SCENARIO;
  const isCashApproach = session.risk_approach === CASH_APPROACH;
  const showDeposits = isRealTime && isCashApproach;

  const sessionHasNoEquities = Object.values(assets).every(
    asset => asset.resourcetype !== 'Equity'
  );

  const columns: ColumnsType<CashTableType> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      align: 'left',
    },
    {
      title: 'Symbol',
      key: 'symbol',
      dataIndex: 'symbol',
      align: 'left',
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      align: 'right',
      render: formatters.commasNoDigits,
    },
    // {
    //   title: 'Collateral Amount',
    //   dataIndex: 'collateral_amount',
    //   key: 'collateral_amount',
    //   render: formatters.commasNoDigits,
    //   hidden: !collateralExists,
    // },
  ];

  if (showDeposits) {
    columns.push({
      title: 'Accrued interest',
      key: 'total_currency_interest',
      dataIndex: 'total_currency_interest',
      align: 'right',
      render: formatters.commasNoDigits,
    });
  }

  if (!sessionHasNoEquities) {
    columns.push({
      title: 'Outstanding DIV',
      key: 'outstanding',
      dataIndex: 'outstanding',
      align: 'right',
      render(value: number) {
        if (divWaiting) {
          return <Spin size="small" />;
        }
        return formatters.commas2Digits(value);
      },
    });
  }

  return (
    <div>
      <Typography.Title level={4} style={{ marginTop: 16 }}>
        Cash Accounts
      </Typography.Title>

      <Table
        rowKey="id"
        bordered
        dataSource={data}
        pagination={false}
        style={{ overflow: 'hidden' }}
        columns={columns}
      />

      <br />

      {showDeposits && (
        <div>
          <Typography.Title level={4} style={{ marginTop: 16 }}>
            Deposits
          </Typography.Title>
          <Deposits playerId={playerId} />
        </div>
      )}
    </div>
  );
};

export default PlayerCash;
