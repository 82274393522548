import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, InputNumber, message } from 'antd';

import { selectors, createODA, updateOda, utils, fetchBlotter } from 'state';

type Props = {
  visible: boolean;
  hide: () => void;
  id: number;
};

const ODAModal: React.FC<Props> = ({ visible, hide, id }) => {
  const dispatch = useDispatch();

  const session = useSelector(selectors.activeSession);
  const { fetched_blotter, fetching_blotter } = useSelector(selectors.player);
  const financialInstrument = useSelector(
    selectors.financialInstrumentById(id)
  );
  const underlyingAsset = useSelector(
    selectors.underlyingAssetOfInstrument(financialInstrument)
  );

  const oda = useSelector(selectors.odaOfInstrumentCreateSelector(id));

  const { stopLoss, takeProfit } = oda ?? {};

  const currentPrice = useMemo(() => {
    const instrumentAmount = financialInstrument.amount;
    const currentBid = underlyingAsset.bid;
    const currentAsk = underlyingAsset.ask;
    return instrumentAmount > 0 ? currentBid : currentAsk;
  }, [underlyingAsset, financialInstrument]);

  // const [isOpen] = utils.assetIsOpen(underlyingAsset, session);

  const [stopLossPrice, setStopLossPrice] = useState<number | undefined>(
    stopLoss?.limit_price || currentPrice
  );
  const [takeProfitPrice, setTakeProfitPrice] = useState<number | undefined>(
    takeProfit?.limit_price || currentPrice
  );

  // Pull orders if not already
  useEffect(() => {
    if (fetching_blotter || fetched_blotter) {
      return;
    }
    dispatch(fetchBlotter({}));
  }, [dispatch, fetching_blotter, fetched_blotter]);

  // Set new price
  useEffect(() => {
    setStopLossPrice(stopLoss?.limit_price || currentPrice);
    setTakeProfitPrice(takeProfit?.limit_price || currentPrice);
  }, [currentPrice, stopLoss, takeProfit]);

  // if (!isOpen) {
  //   return null;
  // }

  if (oda !== undefined && (!stopLoss || !takeProfit)) {
    return null;
  }

  const { name } = underlyingAsset;

  const stopTooHigh =
    financialInstrument.amount > 0 &&
    typeof stopLossPrice === 'number' &&
    stopLossPrice > currentPrice;
  const stopTooLow =
    financialInstrument.amount < 0 &&
    typeof stopLossPrice === 'number' &&
    stopLossPrice < currentPrice;
  const takeTooHigh =
    financialInstrument.amount < 0 &&
    typeof takeProfitPrice === 'number' &&
    takeProfitPrice > currentPrice;
  const takeTooLow =
    financialInstrument.amount > 0 &&
    typeof takeProfitPrice === 'number' &&
    takeProfitPrice < currentPrice;

  return (
    <Modal
      visible={visible}
      title={`ODA ${name}`}
      onOk={hide}
      onCancel={hide}
      footer={[
        <Button
          key="place"
          type="primary"
          onClick={() => {
            const fn = oda ? updateOda : createODA;

            if (stopLossPrice === undefined || takeProfitPrice === undefined) {
              message.error('Prices must be numbers!');
              return;
            }

            if (stopTooHigh) {
              message.error(
                'StopLossPrice has to be lower than current price!'
              );
              return;
            }
            if (takeTooLow) {
              message.error(
                'TakeProfitPrice has to be higher than current price!'
              );
              return;
            }

            if (stopTooLow) {
              message.error(
                'StopLossPrice has to be higher than current price!'
              );
              return;
            }
            if (takeTooHigh) {
              message.error(
                'TakeProfitPrice has to be lower than current price!'
              );
              return;
            }

            dispatch(
              fn({
                financial_instrument: financialInstrument.id,
                stop_loss_price: stopLossPrice,
                take_profit_price: takeProfitPrice,
              })
            );
            hide();
          }}
        >
          {oda ? 'Update ODA' : 'Set ODA'}
        </Button>,
      ]}
    >
      Stop Loss:{' '}
      <InputNumber
        value={stopLossPrice}
        // min={instrumentAmount > 0 ? undefined : currentPrice}
        // max={instrumentAmount > 0 ? currentPrice : undefined}
        onChange={val => {
          setStopLossPrice(val);
        }}
      />
      <br />
      <br />
      Take Profit{' '}
      <InputNumber
        value={takeProfitPrice}
        // min={instrumentAmount > 0 ? currentPrice : undefined}
        // max={instrumentAmount > 0 ? undefined : currentPrice}
        onChange={val => setTakeProfitPrice(val || 0)}
      />
    </Modal>
  );
};

export default ODAModal;
