import React from 'react';
import { useSelector } from 'react-redux';

import { Badge, Collapse, Divider, Spin, Tooltip, Typography } from 'antd';

import InstrumentTable from './InstrumentTable';
import {
  FinancialInstrument,
  selectors,
  REGIONS,
  APAC,
  EUROPE,
  NAM,
  LATAM,
  ME,
  HISTORICAL_SCENARIO,
  AFRICA,
  utils,
  WEST_AFRICA,
  GLOBAL,
} from 'state';
import { formatters } from 'services';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';

type props = {
  financialInstruments: FinancialInstrument[];
  selectedInstrumentId: number;
  selectInstrument: (instrument: FinancialInstrument) => void;
  showOrderModal: () => void;
  showHedgeOrderModal: () => void;
  showRepoModal: () => void;
  showCDSModal: () => void;
  showTBillModal: () => void;
  showContractsModal: () => void; // FWD
  showCDSContractsModal: () => void;
  showTBillContractsModal: () => void;
  showRepoContractsModal: () => void;
  showODAModal: () => void;
  showActiveOrders: boolean;
};

const InstrumentTableDivisor: React.FC<props> = props => {
  // console.log('reached InstrumentTableDivisor');
  const { financialInstruments, showActiveOrders } = props;
  const session_obj = useSelector(selectors.activeSession);
  const portfolioDuration = useSelector(selectors.portfolioDuration);
  const { session } = useSelector(selectors.player);
  const reportingCurrency = utils.useReportingCurrency(session);
  const player = useSelector(selectors.player);
  const forwardContractsFetching = useSelector(
    selectors.fwdContractsFetching(undefined)
  );

  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const currencies = useSelector(selectors.currencies);
  let dataSource = financialInstruments.map(item => ({
    ...item,
    ...underlyingAssets[item.underlying_asset],
    id: item.id,
  }));
  if (showActiveOrders) {
    dataSource = dataSource.filter(item => item.amount !== 0);
  }

  const RegionPanel = (region: keyof typeof REGIONS) => {
    const data = dataSource.filter(item => item.region === region);

    if (!data.length) return null;

    const header = REGIONS[region];
    return (
      <Collapse.Panel header={header} key={header}>
        <InstrumentTable {...props} financialInstruments={data} />
      </Collapse.Panel>
    );
  };

  if (
    dataSource[0]?.resourcetype.includes('ETF') ||
    dataSource[0]?.resourcetype.includes('Equity')
  ) {
    return (
      <Collapse accordion>
        {RegionPanel(APAC)}
        {RegionPanel(EUROPE)}
        {RegionPanel(NAM)}
        {RegionPanel(LATAM)}
        {RegionPanel(ME)}
        {RegionPanel(AFRICA)}
        {RegionPanel(WEST_AFRICA)}
      </Collapse>
    );
  }

  const CurrencyPanel = (symbol: string) => {
    const data = dataSource.filter(
      item =>
        currencies[item.base_currency].symbol === symbol ||
        currencies[item.variable_currency].symbol === symbol
    );

    if (!data.length) return null;

    return (
      <Collapse.Panel header={symbol} key={symbol}>
        <InstrumentTable {...props} financialInstruments={data} />
      </Collapse.Panel>
    );
  };

  const allFxBaseCurrenciesSymbolDuplicates = financialInstruments.map(
    fin =>
      currencies[underlyingAssets[fin.underlying_asset].base_currency].symbol
  );
  const allFXBaseCurrenciesSymbol = Array.from(
    new Set(allFxBaseCurrenciesSymbolDuplicates)
  );
  if (dataSource[0]?.resourcetype === 'FX') {
    return (
      <Collapse accordion>
        {/*{CurrencyPanel('USD')}*/}
        {/*{CurrencyPanel('EUR')}*/}
        {allFXBaseCurrenciesSymbol.map(ccySymbol => CurrencyPanel(ccySymbol))}
        {!allFXBaseCurrenciesSymbol.includes(reportingCurrency.symbol) &&
          CurrencyPanel(reportingCurrency.symbol)}
      </Collapse>
    );
  }

  if (dataSource[0]?.resourcetype === 'FxForwards') {
    // console.log('forwardContractsFetching: ', forwardContractsFetching);
    if (forwardContractsFetching) {
      return (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 150 }} spin />} />
      );
    } else {
      return <InstrumentTable {...props} financialInstruments={dataSource} />;
    }
  }

  if (dataSource[0]?.resourcetype === 'Repo') {
    return <InstrumentTable {...props} financialInstruments={dataSource} />;
  }

  if (dataSource[0]?.resourcetype === 'CDS') {
    return <InstrumentTable {...props} financialInstruments={dataSource} />;
  }

  if (dataSource[0]?.resourcetype === 'Crypto') {
    return <InstrumentTable {...props} financialInstruments={dataSource} />;
  }

  const GeographyPanel = (geography?: string) => {
    const data = dataSource.filter(item => item.geography === geography);

    if (!data.length) return null;

    return (
      <Collapse.Panel
        header={geography || 'Others'}
        key={geography || 'Others'}
      >
        <InstrumentTable {...props} financialInstruments={data} />
      </Collapse.Panel>
    );
  };

  const htmPortfolioInfo = (
    <div>
      You will not be able to sell your HTM positions until your trainer allows
      HTM portfolio rebalancing. However, you are able to add to your HTM
      positions at any time. For trading, use the constant maturity bonds.
    </div>
  );

  if (['GovtBond', 'Bond', 'HTMBond'].includes(dataSource[0]?.resourcetype)) {
    const geographies = Array.from(
      new Set(dataSource.map(asset => asset.geography))
    );

    return (
      <>
        {session_obj.scenario_type !== HISTORICAL_SCENARIO && (
          <p>
            Portfolio duration: {formatters.commas2Digits(portfolioDuration)}
          </p>
        )}
        {dataSource[0]?.resourcetype.endsWith('HTMBond') && (
          <div style={{ position: 'relative' }}>
            <Typography.Title level={4} style={{ display: 'inline-block' }}>
              Your HTM portfolio:
            </Typography.Title>
            <Tooltip title={htmPortfolioInfo}>
              <InfoCircleOutlined
                style={{
                  position: 'absolute',
                  marginLeft: 10,
                  top: 7,
                }}
              />
            </Tooltip>
            <span style={{ position: 'absolute', top: 3, marginLeft: 27 }}>
              <Divider type={'vertical'} />
              <Badge
                status={'processing'}
                color={session_obj.allow_htm ? 'green' : 'red'}
                text={
                  session_obj.allow_htm
                    ? 'HTM rebalancing allowed'
                    : 'HTM portfolio locked'
                }
              />
              <Divider type={'vertical'} />
              <Typography.Text>
                HTM portfolio dv01:{' '}
                {formatters.commas2Digits(player.htm_portfolio_dv01)}
              </Typography.Text>
              <Divider type={'vertical'} />
              <Typography.Text>
                HTM portfolio duration:{' '}
                {formatters.commas2Digits(player.htm_portfolio_duration)}
              </Typography.Text>
            </span>
          </div>
        )}

        <Collapse accordion>
          {/*{geographies.map(ge => GeographyPanel(ge))}*/}
          {<InstrumentTable {...props} financialInstruments={dataSource} />}
        </Collapse>
      </>
    );
  }

  if (dataSource[0]?.resourcetype === 'AFSBond') {
    return (
      <>
        <div style={{ position: 'relative' }}>
          <Typography.Title level={4} style={{ display: 'inline-block' }}>
            Your Bond Portfolio:
          </Typography.Title>
          {/*<Tooltip title={htmPortfolioInfo}>*/}
          {/*  <InfoCircleOutlined*/}
          {/*      style={{*/}
          {/*        position: 'absolute',*/}
          {/*        marginLeft: 10,*/}
          {/*        top: 7,*/}
          {/*      }}*/}
          {/*  />*/}
          {/*</Tooltip>*/}
          <span style={{ position: 'absolute', top: 3, marginLeft: 27 }}>
            <Typography.Text>
              Portfolio DV01:{' '}
              {formatters.commas2Digits(player.afs_portfolio_dv01)}
            </Typography.Text>
            <Divider type={'vertical'} />
            <Typography.Text>
              Portfolio Duration:{' '}
              {formatters.commas2Digits(player.afs_portfolio_duration)}
            </Typography.Text>
          </span>
        </div>
        <Collapse accordion>
          {/*{geographies.map(ge => GeographyPanel(ge))}*/}
          {<InstrumentTable {...props} financialInstruments={dataSource} />}
        </Collapse>
        {/*<Collapse accordion>*/}
        {/*  {RegionPanel(APAC)}*/}
        {/*  {RegionPanel(GLOBAL)}*/}
        {/*  {RegionPanel(EUROPE)}*/}
        {/*  {RegionPanel(NAM)}*/}
        {/*  {RegionPanel(LATAM)}*/}
        {/*  {RegionPanel(ME)}*/}
        {/*  {RegionPanel(AFRICA)}*/}
        {/*  {RegionPanel(WEST_AFRICA)}*/}
        {/*</Collapse>*/}
      </>
    );
  }

  if (dataSource[0]?.resourcetype === 'CorporateBond') {
    return (
      <>
        {session_obj.scenario_type !== HISTORICAL_SCENARIO && (
          <p>
            Portfolio duration: {formatters.commas2Digits(portfolioDuration)}
          </p>
        )}

        <InstrumentTable {...props} />
      </>
    );
  }

  if (dataSource[0]?.resourcetype === 'Metal') {
    return <InstrumentTable {...props} financialInstruments={dataSource} />;
  }

  if (dataSource[0]?.resourcetype === 'TBill') {
    return <InstrumentTable {...props} financialInstruments={dataSource} />;
  }

  return null;
};

export default InstrumentTableDivisor;
