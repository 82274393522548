import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, Select, Input, Card, notification, Row } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { updateInvestmentProfile, selectors } from 'state';

const { Option } = Select;

const InvestmentProfile: React.FC = () => {
  const dispatch = useDispatch();
  const { investment_profile } = useSelector(selectors.player);

  const [form] = Form.useForm();

  const createSelect = (name: string, options: string[]) => (
    <Form.Item
      name={name}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select placeholder={`Select your ${name.split('_').join(' ')}`}>
        {options.map((option, i) => (
          <Option value={i} key={option}>
            {option}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <Card title="Investment Profile">
      <Form
        form={form}
        name="normal_InvestmentProfile"
        className="InvestmentProfile-form"
        initialValues={{ ...investment_profile }}
        onFinish={() => {
          const data = form.getFieldsValue() as any;
          dispatch(updateInvestmentProfile({ data }));
        }}
      >
        {createSelect('risk_attitude', ['Risk averse', 'Risk taker'])}
        {createSelect('loss_tolerance', [
          'Conservative',
          'Moderate',
          'Aggressive',
        ])}
        {/*{createSelect('investment_horizon', [*/}
        {/*  'Short term',*/}
        {/*  'Medium term',*/}
        {/*  'Long term',*/}
        {/*])}*/}
        {createSelect('investment_goal', [
          'Income',
          'Growth',
          'Income and growth',
          'Value',
          'Trading and speculations',
        ])}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default InvestmentProfile;
