import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Row } from 'antd';

import {
  connectToAdminSocket,
  disconnectFromAdminSocket,
  fetchBadges,
  fetchCurrencies,
  fetchHistoricalStep,
  fetchIndexFunds,
  fetchMarketHours,
  fetchUnderlyingDeposits,
  selectors,
} from 'state';
import Header from './Header';
import Data from './Data';
import Sessions from './Sessions';
import Users from './Users';
import InitialPortfolioNotifications from './InitialPortfolio/InitialPortfolioNotifications';
import InitialPortfolio from './InitialPortfolio';
import Trainers from './Trainers';
import MarketHistory from './MarketHistory';
import Commands from './Commands';
import Errors from './Errors';

const AdminDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectors.token);
  // console.log('token before admin dashboard index: ', token);
  useEffect(() => {
    dispatch(fetchBadges({}));
    dispatch(fetchCurrencies({}));
    dispatch(fetchUnderlyingDeposits({}));
    dispatch(fetchIndexFunds({}));
    dispatch(fetchHistoricalStep({}));
    dispatch(fetchMarketHours({}));
  }, [dispatch]);

  useEffect(() => {
    if (!token) {
      // console.log('RETURN NULL');
      return;
    }

    dispatch(connectToAdminSocket(token));

    return () => {
      dispatch(disconnectFromAdminSocket(token));
    };
  }, [dispatch]);

  return (
    <div>
      <InitialPortfolioNotifications />
      <Header />
      <Row justify="center">
        <div style={{ width: '100%', padding: '30px 70px 70px 70px' }}>
          <Switch>
            <Route path={'/dashboard/data'}>
              <Data />
            </Route>
            <Route path={'/dashboard/market-history'}>
              <MarketHistory />
            </Route>
            <Route path={'/dashboard/sessions'}>
              <Sessions />
            </Route>
            <Route path={'/dashboard/users'}>
              <Users />
            </Route>
            <Route path={'/dashboard/trainers'}>
              <Trainers />
            </Route>
            <Route path={'/dashboard/initial-portfolio'}>
              <InitialPortfolio />
            </Route>
            <Route path={'/dashboard/commands'}>
              <Commands />
            </Route>
            <Route path={'/dashboard/errors'}>
              <Errors />
            </Route>
            <Redirect to={'/dashboard/data'} />
          </Switch>
        </div>
      </Row>
    </div>
  );
};

export default AdminDashboard;
