import { appManager, AppState } from '../manager';
import * as types from '../types';

export const retrieveLastMatrix = appManager.createApi<
  unknown,
  types.CorrelationMatrix,
  AppState
>('RETRIEVE_LAST_MATRIX', {
  path: '/correlation_matrices/get_last',
  method: 'GET',
  startReducer(state) {
    state.correlationMatrix.fetched = false;
    state.correlationMatrix.waiting = true;
  },
  successReducer(state, result) {
    state.correlationMatrix.item = result;
    state.correlationMatrix.fetched = true;
    state.correlationMatrix.waiting = false;
  },
  failReducer(state) {
    state.correlationMatrix.waiting = false;
  },
});

export const createMatrix = appManager.createApi<
  { correlation_fields: Omit<types.CorrelationMatrixField, 'id'>[] },
  types.CorrelationMatrix,
  AppState
>('CREATE_MATRIX', {
  path: '/correlation_matrices/',
  method: 'POST',
  startReducer(state) {
    state.correlationMatrix.waiting = true;
  },
  successReducer(state, result) {
    state.correlationMatrix.item = result;
    state.correlationMatrix.waiting = false;
  },
  failReducer(state) {
    state.correlationMatrix.waiting = false;
  },
});

export const getCorrelationValues = appManager.createApi<
  { rows: number[]; columns: number[] },
  {
    correlation_values: types.CorrelationValue[];
    to_compute: [number, number][];
  },
  AppState
>('GET_CORRELATION_VALUES', {
  path: '/correlation_matrices/get_values',
  method: 'POST',
  startReducer(state) {
    state.correlationValues.waiting = true;
  },
  successReducer(state, result) {
    result.correlation_values.forEach(value => {
      state.correlationValues.items[value.id] = value;
    });
    state.correlationValues.waiting = false;

    const flaggedWaitingList = result.to_compute.map(pair => {
      const eq1 = pair[0];
      const eq2 = pair[1];
      return [eq1, eq2, 0];
    });
    state.correlationValuesWaitingList = flaggedWaitingList;
  },
  failReducer(state) {
    state.correlationValues.waiting = false;
  },
});

appManager.createSocketListener<types.CorrelationValue, AppState>(
  'correlation_value_computed',
  (state, result) => {
    const eq1 = result.equities[0];
    const eq2 = result.equities[1];

    const flaggedPair = [eq1, eq2, 0];
    const flaggedPairReverse = [eq2, eq1, 0];

    state.correlationValuesWaitingList.forEach((value, index) => {
      if (
        value.toString() === flaggedPair.toString() ||
        value.toString() === flaggedPairReverse.toString()
      ) {
        state.correlationValuesWaitingList[index][2] = 1;
      }
    });
    state.correlationValues.items[result.id] = result;
  }
);

appManager.createSocketListener<string, AppState>(
  'correlation_value_error',
  (state, result) => {
    const parsed_result = JSON.parse(result);
    const eq1 = parsed_result.eq1;
    const eq2 = parsed_result.eq2;

    const flaggedPair = [eq1, eq2, 0];
    const flaggedPairReverse = [eq2, eq1, 0];

    state.correlationValuesWaitingList.forEach((value, index) => {
      if (
        value.toString() === flaggedPair.toString() ||
        value.toString() === flaggedPairReverse.toString()
      ) {
        state.correlationValuesWaitingList[index][2] = -1;
      }
    });
  }
);
