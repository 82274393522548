import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Empty,
  InputNumber,
  Modal,
  notification,
  Select,
  Table,
  Typography,
} from 'antd';
import {
  AuctionResponseType,
  receiveCreatedAuctionType,
} from '../../../state/app/auctions/types';
// import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import { useDispatch, useSelector } from 'react-redux';
import {
  issueSecurity,
  sendAuctionResponse,
} from '../../../state/app/auctions/actions';
import { getAuctionById, player } from '../../../state/app/selectors';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import { respondToAuctionColumns } from '../../TraineeSession/Auctions/RespondToAuctionModal';
import { formatters } from 'services';
type Props = {
  visible: boolean;
  hide: () => void;
  auction_id: number | undefined;
};

const auctionOffersColumns: ((
  | ColumnGroupType<AuctionResponseType>
  | ColumnType<AuctionResponseType>
) & {
  dataIndex: string;
})[] = [
  {
    title: 'Team',
    dataIndex: 'player_name',
    key: 'player_name',
  },
  {
    title: 'Bid value',
    dataIndex: 'initial_bid_value',
    key: 'initial_bid_value',
    render: (value: number) => formatters.commasNoDigits(value),
  },
  // {
  //   title: 'Unit',
  //   dataIndex: 'bid_unit',
  //   key: 'bid_unit',
  //   render: (text: number) => {
  //     if (text === 0) {
  //       return <div>Million</div>;
  //     } else if (text === 1) {
  //       return <div>Billion</div>;
  //     } else if (text === 2) {
  //       return <div>Trillion</div>;
  //     }
  //   },
  // },
  {
    title: 'CCY',
    dataIndex: 'currency_symbol',
    key: 'ccy',
  },
];
const ViewAuctionOffersModal: React.FC<Props> = ({
  visible,
  hide,
  auction_id,
}) => {
  const dispatch = useDispatch();
  const auction = useSelector(getAuctionById(auction_id));
  // console.log('auction from modal: ', auction);
  if (auction) {
    // console.log('auction responses: ', auction.responses);
  }
  useEffect(() => {
    //
  }, [auction?.responses]);

  const handleIssueSecurity = () => {
    if (auction) {
      dispatch(issueSecurity({ auction_id: auction.id }));
      hide();
    } else {
      notification.warn({
        message: 'An error occurred. Please refresh the page and try again.',
      });
    }
  };

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`No submitted offers yet.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };
  // console.log('auction: ', auction);
  let disabled = true;
  if (auction && auction.responses.length > 0) {
    disabled = false;
  }
  return (
    <Modal
      visible={visible}
      onCancel={hide}
      width={900}
      footer={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {/*<div style={{ marginRight: 5 }}>(time left: 2:34)</div>*/}
          {/*<Button onClick={() => hide()}>Cancel</Button>*/}
          <Button
            type={'primary'}
            onClick={() => {
              handleIssueSecurity();
            }}
            disabled={disabled}
          >
            Issue Security
          </Button>
        </div>
      }
    >
      <Card title={'Auction Details:'} size={'small'}>
        <Table
          pagination={false}
          bordered
          dataSource={auction ? [auction] : []}
          columns={respondToAuctionColumns}
        ></Table>
      </Card>
      <br />
      <Typography.Title level={4} style={{ margin: 0 }}>
        Received offers:
      </Typography.Title>
      <br />
      <Table
        bordered
        pagination={false}
        columns={auctionOffersColumns}
        dataSource={auction?.responses}
        locale={noDataBehaviour}
      />
      <br />
      <div>
        (If total bidding is higher than auction value, the auction value will
        be split on a pro-rata basis among selected teams)
      </div>
    </Modal>
  );
};

export default ViewAuctionOffersModal;
