import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Typography, Divider, Card, Row, Col, Popover } from 'antd';
import { fetchAssetDetails, FinancialInstrument, selectors } from 'state';
import { formatters } from 'services';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useReportingCurrency } from '../state/app/utils';

type Props = {
  assetId: number;
  visible: boolean;
  hide: () => void;
  instrument: FinancialInstrument;
};

const BondDetailsModal: React.FC<Props> = ({
  assetId,
  visible,
  hide,
  instrument,
}) => {
  const asset = useSelector(selectors.underlyingAssetById(assetId));
  const currency = useSelector(selectors.currencyById(asset.base_currency))
    .symbol;
  const session = useSelector(selectors.activeSession);
  const reporting_currency = useReportingCurrency(session.id);
  const orders = useSelector(selectors.orders);
  // console.log(orders);
  // let margin = 0;
  const instrumentOrders = Array.from(new Set(instrument.orders));
  const margin = useMemo(() => {
    // console.log(instrumentOrders);
    return instrumentOrders.reduce((previousValue, currentValue) => {
      const currentOrder = orders.find(order => order.id === currentValue);
      if (currentOrder) {
        if (currentOrder.collateral) {
          // console.log(currentOrder.collateral);
          return previousValue + currentOrder.collateral;
        } else {
          return previousValue;
        }
      } else {
        return previousValue;
      }
    }, 0);
  }, [instrumentOrders, orders]);
  if (instrument) {
    // instrument.orders.forEach(instrumentOrder => {
    //   const orderObject = orders.find(order => order.id === instrumentOrder);
    //   console.log(orderObject);
    //   if (orderObject !== undefined) {
    //     const instrumentMargin = orderObject.collateral;
    //     if (instrumentMargin !== undefined) {
    //       console.log('instrumentMargin: ', instrumentMargin);
    //       margin += instrumentMargin;
    //     }
    //   }
    // });
  }
  // console.log('margin: ', margin);
  const positionAccInterest = instrument
    ? formatters.commasNoDigits(instrument.current_accrued_interest)
    : 0;

  const total_reporting_interest = instrument
    ? formatters.commasNoDigits(instrument.total_reporting_accrued)
    : 0;

  const weighted_accrued_days = instrument
    ? formatters.commas2Digits(instrument.weighted_accrued_days)
    : 0;
  return (
    <Modal
      title={'Bond details:'}
      visible={visible}
      onCancel={hide}
      footer={null}
      style={{ width: 'fit-content' }}
    >
      <Divider> {asset.name} </Divider>
      <Typography style={{ marginBottom: 4 }}>Currency: {currency}</Typography>
      <Typography style={{ marginBottom: 4 }}>
        Coupon rate: {asset.coupon_rate} %
      </Typography>
      <Typography style={{ marginBottom: 4 }}>
        Next coupon date: {asset.coupon_date}
      </Typography>
      <Typography style={{ marginBottom: 4 }}>
        Days accrued since last coupon: {asset.accrued_days}
      </Typography>
      <Typography style={{ marginBottom: 4 }}>
        Accrued interest since last: {asset.accrued_interest} %
      </Typography>
      <Divider> Position specific </Divider>
      <Typography style={{ marginBottom: 4 }}>
        Current pos. accrued interest: {positionAccInterest} {currency}
      </Typography>
      <Typography style={{ marginBottom: 4 }}>
        Average days accrued: {weighted_accrued_days}{' '}
        <Popover
          placement={'right'}
          content={
            'Weighted average of all trades taken in current open position.'
          }
        >
          <InfoCircleOutlined />
        </Popover>
      </Typography>
      <Typography style={{ marginBottom: 4 }}>
        Margin: {formatters.commasNoDigits(margin)} {currency}
      </Typography>
      <Divider />
      <Typography style={{ marginBottom: 4 }}>
        Acc. Interest: {total_reporting_interest} {reporting_currency.symbol}{' '}
        <Popover
          placement={'right'}
          content={
            'Total reporting acc. interest from all past and current positions taken.'
          }
        >
          <InfoCircleOutlined />
        </Popover>
      </Typography>
    </Modal>
  );
};

export default BondDetailsModal;
