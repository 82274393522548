import React from 'react';
import { Popover, Table } from 'antd';
import { useSelector } from 'react-redux';
import { REGIONS, selectors } from 'state';
import { formatters } from 'services';
import { InfoCircleOutlined } from '@ant-design/icons';

const Valuation: React.FC = () => {
  const equities = useSelector(selectors.equityList);
  const currencies = useSelector(selectors.currencies);

  const volatilities = equities
    .map(e => e.last_volatility)
    .filter(v => v !== undefined) as number[];

  const maxVolatility = volatilities.length ? Math.max(...volatilities) : 0;
  const betaInfoText = (
    <div style={{ width: 350 }}>
      5 Year Monthly Beta is the measure of a company&apos;s common stock price
      volatility relative to market price volatility for a 5 year duration using
      a least squares linear regression line. 5 Year Beta is calculated using
      monthly Price Close change values with a minimum of 40 monthly Price Close
      change values required within the 5 year trading period. Beta is levered
      in this case.
    </div>
  );
  const betaTitle = (
    <div>
      <Popover placement={'bottom'} content={betaInfoText}>
        <InfoCircleOutlined />
      </Popover>
      <span style={{ marginLeft: 10 }}>Beta 5Y (Monthly)</span>
    </div>
  );

  const volatilityInfoText = (
    <div style={{ width: 350 }}>
      A measure of the risk of price moves for a security calculated from the
      standard deviation of day to day logarithmic historical price changes. The
      250 day price volatility equals the annualized standard deviation of the
      relative price change for the 250 most recent trading days closing price,
      expressed as a percentage.
    </div>
  );
  const volatilityTitle = (
    <div>
      <Popover placement={'bottom'} content={volatilityInfoText}>
        <InfoCircleOutlined />
      </Popover>
      <span style={{ marginLeft: 10 }}>Volatility (%)</span>
    </div>
  );

  return (
    <div>
      <Table
        rowKey="id"
        dataSource={equities}
        pagination={{
          defaultPageSize: 20,
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            sorter(a, b) {
              return a.name.localeCompare(b.name);
            },
          },
          {
            title: 'Region',
            dataIndex: 'region',
            render(region: keyof typeof REGIONS) {
              return REGIONS[region];
            },
            sorter(a, b) {
              return a.region - b.region;
            },
            filters: Object.entries(REGIONS).map(([key, value]) => {
              return {
                text: value,
                value: Number(key),
              };
            }),
            onFilter: (value, record) => value === record.region,
          },
          {
            title: 'BBG Ticker',
            dataIndex: 'ticker',
            sorter(a, b) {
              return a.ticker.localeCompare(b.ticker);
            },
          },
          {
            title: 'CCY',
            dataIndex: 'variable_currency',
            render(ccy) {
              return currencies[ccy]?.symbol || '-';
            },
            sorter(a, b) {
              return a.variable_currency - b.variable_currency;
            },
            filters: Object.values(currencies).map(ccy => {
              return {
                text: ccy.symbol,
                value: ccy.id,
              };
            }),
            onFilter: (value, record) => value === record.variable_currency,
          },
          {
            title: '52w. Low-High',
            dataIndex: '',
            align: 'right',
            render(text, record) {
              if (
                typeof record.last_w52_high === 'undefined' ||
                typeof record.last_w52_low === 'undefined'
              ) {
                return '-';
              }
              const low = formatters.commas2Digits(record.last_w52_low);
              const high = formatters.commas2Digits(record.last_w52_high);

              return `${low} - ${high}`;
            },
          },
          // { title: 'MKT. Cap.(B)', dataIndex: '' },
          {
            title: 'P/E Ratio',
            dataIndex: 'last_pe_ratio',
            align: 'right',
            render(value) {
              if (typeof value !== 'number') {
                return '-';
              }
              let opacity = 0;
              if (value <= 1) {
                opacity = 1;
              } else if (value >= 25) {
                opacity = 0;
              } else {
                opacity = 1 - value / 25;
              }

              let color = 'inherit';
              if (opacity >= 0.9) {
                color = '#fff';
              }

              return (
                <div
                  style={{
                    backgroundColor: `rgba(22,119,255,${opacity})`,
                    color,
                    marginTop: '-5px',
                    marginLeft: '-5px',
                    marginRight: '-5px',
                    marginBottom: '-5px',
                    padding: '5px',
                  }}
                >
                  {formatters.commas2Digits(value)}
                </div>
              );
            },
            sorter(a, b) {
              return (a.last_pe_ratio ?? 0) - (b.last_pe_ratio ?? 0);
            },
          },
          {
            title: betaTitle,
            dataIndex: 'last_beta',
            align: 'right',
            render(value) {
              if (value == undefined) {
                return '-';
              }
              return formatters.commas2Digits(value);
            },
            sorter(a, b) {
              return (a.last_beta ?? 0) - (b.last_beta ?? 0);
            },
          },
          {
            title: 'Earnings',
            dataIndex: 'last_earnings',
            align: 'right',
            render(value) {
              return formatters.commas2Digits(value);
            },
            sorter(a, b) {
              return (a.last_earnings ?? 0) - (b.last_earnings ?? 0);
            },
          },
          {
            title: volatilityTitle,
            dataIndex: 'last_volatility',
            width: '100px',
            align: 'right',
            render(value) {
              if (value == undefined || maxVolatility === 0) {
                return '-';
              }

              const percent = (value / maxVolatility) * 100;

              return (
                <div
                  style={{
                    display: 'grid',
                  }}
                >
                  <div
                    style={{
                      gridArea: '1 / 1',
                      marginTop: '-5px',
                      marginLeft: '-5px',
                      marginBottom: '-5px',
                      backgroundColor: `rgba(22, 119, 255, 0.25)`,
                      width: `${percent}%`,
                    }}
                  />
                  <div
                    style={{
                      gridArea: '1 / 1',
                      // color: percent >= 35 ? '#fff' : 'inherit',
                      // color: percent >= 35 ? 'inherit' : 'inherit',
                    }}
                  >
                    {formatters.percent2Digit(value)}
                  </div>
                </div>
              );
            },
            sorter(a, b) {
              return (a.last_volatility ?? 0) - (b.last_volatility ?? 0);
            },
          },
          {
            title: 'Sector',
            dataIndex: 'sector',
            sorter(a, b) {
              return (a.sector ?? '').localeCompare(b.sector || '');
            },
          },
        ]}
      />
    </div>
  );
};

export default Valuation;
