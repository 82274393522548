import React from 'react';
import { useSelector } from 'react-redux';

import { Empty } from 'antd';

import { ColumnChart } from 'components';
import { selectors } from 'state';

const ReturnVsVolatility: React.FC = () => {
  const players = useSelector(selectors.activePlayers);

  const playersData = players
    .map(player => {
      const metrics = player.metrics;
      const lastMetrics = metrics[metrics.length - 1];
      const { portfolio_volatility } = lastMetrics;

      const { last_portfolio_return, name } = player;

      return { last_portfolio_return, portfolio_volatility, name };
    })
    .sort((a, b) => a.last_portfolio_return - b.last_portfolio_return);

  const series = [
    {
      name: 'Return',
      data: playersData.map(data => data.last_portfolio_return),
    },
    {
      name: 'Volatility',
      data: playersData.map(data => data.portfolio_volatility),
    },
  ];
  const categories = playersData.map(player => player.name);

  if (!series.length) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <ColumnChart data={[]} categories={categories} series={series} showLegend />
  );
};

export default ReturnVsVolatility;
