import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Col,
  Empty,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

import {
  ANALYSIS_TYPES,
  BUY,
  cancelOrder,
  cancelOrders,
  CREATED,
  CREATING,
  fetchBlotter,
  FROZEN,
  OpenOrdersErrorType,
  PAUSED,
  selectors,
  TERMS,
  TRANSACTION_TYPES,
} from 'state';
import { formatters } from 'services';
import { ColumnsType } from 'antd/es/table';

const OpenOrders: React.FC = () => {
  const dispatch = useDispatch();

  const { fetched_blotter } = useSelector(selectors.player);
  const orders = useSelector(selectors.orders);
  const financialInstruments = useSelector(selectors.financialInstruments);
  const currencies = useSelector(selectors.currencies);
  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const persons = useSelector(selectors.persons);
  const { status } = useSelector(selectors.activeSession);
  const failedExecutionData = useSelector(selectors.failedExecutions);

  const isPaused = status === PAUSED || status === CREATING;

  useEffect(() => {
    if (fetched_blotter) return;
    dispatch(fetchBlotter({}));
  }, [dispatch, fetched_blotter]);

  console.log(orders);

  const data = orders
    .filter(order => order.status === CREATED)
    .map(order => {
      const result = {
        ...order,
        currency: '--',
        value: 0,
        underlying_asset_ticker: '--',
        underlying_asset_resourcetype: '--',
      };

      const instrument = financialInstruments[order.financial_instrument];
      const assetId = instrument?.underlying_asset ?? -1;
      const asset = underlyingAssets[assetId];

      if (asset) {
        const { resourcetype, ticker, variable_currency } = asset;

        const value = (order.open_price ?? order.limit_price) * order.quantity;
        const isBond = resourcetype.endsWith('Bond');

        result.value = isBond ? value / 100 : value;
        result.underlying_asset_ticker = ticker;
        result.underlying_asset_resourcetype = resourcetype;

        const currency = currencies[variable_currency];
        if (currency) {
          result.currency = currency.symbol;
        }
      }

      return result;
    });

  const assetFilters = Object.values(
    Object.values(underlyingAssets).reduce((acc, { ticker }) => {
      acc[ticker] = { text: ticker, value: ticker };
      return acc;
    }, {} as { [key: string]: { text: string; value: string } })
  );

  const errorsColumns: ColumnsType<OpenOrdersErrorType> = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: 94,
      render: timestamp => (
        <div>
          <div>{timestamp.split('T')[0]}</div>
          <div>{timestamp.split('T')[1].split('.')[0]}</div>
        </div>
      ),
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: text => <div style={{ whiteSpace: 'normal' }}>{text}</div>,
    },
  ];
  //TODO: split in two components (orders table comp. and failed executions comp.
  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          No open orders at this moment.
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  const noData2 = (
    <Empty
      description={
        <>
          <Typography.Text type={'secondary'} style={{ display: 'block' }}>
            No failed executions at this time.
          </Typography.Text>
          <Typography.Text type={'secondary'} style={{ display: 'block' }}>
            Refresh page to check for new possible errors.
          </Typography.Text>
        </>
      }
    />
  );

  const noDataBehaviour2 = {
    emptyText: noData2,
  };

  return (
    <Row gutter={20}>
      <Col span={18}>
        <Typography.Title level={3}>Open Orders</Typography.Title>
        <Table
          rowKey="id"
          bordered
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
          locale={noDataBehaviour}
          columns={[
            {
              title: 'Asset',
              dataIndex: 'underlying_asset_ticker',
              key: 'underlying_asset_ticker',
              fixed: 'left',
              sorter: (a, b) =>
                a.underlying_asset_ticker.localeCompare(
                  b.underlying_asset_ticker
                ),
              filters: assetFilters,
              onFilter: (value, record) => {
                return value === record.underlying_asset_ticker;
              },
            },
            {
              title: 'Type',
              dataIndex: 'resourcetype',
              key: 'resourcetype',
              fixed: true,
              sorter: (a, b) => a.resourcetype.localeCompare(b.resourcetype),
            },
            {
              title: 'Transaction',
              dataIndex: 'transaction',
              key: 'transaction',
              fixed: true,
              render: (tr: 0 | 1) => TRANSACTION_TYPES[tr],
              sorter: (a, b) => a.transaction - b.transaction,
            },
            {
              title: 'CCY',
              dataIndex: 'currency',
              key: 'currency',
              fixed: true,
              sorter: (a, b) => a.currency.localeCompare(b.currency),
            },
            {
              title: 'Order Price',
              dataIndex: 'limit_price',
              key: 'limit_price',
              align: 'right',
              fixed: true,
              render: (val, record) =>
                val
                  ? formatters.priceFormat(
                      val,
                      record.underlying_asset_ticker,
                      record.underlying_asset_resourcetype
                    )
                  : '--',
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              key: 'quantity',
              align: 'right',
              fixed: true,
              render: (value, record) => {
                return `${
                  record.transaction === BUY ? '+' : '-'
                }${formatters.commasNoDigits(value)}`;
              },
            },
            {
              title: 'Value',
              dataIndex: 'value',
              key: 'value',
              align: 'right',
              fixed: true,
              render: val => {
                return val ? formatters.commasNoDigits(val) : '--';
              },
            },
            // {
            //   title: 'Fee',
            //   dataIndex: 'fee',
            //   key: 'fee',
            //   render: formatters.commasNoDigits,
            // },
            {
              title: 'Timestamp',
              dataIndex: 'timestamp',
              render: val => (val ? formatters.dateWithTime(val) : '--'),
              defaultSortOrder: 'descend',
              fixed: true,
              sorter: (a, b) => {
                const date1 = formatters.dateWithTime(a.timestamp);
                const date2 = formatters.dateWithTime(b.timestamp);
                return date1.localeCompare(date2);
              },
            },
            {
              title: 'Forecast',
              dataIndex: 'forecast',
              key: 'forecast',
              render: (val, record) =>
                val !== record.open_price && val !== 0
                  ? formatters.commas2Digits(val)
                  : '--',
            },
            {
              title: 'Investment rationale',
              dataIndex: 'investment_rationale',
              key: 'investment_rationale',
              width: 300,
              // render: val => val || '--',
              render: text => (
                <div style={{ maxWidth: 300, whiteSpace: 'normal' }}>
                  {text || '--'}
                </div>
              ),
            },
            {
              title: 'Term',
              dataIndex: 'term',
              render: val => TERMS[val as keyof typeof TERMS] || '--',
            },
            {
              title: 'Analysis',
              dataIndex: 'analysis',
              render: val =>
                ANALYSIS_TYPES[val as keyof typeof ANALYSIS_TYPES] || '--',
            },
            {
              title: 'User',
              dataIndex: 'person',
              render: val => persons[val]?.name || val,
            },
            {
              title: (
                <div>
                  Action{' '}
                  <Popconfirm
                    title="Are you sure to cancel all open orders?"
                    onConfirm={() => dispatch(cancelOrders({}))}
                    onCancel={() => null}
                  >
                    <MinusCircleOutlined style={{ color: '#ff4d4f' }} />
                  </Popconfirm>
                </div>
              ),
              key: 'action',
              fixed: 'right',
              render: function Action(text, record) {
                return (
                  <Space key={record.id} size="middle">
                    <Button
                      type="primary"
                      danger
                      onClick={() => dispatch(cancelOrder({ id: record.id }))}
                      disabled={isPaused || status === FROZEN}
                    >
                      Cancel
                    </Button>
                  </Space>
                );
              },
            },
          ]}
        />
      </Col>
      <Col span={6}>
        <Typography.Title level={3}>Failed Executions</Typography.Title>
        <Table
          locale={noDataBehaviour2}
          bordered
          dataSource={failedExecutionData}
          columns={errorsColumns}
        />
      </Col>
    </Row>
  );
};

export default OpenOrders;
