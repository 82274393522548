import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { ASSET_CLASSES, selectors, utils } from 'state';
import { formatters } from 'services';

const AssetClassTable: React.FC = () => {
  const instruments = useSelector(selectors.financialInstrumentsList);
  const pnlByAssetClass = useSelector(selectors.pnlByAssetClass(instruments));

  const { session } = useSelector(selectors.player);
  const reportingCurrency = utils.useReportingCurrency(session);

  return (
    <Table
      rowKey="asset_class"
      bordered
      pagination={false}
      dataSource={pnlByAssetClass}
      columns={[
        {
          title: 'Asset Class',
          dataIndex: 'asset_class',
          render(text) {
            return ASSET_CLASSES[text];
          },
          width: '34%',
        },
        {
          title: 'PnL, ' + reportingCurrency.symbol,
          dataIndex: 'pnl',
          render: formatters.million,
          width: '33%',
          align: 'right',
          sorter: (a, b) => a.pnl - b.pnl,
          defaultSortOrder: 'descend',
        },
        {
          title: 'Allocation',
          dataIndex: 'percent',
          align: 'right',
          render: (text, record) => {
            const percent = record.percent ?? 0;
            return formatters.percent2Digit(percent);
          },
          sorter: (a, b) => (a.percent - b.percent) * 100,
          width: '33%',
        },
      ]}
    />
  );
};

export default AssetClassTable;
