import React from 'react';
import { RichElement, RichLeaf, RichText } from 'state';

const Leaf: React.FC<{ leaf: RichLeaf }> = ({ leaf }) => {
  let result = <span>{leaf.text}</span>;

  if (leaf.bold) {
    result = <strong>{result}</strong>;
  }

  if (leaf.italic) {
    result = <em>{result}</em>;
  }

  if (leaf.underline) {
    result = <u>{result}</u>;
  }

  return result;
};

const Element: React.FC<{ element: RichElement }> = ({ children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return <blockquote style={style}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul style={style}>{children}</ul>;
    case 'heading-one':
      return <h1 style={style}>{children}</h1>;
    case 'heading-two':
      return <h2 style={style}>{children}</h2>;
    case 'list-item':
      return <li style={style}>{children}</li>;
    case 'numbered-list':
      return <ol style={style}>{children}</ol>;
    default:
      return <p style={style}>{children}</p>;
  }
};

type ParseText = (value: RichText | RichText[]) => JSX.Element;

const parseText: ParseText = value => {
  if (Array.isArray(value)) {
    return (
      <>
        {value.map(item => {
          return parseText(item);
        })}
      </>
    );
  }

  if ('children' in value) {
    return <Element element={value}>{parseText(value.children)}</Element>;
  }

  return <Leaf leaf={value} />;
};

export const SlateViewer: React.FC<{ value: RichText[] }> = ({ value }) => {
  const text = parseText(value);
  return <div>{text}</div>;
};

export default SlateViewer;
