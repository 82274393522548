import React from 'react';
import { useSelector } from 'react-redux';
import { WarningOutlined } from '@ant-design/icons';
import { PopoverProps } from 'antd/lib/popover';
import { Popover, Typography } from 'antd';
import {
  FinancialInstrument,
  instrumentTypes,
  MarketHoursType,
  selectors,
  UnderlyingAsset,
} from 'state';
import { formatters } from 'services';

const { Paragraph } = Typography;

export const OverLimitIcon: React.FC<{
  financialInstrument: FinancialInstrument;
}> = ({ financialInstrument }) => {
  const underlyingAsset = useSelector(
    selectors.underlyingAssetOfInstrument(financialInstrument)
  );

  const resourceType = instrumentTypes.find(
    it => it.type === underlyingAsset.resourcetype
  )?.name;

  const content = (
    <Paragraph>
      You cannot exceed the {financialInstrument.pv_limit * 100}% limit on{' '}
      {resourceType} set by your instructor.
      <br />
      See limits list in Portfolio Tab under Instrument Limits.
    </Paragraph>
  );

  return (
    <Popover content={content} title="Cannot buy past limit">
      <WarningOutlined />
    </Popover>
  );
};

export const CannotBuyIcon: React.FC<{ symbol: string }> = ({ symbol }) => {
  const content = (
    <Paragraph>
      You cannot buy this instrument because you do not have {symbol} in your
      account.
      <br />
      You can use the cash exchange to buy some {symbol}.
    </Paragraph>
  );

  return (
    <Popover content={content} title="Insufficient funds">
      <WarningOutlined />
    </Popover>
  );
};

export const CannotSellIcon: React.FC<{ allowShort: boolean }> = ({
  allowShort,
}) => {
  const content = (
    <Paragraph>
      You cannot sell because you currently do not have an open BUY position on
      this instrument.
      <br />
      {allowShort && <Paragraph>Did you mean to short sell?</Paragraph>}
    </Paragraph>
  );

  return (
    <Popover content={content} title="Cannot sell">
      <WarningOutlined />
    </Popover>
  );
};

export const CannotShortSellIcon2: React.FC<{
  pv_limit: number;
}> = ({ pv_limit }) => {
  const content = (
    <Paragraph>
      Asset class limit of {formatters.percent0Digits(pv_limit)} reached.
    </Paragraph>
  );

  return (
    <Popover content={content} title="Cannot short sell">
      <WarningOutlined />
    </Popover>
  );
};

export const CannotCoverMargin: React.FC = () => {
  const content = <Paragraph>No funds to cover margin.</Paragraph>;

  return (
    <Popover content={content} title="Can't open position.">
      <WarningOutlined />
    </Popover>
  );
};

export const CannotShortSellIcon: React.FC<{
  shotSellLeverage: number;
}> = ({ shotSellLeverage }) => {
  const content = (
    <Paragraph>
      You cannot short sell because you have reached the short selling limit (
      {formatters.percent0Digits(1 + shotSellLeverage)} of initial AUM) set by
      your instructor.
      <br />
      To short this instrument, cover other short positions.
    </Paragraph>
  );

  return (
    <Popover content={content} title="Cannot short sell">
      <WarningOutlined />
    </Popover>
  );
};

type InfoPopoverProps = PopoverProps & {
  visible: boolean;
};

const InfoPopover: React.FC<InfoPopoverProps> = ({
  visible,
  title,
  content,
  children,
}) => {
  if (!visible) {
    return <>{children}</>;
  }

  return (
    <Popover title={title} content={content}>
      <span>{children}</span>
    </Popover>
  );
};

export const CannotSellPopover: React.FC<InfoPopoverProps> = props => {
  const params = {
    content: (
      <Paragraph>
        You cannot sell because you currently have an open short sell position
        on this instrument. You can sell only if you have an open long position.
        <br />
        To add to your short position, use the short sell button.
      </Paragraph>
    ),
    title: 'Cannot sell',
  };

  return <InfoPopover {...props} {...params} />;
};

export const CannotSellBecauseHTMisLocked: React.FC<InfoPopoverProps> = props => {
  const params = {
    content: (
      <Paragraph>
        You cannot sell this position because it is part of your HTM portfolio
        and currently HTM rebalancing is not allowed.
        <br />
      </Paragraph>
    ),
    title: 'Cannot sell',
  };

  return <InfoPopover {...props} {...params} />;
};

export const CannotShortPopover: React.FC<InfoPopoverProps> = props => {
  const params = {
    content: (
      <Paragraph>
        You cannot short sell because you currently have an open long position
        on this instrument. <br /> To short sell, first cover your long
        position.
      </Paragraph>
    ),
    title: 'Cannot short sell',
  };

  return <InfoPopover {...props} {...params} />;
};

export const MarketNotOpenPopover: React.FC<
  InfoPopoverProps & {
    geography: string;
    assetMarketHours: MarketHoursType;
    asset: UnderlyingAsset;
  }
> = ({ asset, assetMarketHours, geography, ...props }) => {
  const params = {
    content: (
      <div>
        {asset.resourcetype !== 'Crypto' && (
          <div>
            <div>{geography} Hours(GMT):</div>
            <div>open: {assetMarketHours.open_hours}</div>
            <div>close: {assetMarketHours.closing_hours}</div>
            {asset.resourcetype === 'Metal' && (
              <div>
                <div>Sunday open: {assetMarketHours.metals_sunday_open}</div>
                <div>Friday close: {assetMarketHours.metals_friday_close}</div>
              </div>
            )}
          </div>
        )}
      </div>
    ),
    title: 'Market is closed',
  };
  // console.log('assetMarketHours: ', assetMarketHours);
  // if (assetMarketHours) {
  return (
    <InfoPopover
      {...(props as Omit<typeof props, 'geography' | 'market_hours' | 'asset'>)}
      {...params}
    />
  );
  // } else {
  //   return;
  // }
};
