import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { EmptyHeader } from 'components';

import { logout, selectors } from 'state';

const MyHeader: React.FC = () => {
  const dispatch = useDispatch();
  const username = useSelector(selectors.username);

  return (
    <EmptyHeader defaultKey="dashboard">
      <Menu.Item key="dashboard">
        <Link to={`/dashboard`}>Dashboard</Link>
      </Menu.Item>
      <Menu.SubMenu
        title={
          <>
            <UserOutlined />
            {username}
          </>
        }
        style={{ float: 'right' }}
      >
        <Menu.Item onClick={() => dispatch(logout({}))}>Logout</Menu.Item>
      </Menu.SubMenu>
    </EmptyHeader>
  );
};

export default MyHeader;
