import { API } from '../../app/manager';

export type CurrentLoans = {
  id: number;
  tenor: number;
  awarded_sum: number;
  rate: number;
};

export type PastLoans = {
  id: number;
  tenor: number;
  awarded_sum: number;
  rate: number;
  interest: number;
};

export type FutureLoans = {
  id: number;
  tenor: number;
  min: number;
  max: number;
  rate: number;
};

export type CurrentDepos = {
  id: number;
  tenor: number;
  awarded_sum: number;
  rate: number;
};

export type PastDepos = {
  id: number;
  tenor: number;
  awarded_sum: number;
  rate: number;
  interest: number;
};

export type FutureDepos = {
  id: number;
  tenor: number;
  min: number;
  max: number;
  rate: number;
};

export type ClientMoney = {
  current_loans: API<CurrentLoans>;
  past_loans: API<PastLoans>;
  future_loans: API<FutureLoans>;
  current_depos: API<CurrentDepos>;
  past_depos: API<PastDepos>;
  future_depos: API<FutureDepos>;
  lombard_rate: number;
};

export type ReceiveClientCards = {
  current_loans: CurrentLoans[];
  past_loans: PastLoans[];
  future_loans: FutureLoans[];
  current_depos: CurrentDepos[];
  past_depos: PastDepos[];
  future_depos: FutureDepos[];
  lombard_rate: number;
};

// export type ReceiveClientCardsDebug = {
//   current_loans: CurrentLoans[];
//   // past_loans: PastLoans[];
//   // future_loans: FutureLoans[];
//   current_depos: CurrentDepos[];
//   // past_depos: PastDepos[];
//   // future_depos: FutureDepos[];
// };

export const ClientMoneyInitialState = {
  fetched: false,
  fetching: false,
  current_loans: { items: {}, waiting: false },
  past_loans: { items: {}, waiting: false },
  future_loans: { items: {}, waiting: false },
  current_depos: { items: {}, waiting: false },
  past_depos: { items: {}, waiting: false },
  future_depos: { items: {}, waiting: false },
};

export const loan_tenors: { [index: number]: string } = {
  0: '0-1',
  1: '1-3',
  2: '3-7',
  3: '7-10',
  4: '10-15',
  5: '15+',
};

export const depo_tenors: { [index: number]: string } = {
  0: '0-3',
  1: '3-12',
};

export const LEND = 0;
export const BORROW = 1;
export const NOTHING = 2;
