import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectors,
  ASSET_CLASSES,
  updateAssetClassOptions,
  RISK_WEIGHTED_APPROACH,
  AssetGroupAllocationPayload,
  createUpdateGroupAllocations,
  AssetClassOptions,
} from 'state';
import { EditableTable } from 'reusable';
import { PercentInput } from 'components';
import {
  Button,
  Empty,
  InputNumber,
  Popover,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

type State = {
  [assetClass: number]: AssetClassOptions;
};

const AssetClassLimits: React.FC = () => {
  const dispatch = useDispatch();
  const { asset_class_options, risk_approach } = useSelector(
    selectors.activeSession
  );
  // console.log('asset_class_options: ', asset_class_options);
  const [editing, setEditing] = useState(false);
  const [limits, setLimits] = useState<State>({});
  const [errors, setErrors] = useState<string[]>([]);

  const playableAssetClasses_ = useSelector(selectors.playableAssetClasses());
  useEffect(() => {
    const newLimits = {} as State;
    asset_class_options.forEach(a => {
      newLimits[a.asset_class] = {
        asset_class: a.asset_class,
        pv_limit: a.pv_limit,
        risk_weight: a.risk_weight,
      };
    });
    setLimits(newLimits);
  }, [asset_class_options]);

  const dataSource = asset_class_options
    .map(({ ...item }) => {
      const limit = {
        asset_class: item.asset_class,
        pv_limit: item.pv_limit,
        risk_weight: item.risk_weight,
      };
      const name = ASSET_CLASSES[item.asset_class];
      const assetClass = item.asset_class;
      return { name, assetClass, limit };
    })
    .filter(assetClassOption =>
      playableAssetClasses_.includes(assetClassOption.assetClass)
    );

  // console.log('datasource ACL: ', dataSource);

  const reset = () => {
    const newLimits = {} as State;
    asset_class_options.forEach(a => {
      newLimits[a.asset_class] = {
        asset_class: a.asset_class,
        pv_limit: a.pv_limit,
        risk_weight: a.risk_weight,
      };
    });
    setLimits(newLimits);
    setErrors([]);
  };

  const getProps = (field: 'pv_limit' | 'risk_weight') => {
    return {
      render(_: unknown, record: typeof dataSource[0]) {
        if (editing) {
          // const assetClass = record.asset_class;
          const allocation = limits[record.assetClass];
          const limit = allocation?.[field] ?? 0;

          return (
            <div>
              <InputNumber
                value={limit}
                style={{ width: 70 }}
                onChange={value => {
                  if (value === undefined) {
                    return;
                  }
                  setLimits({
                    ...limits,
                    [record.assetClass]: {
                      ...allocation,
                      asset_class: record.assetClass,
                      [field]: value,
                    },
                  });
                }}
                step={0.01}
                min={0}
                max={1}
                formatter={value => {
                  const nr = Number(value) || 0;
                  return `${Math.round(nr * 100)}%`;
                }}
                parser={value => {
                  const parsedValue = `${value?.replaceAll('%', '')}`;
                  const nr = Number(parsedValue);
                  if (!isNaN(nr)) {
                    return (nr / 100).toFixed(2);
                  }
                  return '';
                }}
              />
            </div>
          );
        }

        if (!record.limit) {
          return <div>-</div>;
        }
        return <div>{Math.round(record.limit[field] * 100)}%</div>;
      },
    };
  };

  const editButton = (
    <div
      style={{ position: 'relative', display: 'flex', gap: '10px', height: 30 }}
    >
      {!editing && (
        <Button
          onClick={() => {
            setEditing(true);
          }}
          style={{ position: 'absolute', right: 0 }}
        >
          Edit
        </Button>
      )}
      {editing && (
        <>
          <Button
            type="primary"
            onClick={() => {
              if (!isValid()) {
                return;
              }

              setEditing(false);
              dispatch(
                // TODO: this took too long
                updateAssetClassOptions({ asset_class_options: limits })
              );
            }}
            style={{ position: 'absolute', right: 90 }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setEditing(false);
              reset();
            }}
            style={{ position: 'absolute', right: 0 }}
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  );

  const isValid = () => {
    const errorsList = [];

    const valid = Object.values(limits).reduce(
      (acc, { pv_limit, risk_weight }) => {
        return (
          acc &&
          pv_limit >= 0 &&
          pv_limit <= 100 &&
          risk_weight >= 0 &&
          risk_weight <= 100
        );
      },
      true
    );

    if (!valid) {
      errorsList.push('Limits must be between 0 and 100');
    }

    if (errorsList.length) {
      setErrors(errorsList);
      return false;
    }

    return true;
  };

  const columns = [
    {
      title: 'Asset Class',
      dataIndex: 'name',
    },
    {
      title: 'Limit',
      ...getProps('pv_limit'),
    },
  ];

  if (risk_approach === RISK_WEIGHTED_APPROACH) {
    columns.push({
      title: 'Risk weight',
      ...getProps('risk_weight'),
    });
  }

  const assetClassLimitsInfo = (
    <div>
      Specifying a limit on a certain asset class will enforce a hard limit on
      each instrument of that asset class.
    </div>
  );

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          Finish saving your instrument selection.
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <Typography.Title level={4} style={{ display: 'inline-block' }}>
          Asset Class Limits
        </Typography.Title>
        <Tooltip title={assetClassLimitsInfo}>
          <InfoCircleOutlined
            style={{
              position: 'absolute',
              marginLeft: 10,
              top: 7,
            }}
          />
        </Tooltip>
      </div>

      <Table
        rowKey="assetGroup"
        dataSource={dataSource}
        columns={columns}
        locale={noDataBehaviour}
        pagination={false}
        bordered
        footer={() => editButton}
      />
    </div>
  );
};

export default AssetClassLimits;
