import React from 'react';
import { useSelector } from 'react-redux';

import { Descriptions, Card, Typography, Table } from 'antd';

import {
  selectors,
  BenchmarkComposition as BenchmarkComp,
  BenchmarkPortfolioComposition,
} from 'state';
import { formatters } from 'services';
import { percent0Digits } from 'services/formatters';

const BenchmarkComposition: React.FC = () => {
  const session = useSelector(selectors.activeSession);
  const indexFunds = useSelector(selectors.indexFunds);
  const assets = useSelector(selectors.underlyingAssets);
  const player = useSelector(selectors.player);

  // INDEX COMP. benchmark
  let benchmarkIndexCompositions: BenchmarkComp[];
  if (session.allow_individual_benchmark && player.benchmark_compositions) {
    benchmarkIndexCompositions = player.benchmark_compositions;
  } else {
    benchmarkIndexCompositions = session.benchmark_compositions;
  }

  if (!benchmarkIndexCompositions) {
    benchmarkIndexCompositions = [];
  } else {
    benchmarkIndexCompositions = benchmarkIndexCompositions.map(obj => {
      return {
        fund: indexFunds[obj.fund]?.name || '',
        weight: obj.weight,
        key: obj.fund,
      };
    });
  }

  // PORTFOLIO COMP. benchmark
  let benchmarkPortfolioComposition: BenchmarkPortfolioComposition[] =
    session.benchmark_portfolio_compositions;
  if (benchmarkPortfolioComposition.length > 0) {
    benchmarkPortfolioComposition = benchmarkPortfolioComposition.map(obj => {
      let weight: number;
      if (session.starting_cash_amount !== 0) {
        weight = obj.reporting_value! / session.starting_cash_amount;
      } else {
        weight = 0;
      }
      return {
        asset: assets[obj.asset]?.name || '',
        weight: weight,
        key: obj.asset,
      };
    });
  }

  // Constant benchmark.
  const constantBenchmark = session.benchmark_constant;
  return (
    <div>
      {benchmarkIndexCompositions.length > 0 && (
        <>
          <Typography.Title level={4}>
            Benchmark Index Composition
          </Typography.Title>
          <Table
            dataSource={benchmarkIndexCompositions}
            columns={[
              { title: 'Fund', dataIndex: 'fund', key: 'key' },
              {
                title: 'Weight',
                dataIndex: 'weight',
                key: 'key',
                align: 'right',
                render: value => formatters.percent0Digits(value),
              },
            ]}
            bordered
            pagination={false}
          ></Table>
        </>
      )}
      {benchmarkPortfolioComposition.length > 0 && (
        <>
          <Typography.Title level={4}>
            Benchmark Portfolio Composition
          </Typography.Title>
          <Table
            dataSource={benchmarkPortfolioComposition}
            columns={[
              { title: 'Asset', dataIndex: 'asset', key: 'key' },
              {
                title: 'Weight',
                dataIndex: 'weight',
                key: 'key',
                align: 'right',
                render: value => formatters.percent0Digits(value),
              },
            ]}
            bordered
            pagination={false}
          ></Table>
        </>
      )}
      {constantBenchmark !== 0 && (
        <>
          <Typography.Title level={4}>Benchmark</Typography.Title>
          <Card>
            <Typography.Text style={{ whiteSpace: 'nowrap' }}>
              Benchmark is set to {formatters.percent2Digit(constantBenchmark)}
            </Typography.Text>
          </Card>
        </>
      )}
    </div>
  );
};

export default BenchmarkComposition;
