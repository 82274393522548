import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Tabs } from 'antd';

import {
  selectors,
  retrievePlayer,
  CASH_APPROACH,
  fetchForwardContracts,
  fetchForwardContractsByPlayerId,
} from 'state';
import InstrumentTable from './InstrumentTable';
import { Blotter, IndividualReport } from 'reusable';
import OpenOrders from './OpenOrders';
import { LoadingScreen } from 'components';
import PlayerCash from './PlayerCash';
import { LoadingOutlined } from '@ant-design/icons';

type props = {
  playerId: number;
  hide: () => void;
};

const PlayerModal: React.FC<props> = ({ playerId, hide }) => {
  const dispatch = useDispatch();
  const session = useSelector(selectors.activeSession);
  const player = useSelector(selectors.playerById(playerId));
  const { fetched_data } = player;
  const fetchedForwards = useSelector(
    selectors.fetchedForwardsByPlayerId(playerId)
  );

  useEffect(() => {
    if (fetched_data) return;

    dispatch(retrievePlayer({ player_id: player?.id }));
  }, [dispatch, fetched_data]);
  useEffect(() => {
    if (fetchedForwards) return;
    dispatch(fetchForwardContractsByPlayerId({ player_id: player?.id }));
  }, [dispatch, fetchedForwards]);

  const hasCashApproach = session.risk_approach === CASH_APPROACH;

  return (
    <Modal
      visible={!!playerId}
      onOk={hide}
      onCancel={hide}
      title={player.name}
      width="80%"
    >
      {player.fetched_success ? (
        <Tabs defaultActiveKey="0">
          {hasCashApproach && (
            <Tabs.TabPane key="0" tab="Cash">
              <PlayerCash playerId={playerId} />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane key="1" tab="PnL Breakdown">
            <InstrumentTable playerId={playerId} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="Blotter">
            <Blotter playerId={playerId} />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab="Open Orders">
            <OpenOrders playerId={playerId} />
          </Tabs.TabPane>
          <Tabs.TabPane key="4" tab="Individual Report">
            <IndividualReport player={player} />
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <LoadingScreen />
      )}
    </Modal>
  );
};

export default PlayerModal;
