import React from 'react';
import { Col, Row } from 'antd';

import AssetClassAllocations from './AssetClassAllocations';
import AssetClassLimits from './AssetClassLimits';
import MetricsSelector from './MetricsSelector';
import BenchmarkSelector from './Benchmarks/BenchmarkSelector';
import SessionInstrumentsSelector from './SessionInstrumentSelector';
import OtherSettings from './OtherSettings';
import LeverageSettings from './LeverageSettings';

const SessionSettings: React.FC = () => {
  return (
    <Row gutter={80} style={{ flexFlow: 'row nowrap' }}>
      <Col
        span={5}
        style={{
          minWidth: 330,
          display: 'flex',
          flexDirection: 'column',
          gap: 30,
        }}
      >
        <AssetClassAllocations />
        <AssetClassLimits />
        <LeverageSettings />
      </Col>
      <Col
        span={6}
        style={{
          minWidth: 400,
          borderLeft: '2px solid #eeeeee',
          display: 'flex',
          flexDirection: 'column',
          gap: 30,
        }}
      >
        <BenchmarkSelector />
        <MetricsSelector />
        <OtherSettings />
      </Col>
      <Col span={13} style={{ borderLeft: '2px solid #eeeeee' }}>
        <SessionInstrumentsSelector />
      </Col>
    </Row>
  );
};

export default SessionSettings;
