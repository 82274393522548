import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Table, Row, Col } from 'antd';
import { fetchAssetDetails, selectors } from 'state';

type Props = {
  assetId: number;
  visible: boolean;
  hide: () => void;
};

const ETFDetailsModal: React.FC<Props> = ({ assetId, visible, hide }) => {
  const dispatch = useDispatch();
  const asset = useSelector(selectors.underlyingAssetById(assetId));

  useEffect(() => {
    if (asset?.fetched_details) return;

    dispatch(fetchAssetDetails({ id: asset.id }));
  }, [assetId]);

  if (!asset) return null;

  const investmentTheme = Object.entries(asset.investment_theme || {})
    .map(entry => ({ key: entry[0], value: entry[1] }))
    .filter(o => o.value);

  const topHoldings = asset.top_holdings?.map(str => {
    console.log('holding: ', str);
    console.log('holding: ', typeof str);
    const [symbol, holding] = str.split('\t');
    console.log('symbol: ', symbol);
    console.log('holding: ', holding);
    return { symbol, holding };
  });

  console.log('asset links: ', asset.links);

  return (
    <Modal
      title={asset.name}
      visible={visible}
      onCancel={hide}
      footer={null}
      width="70%"
    >
      <Row gutter={16}>
        <Col span={12}>
          <h2>Investment Themes</h2>
          <Table
            rowKey="key"
            dataSource={investmentTheme}
            showHeader={false}
            columns={[{ dataIndex: 'key' }, { dataIndex: 'value' }]}
            pagination={false}
          />
          <br />
          <h2>Objectives and Features of the Fund </h2>
          {asset.objectives?.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </Col>
        <Col span={12}>
          <h2>Top Holdings</h2>
          <Table
            rowKey="symbol"
            dataSource={topHoldings}
            columns={[
              { title: 'Symbol', dataIndex: 'symbol' },
              { title: 'Holding', dataIndex: 'holding' },
            ]}
            pagination={false}
          />
          <br />
          <h2>See more</h2>
          {asset.links?.map(link => (
            <div key={link}>
              <a href={link} target="_blank" rel="noreferrer">
                {link}
              </a>
            </div>
          ))}
        </Col>
      </Row>
    </Modal>
  );
};

export default ETFDetailsModal;
