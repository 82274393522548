import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputNumber } from 'antd';

type Props = {
  style?: React.CSSProperties;
  value?: number;
  onChange?: (val: any) => void;
  [key: string]: any;
};

const PercentInput = React.forwardRef(function Input(props: Props, ref) {
  return (
    <InputNumber
      // min={0}
      // max={100}
      formatter={val => `${val}%`}
      parser={val => `${val?.replace('%', '')}`}
      {...(props as any)}
      ref={ref}
    />
  );
});

export default PercentInput;
