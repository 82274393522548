import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Radio, Input, Button, Space } from 'antd';

import { selectors, createPerson, selectPerson } from 'state';

type Props = {
  visible: boolean;
};

const PersonSelector: React.FC<Props> = ({ visible }) => {
  const dispatch = useDispatch();
  const persons = useSelector(selectors.personsList);

  const [newPerson, setNewPerson] = useState('');
  const [selectedPerson, setSelectedPerson] = useState(-1);

  useEffect(() => {
    if (persons.length && selectedPerson === -1)
      setSelectedPerson(persons[0].id);
  }, [persons]);

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <Modal
      title="Choose your username or add yourself to the team!"
      visible={visible}
      closable={false}
      footer={[
        <Button
          disabled={selectedPerson === -1}
          type="primary"
          key="ok"
          onClick={() => dispatch(selectPerson(selectedPerson))}
        >
          Ok
        </Button>,
      ]}
    >
      <Radio.Group
        value={selectedPerson}
        onChange={e => setSelectedPerson(e.target.value)}
      >
        {persons.map(person => (
          <Radio style={radioStyle} value={person.id} key={person.id}>
            {person.name}
          </Radio>
        ))}
      </Radio.Group>
      <br />
      <Space>
        <Input
          placeholder={'John Doe'}
          value={newPerson}
          onChange={e => setNewPerson(e.target.value)}
        />
        <Button
          type="primary"
          onClick={() => {
            dispatch(createPerson({ name: newPerson }));
            setNewPerson('');
          }}
        >
          Add new person
        </Button>
      </Space>
    </Modal>
  );
};

export default PersonSelector;
