import React from 'react';
import { useSelector } from 'react-redux';

import { Empty, Table, Typography } from 'antd';

import { REAL_TIME_SCENARIO, selectors, utils } from 'state';
import { formatters } from 'services';
import { ColumnsType } from 'antd/es/table';
import { TBillsClosedContracts } from '../../../state/app/selectors';
import {
  auctions_tenors,
  SOLD_BEFORE_TERM_TBILL_CONTRACT,
} from '../../../state/app/auctions/types';

const TBillsBlotter: React.FC = () => {
  const TBillContracts = useSelector(selectors.TBillsClosedContracts);
  const assets = useSelector(selectors.underlyingAssets);
  const fins = useSelector(selectors.financialInstruments);
  const ccys = useSelector(selectors.currencies);
  const session = useSelector(selectors.activeSession);
  const reportingCurrency = utils.useReportingCurrency(session.id);

  const data = TBillContracts.map(contract => {
    const asset = assets[fins[contract.financial_instrument].underlying_asset];
    const ccy_symbol = ccys[asset.base_currency].symbol;
    const tenor = asset.tbill_tenor;
    return {
      ...contract,
      // value: value,
      tenor: tenor,
      ccy_symbol: ccy_symbol,
      value: (contract.amount * contract.close_price) / 100,
    };
  });

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`You don't have any TBill contracts yet.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Series',
      dataIndex: 'underlying_asset_name',
    },
    {
      title: 'Tenor',
      dataIndex: 'tenor',
      render: (value: number) => auctions_tenors[value],
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (val: number) => formatters.commasNoDigits(val),
    },
    {
      title: 'Open Price',
      dataIndex: 'open_price',
      render: (val: number) => formatters.commas3Digits(val),
    },
    {
      title: 'Close Price',
      dataIndex: 'close_price',
      render: (val: number) => formatters.commas3Digits(val),
    },
    {
      title: `Value`,
      dataIndex: 'value',
      render: (val: number) => formatters.commasNoDigits(val),
    },
    {
      title: 'CCY',
      dataIndex: 'ccy_symbol',
    },
    {
      title: `Reporting PnL, ${reportingCurrency.symbol}`,
      dataIndex: 'tbill_contract_reporting_realized_pnl',
      render: (val: number) => formatters.commas1Digit(val),
    },
    {
      title: 'Close Type',
      dataIndex: 'status',
      render: (val: number) => {
        if (val === SOLD_BEFORE_TERM_TBILL_CONTRACT) {
          return 'Sold before term';
        } else {
          return 'Held to maturity';
        }
      },
    },
    // {
    //   title: `Premium`,
    //   dataIndex: 'premium_paid',
    //   render: (val: number) => formatters.commasNoDigits(val),
    // },
  ];
  // if (session.scenario_type === REAL_TIME_SCENARIO) {
  //   columns.push({
  //     title: `Open`,
  //     dataIndex: 'scenario_date_created_timestamp',
  //     render: (val: string) =>
  //       val ? formatters.dateWithTimeSeconds(val) : '--',
  //   });
  // }
  // columns.push({
  //   title: `Maturity`,
  //   dataIndex: 'maturity',
  // });

  return (
    <div>
      <Table
        rowKey="id"
        bordered
        dataSource={data}
        pagination={false}
        locale={noDataBehaviour}
        columns={columns}
      />
    </div>
  );
};

export default TBillsBlotter;
