import React from 'react';
import { useSelector } from 'react-redux';

import { Empty, Table, Typography } from 'antd';

import { REAL_TIME_SCENARIO, selectors } from 'state';
import { formatters } from 'services';
import { ColumnsType } from 'antd/es/table';

const CDSBlotter: React.FC = () => {
  const CDSContracts = useSelector(selectors.CDSContracts);
  const assets = useSelector(selectors.underlyingAssets);
  const session = useSelector(selectors.activeSession);

  console.log('CDSContracts: ', CDSContracts);
  const data = CDSContracts.map(contract => {
    let maturity: string;
    if (session.scenario_type === REAL_TIME_SCENARIO) {
      maturity = formatters.dateWithTimeSeconds(
        contract.cds_contract_maturity_date
      );
    } else {
      const maturityDate = new Date(contract.cds_contract_maturity_date);
      const sessionDate = new Date(session.scenario_date);
      const days = Math.floor(
        (maturityDate.getTime() - sessionDate.getTime()) / (1000 * 60 * 60 * 24)
      );
      if (days === 0) {
        maturity = 'today';
      } else if (days === 1) {
        maturity = 'in 1 day';
      } else {
        if (days < 0) {
          maturity = 'matured';
        } else {
          maturity = `in ${days} days`;
        }
      }
    }
    return {
      ...contract,
      // value: value,
      maturity: maturity,
    };
  });

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`You don't have any CDS contracts yet.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Asset',
      dataIndex: 'underlying_asset_name',
    },
    {
      title: 'Open Rate, BP',
      dataIndex: 'open_rate',
      render: (val: number) => val * 10_000,
    },
    {
      title: 'CCY',
      dataIndex: 'cds_contract_ccy',
      // align: 'right',
      // render: formatters.commasNoDigits,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (val: number) => formatters.commasNoDigits(val),
    },
    {
      title: `Premium`,
      dataIndex: 'premium_paid',
      render: (val: number) => formatters.commasNoDigits(val),
    },
  ];
  if (session.scenario_type === REAL_TIME_SCENARIO) {
    columns.push({
      title: `Open`,
      dataIndex: 'scenario_date_created_timestamp',
      render: (val: string) =>
        val ? formatters.dateWithTimeSeconds(val) : '--',
    });
  }
  columns.push({
    title: `Maturity`,
    dataIndex: 'maturity',
  });

  return (
    <div>
      <Table
        rowKey="id"
        bordered
        dataSource={data}
        pagination={false}
        locale={noDataBehaviour}
        columns={columns}
      />
    </div>
  );
};

export default CDSBlotter;
