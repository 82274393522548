import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { notification } from 'antd';

import { selectors } from 'state';

const News: React.FC = () => {
  const lastNews = useSelector(selectors.lastNews);

  useEffect(() => {
    if (!lastNews) return;

    lastNews.forEach(news => {
      notification.info({
        message: news.headline,
      });
    });
  }, [lastNews]);

  return null;
};

export default News;
