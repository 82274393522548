import React from 'react';
import { useSelector } from 'react-redux';

import { Card, Statistic, Row, Col } from 'antd';

import {
  CASH_APPROACH,
  HISTORICAL_SCENARIO,
  REAL_TIME_SCENARIO,
  RISK_WEIGHTED_APPROACH,
  selectors,
} from 'state';
import { formatters } from 'services';
import { useReportingCurrency } from '../../../../state/app/utils';

const OverviewCards: React.FC = () => {
  const {
    risk_weighted_limit,
    risk_approach,
    client_money,
    scenario_type,
  } = useSelector(selectors.activeSession);
  const session_obj = useSelector(selectors.activeSession);
  const {
    // market_value,
    last_market_value,
    risk_weighted_value,
    // portfolio_return,
    last_portfolio_return,
    // pnl_sum,
    last_pnl_sum,
    metrics,
    // fx_return,
    last_fx_return,
    session,
  } = useSelector(selectors.player);
  const player = useSelector(selectors.player);
  const totalCurrentLoans = useSelector(selectors.totalCurrentLoans);
  const totalCurrentDepos = useSelector(selectors.totalCurrentDepos);
  const reportingCurrency = useReportingCurrency(session);
  const { var05 } = metrics.slice(-1)[0];

  const instruments = useSelector(selectors.financialInstrumentsList);
  const ccys = useSelector(selectors.playableCurrencies(instruments));
  const differentCCYs = ccys.length > 1;

  const StatsCard = (title: string, value: string) => (
    <Card style={{ whiteSpace: 'nowrap' }}>
      <Statistic title={title} value={value} />
    </Card>
  );
  const MarketValueCard = StatsCard(
    'Market Value, ' + reportingCurrency.symbol,
    formatters.million(last_market_value)
  );
  const ReturnCard = StatsCard(
    'Return %',
    formatters.percent2Digit(last_portfolio_return)
  );
  const PNLCard = StatsCard(
    'PnL, ' + reportingCurrency.symbol,
    formatters.million(last_pnl_sum)
  );
  const RiskWeightedValueCard = StatsCard(
    'Risk weighted value, ' + reportingCurrency.symbol,
    formatters.million(risk_weighted_value)
  );
  const RiskWeightedLimitCard = StatsCard(
    'Risk weighted limit, ' + reportingCurrency.symbol,
    formatters.million(risk_weighted_limit)
  );
  const VarCard = StatsCard(
    'VaR, ' + reportingCurrency.symbol,
    formatters.million(var05)
  );
  const FXReturnCard = StatsCard(
    'Cash Txn MTM %',
    formatters.percent2Digit(last_fx_return / session_obj.starting_cash_amount)
  );
  // if (
  //   scenario_type === HISTORICAL_SCENARIO &&
  //   risk_approach === CASH_APPROACH
  // ) {
  //   return (
  //     <>
  //       {MarketValueCard}
  //       {FXReturnCard}
  //       {ReturnCard}
  //     </>
  //   );
  // }
  if (client_money) {
    const cash =
      session_obj.starting_cash_amount -
      player.total_repoed_amount +
      totalCurrentDepos -
      totalCurrentLoans +
      player.interbank_amount -
      player.trading_book;

    console.log(`starting_cash_amount: ${session_obj.starting_cash_amount}
                player.total_repoed_amount: ${player.total_repoed_amount}
                totalCurrentDepos: ${totalCurrentDepos}
                totalCurrentLoans: ${totalCurrentLoans}
                player.interbank_amount: ${player.interbank_amount}
                player.trading_book: ${player.trading_book}
                
                total cash: ${cash}
    `);
    return (
      <Card>
        <Row>
          <Col span={12}>
            <div>
              Capital: {formatters.million(session_obj.starting_cash_amount)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
            <div>
              Trading Book: {formatters.million(player.trading_book)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
            {/*<div>*/}
            {/*  Cash: {formatters.million(cash)}*/}
            {/*  /!*{reportingCurrency.symbol}*!/*/}
            {/*</div>*/}
            <div>
              Client Loans: {formatters.million(totalCurrentLoans)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
            <div>
              Client Depos: {formatters.million(totalCurrentDepos)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
            <div>
              Interbank Amount: {formatters.million(player.interbank_amount)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
            <div>
              Repos: {formatters.million(player.total_repoed_amount)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
          </Col>
          <Col span={12}>
            <div>
              Trading Book: {formatters.million(player.trading_book_pnl)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
            <div>
              Net client interest: {formatters.million(player.client_interest)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
            <div>
              Net interbank interest:{' '}
              {formatters.million(player.interbank_interest)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
            <div>
              Paid lombard interest:
              {formatters.million(player.paid_lombard_interest)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
            <div>
              Repos interest:
              {formatters.million(player.paid_repo_interest)}{' '}
              {/*{reportingCurrency.symbol}*/}
            </div>
          </Col>
        </Row>
      </Card>
    );
  }

  if (risk_approach === CASH_APPROACH)
    return (
      <div>
        <Row>
          <Col span={14}>{MarketValueCard}</Col>
          {differentCCYs && <Col span={14}>{FXReturnCard}</Col>}
          <Col span={10}>{ReturnCard}</Col>
        </Row>
        {/*<Row>*/}
        {/*  <Col span={10}>{ReturnCard}</Col>*/}
        {/*  <Col span={14}>{AccruedInterestCard}</Col>*/}
        {/*</Row>*/}
      </div>
    );

  if (risk_approach === RISK_WEIGHTED_APPROACH)
    return (
      <div>
        <Row>
          <Col span={8}>{MarketValueCard}</Col>
          <Col span={8}>{ReturnCard}</Col>
          <Col span={8}>{PNLCard}</Col>
        </Row>
        <Row>
          <Col span={8}>{RiskWeightedValueCard}</Col>
          <Col span={8}>{RiskWeightedLimitCard}</Col>
          <Col span={8}>{VarCard}</Col>
        </Row>
      </div>
    );

  return null;
};

export default React.memo(OverviewCards);
