import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spin, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons/lib';

type Props = {
  fullscreen?: boolean;
};

const LoadingScreen: React.FC<Props> = ({ fullscreen }) => {
  return (
    <div>
      <Row
        justify="center"
        align="middle"
        style={{ height: fullscreen ? '100vh' : '100%' }}
      >
        <Col>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 150 }} spin />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default LoadingScreen;
