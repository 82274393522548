import { appManager, AppState } from '../manager';
import { arrayToIndexedObject } from 'services/indexedObject';
import type {
  UnderlyingAsset,
  MarketHistory,
  Equity,
  Dividend,
  IndexFund,
} from 'state';
import { CreateIndexFund } from 'state';

export const retrieveHistory = appManager.createApi<
  {
    underlying_asset_id: number;
    period:
      | 'one_day'
      | 'five_days'
      | 'one_month'
      | 'six_months'
      | 'one_year'
      | 'three_years';
  },
  { underlying_asset_id: number; market_history: MarketHistory[] },
  AppState
>('RETRIEVE_HISTORY', {
  path: '/underlying_assets/:underlying_asset_id/retrieve_history',
  method: 'GET',
  startReducer(state, payload) {
    state.underlying_assets.items[payload.underlying_asset_id].market_history[
      payload.period
    ] = [];
  },
  successReducer(state, result, payload) {
    state.underlying_assets.items[result.underlying_asset_id].market_history[
      payload.period
    ] = result.market_history;
  },
});

export const retrieveUnderlyingAssets = appManager.createApi<
  unknown,
  UnderlyingAsset[],
  AppState
>('RETRIEVE_UNDERLYING_ASSETS', {
  path: '/underlying_assets',
  method: 'GET',
  startReducer(state) {
    state.underlying_assets.waiting = true;
  },
  successReducer(state, result) {
    state.underlying_assets.items = arrayToIndexedObject(result);
    state.underlying_assets.waiting = false;
  },
});

export const fetchAssetDetails = appManager.createApi<
  { id: number },
  UnderlyingAsset,
  AppState
>('FETCH_ASSET_DETAILS', {
  path: '/underlying_assets/:id/details',
  method: 'GET',
  startReducer(state, payload) {
    state.underlying_assets.items[payload.id].fetched_details = true;
  },
  successReducer(state, result, payload) {
    const items = state.underlying_assets.items;
    items[payload.id] = { ...items[payload.id], ...result };
  },
});

export const listSessionEquities = appManager.createApi<
  unknown,
  Equity[],
  AppState
>('LIST_SESSION_EQUITIES', {
  path: '/equities/session_equities',
  method: 'GET',
  startReducer(state) {
    state.equities.waiting = true;
  },
  successReducer(state, result) {
    result.forEach(eq => {
      state.equities.items[eq.id] = eq;
    });
    state.equities.waiting = false;
    state.equities.fetched = true;
  },
  failReducer(state) {
    state.equities.waiting = false;
  },
});

export const listPlayerDividends = appManager.createApi<
  { player_id?: number; session_id?: number },
  Dividend[],
  AppState
>('LIST_PLAYER_DIVIDENDS', {
  path: '/equities/player_dividends',
  method: 'GET',

  successReducer(state, result, payload) {
    result.forEach(div => {
      state.dividends.items[div.id] = div;
      const player = state.players.items[payload.player_id || -1];
      if (player) {
        player.dividends = result;
      }
    });
    state.dividends.fetched = true;
  },
});

export const createUnderlyingAsset = appManager.createApi<
  Partial<UnderlyingAsset>,
  UnderlyingAsset,
  AppState
>('CREATE_UNDERLYING_ASSET', {
  path: '/underlying_assets/',
  method: 'POST',
  // startReducer(state, payload) {},
  successReducer(state, result) {
    const items = state.underlying_assets.items;
    items[result.id] = result;
  },
});

export const createIndexFund = appManager.createApi<
  CreateIndexFund,
  IndexFund,
  AppState
>('CREATE_INDEX_FUND', {
  path: '/admin/create_index_fund',
  method: 'POST',
  // startReducer(state, payload) {},
  successReducer(state, result) {
    state.index_funds.items[result.id] = result;
  },
});

export const deleteIndexFund = appManager.createApi<
  IndexFund,
  number,
  AppState
>('DELETE_INDEX_FUND', {
  path: '/admin/delete_index_fund',
  method: 'POST',
  // startReducer(state, payload) {},
  successReducer(state, fund_id) {
    delete state.index_funds.items[fund_id];
  },
});

export const deleteUnderlyingAsset = appManager.createApi<
  { id: number },
  unknown,
  AppState
>('DELETE_UNDERLYING_ASSET', {
  path: '/underlying_assets/:id',
  method: 'DELETE',
  // startReducer(state, payload) {},
  successReducer(state, result, payload) {
    const items = state.underlying_assets.items;
    delete items[payload.id];
  },
});
