import React from 'react';
import { Form } from 'antd';

type Props = {
  field: any;
  label: string;
  name: string;
  missingMessage: string;
};

const DynamicFormFieldItem: React.FC<Props> = props => {
  const { field, label, name, missingMessage, children } = props;

  return (
    <Form.Item
      {...field}
      label={label}
      name={[field.name, name]}
      fieldKey={[field.fieldKey, name] as any}
      rules={[{ required: true, message: missingMessage }]}
    >
      {children}
    </Form.Item>
  );
};

export default DynamicFormFieldItem;
