import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { message } from 'antd';

import { selectors } from 'state';

const Operations: React.FC = () => {
  const error = useSelector(selectors.error);
  const waiting = useSelector(selectors.waiting);
  const lastAction = useSelector(selectors.lastAction);

  useEffect(() => {
    if (!lastAction) return;
    if (waiting) return;

    if (lastAction.includes('FETCH')) return;
    if (lastAction.includes('RETRIEVE')) return;
    if (lastAction.includes('LIST')) return;

    if (!error) {
      return message.success('Successful operation');
    }

    if (typeof error === 'string' && error !== '[object Object]') {
      return message.error(error);
    }

    return message.error('An error has occurred');
  }, [waiting, error, lastAction]);

  return null;
};

export default Operations;
