import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Affix, Tabs, Badge, Switch, Typography } from 'antd';

import {
  selectors,
  instrumentTypes,
  FinancialInstrument,
  CASH_APPROACH,
  CASH,
  fetchBlotter,
  // fetchFwdContracts,
} from 'state';
import { formatters } from 'services';
import OrderModal from '../OrderModal';
import HedgeOrderModal from '../HedgeOrderModal';
import ODAModal from '../ODAModal';
import InstrumentTableDivisor from './InstrumentTableDivisor';
import PortfolioOverview from './PortfolioOverview';
import CashTab from './CashTab';
import ContractsModal from '../ContractsModal';
import { TraineeSessionContext } from '../TraineeSessionContext';
import RepoModal from '../RepoModal';
import CDSModal from '../CDSModal';
import CDSContractsModal from '../CDSContractsModal';
import RepoContractsModal from '../RepoContractsModal';
import TBillModal from '../TBillModal';
import TBillContractsModal from '../TBillContractsModal';
import { fetchClientMoney } from '../../../state/app/client_money/actions';

const MarketsTab: React.FC = () => {
  const dispatch = useDispatch();
  const session = useSelector(selectors.activeSession);
  const clients_fetched = useSelector(selectors.clientsFetched);
  const { risk_approach } = useSelector(selectors.activeSession);
  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const { fetched_blotter } = useSelector(selectors.player);
  // actually traineeContext
  const trainerContext = useContext(TraineeSessionContext);

  useEffect(() => {
    if (fetched_blotter) return;
    dispatch(fetchBlotter({}));
  }, [dispatch]);

  useEffect(() => {
    if (session.client_money) {
      if (clients_fetched) return;
      dispatch(fetchClientMoney({ session_id: session.id }));
    }
  }, [dispatch]);

  // useEffect(() => {
  //   // if (fetched_blotter) return;
  //   dispatch(fetchFwdContracts({}));
  // }, [dispatch]);

  const instrumentFilter = (
    instrument: FinancialInstrument,
    type: string,
    name: string
  ) => {
    const sameType =
      underlyingAssets[instrument.underlying_asset].resourcetype === type;

    const hedge = instrument.fx_hedge === name.includes('Forwards');

    return sameType && hedge;
  };

  const _financialInstruments = useSelector(selectors.financialInstrumentsList);

  useEffect(() => {
    if (!trainerContext.instrumentType) {
      for (let i = 0; i < instrumentTypes.length; i++) {
        const details = instrumentTypes[i];
        const instruments = _financialInstruments.filter(fn =>
          instrumentFilter(fn, details.type, details.name)
        );

        if (instruments.length) {
          trainerContext.setInstrumentType(`${i}`);
          break;
        }
      }
    }
  }, [_financialInstruments, trainerContext]);

  // if (!trainerContext.instrumentType) {
  //   for (let i = 0; i < instrumentTypes.length; i++) {
  //     const details = instrumentTypes[i];
  //     const instruments = _financialInstruments.filter(fn =>
  //       instrumentFilter(fn, details.type, details.name)
  //     );
  //
  //     if (instruments.length) {
  //       trainerContext.setInstrumentType(`${i}`);
  //       break;
  //     }
  //   }
  // }

  const details = instrumentTypes[parseInt(trainerContext.instrumentType)] || {
    type: '',
    name: '',
  };

  const financialInstruments = _financialInstruments.filter(fn =>
    instrumentFilter(fn, details.type, details.name)
  );

  const [instrument, setInstrument] = useState(_financialInstruments[0]);

  const [visibleOrderModal, setVisibleOrderModal] = useState(false);
  const [visibleHedgeOrderModal, setVisibleHedgeOrderModal] = useState(false);
  const [visibleCDSModal, setVisibleCDSModal] = useState(false);
  const [visibleTBillModal, setVisibleTBillModal] = useState(false);
  const [visibleRepoModal, setVisibleRepoModal] = useState(false);
  const [visibleContractsModal, setVisibleContractsModal] = useState(false);
  const [visibleTBillContractsModal, setVisibleTBillContractsModal] = useState(
    false
  );
  const [visibleRepoContractsModal, setVisibleRepoContractsModal] = useState(
    false
  );
  const [visibleCDSContractsModal, setVisibleCDSContractsModal] = useState(
    false
  );
  const [visibleODAModal, setVisibleODAModal] = useState(false);

  // const [showCash, setShowCash] = useState(false);

  const { tactical_asset_allocations } = useSelector(selectors.player);

  const allocations = {} as { [assetClass: number]: string };
  tactical_asset_allocations.forEach(allocation => {
    if (allocation.percent !== 0) {
      allocations[allocation.asset_class] = formatters.percent2Digit(
        allocation.percent
      );
    }
  });

  const getTAA = (assetClass: number) => {
    if (!allocations[assetClass]) {
      return '';
    }

    return (
      <Typography.Text
        type="danger"
        style={{
          fontSize: '75%',
        }}
      >
        <> ({allocations[assetClass]})</>
      </Typography.Text>
    );
  };

  const [showActiveOrders, setShowActiveOrders] = useState(false);

  if (!trainerContext.instrumentType) return null;

  return (
    <div>
      <Affix offsetTop={94}>
        <div className="markets-overview">
          <PortfolioOverview instrument={instrument} />

          <div style={{ display: 'flex', width: '100%', gap: 15 }}>
            <Tabs
              style={{ flex: 1 }}
              defaultActiveKey={trainerContext.instrumentType}
              onChange={val => {
                if (val === 'cash') return trainerContext.setShowCash(true);

                trainerContext.setShowCash(false);

                trainerContext.setInstrumentType(val);
                setInstrument(
                  _financialInstruments.filter(fn =>
                    instrumentFilter(
                      fn,
                      instrumentTypes[parseInt(val)].type,
                      instrumentTypes[parseInt(val)].name
                    )
                  )[0] || instrument
                );
              }}
            >
              {risk_approach === CASH_APPROACH && (
                <Tabs.TabPane
                  key="cash"
                  tab={<span>Cash{getTAA(CASH)}</span>}
                />
              )}

              {instrumentTypes.map((item, i) => {
                // console.log('item: ', item);
                const instruments = _financialInstruments.filter(fn =>
                  instrumentFilter(fn, item.type, item.name)
                );

                if (!instruments.length) return null;

                const activeInstruments = instruments.filter(fn => fn.amount);

                if (showActiveOrders && !activeInstruments.length) {
                  return null;
                }

                return (
                  <Tabs.TabPane
                    key={`${i}`}
                    tab={
                      <Badge count={activeInstruments.length} offset={[10, -5]}>
                        {item.name}
                        {getTAA(item.assetClass)}
                      </Badge>
                    }
                  />
                );
              })}
            </Tabs>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                paddingBottom: 15,
              }}
            >
              Show active trades
              <Switch
                checked={showActiveOrders}
                onChange={setShowActiveOrders}
              />
            </div>
          </div>
        </div>
      </Affix>

      {trainerContext.showCash ? (
        <CashTab />
      ) : (
        <InstrumentTableDivisor
          financialInstruments={financialInstruments}
          selectedInstrumentId={instrument.id}
          selectInstrument={setInstrument}
          showOrderModal={() => setVisibleOrderModal(true)}
          showHedgeOrderModal={() => setVisibleHedgeOrderModal(true)}
          showCDSModal={() => setVisibleCDSModal(true)}
          showTBillModal={() => setVisibleTBillModal(true)}
          showRepoModal={() => setVisibleRepoModal(true)}
          showContractsModal={() => setVisibleContractsModal(true)}
          showCDSContractsModal={() => setVisibleCDSContractsModal(true)}
          showTBillContractsModal={() => {
            // console.log('showTBillContractsModal');
            setVisibleTBillContractsModal(true);
          }}
          showRepoContractsModal={() => setVisibleRepoContractsModal(true)}
          showODAModal={() => setVisibleODAModal(true)}
          showActiveOrders={showActiveOrders}
        />
      )}

      {visibleOrderModal && (
        <OrderModal
          visible={visibleOrderModal}
          hide={() => setVisibleOrderModal(false)}
          id={instrument.id}
        />
      )}
      {visibleHedgeOrderModal && (
        <HedgeOrderModal
          visible={visibleHedgeOrderModal}
          hide={() => setVisibleHedgeOrderModal(false)}
          id={instrument.id}
        />
      )}
      {visibleCDSModal && (
        <CDSModal
          visible={visibleCDSModal}
          hide={() => setVisibleCDSModal(false)}
          id={instrument.id}
        />
      )}
      {visibleTBillModal && (
        <TBillModal
          visible={visibleTBillModal}
          hide={() => setVisibleTBillModal(false)}
          id={instrument.id}
        />
      )}
      {visibleRepoModal && (
        <RepoModal
          visible={visibleRepoModal}
          hide={() => setVisibleRepoModal(false)}
          id={instrument.id}
        />
      )}
      {visibleContractsModal && (
        <ContractsModal
          visible={visibleContractsModal}
          hide={() => setVisibleContractsModal(false)}
          id={instrument.id}
        />
      )}
      {visibleRepoContractsModal && (
        <RepoContractsModal
          visible={visibleRepoContractsModal}
          hide={() => setVisibleRepoContractsModal(false)}
          id={instrument.id}
        />
      )}
      {visibleCDSContractsModal && (
        <CDSContractsModal
          visible={visibleCDSContractsModal}
          hide={() => setVisibleCDSContractsModal(false)}
          id={instrument.id}
        />
      )}
      {visibleTBillContractsModal && (
        <TBillContractsModal
          visible={visibleTBillContractsModal}
          hide={() => setVisibleTBillContractsModal(false)}
          id={instrument.id}
        />
      )}
      {visibleODAModal && (
        <ODAModal
          visible={visibleODAModal}
          hide={() => setVisibleODAModal(false)}
          id={instrument.id}
        />
      )}
    </div>
  );
};

export default MarketsTab;
