import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Input,
  InputNumber,
  notification,
  Select,
  Table,
  Typography,
  Radio,
  Row,
  Col,
  Slider,
  Tag,
  Space,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  createFXCross,
  disconnectFromKlassSocket,
  fetchAssets,
  fetchSessionAssets,
  REAL_TIME_SCENARIO,
  selectors,
} from 'state';
import { formatStringDate } from '../../../state/app/utils';
import {
  createAuction,
  fetchYields,
} from '../../../state/app/auctions/actions';
import {
  auctionsListSelector,
  currenciesList,
  currencyById,
  playableCurrencies,
  playableCurrenciesByAssets,
} from '../../../state/app/selectors';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import {
  receiveCreatedAuctionType,
  auctions_tenors,
} from '../../../state/app/auctions/types';
import RespondToAuctionModal from '../../TraineeSession/Auctions/RespondToAuctionModal';
import ViewAuctionOffersModal from './viewOffersModal';
import { RadioChangeEvent } from 'antd/es/radio';
import { formatters } from 'services';
import { LoadingScreen } from 'components';

const CentralBank: React.FC = () => {
  const dispatch = useDispatch();
  const currencies = useSelector(playableCurrenciesByAssets);
  // console.log('currencies: ', currencies);
  const auctionsList = useSelector(auctionsListSelector);
  const [tenor, setTenor] = useState<number>(0);
  const [rateType, setRateType] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [bid, setBid] = useState<number | undefined>(80);
  const [bidUnit, setBidUnit] = useState(0);
  // TODO: initialize rate with the historical rate from current scenario date
  const [selectedCcy, setSelectedCcy] = useState(currencies[0]);
  const [selectedAuction, setSelectedAuction] = useState<
    receiveCreatedAuctionType | undefined
  >(undefined);
  const [showViewOffersModal, setShowViewOffersModal] = useState(false);
  const session = useSelector(selectors.activeSession);
  const yields = useSelector(selectors.getYields);
  useEffect(() => {
    dispatch(fetchSessionAssets({ id: session.id }));
    if (yields === undefined || yields.ccy_symbol !== selectedCcy.symbol) {
      dispatch(
        fetchYields({ session_id: session.id, ccy_symbol: selectedCcy.symbol })
      );
    }
  }, [selectedCcy]);

  let historicalRate: null | number = null;
  if (tenor === 0) {
    historicalRate = yields.yields.wk4;
  } else if (tenor === 1) {
    historicalRate = yields.yields.wk8;
  } else if (tenor === 2) {
    historicalRate = yields.yields.wk13;
  } else if (tenor === 3) {
    historicalRate = yields.yields.wk26;
  } else if (tenor === 4) {
    historicalRate = yields.yields.wk52;
  }
  // const historicalRate = yields.yields.;
  // const historicalRate = 5.368;
  const [rate, setRate] = useState<number>(historicalRate || 0);
  const rateNotAvailable = historicalRate === null;
  let currentDate;
  if (session.scenario_type === REAL_TIME_SCENARIO) {
    currentDate = new Date();
  } else {
    currentDate = new Date(session.start_date);
    currentDate.setDate(currentDate.getDate() + session.elapsed_days);
  }

  const currentDateformatted = formatStringDate(currentDate.toISOString());

  const maturityDate = new Date(currentDate); // Clone the currentDate to avoid mutating the original date
  if (tenor !== undefined) {
    // switch (tenorUnit) {
    switch (tenor) {
      case 0: // 4wk
        maturityDate.setDate(currentDate.getDate() + 4 * 7 - 1);
        break;
      case 1: // 8wk
        maturityDate.setDate(currentDate.getDate() + 8 * 7 - 1); // Convert weeks to days
        break;
      case 2: // 13wk
        maturityDate.setDate(currentDate.getDate() + 13 * 7 - 1);
        break;
      case 3: // 26wk
        maturityDate.setDate(currentDate.getDate() + 26 * 7 - 1);
        break;
      case 4: // 52wk
        maturityDate.setDate(currentDate.getDate() + 52 * 7 - 1);
        break;
      default:
        console.log('Invalid tenorUnit');
    }
  }
  const maturityDateformatted = formatStringDate(maturityDate.toISOString());

  const handleCreateAuction = () => {
    if (tenor !== undefined && bid !== undefined && rate !== undefined) {
      dispatch(
        createAuction({
          session_id: session.id,
          tenor: tenor,
          // tenor_unit: tenorUnit,
          auction_series: series,
          maturity_date: maturityDate,
          bid_value_no_units: bid,
          bid_unit: bidUnit,
          currency: selectedCcy.id,
          rate: rate,
          rate_difference_from_historical_rate: rate - historicalRate!,
        })
      );
    } else {
      notification.warn({
        message: 'Invalid Details',
      });
    }
  };

  const auctionColumns: ((
    | ColumnGroupType<receiveCreatedAuctionType>
    | ColumnType<receiveCreatedAuctionType>
  ) & {
    dataIndex: string;
  })[] = [
    {
      title: 'Tenor',
      dataIndex: 'tenor',
      key: 'tenor',
      render: (value: number) => {
        return auctions_tenors[value];
      },
    },
    {
      title: 'Series',
      dataIndex: 'auction_series',
      key: 'auction_series',
    },
    {
      title: 'Maturity',
      dataIndex: 'maturity_date',
      key: 'maturity_date',
    },
    {
      title: 'Auction Value (M)',
      dataIndex: 'bid_value',
      key: 'bid_value',
      width: 50,
      render: (value: number) => formatters.millionNoUnit(value),
    },
    // {
    //   title: 'Unit',
    //   dataIndex: 'bid_unit',
    //   key: 'bid_unit',
    //   render: (text: number) => {
    //     if (text === 0) {
    //       return <div>Million</div>;
    //     } else if (text === 1) {
    //       return <div>Billion</div>;
    //     } else if (text === 2) {
    //       return <div>Trillion</div>;
    //     }
    //   },
    // },
    {
      title: 'Issued (M)',
      dataIndex: 'issued_value',
      key: 'issued_value',
      render: (value: number) => {
        if (value) {
          return formatters.millionNoUnit(value);
        } else {
          return '--';
        }
      },
    },
    {
      title: 'CCY',
      dataIndex: 'currency_symbol',
      key: 'ccy',
    },
    {
      title: 'Yield',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Actions/Status',
      dataIndex: 'actions',
      render: (_, record) => {
        if (record.finished === false) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={() => {
                  // console.log(record);
                  setSelectedAuction(record);
                  setShowViewOffersModal(true);
                }}
              >
                View offers ({record.responses.length})
              </Button>
              <div style={{ marginLeft: 5 }}>/ open</div>
            </div>
          );
        } else {
          return <div>Auction has concluded</div>;
        }
      },
    },
  ];

  const onChangeTenor = (e: RadioChangeEvent) => {
    // console.log(`radio checked:${e.target.value}`);
    setTenor(e.target.value);
  };
  // useEffect(() => {
  //   // rate += sliderValue / 1000;
  // }, [sliderValue]);
  let lowSliderVal = '0';
  let midSliderVal = '0';
  let hiSliderVal = '0';

  if (historicalRate !== null) {
    lowSliderVal = ((historicalRate - 0.5) * 1000).toString();
    midSliderVal = (historicalRate * 1000).toString();
    hiSliderVal = ((historicalRate + 0.5) * 1000).toString();
  } else {
  }

  const marks = {
    [lowSliderVal]: '-0.5',
    [midSliderVal]: '0',
    [hiSliderVal]: '+0.5',
  };

  const labelColWidth = '180px';

  const units: { [index: number]: string } = {
    0: 'M',
    1: 'B',
    2: 'T',
  };
  console.log('historicalRate: ', historicalRate);
  console.log('rate: ', rate);

  // const series = 'SUKUK0102TNRO';
  const series = `TB-${selectedCcy.symbol.slice(0, 2)}${
    auctions_tenors[tenor]
  }${bid}${units[bidUnit]}${session.id}`;
  return (
    <div>
      <Card title={'Create an auction'}>
        <Row gutter={[20, 20]}>
          <Col
            flex={labelColWidth}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Typography.Text>Choose a tenor: </Typography.Text>
          </Col>
          <Col>
            <Radio.Group onChange={onChangeTenor} defaultValue={tenor}>
              <Radio.Button value={0}>4WK</Radio.Button>
              <Radio.Button value={1}>8WK</Radio.Button>
              <Radio.Button value={2}>13WK</Radio.Button>
              <Radio.Button value={3}>26WK</Radio.Button>
              <Radio.Button value={4}>52WK</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            flex={labelColWidth}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Typography.Text>Maturity: </Typography.Text>
          </Col>
          <Col>{maturityDateformatted}</Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            flex={labelColWidth}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Typography.Text>Total value: </Typography.Text>
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <InputNumber
              min={1}
              value={bid}
              onChange={choice => {
                console.log(choice);
                setBid(choice);
              }}
              // addonAfter={}
              style={{ width: 65, marginRight: 10 }}
            />
            <Select
              style={{ width: 85, marginRight: 23 }}
              value={bidUnit}
              options={[
                {
                  value: 0,
                  label: 'million',
                },
                {
                  value: 1,
                  label: 'billion',
                },
                {
                  value: 2,
                  label: 'trillion',
                },
              ]}
              onChange={choice => {
                setBidUnit(choice);
              }}
            />
            <Select
              // style={{ width: 70 }}
              placeholder="CCY"
              value={selectedCcy.symbol}
              onChange={newSymbol => {
                const newCurrency = currencies.find(
                  currency => currency.symbol === newSymbol
                );
                if (newCurrency) {
                  setSelectedCcy(newCurrency);
                }
              }}
            >
              {currencies.map(currency => (
                <Select.Option key={currency.id} value={currency.symbol}>
                  {currency.symbol}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            flex={labelColWidth}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Typography.Text>Rate: </Typography.Text>
          </Col>
          <Col>
            <Radio.Group
              style={{ marginRight: 25 }}
              onChange={(e: RadioChangeEvent) => {
                if (e.target.value == 0) {
                  setSliderValue(0);
                  setRate(historicalRate || 0);
                }
                setRateType(e.target.value);
              }}
              value={rateType}
            >
              <Radio value={0}>Historical</Radio>
              <Radio value={1} disabled={historicalRate === null}>
                Custom
              </Radio>
            </Radio.Group>
            {historicalRate !== null ? (
              <Space>
                <InputNumber
                  // addonAfter="$"
                  min={historicalRate - 0.5}
                  max={historicalRate + 0.5}
                  style={{ width: 73 }}
                  disabled={rateType === 0}
                  step={0}
                  value={rate}
                  onChange={value => {
                    if (typeof value === 'number') {
                      setRate(value);
                    }
                  }}
                />
                <div>%</div>
              </Space>
            ) : (
              <Input disabled={true} value={'N/A'} style={{ width: 73 }} />
            )}
          </Col>
        </Row>
        {rateType === 1 && (
          <Row gutter={[20, 20]}>
            <Col
              flex={labelColWidth}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
              }}
            ></Col>
            {historicalRate !== null && (
              <Col>
                <Slider
                  marks={marks}
                  defaultValue={rate * 1000}
                  value={rate * 1000}
                  min={(historicalRate - 0.5) * 1000}
                  max={(historicalRate + 0.5) * 1000}
                  style={{ width: 310 }}
                  tipFormatter={null}
                  onChange={e => {
                    setRate(Number((Number(e) / 1000).toFixed(3)));
                  }}
                />
              </Col>
            )}
          </Row>
        )}
        <Row gutter={[20, 20]}>
          <Col
            flex={labelColWidth}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Typography.Text>Series: </Typography.Text>
          </Col>
          <Col>{series}</Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col
            flex={labelColWidth}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Typography.Text>Action: </Typography.Text>
          </Col>
          <Col>
            <Button
              type={'primary'}
              onClick={() => handleCreateAuction()}
              disabled={rateNotAvailable || rate > historicalRate! + 0.5}
            >
              Start this auction
            </Button>
          </Col>
        </Row>
      </Card>
      <br />
      <Card>
        <Typography.Title level={4}>Auctions</Typography.Title>
        <Table
          bordered
          columns={auctionColumns}
          dataSource={auctionsList}
        ></Table>
      </Card>
      {showViewOffersModal && (
        <ViewAuctionOffersModal
          visible={showViewOffersModal}
          hide={() => {
            setShowViewOffersModal(false);
          }}
          auction_id={selectedAuction?.id}
        />
      )}
    </div>
  );
};

export default CentralBank;
