import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import {
  FinancialInstrument,
  HISTORICAL_SCENARIO,
  selectors,
  TRADING_WINDOWS,
  UnderlyingAsset,
} from 'state';
import Overview from './Overview';
import ReturnByAssetClass from './ReturnByAssetClass';
import ReturnByRegion from './ReturnByRegion';
import ReturnByIndustry from './ReturnByIndustry';
import LimitsAndWeights from './LimitsAndWeights';
import BenchmarkComposition from './BenchmarkComposition';
import TradingWindows from './TradingWindows';
import Investments from './Investments';
import PortfolioLimits from './PortfolioLimits';
import ReturnBySector from './ReturnBySector';

export type InvestmentData = FinancialInstrument &
  UnderlyingAsset & {
    currency: string;
    reportingValue: number;
    dollarToPortfolioRatio: number;
  };

const PortfolioTab: React.FC = () => {
  const { scenario_type, trading_time_type } = useSelector(
    selectors.activeSession
  );
  const assets = useSelector(selectors.underlyingAssets);
  const sessionHasNoEquities = Object.values(assets).every(
    asset => asset.resourcetype !== 'Equity'
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
      <Row style={{ flexFlow: 'row nowrap' }} gutter={40}>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 40,
            width: '100%',
          }}
        >
          <Overview />
          <ReturnByAssetClass />
          <ReturnByRegion />
          {!sessionHasNoEquities && (
            <>
              <ReturnByIndustry />
              <ReturnBySector />
            </>
          )}
        </Col>

        <Col
          style={{
            width: 'min-content',
            display: 'flex',
            flexDirection: 'column',
            gap: 40,
            borderLeft: '1px solid #eeeeee',
          }}
        >
          {/*{scenario_type === HISTORICAL_SCENARIO && <PortfolioLimits />}*/}
          <PortfolioLimits />
          <LimitsAndWeights />
          <BenchmarkComposition />
          {trading_time_type === TRADING_WINDOWS && <TradingWindows />}
        </Col>
      </Row>

      <Investments />
    </div>
  );
};

export default PortfolioTab;
