import React from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { Empty, Typography } from 'antd';

import { formatters } from 'services';
import { CDS_GROUP, GROUP_ASSET_NAME, REPO_GROUP, selectors } from 'state';

const TacticalAssetAllocation: React.FC = () => {
  const instruments = useSelector(selectors.financialInstrumentsList);
  const data = useSelector(selectors.pnlByAssetGroup(instruments));
  // console.log('TacticalAssetAllocation data: ', data);
  // TODO: use this until fixing the backend compute_tactical_allocations
  const dataNoReposNoCDS = data.filter(obj => {
    if (obj.assetGroup === REPO_GROUP || obj.assetGroup === CDS_GROUP) {
    } else {
      return obj;
    }
  });

  // console.log('TacticalAssetAllocation data2: ', dataNoReposNoCDS);

  const series = [
    {
      name: 'Short',
      data: dataNoReposNoCDS.map(group => ({
        x: GROUP_ASSET_NAME[group.assetGroup],
        y: group.percent_short,
      })),
    },
    {
      name: 'Long',
      data: dataNoReposNoCDS.map(group => ({
        x: GROUP_ASSET_NAME[group.assetGroup],
        y: group.percent_long,
      })),
    },
  ];
  // console.log('TacticalAssetAllocation series: ', series);

  if (!series?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <div style={{ minWidth: 270 }}>
      <Typography.Title
        level={4}
        style={{ whiteSpace: 'nowrap', marginBottom: 0 }}
      >
        Tactical Asset Allocation
      </Typography.Title>
      <Chart
        height={370}
        series={series}
        type="bar"
        options={{
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          tooltip: {
            y: {
              formatter: formatters.percent2Digit,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              formatter: formatters.percent0Digits,
            },
          },
        }}
      />
    </div>
  );
};

export default TacticalAssetAllocation;
