import { formatters } from './index';

type SNU = string | number | undefined;

const nr = (val: SNU) => {
  if (typeof val === 'number') return val;
  if (typeof val === 'string') {
    const parsed = parseFloat(val);

    if (isNaN(parsed)) return 0;

    return parsed;
  }
  return 0;
};

export const commasNoDigits = (n: SNU) =>
  nr(n).toLocaleString('en', { maximumFractionDigits: 0 });
export const commas1Digit = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  });
export const commas2Digits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
export const commasExact2Digits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
export const commas3Digits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 0,
  });
export const commasExact3Digits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  });
export const commas4Digits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 4,
    minimumFractionDigits: 0,
  });
export const commasExact4Digits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
  });
export const commas5Digits = (n: SNU) =>
  nr(n).toLocaleString('en', {
    maximumFractionDigits: 5,
    minimumFractionDigits: 0,
  });
export const percent0Digits = (n: SNU) =>
  (nr(n) * 100).toLocaleString('en', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }) + '%';
export const percent1Digit = (n: SNU) =>
  (nr(n) * 100).toLocaleString('en', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  }) + ' %';
export const percent2Digit = (n: SNU) =>
  (nr(n) * 100).toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }) + ' %';
export const percent3Digit = (n: SNU) =>
  (nr(n) * 100).toLocaleString('en', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  }) + ' %';
export const priceFormat = (n: SNU, ticker = '', resourcetype = '') => {
  if (resourcetype === 'Crypto') {
    return commas5Digits(n);
  } else if (resourcetype == 'FxForwards') {
    if (ticker === 'USDJPY1M=' || ticker === 'JPY1M=')
      return commasExact2Digits(n);
    return commasExact4Digits(n);
  } else if (
    resourcetype !== 'FX' ||
    ['JPY1M', 'EURJPY1M', 'JPY='].includes(ticker)
  ) {
    return commas2Digits(n);
  }
  return commas4Digits(n);
};
export const metricsCommas2Digits = (n: SNU) => {
  n = parseFloat(`${n}`);
  if (n < -100 || n > 100) return 'N/A';
  return commasExact2Digits(n);
};
export const metricsCommas4Digits = (n: SNU) => {
  n = parseFloat(`${n}`);
  if (n < -100 || n > 100) return 'N/A';
  return commasExact4Digits(n);
};
export const metricsPercent1Digit = (n: SNU) => {
  n = parseFloat(`${n}`);
  if (n < -100 || n > 100) return 'N/A';
  return percent1Digit(n);
};

export const millionNoUnit = (n: SNU) => {
  n = parseFloat(`${n}`);
  let fmt = formatters.commas2Digits(n / 1000000);
  if (fmt === '-0') {
    fmt = '0';
  }
  return fmt;
};

export const millionNoUnitExact2Digits = (n: SNU) => {
  n = parseFloat(`${n}`);
  let fmt = formatters.commasExact2Digits(n / 1000000);
  if (fmt === '-0') {
    fmt = '0';
  }
  return fmt;
};

export const millionDollarsNoUnit = (n: SNU) => '$' + millionNoUnit(n);

export const million = (n: SNU) => `${millionNoUnit(n)}M`;

export const millionDollars = (n: SNU) => '$' + million(n);

export const dateWithTime = (d: string) => {
  return d.replace(
    /(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)(\.(\d+))*Z/,
    '$1-$2-$3 $4:$5'
  );
};

export const dateWithTimeSeconds = (d: string) => {
  return d.replace(
    /(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)(\.(\d+))*Z/,
    '$1-$2-$3 $4:$5:$6'
  );
};

export const dateWithTimeSecondsMiliseconds = (d: string) => {
  return d.replace(
    /(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)(\.(\d+))*Z/,
    '$1-$2-$3 $4:$5:$6$7'
  );
};
