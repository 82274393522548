import {
  createSocketConnectAction,
  createSocketDisconnectAction,
} from 'services';

const klassUrl = '/klass/';
const klassDesc = 'klass';
export const connectToKlassSocket = createSocketConnectAction(
  klassUrl,
  klassDesc
);
export const disconnectFromKlassSocket = createSocketDisconnectAction(
  klassUrl,
  klassDesc
);

const sessionUrl = '/session';
const sessionDesc = 'session';
const sessionOptions = { id: true };
export const connectToSessionSocket = createSocketConnectAction(
  sessionUrl,
  sessionDesc,
  sessionOptions
);
export const disconnectFromSessionSocket = createSocketDisconnectAction(
  sessionUrl,
  sessionDesc,
  sessionOptions
);

const adminUrl = '/admin/';
const adminDesc = 'admin';
const adminOptions = {};
export const connectToAdminSocket = createSocketConnectAction(
  adminUrl,
  adminDesc,
  adminOptions
);
export const disconnectFromAdminSocket = createSocketDisconnectAction(
  adminUrl,
  adminDesc,
  adminOptions
);
