import React from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux-actions';
import { Modal, Typography } from 'antd';

const { Paragraph } = Typography;

type Props = {
  action: Action<unknown>;
  title: string;
  message: string;
  visible: boolean;
  hide: () => void;
};

const ActionModal: React.FC<Props> = ({
  action,
  title,
  message,
  visible,
  hide,
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={hide}
      onOk={() => {
        dispatch(action);
        hide();
      }}
    >
      <Paragraph>{message}</Paragraph>
    </Modal>
  );
};

export default ActionModal;
