import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import Chart from 'react-apexcharts';

import { formatters } from 'services';
import { FinancialInstrument, selectors } from 'state';
import { AreaChart } from 'components';
import { name } from 'camelcase';
import { auctions_tenors } from '../../../../state/app/auctions/types';

type props = {
  height: number;
};

const YieldCurveGraph: React.FC<props> = ({ height }) => {
  // const data_old = useSelector(selectors.AFSDurationBreakdown());
  const yields = useSelector(selectors.todaysYields);
  // console.log('yields: ', yields);

  const data = [
    {
      x: 0,
      y: yields.yields.wk4,
    },
    {
      x: 2,
      y: yields.yields.wk13,
    },
    {
      x: 3,
      y: yields.yields.wk26,
    },
    {
      x: 4,
      y: yields.yields.wk52,
    },
    {
      x: 5,
      y: yields.yields.yr2,
    },
    {
      x: 7,
      y: yields.yields.yr5,
    },
    {
      x: 9,
      y: yields.yields.yr10,
    },
    {
      x: 10,
      y: yields.yields.yr30,
    },
  ];

  // console.log('data: ', data);

  const series = [
    {
      name: 'Holding',
      data: data,
    },
  ];

  const options: Record<string, unknown> = {
    chart: {
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    title: {
      text: 'Yield Curve',
    },
    stroke: { curve: 'smooth' },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      // gradient: {
      //   shadeIntensity: 1,
      //   inverseColors: false,
      //   opacityFrom: 0.5,
      //   opacityTo: 0,
      //   stops: [0, 90, 100],
      // },
    },
    yaxis: {
      labels: {
        formatter: formatters.commasExact2Digits,
        // ? yaxisFormatter
        // : (value: string | number | undefined) =>
        //     value?.toLocaleString?.() ?? '',
      },
    },
    xaxis: {
      labels: {
        formatter: (value: number) => auctions_tenors[value],
      },
      tooltip: false,
    },
  };

  return (
    <div
      style={{ minWidth: 242 }}
      // style={{ minWidth: 442 }}
    >
      {/*<Typography.Title*/}
      {/*  level={4}*/}
      {/*  style={{ whiteSpace: 'nowrap', marginBottom: 0 }}*/}
      {/*>*/}
      {/*  Yield Curve*/}
      {/*</Typography.Title>*/}
      <Chart
        height={height}
        series={[
          {
            name: 'Yields',
            data: data,
          },
        ]}
        type={'line'}
        options={options}
      />
    </div>
  );
};

export default YieldCurveGraph;
