import React, { useEffect, useRef, useState } from 'react';

import { Input, InputNumber, Switch, Select } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'state';

type TextInputProps = {
  defaultVal: string;
  update: (val: string) => void;
  hide: () => void;
};
const TextInput: React.FC<TextInputProps> = ({ defaultVal, update, hide }) => {
  const ref = useRef<Input>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const val = () => ref.current?.input.value || '';

  return (
    <Input
      defaultValue={defaultVal}
      ref={ref}
      onKeyDown={e => {
        if (e.key !== 'Enter') return;

        const value = val();
        value !== defaultVal && update(value);
        hide();
      }}
      onBlur={() => {
        const value = val();
        value !== defaultVal && update(value);
        hide();
      }}
    />
  );
};

export const TextType = (defaultVal: string, update: (val: string) => void) => {
  const [editing, setEditing] = useState(false);

  if (!editing)
    return (
      <div
        className={
          'editable-cell-value-wrap' + (!defaultVal ? '-no-content' : '')
        }
        onClick={() => setEditing(true)}
      >
        {defaultVal}
      </div>
    );

  return (
    <TextInput
      defaultVal={defaultVal}
      hide={() => setEditing(false)}
      update={update}
    />
  );
};

type NumberInputProps = {
  defaultVal: number;
  update: (val: number) => void;
  hide: () => void;
};
const NumberInput: React.FC<NumberInputProps> = ({
  defaultVal,
  update,
  hide,
}) => {
  const [value, setValue] = useState(defaultVal);
  const ref = useRef<InputNumber>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <InputNumber
      value={value}
      onChange={val => setValue(val || 0)}
      ref={ref}
      onKeyDown={e => {
        if (e.key !== 'Enter') return;

        value !== defaultVal && update(value);
        hide();
      }}
      onBlur={() => {
        value !== defaultVal && update(value);
        hide();
      }}
    />
  );
};

export const NumberType = (
  defaultVal: number,
  update: (val: number) => void
) => {
  const [editing, setEditing] = useState(false);

  if (!editing)
    return (
      <div
        className="editable-cell-value-wrap"
        onClick={() => setEditing(true)}
      >
        {defaultVal}
      </div>
    );

  return (
    <NumberInput
      defaultVal={defaultVal}
      update={update}
      hide={() => setEditing(false)}
    />
  );
};

export const SwitchInput = (val: boolean, update: (val: boolean) => void) => (
  <Switch defaultChecked={val} onChange={update} />
);

export const SelectInput = (
  defaultVal: number | undefined,
  options: { value: number; name: string }[],
  update: (val: number) => void
) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Select>(null);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  if (!editing) {
    const name = options.find(op => op.value === defaultVal)?.name;

    const isNumber = typeof defaultVal === 'number';

    return (
      <div
        className={
          'editable-cell-value-wrap' + (!isNumber ? '-no-content' : '')
        }
        onClick={() => setEditing(true)}
      >
        {name}
      </div>
    );
  }

  return (
    <Select
      defaultOpen
      ref={inputRef as any}
      defaultValue={defaultVal}
      onChange={val => {
        val !== defaultVal && update(val);
        setEditing(false);
      }}
      onBlur={() => {
        setEditing(false);
      }}
      style={{ minWidth: 150 }}
    >
      {options.map(({ value, name }) => (
        <Select.Option key={value} value={value}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

type IndexFundSelectProps = {
  defaultVal: number | undefined;
  update: (val: number) => void;
};

export const IndexFundSelect: React.FC<IndexFundSelectProps> = ({
  defaultVal,
  update,
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Select>(null);

  const indexFunds = useSelector(selectors.indexFunds);

  const options = Object.values(indexFunds || {}).map(index => {
    return { value: index.id, label: index.ric };
  });

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  if (!editing) {
    const ric = options.find(op => op.value === defaultVal)?.label;

    const isNumber = typeof defaultVal === 'number';

    return (
      <div
        className={
          'editable-cell-value-wrap' + (!isNumber ? '-no-content' : '')
        }
        onClick={() => setEditing(true)}
      >
        {ric}
      </div>
    );
  }

  return (
    <Select
      style={{ minWidth: 200 }}
      defaultOpen
      ref={inputRef as any}
      defaultValue={defaultVal}
      onChange={val => {
        val !== defaultVal && update(val);
        setEditing(false);
      }}
      onBlur={() => {
        setEditing(false);
      }}
      allowClear
      showSearch
      filterOption={(value, option) => {
        const searchId = Number(value);
        const indexId = Number(option?.value);

        if (!isNaN(searchId)) {
          return indexId.toString().includes(searchId.toString());
        } else {
          const lowerValue = value.toLowerCase();
          const index = indexFunds[indexId];
          if (!index) {
            return false;
          }

          const name = (index.name || '').toLowerCase();
          const ric = (index.ric || '').toLowerCase();

          return name.includes(lowerValue) || ric.includes(lowerValue);
        }
      }}
    >
      {options.map(({ value, label }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
};
