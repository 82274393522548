import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Layout, Menu, Affix } from 'antd';

import logo from 'assets/images/logo.svg';

type props = {
  defaultKey: string;
  home?: string;
  level?: number;
};

const EmptyHeader: React.FC<props> = ({
  defaultKey,
  home,
  level,
  children,
}) => {
  const { pathname } = useLocation();
  const pathStrings = pathname.split('/');
  const key = level ? pathStrings[level] : pathStrings.slice(-1)[0];

  return (
    <Affix>
      <Layout.Header className="header">
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={[defaultKey]}
          selectedKeys={[key]}
        >
          <Menu.Item disabled={!home} style={{ cursor: 'auto' }}>
            <Link to={home || '/'}>
              <img src={logo} alt="traderion logo" />
            </Link>
          </Menu.Item>
          {children}
        </Menu>
      </Layout.Header>
    </Affix>
  );
};

export default EmptyHeader;
