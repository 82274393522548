import React from 'react';

import Operations from './Operations';
import News from './News';
import OrderFilled from './OrderFilled';

const NotificationCenter: React.FunctionComponent = () => {
  return (
    <>
      <Operations />
      <News />
      <OrderFilled />
    </>
  );
};

export default NotificationCenter;
