import React from 'react';
import { useSelector } from 'react-redux';

import { Card, Statistic, Row, Col } from 'antd';
import { AreaChart } from 'components';

import { HISTORICAL_SCENARIO, METRICS_TYPES, selectors } from 'state';
import { formatters } from 'services';
import { useReportingCurrency } from '../../state/app/utils';

const nameDict: { [key: string]: string } = {
  portfolio_volatility: 'Portfolio Volatility',
  alpha: 'Alpha',
  beta: 'Beta',
  sharpe_ratio: 'Sharpe Ratio',
  treynor_ratio: 'Treynor Ratio',
  portfolio_duration: 'Portfolio Duration',
  portfolio_dv01: 'Portfolio DV01',
  market_value: 'Market Value',
  var05: 'VaR',
};

const Metrics: React.FC = () => {
  const metrics = useSelector(selectors.metrics);
  const portfolioDurationShort = useSelector(selectors.portfolioDurationShort);
  const portfolioDurationLong = useSelector(selectors.portfolioDurationLong);
  // const portfolioDv01 = useSelector(selectors.portfolioDv01);
  const hasHTM = useSelector(selectors.sessionHasHTMBonds);
  const hasAFS = useSelector(selectors.sessionHasAFSBonds);
  const HTMorAFS = hasAFS || hasHTM;

  const {
    // market_value,
    last_market_value,
    // portfolio_return,
    last_portfolio_return,
    session,
  } = useSelector(selectors.player);
  const { scenario_type, display_metrics } = useSelector(
    selectors.activeSession
  );

  const lastMetrics = { ...(metrics.slice(-1)[0] || {}) };
  delete lastMetrics.timestamp;
  delete lastMetrics.id;

  const reportingCurrency = useReportingCurrency(session);

  const data: { name: string; value: string }[] = [
    {
      name: 'Volatility',
      value: formatters.metricsPercent1Digit(lastMetrics.portfolio_volatility),
    },
    {
      name: 'Alpha',
      value: formatters.metricsCommas4Digits(lastMetrics.alpha),
    },
    {
      name: 'Beta',
      value: formatters.metricsCommas4Digits(lastMetrics.beta),
    },
    {
      name: 'Sharpe Ratio',
      value: formatters.metricsCommas2Digits(lastMetrics.sharpe_ratio),
    },
    {
      name: 'Treynor Ratio',
      value: formatters.metricsCommas2Digits(lastMetrics.treynor_ratio),
    },
  ];
  if (!HTMorAFS) {
    data.push({
      name: 'VaR, ' + reportingCurrency.symbol,
      value: formatters.million(lastMetrics.var05),
    });
  }
  data.push(
    {
      name: 'Duration (long/short)',
      value: `${formatters.commas2Digits(
        portfolioDurationLong
      )} / ${formatters.commas2Digits(portfolioDurationShort)}`,
    },
    // TODO: add this back
    // {
    //   name: 'Portfolio DV01',
    //   value: formatters.commas2Digits(portfolioDv01),
    // },
    {
      name: 'Portfolio',
      value: `${formatters.millionDollars(
        last_market_value
      )} / ${formatters.percent1Digit(last_portfolio_return)}`,
    }
  );

  // TODO: add VaR back
  const finalData = data
    .filter(
      item =>
        !(
          ['Duration (long/short)', 'Portfolio DV01'].includes(item.name) &&
          scenario_type === HISTORICAL_SCENARIO
        )
    )
    .filter((_, index) => display_metrics.some(i => i === index));

  let names = display_metrics.map(
    index => METRICS_TYPES[index as keyof typeof METRICS_TYPES]
  );
  if (HTMorAFS) {
    names = names.filter(name => name !== 'VaR');
  }
  const metricsKeys = Object.keys(lastMetrics).filter(key =>
    names.includes(nameDict[key])
  );

  return (
    <div>
      <Row>
        {finalData.map(item => (
          <Col key={item.name}>
            <Card>
              <Statistic title={item.name} value={item.value} />
            </Card>
          </Col>
        ))}
      </Row>
      <br />
      <Row>
        {metricsKeys.map(key => {
          if (!nameDict[key]) return null;

          const data = metrics.map((item: { [key: string]: any }) => ({
            x: item.timestamp,
            y: item[key] as any,
          }));

          return (
            <Col lg={8} key={key}>
              <AreaChart
                title={nameDict[key]}
                dataSeriesName={nameDict[key]}
                data={data}
                style={{ width: 400 }}
                tooltipDateFormat={'d MMM HH:mm'}
                cleaningCondition={
                  key !== 'var05' ? ({ y }) => y < -100 || y > 100 : undefined
                }
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Metrics;
