import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Moment } from 'moment';
import {
  Modal,
  Button,
  Radio,
  InputNumber,
  Select,
  Input,
  message,
  Space,
  Row,
  Col,
  DatePicker,
  TimePicker,
} from 'antd';

import {
  selectors,
  createOrder,
  SELL,
  BUY,
  SHORT_SELL,
  forceForwardContractFromAdmin,
} from 'state';
import { formatters } from 'services';

type Props = {
  visible: boolean;
  hide: () => void;
  playerId: number;
  sessionId: number;
};

const OrderModal: React.FC<Props> = ({ visible, hide, playerId }) => {
  const dispatch = useDispatch();

  const financialInstruments = useSelector(
    selectors.financialInstrumentsListByPlayerId(playerId)
  );
  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const [instrumentId, setInstrumentId] = useState(financialInstruments[0].id);

  const [investmentRationale, setInvestmentRationale] = useState('');

  const financialInstrument = useSelector(
    selectors.financialInstrumentById(instrumentId, playerId)
  );
  const isFxForwards = financialInstrument.fx_hedge === true;
  const underlyingAsset = useSelector(
    selectors.underlyingAssetOfInstrument(financialInstrument)
  );

  const [quantity, setQuantity] = useState(100);
  const orderType = 'MarketOrder';

  const player = useSelector(selectors.playerById(playerId));

  const persons = useSelector(selectors.persons);
  const personsList = useSelector(selectors.personsListAdmin);

  const playerPersons = player.user.persons
    .map(id => persons[id])
    .filter(p => p !== undefined);

  const adminPerson = personsList.find(p =>
    p.name.toLowerCase().includes('admin')
  );
  if (adminPerson) {
    playerPersons.push(adminPerson);
  }

  const [transaction, setTransaction] = useState(0);
  const sign = transaction === BUY ? 1 : -1;

  const currencyId = underlyingAsset.base_currency;
  const currency = useSelector(selectors.currencyById(currencyId));

  const [price, setPrice] = useState(1);

  const [date, setDate] = useState<Moment | undefined>(undefined);
  const [maturity, setMaturity] = useState<Moment | undefined>(undefined);
  const [time, setTime] = useState<Moment | undefined>(undefined);

  const getTimestamp = (input_date: Moment | undefined, withTime: boolean) => {
    if (input_date === undefined) {
      return undefined;
    }
    const dateStr = input_date.format('YYYY-MM-DD');
    const timeStr = time?.format('HH:mm:ss') ?? '23:59:00';
    if (withTime) {
      return `${dateStr} ${timeStr}`;
    } else {
      return `${dateStr}`;
    }
  };

  const [personId, setPersonId] = useState(personsList[0]?.id);

  const wallet = player.wallets.find(
    wallet => underlyingAsset.variable_currency === wallet.currency
  );

  if (!wallet) {
    message.error('Wallet not found!');
    return null;
  }

  return (
    <Modal
      visible={visible}
      title={`New order`}
      onOk={hide}
      onCancel={hide}
      footer={[
        <Button
          key="place"
          type="primary"
          onClick={() => {
            if (isFxForwards) {
              dispatch(
                forceForwardContractFromAdmin({
                  financial_instrument_id: financialInstrument.id,
                  amount: sign * quantity,
                  open_price: price,
                  transaction: transaction,
                  open_date: getTimestamp(date, true),
                  maturity_date: getTimestamp(maturity, false),
                  // investment_rationale: investmentRationale,
                })
              );
            } else {
              dispatch(
                createOrder({
                  resourcetype: orderType as any,
                  financial_instrument: financialInstrument.id,
                  forecast: 0,
                  transaction,
                  quantity,
                  limit_price: 0,
                  investment_rationale: investmentRationale,
                  forced_price: price,
                  hedge: false,
                  timestamp: getTimestamp(date, true),
                  person: Number(personId),
                })
              );
            }
            hide();
          }}
        >
          Place Order
        </Button>,
      ]}
    >
      <Space direction="vertical" size={10} style={{ display: 'flex' }}>
        <Row>
          <Col span={6}>Instrument:</Col>
          <Col span={18}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: 200 }}
              value={instrumentId}
              onChange={setInstrumentId}
            >
              {financialInstruments.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {underlyingAssets[item.underlying_asset].ric}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={6}>Transaction:</Col>
          <Col span={18}>
            <Radio.Group
              onChange={e => setTransaction(e.target.value)}
              value={transaction}
            >
              <Radio value={BUY}>Buy</Radio>
              <Radio value={SELL}>Sell</Radio>
              {!isFxForwards && <Radio value={SHORT_SELL}>Short Sell</Radio>}
            </Radio.Group>
          </Col>
        </Row>

        <Row>
          <Col span={6}>Price ({currency.symbol}):</Col>
          <Col span={18}>
            <InputNumber
              value={price}
              onChange={val => setPrice(val || 0)}
              style={{ width: 100 }}
            />
          </Col>
        </Row>

        <Row>
          <Col span={6}>Quantity:</Col>
          <Col span={18}>
            <InputNumber
              value={quantity}
              formatter={formatters.commasNoDigits}
              // parser={parseNumber}
              onChange={val => setQuantity(val || 0)}
              style={{ width: 100 }}
            />
          </Col>
        </Row>

        <Row>
          {isFxForwards ? (
            <Col span={6}>Open date:</Col>
          ) : (
            <Col span={6}>Timestamp:</Col>
          )}

          <Col span={18}>
            <DatePicker
              value={date}
              onChange={value => setDate(value as any)}
            />
            <TimePicker
              value={time}
              onChange={value => setTime(value as any)}
            />
          </Col>
        </Row>
        {isFxForwards && (
          <Row>
            <Col span={6}>Maturity:</Col>
            <Col span={18}>
              <DatePicker
                value={maturity}
                onChange={value => setMaturity(value as any)}
              />
            </Col>
          </Row>
        )}
        {!isFxForwards && (
          <Input
            placeholder="Investment Rationale"
            value={investmentRationale}
            onChange={e => setInvestmentRationale(e.target.value)}
          />
        )}
        {!isFxForwards && (
          <Select
            optionFilterProp="children"
            style={{ width: 200 }}
            value={personId}
            onChange={setPersonId}
          >
            {playerPersons.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Space>
    </Modal>
  );
};

export default OrderModal;
