import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Button,
  Table,
  Popover,
  Space,
  Empty,
  Typography,
  Popconfirm,
} from 'antd';

import {
  selectors,
  ForwardContract,
  closeForwardContract,
  PAUSED,
  CDSContractType,
  REAL_TIME_SCENARIO,
} from 'state';
import { formatters } from 'services';
import { cashExchange, useReportingCurrency } from '../../state/app/utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { CDSContractsOfInstrument } from '../../state/app/selectors';

type props = {
  visible: boolean;
  hide: () => void;
  id: number;
};

type displayContract = {
  contract: CDSContractType;
  key: number;
  name: string;
  open_date: string;
  currency: string;
  open_price: number;
  amount: string;
  reporting_unrealized_pnl: string;
  // value: string; MKT_VAL INSTEAD
};

const CDSContractsModal: React.FC<props> = ({ visible, hide, id }) => {
  const dispatch = useDispatch();
  // console.log('reached CDS contracts modal');
  const contracts = useSelector(selectors.CDSContractsOfInstrument(id));
  // console.log('cds contracts: ', contracts);
  const fin = useSelector(selectors.financialInstrumentById(id));
  const asset = useSelector(selectors.underlyingAssetOfInstrument(fin));
  const currency = useSelector(selectors.currencyById(asset.base_currency));
  const session = useSelector(selectors.activeSession);
  const reportingCurrency = useReportingCurrency(session.id);
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);
  const scenarioDate = new Date(session.scenario_date.split('T')[0]);
  const data: displayContract[] = contracts
    .filter(contract => !contract.matured)
    .map(contract => {
      let maturity: string;
      if (session.scenario_type === REAL_TIME_SCENARIO) {
        maturity = formatters.dateWithTimeSeconds(
          contract.cds_contract_maturity_date
        );
      } else {
        const maturityDate = new Date(contract.cds_contract_maturity_date);
        const sessionDate = new Date(session.scenario_date);
        const days = Math.floor(
          (maturityDate.getTime() - sessionDate.getTime()) /
            (1000 * 60 * 60 * 24)
        );
        if (days === 0) {
          maturity = 'today';
        } else if (days === 1) {
          maturity = 'in 1 day';
        } else {
          if (days < 0) {
            maturity = 'matured';
          } else {
            maturity = `in ${days} days`;
          }
        }
      }

      return {
        contract: contract,
        key: contract.id,
        name: asset.name,
        open_date: formatters.dateWithTime(
          contract.scenario_date_created_timestamp
        ),
        currency: currency.symbol,
        open_price: contract.open_rate * 10_000,
        amount: formatters.commas2Digits(contract.amount),
        reporting_unrealized_pnl: formatters.commas2Digits(
          contract.reporting_cds_contract_pnl
        ),
        mkt_val: formatters.commasNoDigits(
          contract.cds_contract_current_mkt_value
        ),
        premium_paid: formatters.commasNoDigits(contract.premium_paid),
        // value: formatters.commas2Digits(-1 * reporting_value), MKT_VAL INSTEAD !!
        maturity: maturity,
      };
    });

  // console.log('cds_data: ', data);

  const columns: ColumnsType<displayContract> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'CCY',
      dataIndex: 'currency',
    },
    {
      title: 'Open Price',
      dataIndex: 'open_price',
    },
    {
      title: `Amount, ${reportingCurrency.symbol}`,
      dataIndex: 'amount',
    },
    {
      title: `Mkt Val, ${reportingCurrency.symbol}`,
      dataIndex: 'mkt_val',
    },
    {
      title: `Unrealized, ${reportingCurrency.symbol}`,
      dataIndex: 'reporting_unrealized_pnl',
      render: (text, record) => {
        return record.contract
          ? formatters.commasNoDigits(
              record.contract.reporting_cds_contract_pnl
            )
          : null;
      },
    },
    {
      title: `Premium, ${reportingCurrency.symbol}`,
      dataIndex: 'premium_paid',
    },
  ];

  if (session.scenario_type === REAL_TIME_SCENARIO) {
    columns.push({
      title: 'Open',
      dataIndex: 'open_date',
    });
  }

  columns.push({
    title: 'Maturity',
    dataIndex: 'maturity',
  });

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`You don't have any active CDS contracts yet.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };
  // console.log('before return');
  return (
    <Modal
      visible={visible}
      title={`Your active ${asset.name} CDS Contracts`}
      onOk={hide}
      onCancel={hide}
      // style={{ width: '70%' }}
      width={'70%'}
      // footer={[
      //   <Button
      //     key="place"
      //     type="primary"
      //     disabled={quantity <= 0 || quantity > maxQuantity}
      //     onClick={() => {
      //       dispatch(
      //         buyForwardContract({
      //           financial_instrument_id: financialInstrument.id,
      //           amount: quantity,
      //           transaction: transaction,
      //         })
      //       );
      //       hide();
      //     }}
      //   >
      //     Buy contract
      //   </Button>,
      // ]}
    >
      <Table
        dataSource={data}
        columns={columns}
        bordered
        locale={noDataBehaviour}
      ></Table>
    </Modal>
  );
};

export default CDSContractsModal;
