import React from 'react';

import Chart from 'react-apexcharts';

import { formatters } from 'services';
import { Empty } from 'antd';

type props = {
  data: { value: number; label: string }[];
  series?: { name?: string; data: number[] }[];
  categories?: string[];
  style?: React.CSSProperties;
  showLegend?: boolean;
  title?: string;
};

const ColumnChart: React.FC<props> = ({
  data,
  style,
  series,
  categories,
  showLegend,
  title,
}) => {
  series = series || [{ data: data.map(item => item.value) }];
  categories = categories || data.map(item => item.label);

  if (!categories.length) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <div style={style}>
      <Chart
        series={series}
        type="bar"
        options={{
          chart: {
            // height: 350,
            width: '100%',
            type: 'bar',
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          title: {
            text: title,
          },
          plotOptions: {
            bar: {
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          legend: {
            show: !!showLegend,
          },
          xaxis: {
            categories,
            labels: {
              style: {
                fontSize: '12px',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: formatters.percent2Digit,
            },
          },
        }}
      />
    </div>
  );
};

export default ColumnChart;
