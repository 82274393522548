import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Button, Typography } from 'antd';

import { selectors } from 'state';
import { formatters } from 'services';
import KlassUsers from './KlassUsers';

type props = {
  klass: number;
  onSelect: (id: number) => void;
};

const Klasses: React.FC<props> = ({ onSelect, klass }) => {
  const klasses = useSelector(selectors.klassesList);

  const [usersVisible, setUsersVisible] = useState(false);

  useEffect(() => {
    if (klasses.length && !klass) onSelect(klasses[0].id);
  }, [klass, klasses, onSelect]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        <Typography.Title level={4} style={{ margin: 0 }}>
          Classes
        </Typography.Title>
        <Typography.Paragraph style={{ margin: 0 }}>
          Please <em>SELECT</em> the class you are training from the list below!
        </Typography.Paragraph>
      </div>

      <Table
        rowKey="id"
        bordered
        dataSource={klasses}
        pagination={false}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Created date',
            dataIndex: 'start_date',
            render: function StartDate(value) {
              return value ? formatters.dateWithTime(value) : '-';
            },
          },
          {
            title: 'End date',
            dataIndex: 'end_date',
            render: function EndDate(value) {
              return value ? formatters.dateWithTime(value) : '-';
            },
          },
          {
            title: 'Actions',
            key: 'actions',
            render: function Actions(_, record) {
              return (
                <div>
                  <Button
                    style={{ marginRight: 5 }}
                    onClick={() => {
                      onSelect(record.id);
                      setUsersVisible(true);
                    }}
                  >
                    Members
                  </Button>
                  <Button
                    type={record.id === klass ? 'primary' : undefined}
                    onClick={() => onSelect(record.id)}
                  >
                    Select
                  </Button>
                </div>
              );
            },
          },
        ]}
      />
      {usersVisible && (
        <KlassUsers
          visible={usersVisible}
          hide={() => setUsersVisible(false)}
          klassId={klass}
        />
      )}
    </div>
  );
};

export default Klasses;
