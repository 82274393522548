import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table, Popover, Spin } from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';

import {
  selectors,
  TERMS,
  ANALYSIS_TYPES,
  TRANSACTION_TYPES,
  ORDER_STATUSES,
  CREATED,
  CANCELED,
  RESOURCE_TYPES,
  BUY,
  Order,
} from 'state';
import { formatters } from 'services';
import { columnSearchWidget, LoadingScreen } from 'components';
import { dateWithTimeSeconds } from 'services/formatters';

const marketOrders = ['MarketOnOpenOrder', 'MarketOnCloseOrder'];
const limitOrders = ['LimitOrder', 'TakeProfitOrder', 'StopLossOrder'];

const Orders: React.FC = () => {
  const orders = useSelector(selectors.orders);
  const { fetched_blotter, fetching_blotter } = useSelector(selectors.player);
  const ordersFetching = useSelector(selectors.ordersFetching);
  const financialInstruments = useSelector(selectors.financialInstruments);
  const currencies = useSelector(selectors.currencies);
  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const persons = useSelector(selectors.persons);

  const data = useMemo(() => {
    return orders
      .filter(order => order.status !== CREATED)
      .map(order => {
        const underlying_asset_id =
          financialInstruments[order.financial_instrument].underlying_asset ||
          -1;
        const underlying_asset = underlyingAssets[underlying_asset_id];
        let tickerOrIsin = underlying_asset.ticker;
        if (
          underlying_asset.resourcetype === 'AFSBond' ||
          underlying_asset.resourcetype === 'HTMBond'
        ) {
          tickerOrIsin =
            underlying_asset.isin +
            ' ' +
            formatters.commasExact3Digits(underlying_asset.coupon);
        }

        return {
          ...order,
          value: !underlying_asset.resourcetype.endsWith('Bond')
            ? order.open_price * order.quantity
            : (order.open_price * order.quantity) / 100,
          currency: currencies[underlying_asset.variable_currency].symbol,
          underlying_asset: tickerOrIsin,
          underlying_asset_resourcetype: underlying_asset.resourcetype,
        };
      });
    // .map(order => ({
    //   ...order,
    //   fee: (order.value * transaction_fee).toFixed(2),
    // })),
  }, [orders, currencies, financialInstruments, underlyingAssets]);
  if (fetching_blotter) {
    return (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 150 }} spin />} />
    );
  }

  const cleanDirtyPrice = (record: Order) => {
    const cleanPrice = record.open_price;
    // accrued_interest_orders is interest at the time the order was placed
    const dirtyPrice = cleanPrice + record.accrued_interest_orders;
    return (
      <div>
        <div>Clean price: {formatters.commas2Digits(cleanPrice)}</div>
        <div>Dirty price: {formatters.commas2Digits(dirtyPrice)}</div>
        <div>*order execution done at dirty price,</div>
        <div> clean price given for trading reference</div>
      </div>
    );
  };
  const cleanDirtyValue = (record: Order) => {
    const cleanValue = (record.open_price * record.quantity) / 100;
    // accrued_interest_orders is interest at the time the order was placed
    const dirtyValue =
      ((record.open_price + record.accrued_interest_orders) * record.quantity) /
      100;
    return (
      <div>
        <div>Clean value: {formatters.commasNoDigits(cleanValue)}</div>
        <div>Dirty value: {formatters.commasNoDigits(dirtyValue)}</div>
        {/*<div>*order execution done at dirty price,</div>*/}
        {/*<div> clean price given for trading reference</div>*/}
      </div>
    );
  };

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={data}
      pagination={false}
      scroll={{ x: true }}
      rowClassName={record => (record.status === CANCELED ? 'disabled' : '')}
      columns={[
        {
          title: 'Asset',
          dataIndex: 'underlying_asset',
          key: 'underlying_asset',
          fixed: 'left',
          sorter: (a, b) =>
            a.underlying_asset.localeCompare(b.underlying_asset),
          ...columnSearchWidget({ dataIndex: 'underlying_asset' }),
        },
        {
          title: 'Transaction',
          dataIndex: 'transaction',
          key: 'transaction',
          fixed: true,
          render: (tr: number) => TRANSACTION_TYPES[tr],
          sorter: (a, b) => a.transaction - b.transaction,
        },
        {
          title: 'Currency',
          dataIndex: 'currency',
          key: 'currency',
          fixed: true,
          sorter: (a, b) => a.currency.localeCompare(b.currency),
        },
        {
          title: 'Open Price',
          dataIndex: 'open_price',
          key: 'open_price',
          align: 'right',
          fixed: true,
          render: function myfunc(val, record) {
            if (record.status === CANCELED) {
              if (marketOrders.includes(record.resourcetype)) {
                return '-';
              }
              if (limitOrders.includes(record.resourcetype)) {
                val = record.limit_price;
              }
            }

            return (
              <div>
                {formatters.priceFormat(
                  val,
                  record.underlying_asset,
                  record.underlying_asset_resourcetype
                )}
                {record.status !== CANCELED &&
                  record.underlying_asset_resourcetype.endsWith('Bond') && (
                    <Popover
                      placement="bottom"
                      content={cleanDirtyPrice(record)}
                    >
                      <InfoCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  )}
              </div>
            );
          },
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
          align: 'right',
          fixed: true,
          render: (value, record) => {
            return `${
              record.transaction === BUY ? '+' : '-'
            }${formatters.commasNoDigits(value)}`;
          },
        },
        {
          title: 'Value',
          dataIndex: 'value',
          key: 'value',
          align: 'right',
          fixed: true,
          render: function myfunc2(val, record) {
            return (
              <div>
                {formatters.priceFormat(
                  val,
                  record.underlying_asset,
                  record.underlying_asset_resourcetype
                )}
                {record.status !== CANCELED &&
                  record.underlying_asset_resourcetype.endsWith('Bond') && (
                    <Popover
                      placement="bottom"
                      content={cleanDirtyValue(record)}
                    >
                      <InfoCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  )}
              </div>
            );
          },
        },
        // {
        //   title: 'Fee',
        //   dataIndex: 'fee',
        //   key: 'fee',
        //   render: formatters.commasNoDigits,
        // },
        {
          title: 'Timestamp',
          fixed: true,
          render: (_, record) => {
            const val = record.executed_at || record.canceled_at || 0;
            return val ? formatters.dateWithTimeSeconds(val) : '--';
          },
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            const dateA = formatters.dateWithTimeSeconds(
              a.executed_at || a.canceled_at || ''
            );
            const dateB = formatters.dateWithTimeSeconds(
              b.executed_at || b.canceled_at || ''
            );
            return dateA.localeCompare(dateB);
          },
        },
        {
          title: 'Forecast',
          dataIndex: 'forecast',
          key: 'forecast',
          render: (val, record) =>
            val !== record.open_price && val !== 0
              ? formatters.commas2Digits(val)
              : '--',
        },
        {
          title: 'Investment rationale',
          dataIndex: 'investment_rationale',
          key: 'investment_rationale',
          width: 300,
          render: text => (
            <div style={{ maxWidth: 300, whiteSpace: 'normal' }}>
              {text || '--'}
            </div>
          ),
        },
        {
          title: 'Order',
          dataIndex: 'resourcetype',
          key: 'resourcetype',
          sorter: (a, b) => a.resourcetype.localeCompare(b.resourcetype),
          filters: RESOURCE_TYPES.map(resource => ({
            text: resource,
            value: resource,
          })),
          onFilter: (value, record) => value === record.resourcetype,
        },
        {
          title: 'Term',
          dataIndex: 'term',
          render: val => TERMS[val as keyof typeof TERMS] || '--',
        },
        {
          title: 'Analysis',
          dataIndex: 'analysis',
          render: val =>
            ANALYSIS_TYPES[val as keyof typeof ANALYSIS_TYPES] || '--',
        },
        // {
        //   title: 'Hedge',
        //   dataIndex: 'hedge_for',
        //   render: val =>
        //     !val
        //       ? '--'
        //       : underlyingAssets[financialInstruments[val].underlying_asset]
        //           .ticker,
        // },
        {
          title: 'User',
          dataIndex: 'person',
          render: val => persons[val]?.name || val,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          fixed: 'right',
          render: (val: keyof typeof ORDER_STATUSES) => ORDER_STATUSES[val],
          sorter: (a, b) => a.status - b.status,
        },
      ]}
    />
  );
};

export default Orders;
