import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import MyHeader from './Header';
import Overview from './Overview/Overview';
import SessionSettings from './SessionSettings';
import TradingIntervals from './TradingIntervals';
import Performance from './Performance';

import {
  selectors,
  connectToSessionSocket,
  disconnectFromSessionSocket,
  retrieveSession,
  fetchKlasses,
  fetchHistoricalStep,
  fetchMarketHours,
} from 'state';
import { LoadingScreen } from 'components';
import MacroBackground from './MacroBackground';
import { TrainerSessionContextWrapper } from './TrainerSessionContext';
import CentralBank from './CentralBank';
import { fetchAuctions } from '../../state/app/auctions/actions';

const TrainerSession: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectors.token) as string;

  const { url } = useRouteMatch();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const session = useSelector(selectors.activeSession);
  const klass = useSelector(selectors.klassById(session?.klass));
  // console.log('id: ', id);
  useEffect(() => {
    dispatch(retrieveSession({ id }));
    dispatch(fetchKlasses({}));
    dispatch(fetchHistoricalStep({}));
    dispatch(fetchAuctions({ session_id: id }));
    dispatch(fetchMarketHours({}));

    setLoading(false);
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(connectToSessionSocket(token, id));

    return () => {
      dispatch(disconnectFromSessionSocket(token, id));
    };
  }, [dispatch, token, id]);

  if (loading || !session || !klass) {
    return <LoadingScreen fullscreen />;
  }

  return (
    <TrainerSessionContextWrapper>
      <div>
        <MyHeader />
        <div style={{ padding: '30px 70px 70px 70px' }}>
          <Switch>
            <Route path={`${url}/overview`}>
              <Overview />
            </Route>
            <Route path={`${url}/session-settings`}>
              <SessionSettings />
            </Route>
            <Route path={`${url}/central-bank`}>
              <CentralBank />
            </Route>
            {/*<Route path={`${url}/benchmarks`}>*/}
            {/*  <Benchmarks />*/}
            {/*</Route>*/}
            <Route path={`${url}/trading-intervals`}>
              <TradingIntervals />
            </Route>
            {/*<Route path={`${url}/settings`}>*/}
            {/*  <Settings />*/}
            {/*</Route>*/}
            <Route path={`${url}/performance`}>
              <Performance />
            </Route>
            <Route path={`${url}/macro-background`}>
              <MacroBackground />
            </Route>
            {/*<Route path={`${url}/instruments`}>*/}
            {/*  <SessionInstrumentsSelector />*/}
            {/*</Route>*/}
            <Redirect to={`${url}/overview`} />
          </Switch>
        </div>
      </div>
    </TrainerSessionContextWrapper>
  );
};

export default TrainerSession;
