import React from 'react';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';
import Chart from 'react-apexcharts';

import { formatters } from 'services';
import { selectors } from 'state';

const IndustryPieChart: React.FC = () => {
  const industryData = useSelector(selectors.equityPnlByIndustry);

  if (!industryData.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const series = industryData.map(i => i.long);
  const labels = industryData.map(i => i.industry);

  return (
    <div>
      <Chart
        series={series}
        type="pie"
        options={{
          labels,
          chart: {
            animations: {
              enabled: false,
            },
          },
          dataLabels: {
            formatter: (value: number) => value.toFixed(2) + '%',
          },
          tooltip: {
            y: {
              formatter: formatters.million,
              title: {
                formatter: (seriesName: string) => seriesName,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default IndustryPieChart;
