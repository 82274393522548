import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import { Button, Checkbox, Form, Input, Card, notification } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { login, selectors } from 'state';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectors.auth.error);
  const waiting = useSelector(selectors.auth.waiting);

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (error) notification.warn({ message: 'Invalid credentials' });
  }, [error]);

  return (
    <div
      style={{
        fontSize: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div style={{ textAlign: 'center', paddingBottom: 30 }}>
        <h1 style={{ color: 'white' }}>Welcome to Traderion</h1>
        <h3 style={{ color: 'white' }}>Please enter your credentials</h3>
      </div>
      <Form
        form={form}
        name="normal_login"
        className="login-form"
        initialValues={{ username: '', password: '' }}
        onFinish={() => {
          const data = form.getFieldsValue() as {
            username: string;
            password: string;
          };
          dispatch(login(data));
        }}
        style={{ width: '100%' }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input a your username!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={waiting}
            style={{ width: '30%' }}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
