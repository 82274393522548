import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useInterval } from 'services';
import * as views from './views';
import { refreshToken, selectors, TRAINEE, TRAINER, ADMIN } from 'state';
import Cookies from 'js-cookie';

const Routes: React.FC = () => {
  const dispatch = useDispatch();

  const token = useSelector(selectors.token);
  const refresh = useSelector(selectors.refresh);
  const userType = useSelector(selectors.userType);

  // useEffect(() => {
  //   if (token) {
  //     console.log('triggered refresh? -------------------');
  //     console.log('state refresh token: ', refresh);
  //     console.log('cookie refresh token: ', Cookies.get('refresh'));
  //     dispatch(refreshToken({ refresh: Cookies.get('refresh') }));
  //   }
  // }, [dispatch]);

  useInterval(() => {
    if (token) {
      const tokenExpiration = Number(Cookies.get('token_exp'));
      const now = Date.now() / 1000;
      const left = tokenExpiration - now;
      if (left < 60) {
        dispatch(refreshToken({ refresh: Cookies.get('refresh') }));
      }
    }
  }, 1000 * 30);

  const PublicRoute = (path: string, view: React.FC) => (
    <Route path={path} key={path}>
      {view({})}
    </Route>
  );

  const publicRoutes = [PublicRoute('/bond-calculator', views.BondCalculator)];
  // console.log('token before route decision: ', token);
  if (!token)
    return (
      <Switch>
        {publicRoutes}

        <Route path={`/auth`}>
          <views.Auth />
        </Route>
        <Redirect to={`/auth/login`} />
      </Switch>
    );
  // console.log('after !token if statement');

  return (
    <Switch>
      {publicRoutes}

      <Route path={`/dashboard`}>
        {userType === TRAINEE && <views.TraineeDashboard />}
        {userType === TRAINER && <views.TrainerDashboard />}
        {userType === ADMIN && <views.AdminDashboard />}
      </Route>
      <Route path={`/session/:id`}>
        {userType === TRAINEE && <views.TraineeSession />}
        {userType === TRAINER && <views.TrainerSession />}
      </Route>
      <Redirect to={`/dashboard`} />
    </Switch>
  );
};

export default Routes;
