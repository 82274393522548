import React from 'react';
import {
  Card,
  Col,
  Divider,
  Progress,
  Row,
  Statistic,
  Timeline,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import { FINISHED, PLAYING, selectors, utils } from 'state';
import { formatStringDate } from '../../../state/app/utils';

const HistoricalTimeline: React.FC = () => {
  const session = useSelector(selectors.activeSession);

  const progress = Math.floor(
    ((session.total_simulated_days - session.remaining_simulated_days) /
      session.total_simulated_days) *
      100
  );
  const historicalStep = useSelector(selectors.historicalStep);
  const startDateFormatted = formatStringDate(session.start_date);
  const endDateFormatted = formatStringDate(session.end_date);
  const endDateObj = new Date(session.end_date);
  const currentDate = new Date(session.start_date);
  currentDate.setDate(currentDate.getDate() + session.elapsed_days);
  const currentDateformatted = formatStringDate(currentDate.toISOString());
  const currentDateObj = new Date(currentDate);
  const remainingSimulatedDays =
    session.status === FINISHED ? 0 : session.remaining_simulated_days;
  const sessionOver =
    currentDateObj >= endDateObj || session.status === FINISHED;
  const progressBarAnimated:
    | 'success'
    | 'normal'
    | 'exception'
    | 'active'
    | undefined = session.status === PLAYING ? 'active' : 'normal';

  return (
    <>
      <Typography.Title level={3}>Session timeline & progress</Typography.Title>
      <Card style={{ width: 480, height: 325 }}>
        <div style={{ display: 'relative' }}>
          <div>
            <Timeline mode={'left'}>
              <Timeline.Item color="green" label="Scenario start date">
                {startDateFormatted}
              </Timeline.Item>
              <Timeline.Item color="blue" label="Current scenario date">
                {sessionOver ? endDateFormatted : currentDateformatted}
              </Timeline.Item>
              <Timeline.Item color="red" label="Scenario end date">
                {endDateFormatted}
              </Timeline.Item>
            </Timeline>
          </div>
          <div
            style={{ position: 'absolute', top: 130, right: 32, width: 415 }}
          >
            <Divider>
              <Typography.Text type="secondary">Progress </Typography.Text>
            </Divider>
            <Progress
              strokeColor={{
                from: '#108ee9',
                to: '#87d068',
              }}
              percent={progress}
              status={progressBarAnimated}
            />
            <Row gutter={5}>
              <Col span={11}>
                <Statistic
                  style={{ marginTop: 25 }}
                  title="Remaining session time:"
                  value={session.remaining_session_time}
                />
              </Col>
              <Col span={13}>
                <Statistic
                  style={{ marginTop: 25 }}
                  title="Remaining simulated trading days:"
                  value={remainingSimulatedDays}
                />
              </Col>
            </Row>
          </div>
          <div
            style={{
              fontSize: 11,
              position: 'absolute',
              top: 304,
              right: 32,
              width: 415,
            }}
          >
            <Typography.Text type={'secondary'}>
              (1 period / {utils.formattedHistoricalStep(historicalStep)} /{' '}
              {session.frequency} simulated{' '}
              {session.frequency === 1 ? 'day' : 'days'})
            </Typography.Text>
          </div>
        </div>
      </Card>
    </>
  );
};

export default HistoricalTimeline;
