import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, Button, Popover, Spin, Space } from 'antd';
import { NumberType, SelectInput, SwitchInput, TextType } from '../adminInputs';
import {
  clearAssetEodPreview,
  clearAssetIntradayPreview,
  clearIndexFundPreview,
  deleteUnderlyingAsset,
  fetchAssets,
  MarketHistory,
  Order,
  REGIONS,
  requestAssetEodPreview,
  requestAssetIntradayPreview,
  selectors,
  UnderlyingAsset,
  updateAsset,
} from 'state';
import { columnSearchWidget } from 'components';
import { formatters, useModal } from 'services';
import CreateAssetModal from './CreateAssetModal';
import {
  DeleteRowOutlined,
  EyeFilled,
  EyeOutlined,
  EyeTwoTone,
  InfoCircleOutlined,
} from '@ant-design/icons';
import IndexFundPreviewModal from '../IndexFundsTab/IndexFundPreviewModal';
import AssetEodPreviewModal from './AssetEodPreviewModal';
import RefreshIndexFundModal from '../IndexFundsTab/RefreshIndexFundModal';
import RefreshAssetEodModal from './RefreshAssetEodModal';
import UsageTable from './UsageTable';
import {
  onFilterFunction,
  UsageColumnComponents,
  UsageFilters,
  UsageTitle,
} from './UsageColumnComponents';
import { CSVLink } from 'react-csv';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import AssetIntradayPreviewModal from './AssetIntradayPreviewModal';
import { assetIntradayData } from '../../../../state/app/selectors';

const AssetsTab: React.FC = () => {
  const dispatch = useDispatch();
  const assets = useSelector(selectors.underlyingAssetsList, () => true);
  const currencies = useSelector(selectors.currenciesList);
  // console.log('assets: ', assets);
  const [CreateModal, showModal] = useModal(CreateAssetModal);
  const [showEodPreview, setShowEodPreview] = useState(false);
  const [showIntradayPreview, setShowIntradayPreview] = useState(false);
  const [showUsageTable, setShowUsageTable] = useState(false);
  const [inPlayingSessions, setInPlayingSessions] = useState<number[]>();
  const [inPausedSessions, setInPausedSessions] = useState<number[]>();
  const [previewEodLoading, setPreviewEodLoading] = useState(false);
  const [previewIntradayLoading, setPreviewIntradayLoading] = useState(false);

  const [refreshModal, showRefreshModal] = useModal(RefreshAssetEodModal);

  const assetEodData = useSelector(selectors.assetEodData);
  const assetIntradayData = useSelector(selectors.assetIntradayData);

  useEffect(() => {
    if (assetEodData) {
      setPreviewEodLoading(false);
      setShowEodPreview(true);
    } else {
      setShowEodPreview(false);
    }
  }, [assetEodData]);
  useEffect(() => {
    // Cleanup function
    return () => {
      dispatch(clearAssetEodPreview({}));
    };
  }, []);

  useEffect(() => {
    if (assetIntradayData) {
      setPreviewIntradayLoading(false);
      setShowIntradayPreview(true);
    } else {
      setShowIntradayPreview(false);
    }
  }, [assetIntradayData]);
  useEffect(() => {
    // Cleanup function
    return () => {
      dispatch(clearAssetIntradayPreview({}));
    };
  }, []);

  useEffect(() => {
    if (!assets.length) {
      dispatch(fetchAssets({}));
    }
  }, [dispatch]);

  const update = (record: any, key: any) => (val: any) =>
    dispatch(updateAsset({ id: record.id, [key]: val }));

  const onClickUsage = (record: UnderlyingAsset) => {
    setInPlayingSessions(record.in_playing_sessions);
    setInPausedSessions(record.in_paused_sessions);
    setShowUsageTable(true);
  };

  const currencyOptions = currencies.map(c => ({
    value: c.id,
    name: c.symbol,
  }));

  const regionOptions = Object.entries(REGIONS).map(([value, name]) => ({
    value: parseInt(value),
    name,
  }));

  const columns: ((
    | ColumnGroupType<UnderlyingAsset>
    | ColumnType<UnderlyingAsset>
  ) & {
    dataIndex: string;
  })[] = [
    { title: 'ID', dataIndex: 'id' },
    {
      title: 'RIC',
      dataIndex: 'ric',
      render: (val, record) => TextType(val, update(record, 'ric')),
      ...columnSearchWidget({ dataIndex: 'ric' }),
      sorter: (a, b) => (a.ric < b.ric ? -1 : a.ric > b.ric ? 1 : 0),
    },
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      render: (val, record) => TextType(val, update(record, 'ticker')),
      sorter: (a, b) =>
        a.ticker < b.ticker ? -1 : a.ticker > b.ticker ? 1 : 0,
      ...columnSearchWidget({ dataIndex: 'ticker' }),
    },
    {
      title: 'Risk W',
      dataIndex: 'risk_weight',
      render: (val, record) => NumberType(val, update(record, 'risk_weight')),
      sorter: (a, b) => a.risk_weight - b.risk_weight,
    },
    {
      title: 'Visible',
      dataIndex: 'visible',
      render: (val, record) => SwitchInput(val, update(record, 'visible')),
      filters: [
        { text: 'Visible', value: true },
        { text: 'Hidden', value: false },
      ],
      onFilter: (value, record) => {
        return value === record.visible;
      },
    },
    // {
    // title: 'Fetch',
    // children: [
    {
      title: 'Intraday',
      dataIndex: 'fetch_intraday',
      render: (val, record) =>
        SwitchInput(val, update(record, 'fetch_intraday')),
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      onFilter: (value, record) => {
        return value === record.fetch_intraday;
      },
    },
    {
      title: 'EoD',
      dataIndex: 'fetch_eod',
      render: (val, record) => SwitchInput(val, update(record, 'fetch_eod')),
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      onFilter: (value, record) => {
        return value === record.fetch_eod;
      },
    },
    // ],
    // },
    {
      title: UsageTitle,
      dataIndex: 'usage',
      render: (_, record) => {
        return UsageColumnComponents(record, onClickUsage);
      },
      filters: UsageFilters,
      onFilter: (value, record) => {
        return onFilterFunction(value, record);
      },
    },
    {
      title: 'Market History',
      dataIndex: 'last_market_history',
      render: (history?: MarketHistory) => {
        return formatters.dateWithTime(history?.timestamp || '');
      },
    },
    // {
    //   title: 'Currency',
    //   children: [
    {
      title: 'Base',
      dataIndex: 'base_currency',
      render: (val, record) =>
        SelectInput(val, currencyOptions, update(record, 'base_currency')),
      filters: currencies.map(currency => {
        return {
          text: currency.symbol,
          value: currency.id,
        };
      }),
      onFilter: (value, record) => {
        return value === record.base_currency;
      },
    },
    {
      title: 'Var',
      dataIndex: 'variable_currency',
      render: (val, record) =>
        SelectInput(val, currencyOptions, update(record, 'variable_currency')),
      filters: currencies.map(currency => {
        return {
          text: currency.symbol,
          value: currency.id,
        };
      }),
      onFilter: (value, record) => {
        return value === record.variable_currency;
      },
    },
    // ],
    // },

    {
      title: 'Region',
      dataIndex: 'region',
      render: (val, record) =>
        SelectInput(val, regionOptions, update(record, 'region')),
      filters: Object.entries(REGIONS).map(([id, region]) => {
        return {
          text: region,
          value: parseInt(id),
        };
      }),
      onFilter: (value, record) => {
        return value === record.region;
      },
    },
    {
      title: 'Geography',
      dataIndex: 'geography',
      render: (val, record) => TextType(val, update(record, 'geography')),
      filters: Array.from(new Set(assets.map(asset => asset.geography))).map(
        geography => {
          // console.log('geography', geography);
          return { text: geography, value: geography };
        }
      ),
      onFilter: (value, record) => {
        return value === record.geography;
      },
    },
    {
      title: 'Asset Class',
      dataIndex: 'resourcetype',
      filters: Array.from(new Set(assets.map(asset => asset.resourcetype))).map(
        resourcetype => ({
          text: resourcetype,
          value: resourcetype,
        })
      ),
      onFilter: (value, record) => {
        return value === record.resourcetype;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (val, record) => TextType(val, update(record, 'name')),
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      ...columnSearchWidget({ dataIndex: 'name' }),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div>
            <Popover content={'Delete asset'}>
              <Button
                danger
                onClick={() => {
                  dispatch(deleteUnderlyingAsset({ id: record.id }));
                }}
              >
                <DeleteRowOutlined />
              </Button>
            </Popover>
            <Popover content={'Show EoD'}>
              <Button
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setPreviewEodLoading(true);

                  dispatch(requestAssetEodPreview({ asset_id: record.id }));
                }}
              >
                {previewEodLoading ? <Spin /> : <EyeTwoTone />}
                {/*<EyeTwoTone />*/}
              </Button>
            </Popover>
            <Popover content={'Last 24h intraday'}>
              <Button
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setPreviewIntradayLoading(true);

                  dispatch(
                    requestAssetIntradayPreview({ asset_id: record.id })
                  );
                }}
              >
                {previewIntradayLoading ? <Spin /> : <EyeFilled />}
                {/*<EyeTwoTone />*/}
              </Button>
            </Popover>
          </div>
        );
      },
    },
  ];
  const columnsToExclude = ['actions', 'usage'];
  const csvColumns = columns.filter(column => {
    return !columnsToExclude.includes(column.dataIndex);
  });
  csvColumns.push({
    title: 'In playing sesssions',
    dataIndex: 'in_playing_sessions',
  });
  csvColumns.push({
    title: 'In paused sesssions',
    dataIndex: 'in_paused_sessions',
  });
  const csvData = useMemo(() => {
    const titles = csvColumns.map(col => col.title);
    const body = assets.map((asset, index) => {
      return csvColumns.map(col => {
        const value = asset[col.dataIndex as keyof typeof asset];
        if (col.dataIndex.includes('currency')) {
          return currencyOptions.find(op => op.value === value)?.name;
        } else if (col.dataIndex === 'last_market_history') {
          return value
            ? formatters.dateWithTime((value as MarketHistory)?.timestamp)
            : '--';
        } else if (col.dataIndex === 'region') {
          return value ? REGIONS[value as keyof typeof REGIONS] : '--';
        } else return value;
      });
    });
    body.unshift(titles as string[]);
    return body;
  }, [assets]);

  return (
    <>
      {refreshModal}
      {CreateModal}
      {showEodPreview && (
        <AssetEodPreviewModal
          visible={showEodPreview}
          hide={() => {
            setShowEodPreview(false);
          }}
        />
      )}
      {showIntradayPreview && (
        <AssetIntradayPreviewModal
          visible={showIntradayPreview}
          hide={() => {
            setShowIntradayPreview(false);
          }}
        />
      )}
      {showUsageTable && (
        <UsageTable
          visible={showUsageTable}
          hide={() => {
            setShowUsageTable(false);
          }}
          playingSessions={inPlayingSessions}
          pausedSessions={inPausedSessions}
        />
      )}
      <Space>
        <Button type="primary" onClick={() => showModal({})}>
          Create
        </Button>
        <Button
          // type="primary"
          onClick={() => showRefreshModal({})}
          style={{ marginLeft: 10 }}
        >
          Refresh EoD
        </Button>
        <Button
          style={{
            position: 'absolute',
            right: '0',
            // top: '0',
            top: '60px',
            // margin: '19px 0',
          }}
        >
          {/*Download CSV*/}
          <CSVLink filename={'PM_prod_assets'} data={csvData}>
            Download CSV
          </CSVLink>
          {/*Download CSV*/}
        </Button>
      </Space>

      <Table
        style={{ marginTop: 20 }}
        loading={!assets.length}
        dataSource={assets}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
        // footer={() => (
        //   <div>
        //     <Button type="primary" onClick={() => showModal({})}>
        //       Create
        //     </Button>
        //   </div>
        // )}
        columns={columns}
      />
    </>
  );
};

export default AssetsTab;
