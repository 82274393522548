import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { Typography } from 'antd';

import { fetchPersons, fetchSession, selectors } from 'state';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from 'components';
import Player from './Player';
import Players from './Players';
import AdminIndividualReport from './AdminIndividualReport';

const { Title } = Typography;

const Session = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const { session_id } = useParams<{ session_id: string }>();

  const id = parseInt(session_id);
  const session = useSelector(selectors.sessionById(id));

  useEffect(() => {
    if (session) {
      return;
    }
    dispatch(fetchSession({ id }));
    dispatch(fetchPersons({}));
  }, [dispatch, id, session]);

  if (!session) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Title level={3}>Session {session_id}</Title>
      <Typography.Text>
        {session.klass_name} , ID: {session.klass}
      </Typography.Text>
      <br />
      <Typography.Text>{session.trainer_name}</Typography.Text>
      <div style={{ marginTop: 15 }}>
        <Switch>
          <Route path={`${path}/players/:player_id/individual-report`}>
            <AdminIndividualReport />
          </Route>
          <Route path={`${path}/players/:player_id`}>
            <Player />
          </Route>
          <Route path={`${path}/players`}>
            <Players />
          </Route>
          <Redirect to={`${path}/players`} />
        </Switch>
      </div>
    </div>
  );
};

export default Session;
