import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const Label: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <div>
      <Text style={{ display: 'block', fontSize: '80%' }}>{title}</Text>
      {children}
    </div>
  );
};

export default Label;
