import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Table, Button, Typography } from 'antd';

import { fetchPlayers, retrieveUnderlyingAssets, selectors } from 'state';
import { LoadingScreen } from 'components';

const Players: React.FC = () => {
  const dispatch = useDispatch();
  const { session_id } = useParams();

  const session = useSelector(selectors.sessionById(session_id));
  const players = useSelector(selectors.playersListBySessionId(session_id));
  const underlyingAssets = useSelector(selectors.underlyingAssetsList);

  const players_fetched = session?.players_fetched;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(retrieveUnderlyingAssets({}));
  }, [dispatch]);

  useEffect(() => {
    if (loading) return;
    if (!session) return;
    if (players_fetched) return;

    dispatch(fetchPlayers({ session_id }));
    setLoading(true);
  }, [dispatch, loading, players_fetched, session, session_id]);

  if (!session) {
    return (
      <Typography.Paragraph>
        Session {session_id} not found!
      </Typography.Paragraph>
    );
  }

  if (!players.length || !underlyingAssets.length) {
    return <LoadingScreen />;
  }

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={players}
      pagination={false}
      columns={[
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          sorter: (a, b) => a.id - b.id,
          width: '50px',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          width: '200px',
        },
        {
          title: 'Action',
          key: 'action',
          render: function Action(text, record) {
            return (
              <Space key={record.id} size="middle">
                <Link
                  to={`/dashboard/sessions/${session_id}/players/${record.id}`}
                >
                  <Button>Select</Button>
                </Link>
                <Link
                  to={`/dashboard/sessions/${session_id}/players/${record.id}/individual-report`}
                >
                  <Button>Indv. Report</Button>
                </Link>
              </Space>
            );
          },
        },
      ]}
    />
  );
};

export default Players;
