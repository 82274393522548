import React from 'react';
import { UnderlyingAsset } from 'state';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// Render color coding for usage column information in Admin Assets Table
export const UsageColumnComponents = (
  record: UnderlyingAsset,
  onClickUsage: (record: UnderlyingAsset) => void
) => {
  let usedInPlayingSessions = false;
  let usedInPausedSessions = false;
  if (record.in_playing_sessions !== undefined) {
    usedInPlayingSessions = record.in_playing_sessions.length !== 0;
  }
  if (record.in_paused_sessions !== undefined) {
    usedInPausedSessions = record.in_paused_sessions.length !== 0;
  }

  if (usedInPlayingSessions && !usedInPausedSessions) {
    return (
      <div
        onClick={() => {
          onClickUsage(record);
        }}
        style={{
          width: '30px',
          height: '30px',
          backgroundColor: '#8CB369',
          // backgroundColor: '#ecea5b',
          borderRadius: 4,
        }}
      ></div>
    );
  }

  if (usedInPausedSessions && !usedInPlayingSessions) {
    return (
      <div
        onClick={() => {
          onClickUsage(record);
        }}
        style={{
          width: '30px',
          height: '30px',
          // backgroundColor: '#5beca1',
          backgroundColor: '#F4A259',
          borderRadius: 4,
        }}
      ></div>
    );
  }

  if (usedInPausedSessions && usedInPlayingSessions) {
    return (
      <div
        onClick={() => {
          onClickUsage(record);
        }}
        style={{
          width: '30px',
          height: '30px',
          background: 'linear-gradient(to right, #8CB369 50%, #F4A259 50%)',
          borderRadius: '4px',
        }}
      ></div>
    );
  }

  return (
    <div
      style={{
        width: '30px',
        height: '30px',
        // backgroundColor: '#5beca1',
        backgroundColor: '#a2a2a2',
        borderRadius: 4,
      }}
    ></div>
  );
};

// Render custom Title for Usage column in Admin Assets Tab
export const UsageTitle = () => {
  return (
    <div>
      Usage
      <> </>
      <Popover
        content={
          <p>
            <div>Green - used in playing sessions</div>
            <div>Yellow - used in paused sessions</div>
            <div>Gray - unused</div>
            <div>Click color to see which sessions</div>
          </p>
        }
      >
        <InfoCircleOutlined />
      </Popover>
    </div>
  );
};

// Filters for Usage column in Admin Assets Tab
export const UsageFilters = [
  {
    text: 'Unused',
    value: 'unused',
  },
  {
    text: 'In playing sessions',
    value: 'playing',
  },
  {
    text: 'In paused sessions',
    value: 'paused',
  },
  {
    text: 'Both',
    value: 'both',
  },
];

export const onFilterFunction = (value: any, record: UnderlyingAsset) => {
  if (value === 'unused') {
    return (
      record.in_playing_sessions.length === 0 &&
      record.in_paused_sessions.length === 0
    );
  } else if (value === 'playing') {
    return (
      record.in_playing_sessions.length !== 0 &&
      record.in_paused_sessions.length === 0
    );
  } else if (value === 'paused') {
    return (
      record.in_playing_sessions.length === 0 &&
      record.in_paused_sessions.length !== 0
    );
  } else {
    return (
      record.in_playing_sessions.length !== 0 &&
      record.in_paused_sessions.length !== 0
    );
  }
};
