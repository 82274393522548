import React from 'react';
import { Empty } from 'antd';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import { formatters } from 'services';
import { selectors } from 'state';

const SectorBarChart: React.FC = () => {
  const sectorData = useSelector(selectors.equityPnlBySector);

  if (!sectorData.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const series = [
    {
      name: 'Short',
      data: sectorData.map(s => ({
        x: s.sector,
        y: s.short,
      })),
    },
    {
      name: 'Long',
      data: sectorData.map(s => ({
        x: s.sector,
        y: s.long,
      })),
    },
  ];

  return (
    <div style={{ marginTop: '-30px' }}>
      <Chart
        series={series}
        type="bar"
        options={{
          chart: {
            width: '100%',
            type: 'bar',
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            },
          },
          tooltip: {
            y: {
              formatter: formatters.million,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            labels: {
              formatter: formatters.million,
            },
          },
        }}
      />
    </div>
  );
};

export default SectorBarChart;
