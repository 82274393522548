import React, { useLayoutEffect } from 'react';
import type { CSSProperties } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';

import { CREATING, DELAY, FINISHED, FROZEN, PAUSED, PLAYING } from 'state';

type props = {
  status: number;
  delay: number;
};

const PausedSession: React.FC<props> = ({ status, delay }) => {
  useLayoutEffect(() => {
    if (status === CREATING) {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;

      function disableScroll() {
        window.scrollTo(scrollLeft, scrollTop);
      }

      window.addEventListener('scroll', disableScroll);

      return () => window.removeEventListener('scroll', disableScroll);
    }
  }, [status]);

  // if (status === PLAYING || status === FINISHED || status === DELAY)
  if (status === PLAYING || status === FINISHED) return null;

  const commonStyles = {
    position: 'fixed',
    zIndex: 99999,
  } as CSSProperties;

  const pausedStyles = {
    top: '50vh',
    right: 0,
    transformOrigin: 'right top',
    transform: 'rotate(90deg) translateX(50%)',
    backgroundColor: '#fadb14',
    padding: '5px 10px',
    fontSize: '18px',
  } as CSSProperties;

  const creatingStyles = {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(255,255,255,0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '25px',
  } as CSSProperties;

  const overlayStyles = {
    ...commonStyles,
    ...(status === PAUSED || status === DELAY || status === FROZEN
      ? pausedStyles
      : creatingStyles),
  };
  const delayMultipleMinutes = status === DELAY && delay > 1;
  const delayOneMinute = status === DELAY && delay === 1;

  return (
    <div style={overlayStyles}>
      <span
        style={{
          color: '#1f1f1f',
        }}
      >
        <InfoCircleOutlined style={{ marginRight: '5px' }} />
        {status === PAUSED && 'The Session is Paused'}
        {status === CREATING && 'The Session is being Created'}
        {delayMultipleMinutes && `${delay} minutes until session starts`}
        {delayOneMinute && `1 minute until session starts`}
        {status === FROZEN && 'Session frozen'}
      </span>
    </div>
  );
};

export default PausedSession;
