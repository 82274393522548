import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { selectors } from 'state';
import { formatters } from 'services';
import { utils } from 'state';

const IndustryTable: React.FC = () => {
  const dataSource = useSelector(selectors.equityPnlByIndustry);
  const session = useSelector(selectors.activeSession);
  const reportingCurrency = utils.useReportingCurrency(session.id);

  return (
    <Table
      rowKey="industry"
      bordered
      pagination={false}
      dataSource={dataSource}
      columns={[
        {
          title: 'Industry',
          dataIndex: 'industry',
          width: '34%',
        },
        {
          title: 'PnL, ' + reportingCurrency.symbol,
          dataIndex: 'pnl',
          render: formatters.millionDollars,
          width: '33%',
          sorter: (a, b) => a.pnl - b.pnl,
          defaultSortOrder: 'descend',
        },
        {
          title: 'Allocation',
          dataIndex: 'allocation',
          render: formatters.percent2Digit,
          width: '33%',
          sorter: (a, b) => (a.allocation - b.allocation) * 100,
        },
      ]}
    />
  );
};

export default IndustryTable;
