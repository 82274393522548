import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Empty,
  Typography,
} from 'antd';
import { PercentInput } from 'components';

const EditableContext = React.createContext<any>({});

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
  handleSave: (record: Item) => void;
  inputComponent: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputComponent,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (!editing) return;

    (inputRef.current as any).focus();
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex as keyof Item] });
  };

  const save = async () => {
    try {
      const values = form.getFieldsValue();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  const InputComponent: any = inputComponent || Input;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <PercentInput ref={inputRef as any} onPressEnter={save} onBlur={save} />
        {/*<Input ref={inputRef as any} onPressEnter={save} onBlur={save} />*/}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type Props = {
  dataSource: any[];
  columns: {
    title: string;
    dataIndex: string;
    editable?: boolean;
    inputComponent?: React.ReactNode;
    suffix?: string;
    [key: string]: any;
  }[];
  handleSave: (item: any) => void;
  noDataText?: string;
};

const EditableTable: React.FC<Props> = ({
  dataSource,
  columns,
  handleSave,
  noDataText,
}) => {
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const finalColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      render: (val: string) => val + col.suffix,
      onCell: (record: Item) => ({
        record,
        editable: col.editable,
        inputComponent: col.inputComponent,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {noDataText ? noDataText : 'No data'}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  return (
    <div>
      <Table
        rowKey="id"
        components={components}
        rowClassName="editable-row"
        bordered
        dataSource={dataSource}
        columns={finalColumns}
        pagination={false}
        locale={noDataBehaviour}
      />
    </div>
  );
};

export default EditableTable;
