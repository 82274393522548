import React, { useEffect } from 'react';

import { notification, Progress, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'state';

const InitialPortfolioNotifications = () => {
  const statuses = useSelector(selectors.initialPortfolioStatus);
  const statusesList = Object.values(statuses);
  // console.log('reached initialPortfolioNotification');

  useEffect(() => {
    statusesList.forEach(status => {
      const { id, progress, total, errors } = status;

      if (errors.length) {
        notification.open({
          message: (
            <div>
              {errors.map(err => {
                return (
                  <Typography.Paragraph key={err}>{err}</Typography.Paragraph>
                );
              })}
            </div>
          ),
        });
        return;
      }

      const options = {
        key: `session${id}`,
        message: `Session ${id}`,
        description: (
          <Progress
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            percent={Math.round((progress / total) * 100)}
          />
        ),
      };

      notification.open(options);
    });
  }, [statuses]);

  return null;
};

export default InitialPortfolioNotifications;
