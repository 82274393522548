import React, { useState } from 'react';
import moment from 'moment';

import * as bondCalculator from '@floydspace/bond-calculator';

import { CenteredDiv, PercentInput } from 'components';
import { Card, Form, DatePicker, InputNumber, Select } from 'antd';

const BondCalculator: React.FC = () => {
  const [form] = Form.useForm();

  const convention = '30U/360';
  const dateFormat = 'YYYY-MM-DD';

  const initialValues = {
    settlement: moment('2016-12-26', dateFormat),
    maturity: moment('2023-01-17', dateFormat),
    rate: 0.02625 * 100,
    redemption: 100,
    frequency: 2,
    yield: 0,
    price: 0,
  };

  const Calculator = () => {
    const data = form.getFieldsValue();
    data.settlement = data.settlement.format(dateFormat);
    data.maturity = data.maturity.format(dateFormat);
    data.rate /= 100;
    data.convention = convention;
    delete data.yield;
    delete data.price;

    return bondCalculator(data);
  };

  const resetYieldPrice = () => form.setFieldsValue({ yield: 0, price: 0 });

  return (
    <CenteredDiv backgroundColor={'lightgrey'}>
      <Card title="Bond Calculator">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          form={form}
          initialValues={initialValues}
        >
          <Form.Item label="Settlement Date" name="settlement">
            <DatePicker onChange={resetYieldPrice} />
          </Form.Item>
          <Form.Item label="Maturity Date" name="maturity">
            <DatePicker onChange={resetYieldPrice} />
          </Form.Item>
          <Form.Item label="Coupon Rate" name="rate">
            <PercentInput onChange={resetYieldPrice} />
          </Form.Item>
          <Form.Item label="Redemption" name="redemption">
            <InputNumber onChange={resetYieldPrice} />
          </Form.Item>
          <Form.Item label="Frequency" name="frequency">
            <Select onChange={resetYieldPrice}>
              <Select.Option value={1}>Annual</Select.Option>
              <Select.Option value={2}>Semiannual</Select.Option>
              <Select.Option value={4}>Quarterly</Select.Option>
              <Select.Option value={12}>Monthly</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Yield" name="yield">
            <InputNumber
              onChange={n => {
                const calculator = Calculator();
                form.setFieldsValue({ price: calculator.price(n) });
              }}
            />
          </Form.Item>
          <Form.Item label="Price" name="price">
            <InputNumber
              onChange={n => {
                const calculator = Calculator();
                form.setFieldsValue({ yield: calculator.yield(n) });
              }}
            />
          </Form.Item>
        </Form>
      </Card>
    </CenteredDiv>
  );
};

export default BondCalculator;
