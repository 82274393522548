import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  connectToKlassSocket,
  disconnectFromKlassSocket,
  selectors,
  fetchKlasses,
} from 'state';
import MyHeader from './Header';
import Sessions from './Sessions';
import Klasses from './Klasses';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectors.token) as string;

  useEffect(() => {
    dispatch(connectToKlassSocket(token));
    dispatch(fetchKlasses({}));

    return () => {
      dispatch(disconnectFromKlassSocket(token));
    };
  }, [dispatch, token]);

  const [klass, setKlass] = useState(0);

  return (
    <div>
      <MyHeader />
      <div style={{ padding: '30px 70px 70px 70px', width: '100%' }}>
        <div style={{ marginBottom: '30px' }}>
          <Klasses onSelect={setKlass} klass={klass} />
        </div>
        <div>
          <Sessions klass={klass} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
