import { appManager, AppState } from '../manager';
import { DepositApiResult, DepositInstrument, MakeDeposit } from './types';

export const makeDeposit = appManager.createApi<
  MakeDeposit,
  DepositApiResult,
  AppState
>('MAKE_DEPOSIT', {
  path: '/deposits/make_deposit',
  method: 'POST',
  successReducer(state, result) {
    refreshData(state, result);
  },
});

export const breakDeposit = appManager.createApi<
  DepositInstrument,
  DepositApiResult,
  AppState
>('BREAK_DEPOSIT', {
  path: '/deposits/break_deposit',
  method: 'POST',
  successReducer(state, result) {
    refreshData(state, result);
    state.player.item.deposits_history.unshift(result.deposit_history);
  },
});

const refreshData = (state: AppState, result: DepositApiResult) => {
  const new_deposit = result.deposit;
  const new_wallet = result.wallet;
  const current_wallet_id = state.player.item.wallets.findIndex(
    item => item.currency === new_wallet.currency
  );
  state.player.item.wallets[current_wallet_id] = new_wallet;
  const current_deposit_id = state.player.item.deposits.findIndex(
    item =>
      item.underlying_deposit.currency ===
      new_deposit.underlying_deposit.currency
  );
  state.player.item.deposits[current_deposit_id] = new_deposit;
};
