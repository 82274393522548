import React from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'antd';

import { selectors, TRANSACTION_TYPES } from 'state';
import { formatters } from 'services';

const Orders: React.FC = () => {
  const exchangeHistory = useSelector(selectors.exchangeHistory);
  const currencies = useSelector(selectors.currencies);

  const data = exchangeHistory
    .map(item => {
      return {
        ...item,
        transaction: TRANSACTION_TYPES[item.transaction],
        base_currency: currencies[item.base_currency].symbol,
        variable_currency: currencies[item.variable_currency].symbol,
      };
    })
    .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={data}
      pagination={false}
      columns={[
        {
          title: 'Asset',
          dataIndex: 'underlying_asset',
        },
        {
          title: 'Transaction',
          dataIndex: 'transaction',
        },
        {
          title: 'Base Currency',
          dataIndex: 'base_currency',
        },
        {
          title: 'Variable Currency',
          dataIndex: 'variable_currency',
        },
        {
          title: 'Exchange Rate',
          dataIndex: 'exchange_rate',
          align: 'right',
          render: (value, record) => {
            if (
              record.base_currency === 'JPY' ||
              record.variable_currency === 'JPY'
            ) {
              return formatters.commasExact2Digits(value);
            }
            return formatters.commasExact4Digits(value);
          },
        },
        {
          title: 'Quantity',
          dataIndex: 'amount',
          align: 'right',
          render: formatters.commasNoDigits,
        },
        {
          title: 'Value',
          dataIndex: 'value',
          align: 'right',
          render: formatters.commasNoDigits,
        },
        {
          title: 'Fee',
          dataIndex: 'fee',
          align: 'right',
          render: val => (val ? formatters.commasNoDigits(val) : '--'),
        },
        {
          title: 'Timestamp',
          dataIndex: 'timestamp',
          render: val => (val ? formatters.dateWithTime(val) : '--'),
        },
      ]}
    />
  );
};

export default Orders;
