import React from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'antd';

import { selectors, TRANSACTION_TYPES } from 'state';
import { formatters } from 'services';
import { depositsHistory } from '../../../state/app/selectors';

const DepositsBlotters: React.FC = () => {
  const depositsHistory = useSelector(selectors.depositsHistory);
  const currencies = useSelector(selectors.currencies);
  const persons = useSelector(selectors.persons);
  return (
    <Table
      rowKey="id"
      bordered
      dataSource={depositsHistory}
      pagination={false}
      columns={[
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Currency',
          dataIndex: 'currency',
          key: 'currency',
          render: ccy_id => currencies[ccy_id].symbol,
        },
        // {
        //   title: 'Annualized Rate',
        //   dataIndex: 'annualized_rate',
        //   key: 'annualized_rate',
        //   render: rate => formatters.percent2Digit(rate),
        // },
        // {
        //   title: 'O/N Rate',
        //   dataIndex: 'overnight_rate',
        //   key: 'overnight_rate',
        //   render: rate => formatters.percent3Digit(rate),
        // },
        {
          title: 'Average Annualized Rate',
          dataIndex: 'average_annualized_rate',
          key: 'average_annualized_rate',
          render: rate => formatters.percent2Digit(rate),
        },
        {
          title: 'Initial Amount',
          dataIndex: 'initial_amount',
          key: 'initial_amount',
          render: formatters.commasNoDigits,
        },
        {
          title: 'Interest',
          dataIndex: 'accumulated_interest',
          key: 'accumulated_interest',
          // align: 'right',
          // sorter: (a: any, b: any) => a.amount - b.amount,
          render: formatters.commasNoDigits,
        },
        {
          title: 'Maturity, days',
          dataIndex: 'maturity',
          key: 'maturity',
        },
        {
          title: 'Opening Date',
          dataIndex: 'opening_date',
          key: 'opening_date',
          render: date => (date ? formatters.dateWithTime(date) : '--'),
        },
        {
          title: 'Closing Date',
          dataIndex: 'closing_date',
          key: 'closing_date',
          render: date => (date ? formatters.dateWithTime(date) : '--'),
          // return JSON.stringify(date).split('T')[0].slice(1);
        },
        {
          title: 'Closed by',
          dataIndex: 'person',
          key: 'person',
          render: person_id => persons[person_id].name,
        },
      ]}
    />
  );
};

export default DepositsBlotters;
