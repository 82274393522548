export const PING_INTERVAL = 10000;
export const PONG_TIMEOUT = 5000;
export const PING = 'PING';
export const PONG = 'PONG';

// SOCKET_STATES
export const SOCKET_CONNECTING = 'SOCKET_CONNECTING';
export const SOCKET_OPENED = 'SOCKET_OPENED';
export const SOCKET_CLOSING = 'SOCKET_CLOSING';
export const SOCKET_CLOSED = 'SOCKET_CLOSED';

export const SOCKET_STATES: { [key: string]: string } = {
  0: SOCKET_CONNECTING,
  1: SOCKET_OPENED,
  2: SOCKET_CLOSING,
  3: SOCKET_CLOSED,
};

// ACTIONS
export const SOCKET_COMMAND = 'SOCKET_COMMAND';
export const SOCKET_RECEIVE = 'SOCKET_RECEIVE';
export const SOCKET_RECONNECTED = 'SOCKET_RECONNECTED';
export const SOCKET_RECONNECT = 'SOCKET_RECONNECT';

export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
