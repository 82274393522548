export type IndexedObject<T> = {
  [pk: string]: T;
};

export function arrayToIndexedObject(arr: any[]): IndexedObject<any> {
  if (!arr) return {};
  return arr.reduce((p, o) => ({ ...p, [o.id]: o }), {});
}

export function arrayToIndexedObjectByTenor(arr: any[]): IndexedObject<any> {
  if (!arr) return {};
  return arr.reduce((p, o) => ({ ...p, [o.tenor]: o }), {});
}

export function addElementToIndexedArray(arr: IndexedObject<any>, obj: any) {
  Object.assign(arr, { [obj.id]: { ...(arr[obj.id] ?? {}), ...obj } });
}

export function addElementToIndexedArrayImmutable(
  // ensures re-render trigger because it returns a new object
  arr: IndexedObject<any>,
  obj: any
) {
  return {
    ...arr,
    [obj.id]: {
      ...(arr[obj.id] ?? {}),
      ...obj,
    },
  };
}

export function makeAPIProp(arr: any[]) {
  return {
    items: arrayToIndexedObject(arr),
    waiting: false,
    fetched: false,
  };
}

export function makeAPIPropByTenor(arr: any[]) {
  return {
    items: arrayToIndexedObjectByTenor(arr),
    waiting: false,
    fetched: false,
  };
}
