import {
  BUY,
  CASH_APPROACH,
  RISK_WEIGHTED_APPROACH,
  selectors,
  SELL,
  SHORT_SELL,
  utils,
} from 'state';
import { useSelector } from 'react-redux';
import usePrices from './usePrices';
import * as util from 'util';

const useMaxQuantity = (
  instrumentId: number,
  transaction: number,
  limitPrice?: number,
  orderType?: string
) => {
  const session = useSelector(selectors.activeSession);
  const {
    fx_leverage,
    bond_leverage,
    risk_weighted_limit,
    risk_approach,
    starting_cash_amount,
    reporting_currency,
    limit_order_collateral,
  } = session;

  const instrument = useSelector(
    selectors.financialInstrumentById(instrumentId)
  );
  const asset = useSelector(selectors.underlyingAssetOfInstrument(instrument));
  const player = useSelector(selectors.player);

  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);

  const assetIsBond = asset.resourcetype.endsWith('Bond');
  let { price, reportingPrice } = usePrices(instrumentId, transaction);
  if (orderType === 'LimitOrder') {
    if (limitPrice !== undefined) price = limitPrice;
    if (assetIsBond) price /= 100;
    reportingPrice = utils.cashExchange(
      asset.base_currency,
      session.reporting_currency,
      price,
      0,
      assetList,
      currencyList
    );
  }
  const remainingReportingAbsoluteBondLeverage = useSelector(
    // already divided by 100 in backend
    selectors.remainingReportingAbsoluteBondLeverage
  );

  if (!instrument || !price) {
    return 0;
  }

  const wallet = player.wallets.find(
    wallet => asset.variable_currency === wallet.currency
  );
  const reportingCurrency = currencyList.find(c => c.id === reporting_currency);
  if (!wallet || !reportingCurrency) {
    return 0;
  }

  const reporting_avg_open = utils.cashExchange(
    asset.base_currency,
    reportingCurrency.symbol,
    instrument.avg_open_price,
    0,
    assetList,
    currencyList
  );
  const reporting_wallet_amount = utils.cashExchange(
    wallet.currency,
    reportingCurrency.symbol,
    wallet.amount,
    0,
    assetList,
    currencyList
  );

  let maxQuantity = 0;
  // SELL
  if (transaction === SELL) {
    maxQuantity =
      instrument.amount > 0 ? instrument.amount - instrument.repoed_amount : 0;
  }
  // BUY
  else if (transaction === BUY && instrument.amount < 0) {
    maxQuantity = -instrument.amount;
  }

  // CASH APPROACH
  else if (risk_approach === CASH_APPROACH) {
    let reportingAssetLimit = starting_cash_amount * instrument.pv_limit;

    // FX
    if (asset.resourcetype === 'FX') {
      maxQuantity = Math.min(
        player.remaining_fx_amount_limit,
        fx_leverage * starting_cash_amount
      );
    } else if (asset.resourcetype === 'FxForwards') {
      //
      // maxQuantity = Math.min(
      //     player.remaining_reporting_absolute_hedge_amount,
      //     instrumentLimit,
      // )
    } else if (transaction === BUY) {
      // BUY
      let reportingAssetValue = Math.max(
        0,
        instrument.amount * reporting_avg_open
      );

      const remainingValue = Math.min(
        reportingAssetLimit - reportingAssetValue,
        reporting_wallet_amount
      );

      maxQuantity = remainingValue / reportingPrice;

      if (assetIsBond) {
        reportingAssetLimit = reportingAssetLimit / bond_leverage;
        reportingAssetValue /= 100;

        const remainingReportingLimit =
          reportingAssetLimit - reportingAssetValue;
        const maxByCollateral = reporting_wallet_amount / bond_leverage;
        const min = Math.min(
          remainingReportingLimit,
          maxByCollateral,
          remainingReportingAbsoluteBondLeverage
        );
        maxQuantity = min / reportingPrice;
      }

      //SHORT SELL
    } else if (transaction === SHORT_SELL) {
      let reportingAssetValue = instrument.amount * reporting_avg_open;

      const remainingValue = Math.min(
        reportingAssetLimit + reportingAssetValue,
        player.remaining_short_sell_amount_limit
      );
      maxQuantity = remainingValue / reportingPrice;
      if (assetIsBond) {
        reportingAssetValue /= 100;
        reportingAssetLimit = reportingAssetLimit / bond_leverage;
        const remainingReportingLimit =
          reportingAssetLimit + reportingAssetValue;
        const maxByCollateral = reporting_wallet_amount / bond_leverage;
        // console.log('reportingAssetLimit: ', reportingAssetLimit);
        // console.log('reportingAssetValue: ', reportingAssetValue);
        const min = Math.min(
          remainingReportingLimit,
          maxByCollateral,
          remainingReportingAbsoluteBondLeverage,
          player.remaining_short_sell_amount_limit / bond_leverage
        );
        // console.log('remainingReportingLimit: ', remainingReportingLimit);
        // console.log(
        //   'remainingReportingLimit / reportingPrice: ',
        //   remainingReportingLimit / reportingPrice
        // );
        // console.log('maxByCollateral: ', maxByCollateral / reportingPrice);
        // console.log(
        //   'remainingReportingAbsoluteBondLeverage: ',
        //   remainingReportingAbsoluteBondLeverage / reportingPrice
        // );
        // console.log(
        //   'player.remaining_short_sell_amount_limit / bond_leverage: ',
        //   player.remaining_short_sell_amount_limit /
        //     bond_leverage /
        //     reportingPrice
        // );
        // console.log('this: ', min / reportingPrice);
        maxQuantity = min / reportingPrice;
      }
    }
  }

  // RISK WEIGHT APPROACH
  else if (risk_approach === RISK_WEIGHTED_APPROACH) {
    let remaining =
      // (risk_weighted_limit - player.risk_weighted_value) / inverseExchangeRate;
      risk_weighted_limit - player.risk_weighted_value;

    if (assetIsBond) {
      remaining *= 100;
    }

    maxQuantity =
      (risk_weighted_limit * instrument.pv_limit) /
      reportingPrice /
      instrument.risk_weight;

    maxQuantity -= Math.abs(instrument.amount);

    if (remaining < maxQuantity) {
      maxQuantity = remaining;
    }
  }

  // Formatting
  maxQuantity = Math.floor(maxQuantity || 0);
  if (maxQuantity < 0) maxQuantity = 0;

  return maxQuantity;
};

export default useMaxQuantity;
