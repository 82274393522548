import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Button,
  Radio,
  InputNumber,
  Card,
  Row,
  Col,
  message,
} from 'antd';

import {
  selectors,
  BUY,
  buyForwardContract,
  SELL,
  utils,
  enterCDSContract,
  FROZEN,
  PAUSED,
} from 'state';
import { formatters } from 'services';
import { cashExchange, getFWDCurrencyPair } from '../../state/app/utils';

type props = {
  visible: boolean;
  hide: () => void;
  id: number;
};

const CDSModal: React.FC<props> = ({ visible, hide, id }) => {
  const dispatch = useDispatch();
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);

  const session = useSelector(selectors.activeSession);
  const { transaction_fee, risk_approach } = session;

  const financialInstrument = useSelector(
    selectors.financialInstrumentById(id)
  );
  const underlyingAsset = useSelector(
    selectors.underlyingAssetOfInstrument(financialInstrument)
  );
  const asset_variable_currency = useSelector(
    selectors.currencyById(underlyingAsset.variable_currency)
  );
  const asset_base_currency = useSelector(
    selectors.currencyById(underlyingAsset.base_currency)
  );

  const [quantity, setQuantity] = useState(100);

  const player = useSelector(selectors.player);
  const [transaction, setTransaction] = useState(BUY);

  const reportingCurrency = useSelector(
    selectors.currencyById(session.reporting_currency)
  );
  const price = transaction === BUY ? underlyingAsset.ask : underlyingAsset.bid;

  console.log('cds price: ', price);

  let maxQuantity;

  const assetClassLimit =
    financialInstrument.pv_limit * session.starting_cash_amount;
  if (asset_base_currency === reportingCurrency) {
    if (transaction === BUY) {
      console.log('financialInstrument.amount: ', financialInstrument.amount);
      if (financialInstrument.amount < 0) {
        maxQuantity = -financialInstrument.amount;
      } else {
        let remainingLimit = assetClassLimit - financialInstrument.amount;
        if (remainingLimit < 0) remainingLimit = 0;
        maxQuantity = remainingLimit;
      }
    } else {
      if (financialInstrument.amount > 0) {
        maxQuantity = financialInstrument.amount;
      } else {
        let remainingLimit = assetClassLimit + financialInstrument.amount;
        if (remainingLimit < 0) remainingLimit = 0;
        maxQuantity = remainingLimit;
      }
    } // change for redeploy
  } else {
    const assetClassLimitNonReporting = cashExchange(
      session.reporting_currency,
      underlyingAsset.base_currency,
      assetClassLimit,
      0,
      assetList,
      currencyList
    );
    let remainingLimitNonReporting =
      assetClassLimitNonReporting - financialInstrument.amount;
    if (remainingLimitNonReporting < 0) remainingLimitNonReporting = 0;
    maxQuantity = remainingLimitNonReporting;
  }

  const { name } = underlyingAsset;

  const wallet = player.wallets.find(
    wallet => underlyingAsset.variable_currency === wallet.currency
  );

  if (!wallet) {
    message.error('Wallet not found!');
    return null;
  }

  const sign = transaction === BUY ? 1 : -1;

  return (
    <Modal
      visible={visible}
      title={`${name} CDS Contract`}
      onOk={hide}
      onCancel={hide}
      width={600}
      footer={[
        <Button
          key="place"
          type="primary"
          disabled={
            quantity <= 0 ||
            quantity > maxQuantity ||
            session.status === FROZEN ||
            session.status === PAUSED
          }
          onClick={() => {
            dispatch(
              enterCDSContract({
                cds_instrument_id: financialInstrument.id,
                amount: sign * quantity,
                // transaction: transaction,
              })
            );
            console.log('amount: ', sign * quantity);
            hide();
          }}
        >
          {transaction === BUY ? 'Buy CDS' : 'Sell CDS'}
        </Button>,
      ]}
    >
      Transaction:{' '}
      <Radio.Group
        onChange={e => setTransaction(e.target.value)}
        value={transaction}
      >
        <Radio value={BUY}>Buy</Radio>
        <Radio value={SELL}>Short</Radio>
      </Radio.Group>
      <br />
      CDS spread: (bp) {/*{selectedAsset.resourcetype !== 'FX'*/}
      {/*  ? formatters.commas2Digits(price)*/}
      {/*  : formatters.commas4Digits(price)}*/}
      {/*{formatters.priceFormat(*/}
      {/*  price,*/}
      {/*  underlyingAsset.ticker,*/}
      {/*  underlyingAsset.resourcetype*/}
      {/*)}*/}
      {price}
      <br />
      Amount:{' '}
      <InputNumber
        value={quantity}
        min={0}
        max={maxQuantity}
        style={{ width: 150 }}
        formatter={formatters.commasNoDigits}
        // parser={parseNumber}
        onChange={val => setQuantity(val || 0)}
      />
      {`/ ${formatters.commasNoDigits(maxQuantity)}`}
      <br />
      {/*<Card>*/}
      {/*  <Row justify="center">*/}
      {/*    <h3>Order Summary</h3>*/}
      {/*  </Row>*/}
      {/*  <Row justify="space-between" align="middle">*/}
      {/*    <Col>*/}
      {/*      Quantity*/}
      {/*      <br />*/}
      {/*      {formatters.commasNoDigits(quantity)}*/}
      {/*    </Col>*/}
      {/*    <Col>*/}
      {/*      Spot*/}
      {/*      <br />*/}
      {/*      {formatters.priceFormat(*/}
      {/*        spot,*/}
      {/*        underlyingAsset.ticker,*/}
      {/*        underlyingAsset.resourcetype*/}
      {/*      )}*/}
      {/*    </Col>*/}
      {/*    <Col>*/}
      {/*      Swap points*/}
      {/*      <br />*/}
      {/*      {formatters.priceFormat(*/}
      {/*        swapPoints,*/}
      {/*        underlyingAsset.ticker,*/}
      {/*        underlyingAsset.resourcetype*/}
      {/*      )}*/}
      {/*    </Col>*/}
      {/*    <Col>*/}
      {/*      Value*/}
      {/*      <br />*/}
      {/*      {formatters.commasNoDigits(value)}*/}
      {/*    </Col>*/}
      {/*    <Col>*/}
      {/*      Fees**/}
      {/*      <br />*/}
      {/*      {formatters.percent1Digit(transaction_fee)}*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*  <br />*/}
      {/*  <Row justify={'center'}>*/}
      {/*    <Col>*/}
      {/*      Total*/}
      {/*      <br />*/}
      {/*      {formatters.commasNoDigits(total)}*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*  /!*<br />*!/*/}
      {/*  /!*{risk_approach === CASH_APPROACH && (*!/*/}
      {/*  /!*  <Row>Available Cash: {formatters.commasNoDigits(wallet.amount)}</Row>*!/*/}
      {/*  /!*)}*!/*/}
      {/*</Card>*/}
    </Modal>
  );
};

export default CDSModal;
