import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputNumber, Table, Button, Typography, Tooltip, Empty } from 'antd';

import {
  AFS_GROUP,
  ALTERNATIVES_GROUP,
  AssetGroupAllocationPayload,
  BONDS_GROUP,
  CASH_APPROACH,
  CASH_GROUP,
  createUpdateGroupAllocations,
  CRYPTO_GROUP,
  EQUITIES_GROUP,
  FX_GROUP,
  GROUP_ASSET_NAME,
  HTM_GROUP,
  selectors,
} from 'state';
import { red } from '@ant-design/colors';
import { InfoCircleOutlined } from '@ant-design/icons';

type State = {
  [assetGroup: number]: AssetGroupAllocationPayload;
};

const AssetClassAllocations: React.FC = () => {
  const dispatch = useDispatch();
  const { asset_group_allocations, risk_approach } = useSelector(
    selectors.activeSession
  );
  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [allocations, setAllocations] = useState<State>({});

  const hasEquitiesGroup = useSelector(selectors.hasEquitiesGroup());
  const hasBondsGroup = useSelector(selectors.hasBondsGroup());
  const hasAlternativesGroup = useSelector(selectors.hasAlternativesGroup());
  const hasCryptoGroup = useSelector(selectors.hasCryptoGroup());
  const hasHTMGroup = useSelector(selectors.hasHTMGroup());
  const hasAFSGroup = useSelector(selectors.hasAFSGroup());

  const assetGroups = { ...GROUP_ASSET_NAME };
  // FX no longer taken into consideration in allocations table
  delete assetGroups[FX_GROUP];
  if (risk_approach !== CASH_APPROACH) {
    delete assetGroups[CASH_GROUP];
  }
  if (!hasEquitiesGroup) {
    delete assetGroups[EQUITIES_GROUP];
  }
  if (!hasBondsGroup) {
    delete assetGroups[BONDS_GROUP];
  }
  if (!hasAlternativesGroup) {
    delete assetGroups[ALTERNATIVES_GROUP];
  }
  if (!hasCryptoGroup) {
    delete assetGroups[CRYPTO_GROUP];
  }
  if (!hasHTMGroup) {
    delete assetGroups[HTM_GROUP];
  }
  if (!hasAFSGroup) {
    delete assetGroups[AFS_GROUP];
  }

  const reset = () => {
    const newAllocations = {} as State;
    asset_group_allocations.forEach(a => {
      newAllocations[a.asset_group] = {
        id: a.id,
        asset_group: a.asset_group,
        lower_limit: a.lower_limit,
        upper_limit: a.upper_limit,
      };
    });
    setAllocations(newAllocations);
    setErrors([]);
  };

  useEffect(reset, [asset_group_allocations]);
  const dataSource = Object.entries(assetGroups).map(([key, name]) => {
    const assetGroup = parseInt(key);
    const allocation = asset_group_allocations.find(
      a => a.asset_group === assetGroup
    );

    return { name, assetGroup, allocation };
  });

  const getProps = (field: 'lower_limit' | 'upper_limit') => {
    return {
      render(_: unknown, record: typeof dataSource[0]) {
        if (editing) {
          const assetGroup = record.assetGroup;

          const allocation = allocations[assetGroup];
          const limit = allocation?.[field] ?? 0;

          return (
            <div>
              <InputNumber
                value={limit}
                style={{ width: 70 }}
                onChange={value => {
                  if (value === undefined) {
                    return;
                  }
                  setAllocations({
                    ...allocations,
                    [assetGroup]: {
                      ...allocation,
                      asset_group: assetGroup,
                      [field]: value,
                    },
                  });
                }}
                step={0.01}
                min={0}
                max={1}
                formatter={value => {
                  const nr = Number(value) || 0;
                  return `${Math.round(nr * 100)}%`;
                }}
                parser={value => {
                  const parsedValue = `${value?.replaceAll('%', '')}`;
                  const nr = Number(parsedValue);
                  if (!isNaN(nr)) {
                    return (nr / 100).toFixed(2);
                  }
                  return '';
                }}
              />
            </div>
          );
        }

        if (!record.allocation) {
          return <div>-</div>;
        }

        return <div>{Math.round(record.allocation[field] * 100)}%</div>;
      },
    };
  };

  const isValid = () => {
    const errorsList = [];

    const valuesAscending = Object.values(allocations).reduce(
      (acc, { lower_limit, upper_limit }) => {
        // Skip undefined cells
        if (!lower_limit && !upper_limit) {
          return acc;
        }
        return acc && (lower_limit ?? 0) < (upper_limit ?? 0);
      },
      true
    );

    if (!valuesAscending) {
      errorsList.push(
        'Lower limit values must be lesser than upper limit values!'
      );
    }

    if (errorsList.length) {
      setErrors(errorsList);
      return false;
    }

    return true;
  };

  const editButton = (
    <div
      style={{ position: 'relative', display: 'flex', gap: '10px', height: 30 }}
    >
      {!editing && (
        <Button
          onClick={() => {
            setEditing(true);
          }}
          style={{ position: 'absolute', right: 0 }}
        >
          Edit
        </Button>
      )}
      {editing && (
        <>
          <Button
            type="primary"
            onClick={() => {
              if (!isValid()) {
                return;
              }

              setEditing(false);
              dispatch(
                createUpdateGroupAllocations({
                  allocations: Object.values(allocations),
                })
              );
            }}
            style={{ position: 'absolute', right: 90 }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setEditing(false);
              reset();
            }}
            style={{ position: 'absolute', right: 0 }}
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  );
  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          Select and save some instruments.
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  return (
    <div style={{ minWidth: 250 }}>
      <Typography.Title level={4} style={{ display: 'inline-block' }}>
        Asset Class Allocations
      </Typography.Title>
      <Tooltip
        overlayStyle={{ maxWidth: '500px' }}
        title={
          <div>
            <div>
              Setting asset class allocations does not enforce hard limits.
            </div>
            <div>
              Allocations inform the portfolio managers(players) about the
              desired portfolio allocation scheme.
            </div>
            <br />
            <ul>
              <li>Equities: Equities, ETF Equities</li>
              <li>Bonds: Govt Bonds, Corporate Bonds, ETF Bonds</li>
              <li>Alternatives: Metals, ETF Commodities</li>
            </ul>
            <div>
              (red: over allocated, yellow: under allocated, green: within
              guidelines)
            </div>
          </div>
        }
      >
        <InfoCircleOutlined
          style={{
            display: 'inline-block',
            marginLeft: 10,
            // position: 'absolute',
            // marginLeft: 10,
            // top: 7,
          }}
        />
      </Tooltip>
      <Table
        rowKey="assetGroup"
        dataSource={dataSource}
        columns={[
          { title: 'Asset Class', dataIndex: 'name' },
          {
            title: 'Guidelines',
            children: [
              {
                title: 'Lower',
                ...getProps('lower_limit'),
              },
              { title: 'Upper', ...getProps('upper_limit') },
            ],
          },
        ]}
        locale={noDataBehaviour}
        pagination={false}
        bordered
        footer={() => editButton}
      />

      {editing && !!errors.length && (
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {errors.map(e => {
            return (
              <div key={e} style={{ color: red[5] }}>
                {e}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AssetClassAllocations;
